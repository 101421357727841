(function () {
  'use strict';

  angular
    .module('managenetwork')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.managenetwork', {
        url: '/managenetwork/:venueid/:orgid',
        templateUrl: 'managenetwork/views/managenetwork.tpl.html',
        controller: 'ManagenetworkCtrl',
        controllerAs: 'managenetwork'
      });
  }
}());
