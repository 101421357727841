(function () {
  'use strict';

  angular
    .module('editclstype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editclstype', {
        url: '/editclstype/:typeid',
        templateUrl: 'editclstype/views/editclstype.tpl.html',
        controller: 'EditclstypeCtrl',
        controllerAs: 'editclstype'
      });
  }
}());
