(function () {
  'use strict';

  angular
    .module('venues')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.venues', {
        url: '/venues/:orgid',
        templateUrl: 'venues/views/venues.tpl.html',
        controller: 'VenuesCtrl',
        controllerAs: 'venues'
      });
  }
}());
