(function () {
  'use strict';

  angular
    .module('editrole')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editrole', {
        url: '/editrole/:roleid',
        templateUrl: 'editrole/views/editrole.tpl.html',
        controller: 'EditroleCtrl',
        controllerAs: 'editrole'
      });
  }
}());
