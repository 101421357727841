(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrganisationsCtrl
   *
   * @description
   *
   */
  angular
    .module('organisations')
    .controller('OrganisationsCtrl', OrganisationsCtrl);

  function OrganisationsCtrl($state, Organisation, $modal) {
    var vm = this;
    vm.ctrlName = 'OrganisationsCtrl';

    vm.organisationsLoaded = false;
    vm.organisationsLoadedError = false;
    vm.organisations = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Organisation.getAllOrganisations().success(function (data) {
      vm.organisations = data.orgs;
      vm.organisationsLoaded = true;
    }).error(function (err) {
      vm.organisationsLoadedError = true;
      vm.organisationsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllOrganisations() {
      Organisation.getAllOrganisations().success(function (data) {
        vm.organisations = data.orgs;
        vm.organisationsLoaded = true;
      }).error(function (err) {
        vm.organisationsLoadedError = true;
        vm.organisationsLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'organisations/views/orgdeletemodal.tpl.html',
        controller: 'OrgdeletemodalCtrl as orgdeleteModal'
      });

      modalInstance.result.then(function () {
        Organisation.deleteOrganisation(id).success(function () {
          reloadAllOrganisations();
        }).error(function (err) {
          console.log('Error: ', err);
          if(err.message.code === '23503'){
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Organisation! One or more operator(s) depend on this Organisation.';
          }
          else{
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
