(function () {
  'use strict';

  angular
    .module('editzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editzone', {
        url: '/editzone/:zoneid/:venueid/:orgid/:provider',
        templateUrl: 'editzone/views/editzone.tpl.html',
        controller: 'EditzoneCtrl',
        controllerAs: 'editzone'
      });
  }
}());
