(function () {
  'use strict';

  angular
    .module('newvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newvenue', {
        url: '/newvenue/:orgid',
        templateUrl: 'newvenue/views/newvenue.tpl.html',
        controller: 'NewvenueCtrl',
        controllerAs: 'newvenue'
      });
  }
}());
