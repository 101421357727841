(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name newvenue.controller:NewvenueCtrl
   *
   * @description
   *
   */
  angular
    .module('newvenue')
    .controller('NewvenueCtrl', NewvenueCtrl);

  function NewvenueCtrl(Infrastructureapi, $modal, $state, $stateParams) {
    var vm = this;
    vm.venue = {};
    vm.submitted = false;
    var orgid = $stateParams.orgid;

    vm.status = {
      isopen: false
    };

    vm.updateSelection = function(position, images) {
      angular.forEach(images, function(image, index) {
        if (position !== index) {
          image.checked = false;
        } else {
          vm.venue.imageUrl = image.imageuri;
          vm.venue.imageId = image.id;
        }
      });
    };

    vm.selectImage = function() {

      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.venue.imageUrl = thisImage.imageuri;
        vm.venue.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageUrl = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.createVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        Infrastructureapi.createVenue(orgid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues', {
            orgid: orgid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };

    Infrastructureapi.getImages(orgid).success(function(data) {
      vm.imageList = data.images;
      for (var i = 0; i < vm.imageList.length; i++) {
        vm.imageList[i].checked = false;
      }
      console.log(vm.imageList);
    });

  }
}());
