(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('organisations')
    .controller('OrgInfradeletemodalCtrl', OrgInfradeletemodalCtrl);

  function OrgInfradeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OrgInfradeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
