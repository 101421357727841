<div id="newVenue">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit
        {{editvenue.venue.venueTitle}}
        Venue</h3>
    </div>
    <div class="panel-body">
      <form name="createVenue" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>VENUE NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.venuename.$invalid && editvenue.submitted }">
              <input type="text" class="form-control" id="venuename" name="venuename" ng-model="editvenue.venue.venueTitle" placeholder="eg. Some Venue" required>
            </div>
            <div class="help-block" ng-messages="createVenue.venuename.$error" ng-if="editvenue.submitted">
              <p ng-message="required">venue name is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>VENUE IMAGE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-md-offset-4">
                  <img ng-src="{{staticUrl}}{{editvenue.venue.imageuri}}" class="img-responsive"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-danger" ng-click="editvenue.selectImage()">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-danger" ng-click="editvenue.uploadImage()">
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="help-block" ng-show="!editvenue.venue.imageUrl && editvenue.submitted">
              <p>Image is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>EMAIL</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.contactEmail.$invalid && editvenue.submitted }">
              <input type="email" class="form-control" id="contactEmail" name="contactEmail" ng-model="editvenue.venue.contactEmail" placeholder="eg. name@email.com" required>
            </div>
            <div class="help-block" ng-messages="createVenue.contactEmail.$error" ng-if="editvenue.submitted">
              <p ng-message="required">email is required.</p>
              <p ng-message="email">This needs to be a valid email</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>PHONE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.contactPhone.$invalid && editvenue.submitted }">
              <input type="text" class="form-control" id="contactPhone" name="contactPhone" ng-model="editvenue.venue.contactPhone" placeholder="eg. 081 555 556" required>
            </div>
            <div class="help-block" ng-messages="createVenue.contactPhone.$error" ng-if="editvenue.submitted">
              <p ng-message="required">phone is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.description.$invalid && editvenue.submitted }">
              <input type="text" class="form-control" id="description" name="description" ng-model="editvenue.venue.description" placeholder="eg. description ..." required>
            </div>
            <div class="help-block" ng-messages="createVenue.description.$error" ng-if="editvenue.submitted">
              <p ng-message="required">description is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>LOCATION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.location.$invalid && editvenue.submitted }">
              <input type="text" class="form-control" id="location" name="location" ng-model="editvenue.venue.location" placeholder="eg. Address, County" required>
            </div>
            <div class="help-block" ng-messages="createVenue.location.$error" ng-if="editvenue.submitted">
              <p ng-message="required">location is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>WEBSITE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createVenue.website.$invalid && editvenue.submitted }">
              <input type="text" class="form-control" id="website" name="website" ng-model="editvenue.venue.website" placeholder="eg. someurl.com" required>
            </div>
            <div class="help-block" ng-messages="createVenue.website.$error" ng-if="editvenue.submitted">
              <p ng-message="required">website is required.</p>
            </div>
          </div>
        </div>
        <button ng-click="editvenue.updateVenue(!createVenue.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
