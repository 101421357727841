<div id="mailchimp">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">New Mailchimp List</h3>
    </div>
    <div class="panel-body">
      <form name="createNewList" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>List Details</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createNewList.name.$invalid && newlist.submitted }">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" name="name" ng-model="newlist.list.name" placeholder="name" required>
            </div>
            <div class="help-block" ng-messages="createNewList.name.$error" ng-if="newlist.submitted">
              <p ng-message="required">Name is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.company.$invalid && newlist.submitted }">
              <label for="company">Company</label>
              <input type="text" class="form-control" id="company" name="company" ng-model="newlist.list.company" placeholder="company" required>
            </div>
            <div class="help-block" ng-messages="createNewList.company.$error" ng-if="newlist.submitted">
              <p ng-message="required">Company is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.address1.$invalid && newlist.submitted }">
              <label for="address1">Address Line 1</label>
              <input type="text" class="form-control" id="address1" name="address1" ng-model="newlist.list.address1" placeholder="address1" required>
            </div>
            <div class="help-block" ng-messages="createNewList.address1.$error" ng-if="newlist.submitted">
              <p ng-message="required">Address Line 1 is required.</p>
            </div>
            <div class="form-group">
              <label for="address2">Address Line 2</label>
              <input type="text" class="form-control" id="address2" name="address2" ng-model="newlist.list.address2" placeholder="address2">
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.city.$invalid && newlist.submitted }">
              <label for="city">City</label>
              <input type="text" class="form-control" id="city" name="city" ng-model="newlist.list.city" placeholder="city" required>
            </div>
            <div class="help-block" ng-messages="createNewList.city.$error" ng-if="newlist.submitted">
              <p ng-message="required">City is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.state.$invalid && newlist.submitted }">
              <label for="state">State</label>
              <input type="text" class="form-control" id="state" name="state" ng-model="newlist.list.state" placeholder="state" required>
            </div>
            <div class="help-block" ng-messages="createNewList.state.$error" ng-if="newlist.submitted">
              <p ng-message="required">State is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.zip.$invalid && newlist.submitted }">
              <label for="state">Zip</label>
              <input type="text" class="form-control" id="zip" name="zip" ng-model="newlist.list.zip" placeholder="zip" required>
            </div>
            <div class="help-block" ng-messages="createNewList.zip.$error" ng-if="newlist.submitted">
              <p ng-message="required">Zip is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.country.$invalid && newlist.submitted }">
              <label for="state">Country</label>
              <input type="text" class="form-control" id="country" name="country" ng-model="newlist.list.country" placeholder="country" required>
            </div>
            <div class="help-block" ng-messages="createNewList.country.$error" ng-if="newlist.submitted">
              <p ng-message="required">Country is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.permissionReminder.$invalid && newlist.submitted }">
              <label for="permissionReminder">Permission Reminder</label>
              <input type="text" class="form-control" id="permissionReminder" name="permissionReminder" ng-model="newlist.list.permissionReminder" placeholder="e.g. You are receiving this email because you signed up to xyz" required>
            </div>
            <div class="help-block" ng-messages="createNewList.permissionReminder.$error" ng-if="newlist.submitted">
              <p ng-message="required">Permission Reminder is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.campaignFromName.$invalid && newlist.submitted }">
              <label for="campaignFromName">Campaign From Name</label>
              <input type="text" class="form-control" id="campaignFromName" name="campaignFromName" ng-model="newlist.list.campaignFromName" placeholder="Campaign From Name" required>
            </div>
            <div class="help-block" ng-messages="createNewList.campaignFromName.$error" ng-if="newlist.submitted">
              <p ng-message="required">Campaign From Name is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.campaignFromEmail.$invalid && newlist.submitted }">
              <label for="campaignFromEmail">Campaign From Email</label>
              <input type="text" class="form-control" id="campaignFromEmail" name="campaignFromEmail" ng-model="newlist.list.campaignFromEmail" placeholder="Campaign From Email" required>
            </div>
            <div class="help-block" ng-messages="createNewList.campaignFromEmail.$error" ng-if="newlist.submitted">
              <p ng-message="required">Campaign From Email is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.campaignSubject.$invalid && newlist.submitted }">
              <label for="campaignSubject">Campaign Subject</label>
              <input type="text" class="form-control" id="campaignSubject" name="campaignSubject" ng-model="newlist.list.campaignSubject" placeholder="Campaign Subject" required>
            </div>
            <div class="help-block" ng-messages="createNewList.campaignSubject.$error" ng-if="newlist.submitted">
              <p ng-message="required">Campaign Subject is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.campaignLanguage.$invalid && newlist.submitted }">
              <label for="campaignLanguage">Campaign Language</label>
              <input type="text" class="form-control" id="campaignLanguage" name="campaignLanguage" ng-model="newlist.list.campaignLanguage" placeholder="Campaign Language" required>
            </div>
            <div class="help-block" ng-messages="createNewList.campaignLanguage.$error" ng-if="newlist.submitted">
              <p ng-message="required">Campaign Language is required.</p>
            </div>
            <div class="form-group" ng-class="{ 'has-error': createNewList.campaignLanguage.$invalid && newlist.submitted }">
              <label for="emailTypeOption">Email Type Option</label>
              <select name="emailTypeOption" class="form-control" ng-model="newlist.list.emailTypeOption" required>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div class="help-block" ng-messages="createNewList.emailTypeOption.$error" ng-if="newlist.submitted">
              <p ng-message="required">Email Type Option is required.</p>
            </div>
            <button ng-click="newlist.createList(!newList.createNewList.$invalid)" class="btn btn-danger right">Create List</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
