(function () {
  'use strict';

  angular
    .module('editsplashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editsplashtype', {
        url: '/editsplashtype/:typeid',
        templateUrl: 'editsplashtype/views/editsplashtype.tpl.html',
        controller: 'EditsplashtypeCtrl',
        controllerAs: 'editsplashtype'
      });
  }
}());
