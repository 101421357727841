<div id="editoperator">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
        <h3 class="text-center">Update Operator</h3>
    </div>
    <div class="panel-body">
      <form name="createOperator" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>OPERATOR NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.operatorname.$invalid && editoperator.submitted }">
              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="editoperator.operator.name" placeholder="eg. First Last" required>
            </div>
            <div class="help-block" ng-messages="createOperator.operatorname.$error" ng-if="editoperator.submitted">
              <p ng-message="required">operator name is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>LOGIN</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.username.$invalid && editoperator.submitted }">
              <input type="email" class="form-control" id="username" name="username" ng-model="editoperator.operator.login" placeholder="eg. somename@someemail.com" required>
            </div>
            <div class="help-block" ng-messages="createOperator.username.$error" ng-if="editoperator.submitted">
              <p ng-message="required">login is required.</p>
              <p ng-message="email">This needs to be a valid email</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ROLE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.role.$invalid && editoperator.submitted }">
              <select id="role" name="role" class="form-control" ng-model="editoperator.operator.role" ng-options="role.id as role.name for role in editoperator.roles" required>
                <option value="">Select A Role</option>
              </select>
            </div>
            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="editoperator.submitted">
              <p ng-message="required">role is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editoperator.operator.role == 2">
          <div class="panel-heading clearfix">
            <h4>Venue</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.role.$invalid && newoperator.submitted }">
              <select id="venue" name="venue" class="form-control" ng-model="editoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in editoperator.venues" ng-change="editoperator.setVenue(editoperator.operator.details.venue)">
                <option value="">Select A Venue</option>
              </select>
            </div>

            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">
              <p ng-message="required">role is required.</p>
            </div>
          </div>
        </div>
        <button ng-click="editoperator.updateOperator(!createOperator.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
