<div>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit Admin</h3>
    </div>
    <div class="panel-body">
      <div ng-show="editadmin.ErrorMessage">
        <div class="alert alert-danger" role="alert" style="width: 40%;">
          <button ng-click="editadmin.dismiss()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{editadmin.Message}}
        </div>
      </div>
      <form name="form" ng-submit="form.$valid && editadmin.updateAdmin()" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Login</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="form-group">
                <input type="text" class="form-control" id="login" name="login" placeholder="Login" ng-model="editadmin.adminDetails.login" required>
              </div>
              <div ng-show="form.login.$invalid">
                <p>Login is required!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="editadmin.adminDetails.name" required>
              <div ng-show="form.name.$invalid">
                <p>Name is required!</p>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-danger btn-md">Submit</button>
      </form>
    </div>
  </div>
</div>
