(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:GnsApi
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('GnsApi', GnsApi);

  function GnsApi(baseurl, $http, Auth) {
    var GnsApiBase = {},
      token = '';
    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }
    GnsApiBase.someValue = 'GnsApi';

    GnsApiBase.getGNS = function () {
      return $http.get(baseurl + '/gns', {
        headers: {
          'x-access-token': token
        }
      });
    };

    GnsApiBase.createGNSType = function (data) {
      return $http.post(baseurl + '/gns/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    GnsApiBase.getGNSById = function (id) {
      return $http.get(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    GnsApiBase.getGNSByProvider = function (id) {
      return $http.get(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    GnsApiBase.udpateGNS = function (id, data) {
      return $http.put(baseurl + '/gns/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    GnsApiBase.deleteGNS = function (id) {
      return $http.delete(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return GnsApiBase;
  }
}());
