(function () {
  'use strict';

  angular
    .module('splashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.splashtype', {
        url: '/splashtype',
        templateUrl: 'splashtype/views/splashtype.tpl.html',
        controller: 'SplashtypeCtrl',
        controllerAs: 'splashtype'
      });
  }
}());
