<div id="wrapper" ng-class="{'toggled' : toggle}">
  <!-- Sidebar -->
  <div id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <li class="sidebar-header">
        <a data-ui-sref="admin.admins">
          <div class="row">
            <img class="modal-img" src="./images/login/liquidedgelogodark.png">
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.admins">
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-user"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Admins
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.categories">
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-wifi"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Network Opt
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.organisations" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-sitemap"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Organisations
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.organisationroles" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-users"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Organisation Roles
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.leimages" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-picture-o"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Images
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.sociallogins" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-twitter"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Social Login Types
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.analytics" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-bar-chart"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Analytics
            </div>
          </div>
        </a>
      </li>
      <li>
        <a data-ui-sref="admin.cloudservicetypes" >
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-cloud"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Cloud Service Types
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="navbar navbar-default navbar-static-top container-fluid">
      <div class="container-fluid">
        <div class="navbar-header">
          <a class="navbar-brand" data-ng-init='toggle = false' ng-click="toggle = !toggle" href="">
            <i class="fa fa-bars"></i>
          </a>
        </div>
        <ul class="nav navbar-nav navbar-right">
          <li dropdown is-open="it.isOpen" class="dropdownWidth">
            <a href="" dropdown-toggle ng-disabled="isDisabled" role="button">{{admin.profileInfo.userName}} <span class="caret"></span>
            </a>
            <ul class="dropdown-menu options-menu">
              <li role="separator" class="divider"></li>
              <li>
                <a href="" ng-click="admin.logout()">
                  <i class="fa fa-sign-out"></i> Log Out</a>
              </li>
              <li role="separator" class="divider"></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <div data-ui-view></div>
    </div>
  </div>
</div>
