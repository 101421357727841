(function () {
  'use strict';

  angular
    .module('networktype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.networktype', {
        url: '/networktype/:venueid/:orgid/:provider',
        templateUrl: 'networktype/views/networktype.tpl.html',
        controller: 'NetworktypeCtrl',
        controllerAs: 'networktype'
      });
  }
}());
