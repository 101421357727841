(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addprovider.controller:AddproviderCtrl
   *
   * @description
   *
   */
  angular
    .module('addprovider')
    .controller('AddproviderCtrl', AddproviderCtrl);

  function AddproviderCtrl($state, Provider) {
    var vm = this;
    vm.ctrlName = 'AddproviderCtrl';

    vm.ErrorMessage = false;
    vm.providerDetails = [];
    vm.Message = [];

    vm.createProvider = function () {
      var data = JSON.stringify({
        name: vm.providerDetails.name,
        details: vm.providerDetails.details
      });
      Provider.createProvider(data).success(function () {
        $state.go('admin.providers');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
