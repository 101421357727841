<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <h4>Are you sure you want to delete this Service?</h4>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button"
  ng-click="clservicedeleteModal.ok()">Delete</button>
  <button class="btn btn-warning" type="button"
  ng-click="clservicedeleteModal.cancel()">Cancel</button>
</div>
