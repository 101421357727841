(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:imageupload
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('imageupload', 'https://api.liquidedge.eu/admin-api/admin-web/v1');
}());
