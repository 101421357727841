(function () {
  'use strict';

  angular
    .module('editadmin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editadmin', {
        url: '/editadmin/:adminid',
        templateUrl: 'editadmin/views/editadmin.tpl.html',
        controller: 'EditadminCtrl',
        controllerAs: 'editadmin'
      });
  }
}());
