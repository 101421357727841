(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name splashtype.controller:SplashtypeCtrl
   *
   * @description
   *
   */
  angular
    .module('splashtype')
    .controller('SplashtypeCtrl', SplashtypeCtrl);

  function SplashtypeCtrl(Splashtype, $log, $modal, Provider) {
    var vm = this;

    vm.splashtypes = [];

    function getSplashTypes() {
      Splashtype.getAll().then(function (resp) {
        vm.splashtypes = resp.data.splashTypes;
        $log.info(resp.data.splashTypes);
      }, function (err) {
        $log.info(err);
      });
    }

    vm.deleteSplashType = function (id) {
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Splashpage type, are you sure?'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Splashtype.deleteSplashType(id).then(function () {
          getSplashTypes();
        }, function (err) {
          $log.info(err);
        });
      });
    };

    vm.setProviders = function (id) {

      Splashtype.getProviders(id).success(function (result) {
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/setprovidersmodal.tpl.html',
          controller: 'SetProviderModalCtrl as setprovidersmodal',
          resolve: {
            providers: function(){ return result.providers;}
          }
        });
        modalInstance.result.then(function (list) {
          var data = {
            providers: list
          };
          Splashtype.setProviders(id, data).success(function (result) {
            console.log(result);
          });
        }, function (err) {
          console.log(err);
        });
      });



    };

    getSplashTypes();
  }
}());
