<div ng-show="providers.ErrorMessage">
  <div class="alert alert-danger" role="alert">
    <button ng-click="providers.dismiss()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span>
    {{providers.Message}}
  </div>
</div>
<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h2>Providers
        <button class="btn btn-md btn-md btn-danger pull-right" data-ui-sref="admin.addprovider" tooltip-placement="left" tooltip="Add Provider">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h2>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation">
            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>
          </li>
          <li role="presentation" class="active">
            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search Provider" ng-model="providerSearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <thead>
        <tr>
          <th>Name</th>
          <th>Details</th>
          <th></th>
        </tr>
      </thead>
      <tbody dir-paginate="provider in providers.providers | filter:providerSearch | itemsPerPage: 10">
        <tr>
          <td>
            <h4>{{provider.name}}</h4>
          </td>
          <td>
            <h4>{{provider.details}}</h4>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-md btn-danger btn-md" ng-click="providers.deleteEvent(provider.id)" tooltip-placement="left" tooltip="Delete Provider">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <a class="btn btn-md btn-danger btn-md" ui-sref="admin.editprovider({providerid: provider.id})" tooltip-placement="left" tooltip="Edit Provider">
                <i class="fa fa-pencil-square-o"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
  </div>
</div>
