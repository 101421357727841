(function () {
  'use strict';

  angular
    .module('addslt')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addslt', {
        url: '/addslt',
        templateUrl: 'addslt/views/addslt.tpl.html',
        controller: 'AddsltCtrl',
        controllerAs: 'addslt'
      });
  }
}());
