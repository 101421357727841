<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Social Login Types
        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addslt" tooltip-placement="left" tooltip="Add Social Login">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-3 col-md-offset-9">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search Social Login" ng-model="socialLoginSearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr dir-paginate="slt in sociallogins.slts | filter:socialLoginSearch | itemsPerPage: 10">
          <td class="venueIMG">
            <div>
              <img ng-src="{{staticUrl}}{{slt.imageuri}}" class="img-responsive"/>
            </div>
          </td>
          <td>
            <h4>Name:</h4>
            <p>{{slt.name}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="sociallogins.deleteEvent(slt.id)" tooltip-placement="left" tooltip="Delete Social Login">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <a class="btn btn-danger btn-md" ui-sref="admin.editslt({sltid: slt.id})" tooltip-placement="left" tooltip="Edit Social Login">
                <i class="fa fa-pencil-square-o"></i>
              </a>
              <a class="btn btn-danger btn-md" ng-click="sociallogins.setProviders(slt.id)" tooltip-placement="left" tooltip="Select Providers">
                <i class="fa fa-list"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
  </div>
</div>
