(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservicetypes.factory:Cloudservicetype
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Cloudservicetype', Cloudservicetype);

  function Cloudservicetype(baseurl, $http, Auth) {
    var CloudservicetypeBase = {},
      token = '';
    CloudservicetypeBase.someValue = 'Cloudservicetype';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicetypeBase.getCloudServiceTypes = function () {
      return $http.get(baseurl + '/cloudservicetypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.createCloudServiceType = function (data) {
      return $http.post(baseurl + '/cloudservicetypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.getCloudServiceTypeById = function (id) {
      return $http.get(baseurl + '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.udpateCloudServiceType = function (id, data) {
      return $http.put(baseurl + '/cloudservicetypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.deleteCloudServiceType = function (id) {
      return $http.delete(baseurl + '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicetypeBase;
  }
}());
