<h2>Edit Strategy</h2>
<div ng-show="editcategory.ErrorMessage">
  <div class="alert alert-danger" role="alert" style="width: 40%;">
    <button ng-click="editcategory.dismiss()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span>
    {{editcategory.Message}}
  </div>
</div>
<form name="form" role="form" novalidate>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="form-group">
        <div class="col-xs-4">
          <label for="name" class="control-label pull-left">Name</label>
          <input type="text" class="form-control" id="name" name="name" placeholder="name" ng-model="editcategory.categoryDetails.name" required>
        </div>
        <div class="col-xs-4">
          <label for="description" class="control-label pull-left">Description</label>
          <input type="text" class="form-control" id="description" name="description" placeholder="a descritption about category" ng-model="editcategory.categoryDetails.description" required>
        </div>
        <div class="col-xs-4">
          <label for="type" class="control-label pull-left">Type</label>
          <select class="form-control" ng-model="type" ng-options="type.name for type in editcategory.types track by type.id"></select>
        </div>
      </div>
      </br>
      </br>
      </br>
      <div class="form-group">
        <div class="col-xs-4">
          <label for="provider" class="control-label pull-left">Provider</label>
          <select class="form-control" ng-model="provider" ng-options="provider.name for provider in editcategory.providers track by provider.id"></select>
        </div>
      </div>
    </br>
  </br>
</br>
<div class="col-xs-4">
  <div ng-show="form.name.$invalid">
    <p>Name field is required!<p></div>
      <div ng-show="form.description.$invalid">
        <p>Description field is required!<p></div>
          <div ng-show="form.type.$invalid">
            <p>Type is required!<p></div>
              <div ng-show="form.provider.$invalid">
                <p>Provider is required!<p></div>
            </div>
            <div class="col-xs-4">
              <input type="checkbox" class="pull-right" checked="false" id="premium" name="premium" ng-model="editcategory.premium" style="margin-left: 5px;">
              <label for="premium" class="control-label pull-right">Premium:</label>
            </div>
            <div class="col-xs-4">
              <button class="btn btn-danger btn-block" ng-click="editcategory.updateCategory()">Update</button>
            </div>
          </br>
        </br>
      </br>
      <div class="col-xs-12 panel panel-default">
        <div class="panel-body">
          <h3>Network Filters</h3>
          <div class="row">
            <button type="button" class="btn btn-danger btn-md pull-right" ng-click="editcategory.addFilter()" tooltip-placement="left" tooltip="Add Filter">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <div class="row">
            <table class="table" ng-show="editcategory.networkFilters.length > 0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Bandwidth Limit</th>
                  <th></th>
                </tr>
                <tbody>
                  <tr ng-repeat="filter in editcategory.networkFilters">
                    <td>{{filter.website}}</td>
                    <td>{{filter.details.description}}</td>
                    <td>{{filter.bandwidth}}
                      Mb</td>
                    <td>
                      <button class="btn btn-danger btn-md pull-right" ng-click="editcategory.deleteFilter(filter.id)">
                        <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <button button type="button" class="btn btn-danger pull-right mgn-top" ng-click="editcategory.done()">Done</button>
    </div>
  </div>
</form>
