(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name categories.controller:CategoriesCtrl
   *
   * @description
   *
   */
  angular
    .module('categories')
    .controller('CategoriesCtrl', CategoriesCtrl);

  function CategoriesCtrl($state, Category, $modal, Provider) {
    var vm = this;
    vm.ctrlName = 'CategoriesCtrl';

    vm.categoriesLoaded = false;
    vm.categoriesLoadedError = false;
    vm.categories = [];
    vm.providers = [];
    vm.providerLookup = [];
    vm.providersLoadedError = false;
    vm.providersLoaded = false;

    Category.getAllCategories().success(function (data) {
      vm.categories = data.categories;
      vm.categoriesLoaded = true;
    }).error(function (err) {
      vm.categoriesLoadedError = true;
      vm.categoriesLoaded = true;
      console.log('Error: ', err);
    });

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      for (var i = 0; i < vm.providers.length; i++) {
        vm.providerLookup[vm.providers[i].id] = vm.providers[i];
      }
      vm.providersLoadedError = false;
      vm.providersLoaded = true;
    }).error(function (err) {
      console.log('Error: ', err);
      vm.providersLoadedError = true;
      vm.providersLoaded = true;
    });

    vm.getProviderName = function (providerid) {
      if (!vm.providersLoadedError && vm.providersLoaded)
        return vm.providerLookup[providerid].name;
    };

    function reloadAllCategories() {
      Category.getAllCategories().success(function (data) {
        vm.categories = data.categories;
        vm.categoriesLoaded = true;
      }).error(function (err) {
        vm.categoriesLoadedError = true;
        vm.categoriesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'categories/views/catdeletemodal.tpl.html',
        controller: 'CatdeletemodalCtrl as catdeleteModal'
      });

      modalInstance.result.then(function () {
        Category.deleteCategory(id).then(function () {
          reloadAllCategories();
        }, function(err){
          console.log(err);
          var modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete strategy, strategies cannot be deleted if associated with an event',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function() {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function() {

          });
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());
