<div ng-show="organisationroles.ErrorMessage">
  <div class="alert alert-danger" role="alert" style="width: 40%;">
    <button ng-click="organisationroles.dismiss()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span>
    {{organisationroles.Message}}
  </div>
</div>
<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Organisation Roles
        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addrole" tooltip-placement="left" tooltip="Add Role">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-3 col-md-offset-9">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search Organisation Roles" ng-model="orgRoleSearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr dir-paginate="role in organisationroles.organisationRoles | filter:orgRoleSearch | itemsPerPage: 10">
          <td>
            <h4>Name:</h4>
            <p>{{role.name}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="organisationroles.deleteEvent(role.id)" tooltip-placement="left" tooltip="Delete Role">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
              <a class="btn btn-danger btn-md" ui-sref="admin.editrole({roleid: role.id})" tooltip-placement="left" tooltip="Edit Role">
                <i class="fa fa-pencil-square-o"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
  </div>
</div>
