(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SltdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('sociallogins')
    .controller('SetProviderModalCtrl', SetProviderModalCtrl);

  function SetProviderModalCtrl($modalInstance, Provider, providers) {
    var vm = this;
    vm.ctrlName = 'SetProviderModalCtrl';

    vm.selectedproviders = providers;
    vm.providers = [];

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      vm.setPreselected();
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.setPreselected = function() {
      var providerIndex = 0;
      var selectedIndex = 0;
      for (providerIndex = 0; providerIndex < vm.providers.length; providerIndex++) {
        for (selectedIndex = 0; selectedIndex < vm.selectedproviders.length; selectedIndex++) {
          if (vm.providers[providerIndex].id === vm.selectedproviders[selectedIndex].provider) {
            vm.providers[providerIndex].status = true;
          }
        }
      }
    };


    vm.select = function (index){
      vm.providers[index].status = true;
    };

    vm.deselect = function (index){
        vm.providers[index].status = false;
    };

    vm.ok = function () {
      var list = [];
      vm.providers.forEach(function(item){
        if (item.status) {
          list.push(item.id);
        }
      });
      $modalInstance.close(list);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());
