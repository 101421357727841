<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="text-center" ng-hide="addcategory.onSave">Add Strategy</h3>
    <h3 class="text-center" ng-show="addcategory.onSave">Add Filters to Strategy</h3>
  </div>
  <div class="panel-body">
    <div ng-show="addcategory.ErrorMessage">
      <div class="alert alert-danger" role="alert" style="width: 40%;">
        <button ng-click="addcategory.dismiss()" type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Error:</span> {{addcategory.Message}}
      </div>
    </div>
    <form name="form" ng-submit="form.$valid && addcategory.createCategory()" role="form" class="col-md-8 col-md-offset-2" novalidate ng-hide="addcategory.onSave">
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Name</4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <input type="text" class="form-control" id="name" name="name" placeholder="name" ng-model="addcategory.categoryDetails.name" required>
            <div ng-show="form.name.$invalid">
              <p>Name field is required!
                <p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Description</4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <input type="text" class="form-control" id="description" name="description" placeholder="a descritption about category" ng-model="addcategory.categoryDetails.description" required>
            <div ng-show="form.description.$invalid">
              <p>Description field is required!
                <p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Type</h4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <select name="type" id="type" ng-model="addcategory.selectedType" class="form-control" required>
              <option ng-repeat="type in addcategory.types" value="{{type.id}}">{{type.name}}</option>
            </select>
            <div ng-show="form.type.$invalid">
              <p>Type is required!
                <p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Provider</h4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <select name="provider" id="provider" ng-model="addcategory.selectedProvider" class="form-control" required>
              <option ng-repeat="provider in addcategory.providers" value="{{provider.id}}">{{provider.name}}</option>
            </select>
            <div ng-show="form.provider.$invalid">
              <p>Provider is required!
                <p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Premium</h4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label for="premium" class="control-label pull-left">Premium:</label>
            <input type="checkbox" class="pull-left" checked="false" id="premium" name="premium" ng-model="addcategory.premium" style="margin-left: 5px;">
          </div>
        </div>
      </div>
      <div class="row">
        <button type="submit" class="btn btn-danger">Save</button>
      </div>
    </form>
  </div>
  <div class="col-xs-12 panel panel-default" ng-show="addcategory.onSave">
    <div class="panel-body">
      <div class="row">
        <h3>{{addcategory.newCategory.name}}</h3>
      </div>
      <div class="row">
        <button button type="button" class="btn btn-danger pull-right" ng-click="addcategory.addFilter()">Add Filter</button>
      </div>
      <div class="row">
        <table class="table" ng-show="addcategory.networkFilters.length > 0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Bandwidth Limit</th>
              <th></th>
            </tr>
            <tbody>
              <tr ng-repeat="filter in addcategory.networkFilters">
                <td>{{filter.website}}</td>
                <td>{{filter.details.description}}</td>
                <td>{{filter.bandwidth}} Mb</td>
                <td>
                  <button class="btn btn-danger btn-sm pull-right" ng-click="addcategory.deleteFilter(filter.id)">
                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span> Delete</button>
                </td>
              </tr>
            </tbody>
          </thead>
        </table>
      </div>
      <div class="row" ng-show="addcategory.networkFilters.length > 0">
        <button button type="button" class="btn btn-danger pull-right" ng-click="addcategory.done()">Done</button>
      </div>
    </div>
  </div>
</div>
</div>
