<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Cloud Service Type</h3>
    </div>
    <div class="panel-body">
      <div ng-show="addcloudservicetype.ErrorMessage">
        <div class="alert alert-danger" role="alert">
          <button ng-click="addcloudservicetype.dismiss()"
          type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{addcloudservicetype.Message}}
        </div>
      </div>
      <form name="form" ng-submit="form.$valid && addcloudservicetype.createCloudServiceType()" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name"
              name="name" placeholder="Name"
              ng-model="addcloudservicetype.cloudservicetypeDetails.name" required>
              <div ng-show="form.name.$invalid">
                <p> Name is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Description</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="description"
              name="description" placeholder="Description"
               ng-model="addcloudservicetype.cloudservicetypeDetails.description" required>
              <div ng-show="form.description.$invalid">
                <p>Description is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button type="submit" class="btn btn-danger">Submit</button>
        </div>
      </form>
    </div>
  </div>
