(function () {
  'use strict';

  angular
    .module('editinfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editinfrastructure', {
        url: '/editinfrastructure/:infraid/:venueid/:orgid',
        templateUrl: 'editinfrastructure/views/editinfrastructure.tpl.html',
        controller: 'EditinfrastructureCtrl',
        controllerAs: 'editinfrastructure'
      });
  }
}());
