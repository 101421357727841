(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editap.controller:EditapCtrl
   *
   * @description
   *
   */
  angular
    .module('editap')
    .controller('EditapCtrl', EditapCtrl);

  function EditapCtrl(Infrastructureapi, $stateParams, $state, $log) {
    var vm = this;
    vm.ctrlName = 'EditapCtrl';

    var apid = $stateParams.apid;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.accesspoint = {};

    Infrastructureapi.getAp(apid, venueid, orgid).then(function (resp) {
      $log.info(resp);
      vm.accesspoint.provider = resp.data.apoint.provider;
      vm.accesspoint.name = resp.data.apoint.name;
      vm.accesspoint.hwAddr = resp.data.apoint.hwaddr;
      vm.accesspoint.ipAddress = resp.data.apoint.ipaddress;
      vm.accesspoint.sshUsername = resp.data.apoint.sshusername;
      vm.accesspoint.sshPassword = resp.data.apoint.sshpassword;
      $log.info(vm.accesspoint);
    }, function (err) {
      $log.info(err);
    });

    vm.updateAccesspoint = function (isValid) {
      vm.submitted = true;
      if (isValid) {
        vm.accesspoint.orgid = orgid;
        Infrastructureapi.updateAp(apid, venueid, vm.accesspoint).then(function (resp) {
          $log.info(resp);
          $state.go('admin.managenetwork', {
            venueid: venueid,
            orgid: orgid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };
  }
}());
