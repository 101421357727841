(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatordelmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('operators')
    .controller('OperatordelmodalCtrl', OperatordelmodalCtrl);

  function OperatordelmodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OperatordelmodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
