<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Provider</h3>
    </div>
    <div class="panel-body">
      <div ng-show="addprovider.ErrorMessage">
        <div class="alert alert-danger" role="alert">
          <button ng-click="addprovider.dismiss()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{addprovider.Message}}
        </div>
      </div>
      <form name="form" ng-submit="form.$valid && addprovider.createProvider()" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Provider Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="providerName" name="providerName" placeholder="Provider name" ng-model="addprovider.providerDetails.name" required>
              <div ng-show="form.providerName.$invalid">
                <p>Provider Name is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Details</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" placeholder="Details" ng-model="addprovider.providerDetails.details" required>
              <div ng-show="form.details.$invalid">
                <p>Details are required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button type="submit" class="btn btn-danger">Submit</button>
        </div>
      </form>
    </div>
  </div>
