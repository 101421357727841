(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name providers.controller:ProvidersCtrl
   *
   * @description
   *
   */
  angular
    .module('providers')
    .controller('ProvidersCtrl', ProvidersCtrl);

  function ProvidersCtrl($state, Provider, $modal) {
    var vm = this;
    vm.ctrlName = 'ProvidersCtrl';

    vm.providersLoaded = false;
    vm.providersLoadedError = false;
    vm.providers = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      vm.providersLoaded = true;
    }).error(function (err) {
      vm.providersLoadedError = true;
      vm.providersLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllProviders() {
      Provider.getAllProviders().success(function (data) {
        vm.providers = data.providers;
        vm.providersLoaded = true;
      }).error(function (err) {
        vm.providersLoadedError = true;
        vm.providersLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'providers/views/delprovider.tpl.html',
        controller: 'DelproviderCtrl as delprovider'
      });

      modalInstance.result.then(function () {
        Provider.deleteProvider(id).success(function () {
          reloadAllProviders();
        }).error(function (err) {
          console.log('Error: ', err);
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
