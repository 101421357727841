(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editslt.controller:EditsltCtrl
   *
   * @description
   *
   */
  angular
    .module('editslt')
    .controller('EditsltCtrl', EditsltCtrl);

  function EditsltCtrl($state, $stateParams, $modal, Slt, Provider) {
    var vm = this;
    vm.ctrlName = 'EditsltCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];

    vm.slt = {};

    vm.selectImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.slt.imageuri = thisImage.imageuri;
        vm.slt.imageid = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };
    vm.uploadImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        vm.slt.imageuri = image.imageUri;
        vm.slt.imageid = image.id;
      }, function() {
        console.log('canceled');
      });
    };

    Slt.getSLTById($stateParams.sltid).success(function (data) {
      vm.slt = data.slt;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateSLT = function () {
      var data = JSON.stringify({
        name: vm.slt.name,
        imageId: vm.slt.imageid,
        details: '{}'
      });
      console.log(data);
      Slt.updateSLT(vm.slt.id, data).success(function (data) {
        $state.go('admin.sociallogins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
