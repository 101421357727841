(function () {
  'use strict';

  angular
    .module('addgns')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addgns', {
        url: '/addgns',
        templateUrl: 'addgns/views/addgns.tpl.html',
        controller: 'AddgnsCtrl',
        controllerAs: 'addgns'
      });
  }
}());
