(function () {
  'use strict';

  angular
    .module('guestnetworksettings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.guestnetworksettings', {
        url: '/guestnetworksettings',
        templateUrl: 'guestnetworksettings/views/guestnetworksettings.tpl.html',
        controller: 'GuestnetworksettingsCtrl',
        controllerAs: 'guestnetworksettings'
      });
  }
}());
