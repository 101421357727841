(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudtypedeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('cloudservicetypes')
    .controller('CloudtypedeletemodalCtrl', CloudtypedeletemodalCtrl);

  function CloudtypedeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CloudtypedeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
