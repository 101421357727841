<div class="panel panel-default evcConf">
  <div class="panel-heading">
    <h3 class="text-center">Update Operator Password</h3>
  </div>
  <div class="panel-body">
    <div ng-show="editoperatorpwd.ErrorMessage">
      <div class="alert alert-danger" role="alert" style="width: 40%;">
        <button ng-click="editoperatorpwd.dismiss()" type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Error:</span>
        {{editoperatorpwd.Message}}
      </div>
    </div>
    <form name="form" ng-submit="form.$valid && editoperatorpwd.updateOperatorPassword()" role="form" class="col-md-8 col-md-offset-2" novalidate>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Password</h4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <input type="password" class="form-control" id="password" name="password" placeholder="Password" ng-model="editoperatorpwd.operatorDetails.password" required>
            <div ng-show="form.password.$invalid">
              <p>Password is required!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default panel-form">
        <div class="panel-heading clearfix">
          <h4>Confirm Password</h4>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <input type="password" class="form-control" id="password2" name="password2" placeholder="Confirm Password" ng-model="editoperatorpwd.operatorDetails.password2" same-as required>
            <div ng-show="form.password2.$error.required">
              <p>Confirm Password is required!</p>
            </div>
            <div ng-show="form.password2.$error.noMatch">
              <p>Passwords do not match!</p>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-danger btn-md">Submit</button>
    </form>
  </div>
</div>
