(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Provider
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Provider', Provider);

  function Provider(baseurl, $http, Auth) {
    var ProviderBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    ProviderBase.getAllProviders = function () {
      return $http.get(baseurl + '/providers', {
        headers: {
          'x-access-token': token
        }
      });
    };

    ProviderBase.createProvider = function (data) {
      return $http.post(baseurl + '/providers', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ProviderBase.getProviderById = function (id) {
      return $http.get(baseurl + '/providers/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ProviderBase.updateProvider = function (id, data) {
      return $http.put(baseurl + '/providers/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ProviderBase.deleteProvider = function (id) {
      return $http.delete(baseurl + '/providers/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return ProviderBase;
  }
}());
