(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addrole.controller:AddroleCtrl
   *
   * @description
   *
   */
  angular
    .module('addrole')
    .controller('AddroleCtrl', AddroleCtrl);

  function AddroleCtrl($state, Roles) {
    var vm = this;
    vm.ctrlName = 'AddroleCtrl';

    vm.ErrorMessage = false;
    vm.organisationRole = [];
    vm.Message = [];

    vm.createOrganisationRole = function () {
      var data = JSON.stringify({
        name: vm.organisationRole.name,
        details: {}
      });
      Roles.createOrganisationRole(data).success(function (data) {
        $state.go('admin.organisationroles');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      vm.ErrorMessage = false;
    };

  }
}());
