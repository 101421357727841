(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editadmin.controller:EditadminCtrl
   *
   * @description
   *
   */
  angular
    .module('editadmin')
    .controller('EditadminCtrl', EditadminCtrl);

  function EditadminCtrl($state, $stateParams, Leadmin) {
    var vm = this;
    vm.ctrlName = 'EditadminCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];

    Leadmin.getAdminById($stateParams.adminid).success(function (data) {
      vm.adminDetails = data.admin;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateAdmin = function () {
      var data = JSON.stringify({
        login: vm.adminDetails.login,
        name: vm.adminDetails.name,
        details: {}
      });
      Leadmin.updateAdmin(vm.adminDetails.id, data).success(function (data) {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
