(function () {
  'use strict';

  /* @ngdoc object
   * @name liquidedgeLeadmin
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin', [
      'ui.router',
      'ui.bootstrap',
      'home',
      'main',
      'ngCookies',
      'ngMessages',
      'admin',
      'checklist-model',
      'categories',
      'addcategory',
      'editcategory',
      'organisations',
      'addorganisation',
      'editorganisation',
      'organisationroles',
      'addrole',
      'editrole',
      'operators',
      'addoperator',
      'editoperator',
      'editoperatorpwd',
      'sociallogins',
      'addslt',
      'editslt',
      'admins',
      'addadmin',
      'editadmin',
      'editadminpwd',
      'chart.js',
      'analytics',
      'filtercreatemodal',
      'rzModule',
      'modalservice',
      'venues',
      'addzone',
      'addap',
      'managenetwork',
      'editzone',
      'editap',
      'newvenue',
      'ngFileUpload',
      'selectimage',
      'manageimages',
      'editvenue',
      'angularUtils.directives.dirPagination',
      'networktype',
      'editnetworktype',
      'splashtype',
      'editsplashtype',
      'addsplashtype',
      'addInfrastructure',
      'editinfrastructure',
      'providers',
      'addprovider',
      'editprovider',
      'leimages',
      'mailchimp',
      'newlist',
      'cloudservicetypes',
      'addcloudservicetype',
      'editclstype',
      'cloudservices',
      'addclservice',
      'editclservice',
      'orgInfrastructures',
      'guestnetworksettings',
      'addgns',
      'updategns'
    ]);
}());
