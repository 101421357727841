(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name updategns.controller:UpdategnsCtrl
   *
   * @description
   *
   */
  angular
    .module('updategns')
    .controller('UpdategnsCtrl', UpdategnsCtrl);

  function UpdategnsCtrl(Provider, GnsApi, $stateParams, $state) {
    var vm = this;
    vm.ctrlName = 'UpdategnsCtrl';
    console.log($stateParams);

    vm.getProviders = function(){
      Provider.getAllProviders().then(function(res){
        vm.providers = res.data.providers;
      }, function(err){
        console.log(err);
      });
    };

    vm.getProviders();

    vm.getgns = function() {
      GnsApi.getGNSById($stateParams.gnsid).success(function(data){
        vm.gns = data.gns;
        vm.gns.details = JSON.stringify(vm.gns.details);
      });
    };

    vm.getgns();

    vm.updategns = function() {
      GnsApi.udpateGNS($stateParams.gnsid, vm.gns).then(function successCallback(response) {
        console.log(response);
        $state.go('admin.guestnetworksettings');
      }, function errorCallback(response) {
        vm.error = response.status+': Please Ensure Valid JSON.';
        vm.errorLink = 'https://jsonlint.com/';
      });
    };

  }
}());
