(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admins.controller:AdmindeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('admins')
    .controller('AdmindeletemodalCtrl', AdmindeletemodalCtrl);

  function AdmindeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'AdmindeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
