(function () {
  'use strict';

  angular
    .module('editadminpwd')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editadminpwd', {
        url: '/editadminpwd/:adminid',
        templateUrl: 'editadminpwd/views/editadminpwd.tpl.html',
        controller: 'EditadminpwdCtrl',
        controllerAs: 'editadminpwd'
      });
  }
}());
