<div id="addgns">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h2 class="text-center">Create Guest Network Settings</h2>
    </div>
    <div class="panel-body">
      <form name="createGns" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Provider</h4>
          </div>
          <div class="panel-body">
            <select id="provider" name="provider" class="form-control" ng-model="addgns.gns.provider" ng-options="provider.id as provider.name for provider in addgns.providers">
              <option value="">Select A Provider</option>
            </select>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Settings</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <textarea type="text" class="form-control" id="details" name="details" ng-model="addgns.gns.details" placeholder="{ Controls: 'Whitelists' }" required>
              </textarea>
            </div>
          </div>
        </div>
        <button ng-click="addgns.creategns()" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
