(function () {
  'use strict';

  /* @ngdoc object
   * @name editzone
   * @description
   *
   */
  angular
    .module('editzone', [
      'ui.router'
    ]);
}());
