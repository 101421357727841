(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name manageimages.controller:ManageimagesCtrl
   *
   * @description
   *
   */
  angular
    .module('manageimages')
    .controller('ManageimagesCtrl', ManageimagesCtrl);

  function ManageimagesCtrl($modal, Api) {
    var vm = this;

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    function getImages() {
      Api.getImages().success(function (data) {
        vm.imageList = data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
        console.log(vm.imageList);
      });
    }

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: 'Note! Images associated with a Venue/Event cannot be deleted.'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Api.deleteImage(image.id).success(function (resp) {
          console.log(resp);
          getImages();
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        console.log('success');
        getImages();
      }, function () {
        console.log('canceled');
      });
    };

    getImages();

  }
}());
