(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:ModalserviceCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('ModalserviceCtrl', ModalserviceCtrl);

  function ModalserviceCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'ModalserviceCtrl';

    vm.message = message;

      vm.ok = function() {
          $modalInstance.close();
      };

      vm.cancel = function() {
          $modalInstance.dismiss();
      };
  }
}());
