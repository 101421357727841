(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisationroles.controller:OrganisationrolesCtrl
   *
   * @description
   *
   */
  angular
    .module('organisationroles')
    .controller('OrganisationrolesCtrl', OrganisationrolesCtrl);

  function OrganisationrolesCtrl($state, Roles, $modal) {
    var vm = this;
    vm.ctrlName = 'OrganisationrolesCtrl';

    vm.organisationRolesLoaded = false;
    vm.organisationRolesLoadedError = false;
    vm.organisationRoles = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Roles.getAllOrganisationRoles().success(function (data) {
      vm.organisationRoles = data.organisationRoles;
      vm.organisationRolesLoaded = true;
    }).error(function (err) {
      vm.organisationRolesLoadedError = true;
      vm.organisationRolesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllOrganisationRoles() {
      Roles.getAllOrganisationRoles().success(function (data) {
        vm.organisationRoles = data.organisationRoles;
        vm.organisationRolesLoaded = true;
      }).error(function (err) {
        vm.organisationRolesLoadedError = true;
        vm.organisationRolesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'organisationroles/views/roledeletemodal.tpl.html',
        controller: 'RoledeletemodalCtrl as roledeleteModal'
      });

      modalInstance.result.then(function () {
        Roles.deleteOrganisationRole(id).success(function () {
          reloadAllOrganisationRoles();
        }).error(function (err) {
            vm.ErrorMessage = true;
            if (err.message.code === "23503"){
              vm.Message = "Error: This Role id is referenced in Organisation Operators";
            }
            else{
              vm.Message = "Error:" + err.message.detail;
            }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
