(function () {
  'use strict';

  /* @ngdoc object
   * @name admin
   * @description
   *
   */
  angular
    .module('admin', [
      'ui.router'
    ]);
}());
