<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Guest Network Settings
        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addgns" tooltip-placement="left" tooltip="Add new Guest Network Settings">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation">
            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>
          </li>
          <li role="presentation" class="active">
            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>
          </li>
        </ul>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="item in guestnetworksettings.gnsList">
          <td>
            <h4>Provider:</h4>
            <p>{{item.provider}}</p>
          </td>
          <td>
            <h4>Description:</h4>
              <div class="seemore" ng-class="{show: show}">
                {{item.details | json}}
              </div>
            <a ng-click="show = true" ng-hide="show">See More</a>
            <a ng-click="show = false" ng-show="show">Hide</a>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="guestnetworksettings.deletegns(item.id)" tooltip-placement="left" tooltip="Delete">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ui-sref="admin.updategns({gnsid: item.id})" tooltip-placement="left" tooltip="Edit">
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
