(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatorsCtrl
   *
   * @description
   *
   */
  angular
    .module('operators')
    .controller('OperatorsCtrl', OperatorsCtrl);

  function OperatorsCtrl($state, Operator, $modal, $stateParams, Organisation, Roles) {
    var vm = this;
    vm.ctrlName = 'OperatorsCtrl';

    vm.operatorsLoaded = false;
    vm.operatorsLoadedError = false;
    vm.operators = [];
    vm.orgid = $stateParams.organisationid;
    vm.rolesLookup = [];
    vm.roles = [];
    vm.rolesLoaded = false;
    vm.rolesLoadedError = false;

    vm.limits = [1,5,10,15,20,50,'all'];
    vm.all= 0;
    vm.limit = 0;

    vm.setLimit = function(limit){
      console.log(limit);
      if(limit === 'all'){
        vm.limit = vm.all;
      }
    };

    Organisation.getOrganisationById($stateParams.organisationid).then(function(res){
      vm.organisation = res.data.org;
    }, function(err){
      console.log(err);
    });

    Operator.getAllOperatorsByOrgId($stateParams.organisationid).success(function (data) {
      vm.operators = data.operators;
      vm.operatorsLoaded = true;
      vm.limit = vm.operators.length;
      vm.all = vm.operators.length;
    }).error(function (err) {
      vm.operatorsLoadedError = true;
      vm.operatorsLoaded = true;
      console.log('Error: ', err);
    });

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
      for (var i = 0; i < vm.roles.length; i++) {
        vm.rolesLookup[vm.roles[i].id] = vm.roles[i];
      }
      vm.rolesLoaded = true;
      vm.rolesLoadedError = false;
    }).error(function(err) {
      console.log('Error: ', err);
      vm.rolesLoaded = true;
      vm.rolesLoadedError = true;
    });

    function reloadAllOperators() {
      Operator.getAllOperatorsByOrgId($stateParams.organisationid).success(function (data) {
        vm.operators = data.operators;
        vm.operatorsLoaded = true;
      }).error(function (err) {
        vm.operatorsLoadedError = true;
        vm.operatorsLoaded = true;
        vm.operators = [];
        console.log('Error: ', err);
      });
    }

    vm.getRoleName = function (roleid) {
      if (!vm.rolesLoadedError && vm.rolesLoaded){
        return vm.rolesLookup[roleid].name;
      }
    };

    vm.deleteEvent = function (orgid, opid) {
      var modalInstance = $modal.open({
        templateUrl: 'operators/views/operatordelmodal.tpl.html',
        controller: 'OperatordelmodalCtrl as operatordelModal'
      });

      modalInstance.result.then(function () {
        Operator.deleteOperator(orgid, opid).success(function () {
          reloadAllOperators();
        });
      }, function (err) {
        console.log(err);
      });
    };

  }
}());
