(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Category
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Category', Category);

  function Category(baseurl, $http, Auth) {
    var CategoryBase = {},
        token = '';

    CategoryBase.factoryName = 'Category';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CategoryBase.getAllCategories = function () {
      return $http.get(baseurl + '/categories', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.createCategory = function (data) {
      return $http.post(baseurl + '/categories', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CategoryBase.deleteCategory = function (id) {
      return $http.delete(baseurl + '/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.getCategoryById = function (id) {
      return $http.get(baseurl + '/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.updateCategory = function (id, data) {
      return $http.put(baseurl + '/categories/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    return CategoryBase;
  }
}());
