(function () {
  'use strict';

  angular
    .module('addrole')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addrole', {
        url: '/addrole',
        templateUrl: 'addrole/views/addrole.tpl.html',
        controller: 'AddroleCtrl',
        controllerAs: 'addrole'
      });
  }
}());
