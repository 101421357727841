(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mailchimp.controller:MailchimpCtrl
   *
   * @description
   *
   */
  angular
    .module('mailchimp')
    .controller('MailchimpCtrl', MailchimpCtrl);

    function MailchimpCtrl(Infrastructureapi, $scope, $log, $stateParams, $state) {
      var vm = this;
      vm.ctrlName = 'MailchimpCtrl';

      var venueid = $stateParams.venueid;
      vm.orgid = $stateParams.orgid;
      vm.venue = {};
      vm.venueDetails = {};
      vm.ConfirmMessage = false;
      vm.ErrorMessage = false;
      vm.Message = [];
      vm.ListConfirmMessage = false;
      vm.ListErrorMessage = false;
      vm.ListMessage = [];

      vm.getApiKey = function(){
        Infrastructureapi.getVenue(vm.orgid, venueid).then(function(resp) {
          $log.info(resp);
          vm.venue.venueTitle = resp.data.venue.venuetitle;
          vm.venue.imageUrl = resp.data.venue.imageurl;
          vm.venue.imageId = resp.data.venue.imageid;
          vm.venue.contactEmail = resp.data.venue.contactemail;
          vm.venue.contactPhone = resp.data.venue.contactphone;
          vm.venue.description = resp.data.venue.description;
          vm.venue.location = resp.data.venue.location;
          vm.venue.website =  resp.data.venue.website;
          if(resp.data.venue.hasOwnProperty('details')){
            vm.venueDetails = resp.data.venue.details;
            vm.venueDetails = JSON.parse(vm.venueDetails);
            if(vm.venueDetails.hasOwnProperty('mailchimp')){
              if(vm.venueDetails.mailchimp.hasOwnProperty('apiKey')){
                vm.apiKey = vm.venueDetails.mailchimp.apiKey;
                vm.getLists();
              }
              if(vm.venueDetails.mailchimp.hasOwnProperty('listId')){
                vm.listId = vm.venueDetails.mailchimp.listId;
                vm.newListId = vm.listId;
              }
            }
          } else {
            vm.venueDetails = {};
          }

        }, function(err) {
          $log.info(err);
        });
      };

      vm.getApiKey();

      vm.getLists = function(){
        vm.lists = [];
        Infrastructureapi.getLists(vm.apiKey).then(function(resp) {
          for(var i=0; i<resp.data.lists.length; i++){
            vm.lists.push({id: resp.data.lists[i].id, name: resp.data.lists[i].name});
          }
        }, function(err) {
          $log.info(err);
          vm.lists.length=0;
        });
      };

      vm.updateApiKey = function() {
        vm.submitted = true;
        if(vm.venueDetails.hasOwnProperty('mailchimp')){
          vm.venueDetails.mailchimp.apiKey = vm.apiKey;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }else{
          vm.venueDetails.mailchimp = {};
          vm.venueDetails.mailchimp.apiKey = vm.apiKey;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }

        Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          vm.ConfirmMessage = true;
          vm.Message = 'Api Key Updated';
          vm.getLists();
        }, function(err) {
          vm.Message = true;
          vm.Message = err.message;
          console.log('Error = ' + err);
        });
      };

      vm.updateList = function(){
        if(vm.venueDetails.hasOwnProperty('mailchimp')){
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }else{
          vm.venueDetails.mailchimp = {};
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }

        Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          vm.ListConfirmMessage = true;
          vm.ListMessage = 'List Updated';
          vm.getApiKey();
        }, function(err) {
          console.log('Error = ' + err);
        });
      };

      vm.dismiss = function () {
        vm.Message = {};
        vm.ConfirmMessage = false;
      };

      vm.dismissError = function () {
        vm.Message = {};
        vm.ErrorMessage = false;
      };

      vm.listDismiss = function () {
        vm.ListMessage = {};
        vm.ListConfirmMessage = false;
      };

      vm.listDismissError = function () {
        vm.ListMessage = {};
        vm.ListErrorMessage = false;
      };

      vm.createNewList = function(){
        $state.go('admin.newlist', {
          apiKey: vm.apiKey,
          venueid: venueid,
          orgid: vm.orgid
        });
      };
    }
  }());
