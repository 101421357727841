(function () {
  'use strict';

  angular
    .module('addprovider')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addprovider', {
        url: '/addprovider',
        templateUrl: 'addprovider/views/addprovider.tpl.html',
        controller: 'AddproviderCtrl',
        controllerAs: 'addprovider'
      });
  }
}());
