<div id="mailchimp">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Mailchimp Settings</h3>
    </div>
    <div class="panel-body">
      <form name="mailchimpSettings"
      class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>API KEY</h4>
          </div>
          <div class="panel-body">
            <div class="form-group"
            ng-class="{ 'has-error': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">
              <input type="text" class="form-control"
              id="apiKey" name="apiKey"
              ng-model="mailchimp.apiKey"
              placeholder="eg. xxxxxxxxxx-xyz" required>
            </div>
            <div class="help-block"
            ng-messages="mailchimpSettings.apiKey.$error"
            ng-if="editvenue.submitted">
              <p ng-message="required">api key is required.</p>
            </div>
            <button ng-click="mailchimp.updateApiKey(!mailchimpSettings.$invalid)" class="btn btn-danger right">Update Api Key</button>
            <div>
              </br>
            </div>
            <div ng-show="mailchimp.ConfirmMessage">
              <div class="alert alert-success"
              role="alert" style="width: 40%;">
                <button ng-click="mailchimp.dismiss()"
                type="button" class="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <span class="glyphicon glyphicon-exclamation-sign"
                aria-hidden="true"></span>
                <span class="sr-only">Alert:</span>
                {{mailchimp.Message}}
              </div>
            </div>
            <div ng-show="mailchimp.ErrorMessage">
              <div class="alert alert-danger" role="alert"
              style="width: 40%;">
                <button ng-click="mailchimp.dismissError()"
                type="button" class="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <span class="glyphicon glyphicon-exclamation-sign"
                aria-hidden="true"></span>
                <span class="sr-only">Alert:</span>
                {{mailchimp.Message}}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="panel-body">
      <form name="mailchimpSettings"
      class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Lists</h4>
          </div>
          <div class="panel-body">
            <p>Current List ID: {{mailchimp.listId}}</p>
            <br/>
            <p>Choose new List from the dropdown menu or create a new list</p>
              <div class="row">
                <div class="col-xs-9">
                  <select class="form-control" ng-model="mailchimp.newListId">
                    <option ng-repeat="list in mailchimp.lists"
                    value="{{list.id}}">{{list.name}} - {{list.id}}</option>
                  </select>
                </div>
                <div class="col-xs-3">
                  <button ng-click="mailchimp.updateList()"
                  class="btn btn-danger right">Change List</button>
                </div>
              </div>
              <div>
                </br>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <button ng-click="mailchimp.createNewList()"
                  class="btn btn-danger right">Create New List</button>
                </div>
              </div>
              <div>
                </br>
              </div>
              <div ng-show="mailchimp.ListConfirmMessage">
                <div class="alert alert-success" role="alert" style="width: 40%;">
                  <button ng-click="mailchimp.listDismiss()" type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  <span class="sr-only">Alert:</span>
                  {{mailchimp.ListMessage}}
                </div>
              </div>
              <div ng-show="mailchimp.ListErrorMessage">
                <div class="alert alert-danger" role="alert" style="width: 40%;">
                  <button ng-click="mailchimp.listDismissError()" type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  <span class="sr-only">Alert:</span>
                  {{mailchimp.ListMessage}}
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
