(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admins.controller:AdminsCtrl
   *
   * @description
   *
   */
  angular
    .module('admins')
    .controller('AdminsCtrl', AdminsCtrl);

  function AdminsCtrl($state, Leadmin, $modal) {
    var vm = this;
    vm.ctrlName = 'AdminsCtrl';

    vm.adminsLoaded = false;
    vm.adminsLoadedError = false;
    vm.admins = [];

    Leadmin.getAllAdmins().success(function (data) {
      vm.admins = data.admins;
      vm.adminsLoaded = true;
    }).error(function (err) {
      vm.adminsLoadedError = true;
      vm.adminsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllAdmins() {
      Leadmin.getAllAdmins().success(function (data) {
        vm.admins = data.admins;
        vm.adminsLoaded = true;
      }).error(function (err) {
        vm.adminsLoadedError = true;
        vm.adminsLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'admins/views/admindeletemodal.tpl.html',
        controller: 'AdmindeletemodalCtrl as admindeleteModal'
      });

      modalInstance.result.then(function () {
        Leadmin.deleteAdmin(id).success(function () {
          reloadAllAdmins();
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());
