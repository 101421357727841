(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Slt
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Slt', Slt);

  function Slt(baseurl, $http, Auth) {
    var SltBase = {},
        token = '';
    SltBase.someValue = 'Slt';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    SltBase.getAllSLTs = function () {
      return $http.get(baseurl + '/socialLoginTypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.createSLT = function (data) {
      return $http.post(baseurl + '/socialLoginTypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SltBase.getSLTById = function (id) {
      return $http.get(baseurl + '/sociallogintypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.updateSLT = function (id, data) {
      return $http.put(baseurl + '/socialLoginTypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SltBase.deleteSLT = function (id) {
      return $http.delete(baseurl + '/socialLoginTypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.getProviders = function (id) {
      return $http.get(baseurl + '/socialLoginTypes/' + id + '/providers/', {
        headers: {
          'x-access-token': token
        }
      });
    };
    SltBase.setProviders = function (id, data) {
      return $http.post(baseurl + '/socialLoginTypes/' + id + '/providers/', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    return SltBase;
  }
}());
