(function () {
  'use strict';

  /* @ngdoc object
   * @name liquidedgeLeadmin
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin', [
      'ui.router',
      'ui.bootstrap',
      'home',
      'main',
      'ngCookies',
      'ngMessages',
      'admin',
      'checklist-model',
      'categories',
      'addcategory',
      'editcategory',
      'organisations',
      'addorganisation',
      'editorganisation',
      'organisationroles',
      'addrole',
      'editrole',
      'operators',
      'addoperator',
      'editoperator',
      'editoperatorpwd',
      'sociallogins',
      'addslt',
      'editslt',
      'admins',
      'addadmin',
      'editadmin',
      'editadminpwd',
      'chart.js',
      'analytics',
      'filtercreatemodal',
      'rzModule',
      'modalservice',
      'venues',
      'addzone',
      'addap',
      'managenetwork',
      'editzone',
      'editap',
      'newvenue',
      'ngFileUpload',
      'selectimage',
      'manageimages',
      'editvenue',
      'angularUtils.directives.dirPagination',
      'networktype',
      'editnetworktype',
      'splashtype',
      'editsplashtype',
      'addsplashtype',
      'addInfrastructure',
      'editinfrastructure',
      'providers',
      'addprovider',
      'editprovider',
      'leimages',
      'mailchimp',
      'newlist',
      'cloudservicetypes',
      'addcloudservicetype',
      'editclstype',
      'cloudservices',
      'addclservice',
      'editclservice',
      'orgInfrastructures',
      'guestnetworksettings',
      'addgns',
      'updategns'
    ]);
}());

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('sociallogins/views/sociallogins.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Social Login Types\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addslt" tooltip-placement="left" tooltip="Add Social Login">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Social Login" ng-model="socialLoginSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="slt in sociallogins.slts | filter:socialLoginSearch | itemsPerPage: 10">\n' +
    '          <td class="venueIMG">\n' +
    '            <div>\n' +
    '              <img ng-src="{{staticUrl}}{{slt.imageuri}}" class="img-responsive"/>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{slt.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="sociallogins.deleteEvent(slt.id)" tooltip-placement="left" tooltip="Delete Social Login">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editslt({sltid: slt.id})" tooltip-placement="left" tooltip="Edit Social Login">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '              <a class="btn btn-danger btn-md" ng-click="sociallogins.setProviders(slt.id)" tooltip-placement="left" tooltip="Select Providers">\n' +
    '                <i class="fa fa-list"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('sociallogins/views/sltdeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Social Login Type?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="sltdeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="sltdeleteModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('leimages/views/uploadmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-hide="modal.image" class="image-box text-center" ngf-select ngf-drop accept="image/*" ngf-pattern="\'image/*\'" ngf-drag-over-class="dragover" ng-model="modal.image">\n' +
    '    <span class="glyphicon glyphicon-camera"></span>\n' +
    '    <h4>\n' +
    '      <strong>ADD AN IMAGE</strong>\n' +
    '    </h4>\n' +
    '    <p>Choose an image that captures your event</p>\n' +
    '  </div>\n' +
    '  <div class="row modalThumb">\n' +
    '    <img ngf-src="modal.image">\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-file btn-danger" type="file" ngf-select accept="image/*" ngf-max-height="1000" ngf-max-size="1MB" ng-model="modal.image">\n' +
    '        Select File</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label><h4>Keywords</h4></label>\n' +
    '    <textarea class="form-control" rows="3" ng-model="modal.description" placeholder="comma, seperated, values"></textarea>\n' +
    '  </div>\n' +
    '  <button ng-click="modal.uploadFiles(modal.image)" class="btn btn-sm btn-danger">Submit</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('leimages/views/leimages.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Images\n' +
    '        <button class="btn btn-md btn-danger pull-right mgn10r" ng-click="leimages.uploadImage()" tooltip-placement="left" tooltip="Add Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="leimages.limit" ng-options="limit for limit in leimages.limits" ng-change="leimages.setLimit(leimages.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="image in leimages.imageList | filter:imageSearch | limitTo:leimages.limit">\n' +
    '          <td class="evntIMG">\n' +
    '            <div>\n' +
    '              <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <p>{{image.details.description}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="leimages.deleteImage(image)" tooltip-placement="left" tooltip="Delete Image">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/home.tpl.html',
    '<div class="container">\n' +
    '  <div class="row mgn-btm-80"></div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-4 col-md-offset-4">\n' +
    '      <div class="mgn-btm-20">\n' +
    '        <img alt="Brand" src="./images/liquidedge-logo-small.png">\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="home.login()" role="form">\n' +
    '        <div class="form-group">\n' +
    '          <label for="username" class="control-label pull-left">LOGIN</label>\n' +
    '          <input type="text" class="form-control" id="username" placeholder="Username" ng-model="home.loginDetails.username">\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label for="password" class="control-label pull-left">PASSWORD</label>\n' +
    '          <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="home.loginDetails.password">\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-hide="true">\n' +
    '          <label class="checkbox-inline pull-left">\n' +
    '            <input type="checkbox"> Keep me signed in\n' +
    '          </label>\n' +
    '          <label class="checkbox-inline pull-right modal-link">\n' +
    '            <a href="">Forgot Password?</a>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger btn-small pull-right">Login</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/venues.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Venues\n' +
    '        <button class="btn btn-danger btn-md pull-right" ng-click="venues.newvenue()" tooltip-placement="left" tooltip="Add Venue">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="venues.limit" ng-options="limit for limit in venues.limits" ng-change="venues.setLimit(venues.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="venue in venues.venues | filter:venueSearch | limitTo:venues.limit" ng-hide="venues.venueSelected.selected && venues.venueSelected.venue !== venue.id">\n' +
    '          <td class="venueIMG">\n' +
    '            <div>\n' +
    '              <img ng-src="{{staticUrl}}{{venue.imageuri}}" class="img-responsive"/>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              {{venue.venuetitle}}</h4>\n' +
    '            <h4>\n' +
    '              {{venue.location}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Mail:\n' +
    '              <a ng-href="mailto:{{venue.contactemail}}">{{venue.contactemail}}</a>\n' +
    '            </h4>\n' +
    '            <h4>Phone:\n' +
    '              {{venue.contactphone}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="venues.deleteVenue(venue.id)" tooltip-placement="left" tooltip="Delete Venue">\n' +
    '                <span class="fa fa-trash-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="venues.editvenue(venue.id)" tooltip-placement="left" tooltip="Edit Venue">\n' +
    '                <span class="fa fa-pencil-square-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="venues.manageNetwork(venue.id)" tooltip-placement="left" tooltip="Manage Network">\n' +
    '                <i class="fa fa-wifi" aria-hidden="true"></i>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="venues.externalServices(venue.id)" tooltip-placement="left" tooltip="Cloud Services">\n' +
    '                <i class="fa fa-cloud" aria-hidden="true"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row" ng-show="venues.venueSelected.venue > 0">\n' +
    '  <div class="col-md-6" ng-show="venues.zones.length > 0">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-8 col-md-offset-2">\n' +
    '            <div class="input-group">\n' +
    '              <span class="input-group-addon">\n' +
    '                <i class="fa fa-search"></i>\n' +
    '              </span>\n' +
    '              <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div dir-paginate="zone in venues.zones | filter:zoneSearch | itemsPerPage: 10" pagination-id="zones">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-3">\n' +
    '              <h4>Zone:\n' +
    '                {{zone.id}}</h4>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <h4>{{zone.name}}</h4>\n' +
    '            </div>\n' +
    '            <div class="col-md-6">\n' +
    '              <button class="btn btn-primary btn-md pull-right" ng-click="venues.getAccessPoints(zone)">\n' +
    '                Access Points</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="zones"></dir-pagination-controls>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-md-6">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-8 col-md-offset-2">\n' +
    '            <div class="input-group">\n' +
    '              <span class="input-group-addon">\n' +
    '                <i class="fa fa-search"></i>\n' +
    '              </span>\n' +
    '              <input type="text" class="form-control" placeholder="Search Access Points" ng-model="apSearch">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div ng-hide="venues.aps.length > 0">\n' +
    '          <p>No zone selected, or, selected zone has no access points assigned</p>\n' +
    '        </div>\n' +
    '        <div ng-show="venues.aps.length > 0">\n' +
    '          <div>\n' +
    '            <h4>{{venues.venueSelected.zone}}</h4>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div dir-paginate="ap in venues.aps | filter:apSearch | itemsPerPage: 10" pagination-id="aps">\n' +
    '              <div class="col-md-4">\n' +
    '                <h4>AP:\n' +
    '                  {{ap.id}}</h4>\n' +
    '              </div>\n' +
    '              <div class="col-md-4">\n' +
    '                <h4>IP:\n' +
    '                  {{ap.ipaddress}}</h4>\n' +
    '              </div>\n' +
    '              <div class="col-md-4">\n' +
    '                <h4>MAC:\n' +
    '                  {{ap.hwaddr}}</h4>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="aps"></dir-pagination-controls>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/deletevenuemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Venue?</h4>\n' +
    '  <p>Venues with existing associated elements such as events cannot be deleted you must delete all existing associated elements first</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="deleteVenueModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="deleteVenueModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/deletefailedmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>This Venue Cannot Be Deleted!</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-success btn-block" type="button" ng-click="deleteVenueModal.ok()">OK</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('updategns/views/updategns.tpl.html',
    '<div id="updategns">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h2 class="text-center">Update Guest Network Settings</h2>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="updateGns" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select id="provider" name="provider" class="form-control" ng-model="updategns.gns.provider" ng-options="provider.id as provider.name for provider in updategns.providers">\n' +
    '              <option value="">Select A Provider</option>\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Settings</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <textarea class="form-control" id="details" name="details" ng-model="updategns.gns.details" required>\n' +
    '              </textarea>\n' +
    '              <br />\n' +
    '              <div class="text-center text-danger"> {{updategns.error}} <a href="{{updategns.errorLink}}">{{updategns.errorLink}}</a></div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="updategns.updategns()" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('splashtype/views/splashtype.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Splashpage Types\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addsplashtype" tooltip-placement="left" tooltip="Add new splashpage type">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" class="active">\n' +
    '            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search" ng-model="splashSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="splash in splashtype.splashtypes | filter:splashSearch">\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{splash.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Description:</h4>\n' +
    '            <p>{{splash.description}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="splashtype.deleteSplashType(splash.id)" tooltip-placement="left" tooltip="Delete">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ui-sref="admin.editsplashtype({typeid: splash.id})" tooltip-placement="left" tooltip="Edit">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ng-click="splashtype.setProviders(splash.id)" tooltip-placement="left" tooltip="Select Providers">\n' +
    '                <i class="fa fa-list"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('providers/views/providers.tpl.html',
    '<div ng-show="providers.ErrorMessage">\n' +
    '  <div class="alert alert-danger" role="alert">\n' +
    '    <button ng-click="providers.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '      <span aria-hidden="true">&times;</span>\n' +
    '    </button>\n' +
    '    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '    <span class="sr-only">Error:</span>\n' +
    '    {{providers.Message}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h2>Providers\n' +
    '        <button class="btn btn-md btn-md btn-danger pull-right" data-ui-sref="admin.addprovider" tooltip-placement="left" tooltip="Add Provider">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h2>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" class="active">\n' +
    '            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Provider" ng-model="providerSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Name</th>\n' +
    '          <th>Details</th>\n' +
    '          <th></th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody dir-paginate="provider in providers.providers | filter:providerSearch | itemsPerPage: 10">\n' +
    '        <tr>\n' +
    '          <td>\n' +
    '            <h4>{{provider.name}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>{{provider.details}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-md btn-danger btn-md" ng-click="providers.deleteEvent(provider.id)" tooltip-placement="left" tooltip="Delete Provider">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-md btn-danger btn-md" ui-sref="admin.editprovider({providerid: provider.id})" tooltip-placement="left" tooltip="Edit Provider">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('providers/views/delprovider.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Provider?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="delprovider.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="delprovider.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('selectimage/views/selectimage.tpl.html',
    '<div class="selectimage">\n' +
    '  <div class="modal-header">\n' +
    '    <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '    <p class="input-group">\n' +
    '      <div class="input-group-addon"><i class="fa fa-search"></i></div>\n' +
    '      <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">\n' +
    '    </p>\n' +
    '  </div>\n' +
    '  <div class="modal-body imageList">\n' +
    '    <p ng-repeat="image in selectimage.imageList | filter:imageSearch">\n' +
    '      <label>\n' +
    '        <input type="radio" ng-model="selectimage.selectedImage" value="{{image}}">\n' +
    '        <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />\n' +
    '      </label>\n' +
    '    </p>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <div>\n' +
    '      <button class="btn btn-danger" type="button" ng-click="selectimage.ok()">Done</button>\n' +
    '      <button class="btn btn-warning" type="button" ng-click="selectimage.cancel()">Cancel</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('organisations/views/orgdeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Organisation?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orgdeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orgdeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('organisations/views/organisations.tpl.html',
    '<div ng-show="organisations.ErrorMessage">\n' +
    '  <div class="alert alert-danger" role="alert">\n' +
    '    <button ng-click="organisations.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '      <span aria-hidden="true">&times;</span>\n' +
    '    </button>\n' +
    '    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '    <span class="sr-only">Error:</span>\n' +
    '    {{organisations.Message}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Organisations\n' +
    '        <button class="btn btn-md btn-md btn-danger pull-right" data-ui-sref="admin.addorganisation" tooltip-placement="left" tooltip="Add Organisation">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Organisation" ng-model="orgSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Organisation</th>\n' +
    '          <th>Contact Email</th>\n' +
    '          <th>Contact Phone</th>\n' +
    '          <th>Premium</th>\n' +
    '          <th></th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody dir-paginate="organisation in organisations.organisations | filter:orgSearch | itemsPerPage: 10">\n' +
    '        <tr>\n' +
    '          <td>\n' +
    '            <h4>{{organisation.organisationtitle}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>{{organisation.details.email}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>{{organisation.details.phone}}</h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4 ng-show="organisation.premium" class="text-primary">\n' +
    '              <i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>\n' +
    '            </h4>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-md btn-danger btn-md" ng-click="organisations.deleteEvent(organisation.id)" tooltip-placement="left" tooltip="Delete Organisation">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-md btn-danger btn-md" ui-sref="admin.editorganisation({organisationid: organisation.id})" tooltip-placement="left" tooltip="Edit Organisation">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '          <td colspan=5>\n' +
    '            <a class="btn btn-md btn-danger" ui-sref="admin.operators({organisationid: organisation.id})" tooltip-placement="right" tooltip="Organisation Operators">\n' +
    '              <i class="fa fa-users"></i>\n' +
    '            </a>\n' +
    '            <a class="btn btn-md btn-danger" ui-sref="admin.venues({orgid: organisation.id})" tooltip-placement="right" tooltip="Organisation Venues">\n' +
    '              <i class="fa fa-building"></i>\n' +
    '            </a>\n' +
    '            <a class="btn btn-md btn-danger" ui-sref="admin.orgInfrastructures({orgid: organisation.id})" tooltip-placement="right" tooltip="Organisation Infrastructures">\n' +
    '              <i class="fa fa-wifi"></i>\n' +
    '            </a>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('organisationroles/views/roledeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Organisation Role?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="roledeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="roledeleteModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('organisationroles/views/organisationroles.tpl.html',
    '<div ng-show="organisationroles.ErrorMessage">\n' +
    '  <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '    <button ng-click="organisationroles.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '      <span aria-hidden="true">&times;</span>\n' +
    '    </button>\n' +
    '    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '    <span class="sr-only">Error:</span>\n' +
    '    {{organisationroles.Message}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Organisation Roles\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addrole" tooltip-placement="left" tooltip="Add Role">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Organisation Roles" ng-model="orgRoleSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="role in organisationroles.organisationRoles | filter:orgRoleSearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{role.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="organisationroles.deleteEvent(role.id)" tooltip-placement="left" tooltip="Delete Role">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editrole({roleid: role.id})" tooltip-placement="left" tooltip="Edit Role">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/orginfraeditmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h3 class="text-center">Edit Infrastructure</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <form name="createinfrastructure" novalidate>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <!-- <h4>Provider</h4> -->\n' +
    '        <select class="form-control" name="provider" ng-model="orginfraeditModal.infrastructure.provider" ng-options="option.id as option.name for option in orginfraeditModal.providers" ng-change="orginfraeditModal.setProvider(orginfraeditModal.infrastructure.provider)">\n' +
    '          <option value="" disabled selected>- Please Select A Provider -</option>\n' +
    '        </select>\n' +
    '        <div ng-messages="createinfrastructure.provider.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '          <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div ng-show="orginfraeditModal.control.provider">\n' +
    '          <!-- Controller Address -->\n' +
    '          <div ng-hide="orginfraeditModal.matchProvider(\'xirrus\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5>Controller Address</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="orginfraeditModal.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- AUTHENTICATION -->\n' +
    '          <div ng-hide="orginfraeditModal.matchProvider(\'xirrus\', orginfraeditModal.selectedProvider) || orginfraeditModal.matchProvider(\'unifi\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5 ng-if="orginfraeditModal.matchProvider(\'cisco\', orginfraeditModal.selectedProvider)">Authentication</h5>\n' +
    '            <h5 ng-if="orginfraeditModal.matchProvider(\'ruckus\', orginfraeditModal.selectedProvider)">Authentication</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="orginfraeditModal.infrastructure.authentication" placeholder="eg. Auth Key">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Login Credencials -->\n' +
    '          <div ng-hide="">\n' +
    '            <h5>Login credentials</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="orginfraeditModal.infrastructure.username" placeholder="Username">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.username.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="orginfraeditModal.infrastructure.password" placeholder="Password">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- SSID Limit -->\n' +
    '          <div>\n' +
    '            <h5>SSID Limit</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="orginfraeditModal.infrastructure.ssidlimit" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orginfraeditModal.ok()">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orginfraeditModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/orginfradeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Infrastructure?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orginfradeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orginfradeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/org-infrastructures.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Infrastructures\n' +
    '        <button class="btn btn-danger btn-md pull-right" ng-click="orgInfrastructures.createInfrastructure()" tooltip-placement="left" tooltip="Add Infrastructure">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search orgInfrastructures" ng-model="orgInfrastructuresearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="orgInfrastructures.limit" ng-options="limit for limit in orgInfrastructures.limits" ng-change="orgInfrastructures.setLimit(orgInfrastructures.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="infra in orgInfrastructures.infrastructures | filter:orgInfrastructuresearch | limitTo:orgInfrastructures.limit" ng-hide="orgInfrastructures.orgInfrastructureselected.selected && orgInfrastructures.orgInfrastructureselected.infra !== infra.id">\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              Controller:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.controlleraddress}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              User:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.username}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              Limit:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.ssidlimit}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.deleteInfrastructure(infra.id)" tooltip-placement="left" tooltip="Delete Infrastructure">\n' +
    '                <span class="fa fa-trash-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.editInfrastructure(infra.id)" tooltip-placement="left" tooltip="Edit Infrastructure">\n' +
    '                <span class="fa fa-pencil-square-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.manageNetwork(infra.id)" tooltip-placement="left" tooltip="Manage Controller">\n' +
    '                <i class="fa fa-wifi" aria-hidden="true"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/newinfrastructuremodel.tpl.html',
    '<div class="modal-header">\n' +
    '  <h3 class="text-center">Create Infrastructure</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <form name="createinfrastructure" novalidate>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <!-- <h4>Provider</h4> -->\n' +
    '        <select class="form-control" name="provider" ng-model="newinfrastructuremodel.infrastructure.provider" ng-options="option.id as option.name for option in newinfrastructuremodel.providers" ng-change="newinfrastructuremodel.setProvider(newinfrastructuremodel.infrastructure.provider)">\n' +
    '          <option value="" disabled selected>- Please Select A Provider -</option>\n' +
    '        </select>\n' +
    '        <div ng-messages="createinfrastructure.provider.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '          <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div ng-show="newinfrastructuremodel.control.provider">\n' +
    '          <!-- Controller Address -->\n' +
    '          <div ng-hide="newinfrastructuremodel.matchProvider(\'xirrus\', newinfrastructuremodel.selectedProvider)">\n' +
    '            <h5>Controller Address</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="newinfrastructuremodel.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- AUTHENTICATION -->\n' +
    '          <div ng-hide="newinfrastructuremodel.matchProvider(\'xirrus\', newinfrastructuremodel.selectedProvider) || newinfrastructuremodel.matchProvider(\'unifi\', newinfrastructuremodel.selectedProvider)">\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'cisco\', newinfrastructuremodel.selectedProvider)">Authentication</h5>\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'ruckus\', newinfrastructuremodel.selectedProvider)">Authentication</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="newinfrastructuremodel.infrastructure.authentication" placeholder="eg. Auth Key">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Login Credencials -->\n' +
    '          <div ng-hide="">\n' +
    '            <h5>Login credentials</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="newinfrastructuremodel.infrastructure.username" placeholder="Username">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.username.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="newinfrastructuremodel.infrastructure.password" placeholder="Password">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- SSID Limit -->\n' +
    '          <div>\n' +
    '            <h5>SSID Limit</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="newinfrastructuremodel.infrastructure.ssidlimit" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="newinfrastructuremodel.ok()">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="newinfrastructuremodel.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('operators/views/operators.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h2>{{operators.organisation.organisationtitle}}\n' +
    '        Operators\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addoperator({organisationid: operators.orgid})" tooltip-placement="left" tooltip="Add Operator">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h2>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search Operators" ng-model="operatorSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="operators.limit" ng-options="limit for limit in operators.limits" ng-change="operators.setLimit(operators.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-striped">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Name</th>\n' +
    '          <th>Username</th>\n' +
    '          <th>Venue</th>\n' +
    '          <th></th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="operator in operators.operators | filter:operatorSearch | limitTo:operators.limit">\n' +
    '          <td>{{operator.name}}</td>\n' +
    '          <td>{{operator.login}}</td>\n' +
    '          <td><span ng-show="operator.role === 2">{{operator.details.venueName}}</span><span ng-show="operator.role === 1">Admin</span></td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="operators.deleteEvent(operator.organisation, operator.id)" tooltip-placement="left" tooltip="Delete Operator">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editoperator({organisationid:operators.orgid, opid: operator.id})" tooltip-placement="left" tooltip="Edit Operator">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editoperatorpwd({organisationid:operators.orgid, opid: operator.id})" tooltip-placement="left" tooltip="Edit Password">\n' +
    '                <i class="fa fa-lock" aria-hidden="true"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('operators/views/operatordelmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Operator?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="operatordelModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="operatordelModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('newvenue/views/newvenue.tpl.html',
    '<div id="newVenue">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Venue</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createVenue" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.venuename.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="venuename" name="venuename" ng-model="newvenue.venue.venueTitle" placeholder="eg. Some Venue" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.venuename.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">venue name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{newvenue.venue.imageUrl}}" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="newvenue.selectImage()" tooltip-placement="left" tooltip="Select Image">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                  <button class="btn btn-danger" ng-click="newvenue.uploadImage()" tooltip-placement="left" tooltip="Upload Image">\n' +
    '                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-show="!newvenue.venue.imageUrl && newvenue.submitted">\n' +
    '              <p>Image is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>EMAIL</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactEmail.$invalid && newvenue.submitted }">\n' +
    '              <input type="email" class="form-control" id="contactEmail" name="contactEmail" ng-model="newvenue.venue.contactEmail" placeholder="eg. name@email.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactEmail.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">email is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PHONE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactPhone.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="contactPhone" name="contactPhone" ng-model="newvenue.venue.contactPhone" placeholder="eg. 081 555 556" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactPhone.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">phone is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.description.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="newvenue.venue.description" placeholder="eg. description ..." required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.description.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOCATION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.location.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="location" name="location" ng-model="newvenue.venue.location" placeholder="eg. Address" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.location.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">location is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WEBSITE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.website.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="website" name="website" ng-model="newvenue.venue.website" placeholder="eg. someurl.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.website.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">website is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        \n' +
    '        <button ng-click="newvenue.createVenue(!createVenue.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('main/views/main.tpl.html',
    '<div class="container-fluid main-wrap">\n' +
    '  <!-- <div class=\'navbar navbar-inverse navbar-head navbar-fixed-top\' role=\'navigation\'>\n' +
    '    <div class=\'container-fluid mainNav\'>\n' +
    '      <div class=\'navbar-header\'>\n' +
    '        <p class="navbar-brand">\n' +
    '          <img alt="Brand" src="./images/liquidedge-logo-small.png">\n' +
    '        </p>\n' +
    '        <button class=\'navbar-toggle collapsed\' type=\'button\' data-ng-init=\'navCollapsed = true\' data-ng-click=\'navCollapsed = !navCollapsed\'>\n' +
    '          <span class=\'sr-only\'>Toggle Navigation</span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '      <div class=\'navbar-collapse collapse navbar-right\' collapse=\'navCollapsed\'>\n' +
    '        <ul class=\'nav navbar-nav\'>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="main.home">Home</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">About</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">PayWall</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Benefits</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Contact</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a href="" ng-click="main.launchLogin()">Login</a></li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div> -->\n' +
    '  <div class="row gradient">\n' +
    '    <!-- <div class=\'navbar navbar-inverse navbar-main\' role=\'navigation\'>\n' +
    '      <div class=\'container-fluid\'>\n' +
    '        <div class=\'navbar-header\'>\n' +
    '          <p class="navbar-brand">\n' +
    '            <img alt="Brand" src="./images/liquidedge-logo-act.png">\n' +
    '          </p>\n' +
    '          <button class=\'navbar-toggle collapsed\' type=\'button\' data-ng-init=\'navCollapsed = true\' data-ng-click=\'navCollapsed = !navCollapsed\'>\n' +
    '            <span class=\'sr-only\'>Toggle Navigation</span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <div class=\'navbar-collapse collapse navbar-right\' collapse=\'navCollapsed\'>\n' +
    '          <ul class=\'nav navbar-nav\'>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="main.home">Home</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">About</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">PayWall</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Benefits</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Contact</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a href="" ng-click="main.launchLogin()">Login</a></li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div> -->\n' +
    '    <div data-ui-view>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('main/views/login.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '  <p>Enter your account details in the form below</p>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="modal.message.type">\n' +
    '      <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="modal.dismiss()" type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span> {{modal.message.msg}}\n' +
    '      </div>\n' +
    '  </div>\n' +
    '  <form name="form" ng-submit="modal.login()" role="form">\n' +
    '    <div class="form-group">\n' +
    '      <label for="username" class="control-label pull-left">LOGIN</label>\n' +
    '      <input type="text" class="form-control" id="username" placeholder="Username" ng-model="modal.loginDetails.username">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="password" class="control-label pull-left">PASSWORD</label>\n' +
    '      <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="modal.loginDetails.password">\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-hide="true">\n' +
    '      <label class="checkbox-inline pull-left">\n' +
    '        <input type="checkbox"> Keep me signed in\n' +
    '      </label>\n' +
    '      <label class="checkbox-inline pull-right modal-link">\n' +
    '        <a href="">Forgot Password?</a>\n' +
    '      </label>\n' +
    '    </div>\n' +
    '    <button type="submit" class="btn btn-danger btn-block">Login</button>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('newlist/views/newlist.tpl.html',
    '<div id="mailchimp">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">New Mailchimp List</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createNewList" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>List Details</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.name.$invalid && newlist.submitted }">\n' +
    '              <label for="name">Name</label>\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="newlist.list.name" placeholder="name" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.name.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Name is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.company.$invalid && newlist.submitted }">\n' +
    '              <label for="company">Company</label>\n' +
    '              <input type="text" class="form-control" id="company" name="company" ng-model="newlist.list.company" placeholder="company" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.company.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Company is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.address1.$invalid && newlist.submitted }">\n' +
    '              <label for="address1">Address Line 1</label>\n' +
    '              <input type="text" class="form-control" id="address1" name="address1" ng-model="newlist.list.address1" placeholder="address1" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.address1.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Address Line 1 is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label for="address2">Address Line 2</label>\n' +
    '              <input type="text" class="form-control" id="address2" name="address2" ng-model="newlist.list.address2" placeholder="address2">\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.city.$invalid && newlist.submitted }">\n' +
    '              <label for="city">City</label>\n' +
    '              <input type="text" class="form-control" id="city" name="city" ng-model="newlist.list.city" placeholder="city" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.city.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">City is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.state.$invalid && newlist.submitted }">\n' +
    '              <label for="state">State</label>\n' +
    '              <input type="text" class="form-control" id="state" name="state" ng-model="newlist.list.state" placeholder="state" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.state.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">State is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.zip.$invalid && newlist.submitted }">\n' +
    '              <label for="state">Zip</label>\n' +
    '              <input type="text" class="form-control" id="zip" name="zip" ng-model="newlist.list.zip" placeholder="zip" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.zip.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Zip is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.country.$invalid && newlist.submitted }">\n' +
    '              <label for="state">Country</label>\n' +
    '              <input type="text" class="form-control" id="country" name="country" ng-model="newlist.list.country" placeholder="country" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.country.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Country is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.permissionReminder.$invalid && newlist.submitted }">\n' +
    '              <label for="permissionReminder">Permission Reminder</label>\n' +
    '              <input type="text" class="form-control" id="permissionReminder" name="permissionReminder" ng-model="newlist.list.permissionReminder" placeholder="e.g. You are receiving this email because you signed up to xyz" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.permissionReminder.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Permission Reminder is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignFromName.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignFromName">Campaign From Name</label>\n' +
    '              <input type="text" class="form-control" id="campaignFromName" name="campaignFromName" ng-model="newlist.list.campaignFromName" placeholder="Campaign From Name" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignFromName.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign From Name is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignFromEmail.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignFromEmail">Campaign From Email</label>\n' +
    '              <input type="text" class="form-control" id="campaignFromEmail" name="campaignFromEmail" ng-model="newlist.list.campaignFromEmail" placeholder="Campaign From Email" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignFromEmail.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign From Email is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignSubject.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignSubject">Campaign Subject</label>\n' +
    '              <input type="text" class="form-control" id="campaignSubject" name="campaignSubject" ng-model="newlist.list.campaignSubject" placeholder="Campaign Subject" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignSubject.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign Subject is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignLanguage.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignLanguage">Campaign Language</label>\n' +
    '              <input type="text" class="form-control" id="campaignLanguage" name="campaignLanguage" ng-model="newlist.list.campaignLanguage" placeholder="Campaign Language" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignLanguage.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign Language is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignLanguage.$invalid && newlist.submitted }">\n' +
    '              <label for="emailTypeOption">Email Type Option</label>\n' +
    '              <select name="emailTypeOption" class="form-control" ng-model="newlist.list.emailTypeOption" required>\n' +
    '                <option value="true">True</option>\n' +
    '                <option value="false">False</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.emailTypeOption.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Email Type Option is required.</p>\n' +
    '            </div>\n' +
    '            <button ng-click="newlist.createList(!newList.createNewList.$invalid)" class="btn btn-danger right">Create List</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('networktype/views/networktype.tpl.html',
    '<div id="networktype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Network Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createnetworktype" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.name" placeholder="eg. Guest" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="networktype.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.details.usergroup" placeholder="eg. default">\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" required ng-show="networktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VLAN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="details" name="vlan" ng-model="networktype.networkType.vlanId" placeholder="eg. 700">\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.vlan.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Vlan is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="networktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NETWORK OPTIMISATION STRATEGIES</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-heading">\n' +
    '            <div class="row">\n' +
    '              <div class="col-md-8 col-md-offset-2">\n' +
    '                <div class="input-group">\n' +
    '                  <div class="input-group-addon">\n' +
    '                    <i class="fa fa-search"></i>\n' +
    '                  </div>\n' +
    '                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <ul class="">\n' +
    '                <li class="checkbox" ng-repeat="st in networktype.ntstrategies | filter:stSearch">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" data-checklist-model="networktype.selectedStrategies" checklist-value="st.id">\n' +
    '                    {{st.name}} - {{st.description}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="networktype.createNetworkType(!createnetworktype.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/setprovidersmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div class="scrollbox">\n' +
    '    <table class="table table-responsive eventsTable">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Id</th>\n' +
    '          <th>Provider</th>\n' +
    '          <th>Status</th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody ng-repeat="provider in setprovidersmodal.providers  track by $index">\n' +
    '        <tr>\n' +
    '          <td>\n' +
    '            {{provider.id}}\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            {{provider.name}}\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <button class="btn btn-danger btn-sm" ng-hide="provider.status" ng-click="setprovidersmodal.select($index)">Select</button>\n' +
    '            <button class="btn btn-success btn-sm" ng-show="provider.status" ng-click="setprovidersmodal.deselect($index)">Select <i class="fa fa-check" aria-hidden="true"></i></button>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="setprovidersmodal.ok()">Save</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="setprovidersmodal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/modalservice.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div ng-show="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-danger" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>\n' +
    '    <button class="btn btn-warning" type="button" ng-click="modalService.cancel()">{{modalService.message.cancel}}</button>\n' +
    '  </div>\n' +
    '  <div ng-hide="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('manageimages/views/uploadmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-hide="modal.image" class="image-box text-center" ngf-select ngf-drop accept="image/*" ngf-pattern="\'image/*\'" ngf-drag-over-class="dragover" ng-model="modal.image">\n' +
    '    <span class="glyphicon glyphicon-camera"></span>\n' +
    '    <h4>\n' +
    '      <strong>ADD AN IMAGE</strong>\n' +
    '    </h4>\n' +
    '    <p>Choose an image that captures your event</p>\n' +
    '  </div>\n' +
    '  <div class="row modalThumb">\n' +
    '    <img ngf-src="modal.image">\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-file btn-danger" type="file" ngf-select accept="image/*" ngf-max-height="1000" ngf-max-size="1MB" ng-model="modal.image">\n' +
    '        Select File</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label><h4>Keywords</h4></label>\n' +
    '    <textarea class="form-control" rows="3" ng-model="modal.description" placeholder="comma, seperated, values"></textarea>\n' +
    '  </div>\n' +
    '  <button class="btn btn-sm btn-danger" ng-click="modal.uploadFiles(modal.image)">Submit</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('manageimages/views/manageimages.tpl.html',
    '<div class="row row-align">\n' +
    '  <h2>Images\n' +
    '    <button class="btn btn-sm btn-danger pull-right mgn10r" ng-click="manageimages.uploadImage()">New Image</button>\n' +
    '  </h2>\n' +
    '</div>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <div class="input-group-addon"><i class="fa fa-search"></i></div>\n' +
    '            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="manageimages.limit" ng-options="limit for limit in manageimages.limits" ng-change="manageimages.setLimit(manageimages.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="image in manageimages.imageList | filter:imageSearch | limitTo:manageimages.limit">\n' +
    '          <td class="evntIMG">\n' +
    '            <div>\n' +
    '              <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <p>{{image.details.description}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-sm" ng-click="manageimages.deleteImage(image)">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span> Delete</button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('managenetwork/views/managenetwork.tpl.html',
    '<h2>Manage {{managenetwork.venue.venuetitle}} Network\n' +
    '</h2>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3 ng-show="managenetwork.showZones">Zones\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createZone()" tooltip-placement="left" tooltip="New Zone">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showAPoints">Access Points\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createAccesspoint()" tooltip-placement="left" tooltip="New Accesspoint">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showTypes">Network Types\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createNetworkType()" tooltip-placement="left" tooltip="New Network Type">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showInfra">Infrastructure\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createInfrastructure()" tooltip-placement="left" tooltip="New Infrastructure" ng-hide="managenetwork.infrastructures.length > 0">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showInfra)}">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'infra\')" aria-controls="network types" role="tab" data-toggle="tab">Infrastructure</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showZones)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'zone\')" aria-controls="zones" role="tab">Zones</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showAPoints)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'apoints\')" aria-controls="accesspoints" role="tab" data-toggle="tab">Access Points</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showTypes)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'types\')" aria-controls="network types" role="tab" data-toggle="tab">Network Types</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch" ng-show="managenetwork.showZones">\n' +
    '          <input type="text" class="form-control" placeholder="Search Accesspoints" ng-model="apSearch" ng-show="managenetwork.showAPoints">\n' +
    '          <input type="text" class="form-control" placeholder="Search Network Types" ng-model="ntSearch" ng-show="managenetwork.showTypes">\n' +
    '          <input type="text" class="form-control" placeholder="Search Infrastructure" ng-model="inSearch" ng-show="managenetwork.showInfra">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showZones">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr dir-paginate="zone in managenetwork.zones | orderBy:\'id\' | filter:zoneSearch | itemsPerPage: 10" pagination-id="zones">\n' +
    '            <td>\n' +
    '              <h4>{{zone.name}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>{{zone.details.description}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteZone(zone.id)" tooltip-placement="left" tooltip="Delete Zone">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editZone(zone.id)" tooltip-placement="left" tooltip="Edit zone">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="zones"></dir-pagination-controls>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showAPoints">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr dir-paginate="accesspoint in managenetwork.accesspoints | orderBy:\'ipAddress\' | filter:apSearch | itemsPerPage: 10" pagination-id="aps">\n' +
    '            <td>\n' +
    '              <h4> Name : {{accesspoint.name}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Delete Accesspoint">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Edit Accesspoint">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="aps"></dir-pagination-controls>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showTypes">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr ng-repeat="type in managenetwork.networkTypes | filter:ntSearch">\n' +
    '            <td>\n' +
    '              <h4>Name: {{type.name}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>vlan: {{type.vlanid}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteNetworkType(type.id)" tooltip-placement="left" tooltip="Remove Network Type">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editNetworkType(type.id)" tooltip-placement="left" tooltip="Edit Network Type">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showInfra">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr ng-repeat="type in managenetwork.infrastructures | filter:inSearch">\n' +
    '            <td>\n' +
    '              <h4>Provider: {{type.provider.name}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>Controller Address: {{type.controlleraddress}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editInfrastructure(type.id)" tooltip-placement="left" tooltip="Edit Infrastructure">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('mailchimp/views/mailchimp.tpl.html',
    '<div id="mailchimp">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Mailchimp Settings</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="mailchimpSettings"\n' +
    '      class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>API KEY</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group"\n' +
    '            ng-class="{ \'has-error\': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control"\n' +
    '              id="apiKey" name="apiKey"\n' +
    '              ng-model="mailchimp.apiKey"\n' +
    '              placeholder="eg. xxxxxxxxxx-xyz" required>\n' +
    '            </div>\n' +
    '            <div class="help-block"\n' +
    '            ng-messages="mailchimpSettings.apiKey.$error"\n' +
    '            ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">api key is required.</p>\n' +
    '            </div>\n' +
    '            <button ng-click="mailchimp.updateApiKey(!mailchimpSettings.$invalid)" class="btn btn-danger right">Update Api Key</button>\n' +
    '            <div>\n' +
    '              </br>\n' +
    '            </div>\n' +
    '            <div ng-show="mailchimp.ConfirmMessage">\n' +
    '              <div class="alert alert-success"\n' +
    '              role="alert" style="width: 40%;">\n' +
    '                <button ng-click="mailchimp.dismiss()"\n' +
    '                type="button" class="close" aria-label="Close">\n' +
    '                  <span aria-hidden="true">&times;</span>\n' +
    '                </button>\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign"\n' +
    '                aria-hidden="true"></span>\n' +
    '                <span class="sr-only">Alert:</span>\n' +
    '                {{mailchimp.Message}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="mailchimp.ErrorMessage">\n' +
    '              <div class="alert alert-danger" role="alert"\n' +
    '              style="width: 40%;">\n' +
    '                <button ng-click="mailchimp.dismissError()"\n' +
    '                type="button" class="close" aria-label="Close">\n' +
    '                  <span aria-hidden="true">&times;</span>\n' +
    '                </button>\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign"\n' +
    '                aria-hidden="true"></span>\n' +
    '                <span class="sr-only">Alert:</span>\n' +
    '                {{mailchimp.Message}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="mailchimpSettings"\n' +
    '      class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Lists</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <p>Current List ID: {{mailchimp.listId}}</p>\n' +
    '            <br/>\n' +
    '            <p>Choose new List from the dropdown menu or create a new list</p>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-9">\n' +
    '                  <select class="form-control" ng-model="mailchimp.newListId">\n' +
    '                    <option ng-repeat="list in mailchimp.lists"\n' +
    '                    value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '                <div class="col-xs-3">\n' +
    '                  <button ng-click="mailchimp.updateList()"\n' +
    '                  class="btn btn-danger right">Change List</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                </br>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <button ng-click="mailchimp.createNewList()"\n' +
    '                  class="btn btn-danger right">Create New List</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                </br>\n' +
    '              </div>\n' +
    '              <div ng-show="mailchimp.ListConfirmMessage">\n' +
    '                <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                  <button ng-click="mailchimp.listDismiss()" type="button" class="close" aria-label="Close">\n' +
    '                    <span aria-hidden="true">&times;</span>\n' +
    '                  </button>\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <span class="sr-only">Alert:</span>\n' +
    '                  {{mailchimp.ListMessage}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div ng-show="mailchimp.ListErrorMessage">\n' +
    '                <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                  <button ng-click="mailchimp.listDismissError()" type="button" class="close" aria-label="Close">\n' +
    '                    <span aria-hidden="true">&times;</span>\n' +
    '                  </button>\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <span class="sr-only">Alert:</span>\n' +
    '                  {{mailchimp.ListMessage}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('filtercreatemodal/views/filtercreatemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '  <div class="form-group">\n' +
    '    <label class="control-label" for="filterTypeSelect">Select Filter Type</label>\n' +
    '    <select class="form-control" name="filterTypeSelect" id="filterTypeSelect" ng-model="modalService.selected" ng-options="o.name for o in modalService.message.filters">\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div ng-show="modalService.selected">\n' +
    '    <div class="form-group">\n' +
    '      <label class="control-label" for="filterTypeSelect">Select Filter</label>\n' +
    '      <select class="form-control" name="filterSelect" id="filterSelect" ng-model="modalService.filterSelected">\n' +
    '        <option ng-repeat="option in modalService.selected.details.filterlist" value="{{option.value}}">{{option.name}}</option>\n' +
    '      </select>\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-show="modalService.filterSelected">\n' +
    '      <label class="control-label" for="description">Description</label>\n' +
    '      <textarea class="form-control" rows="3" ng-model="modalService.description"></textarea>\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-show="modalService.filterSelected">\n' +
    '      <label class="control-label">Set Bandwidth</label>\n' +
    '      <rzslider rz-slider-model="modalService.priceSlider.value" rz-slider-options="modalService.priceSlider.options"></rzslider>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div>\n' +
    '    <button class="btn btn-danger" type="button" ng-click="modalService.ok()">Submit</button>\n' +
    '    <button class="btn btn-warning" type="button" ng-click="modalService.cancel()">Cancel</button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('guestnetworksettings/views/guestnetworksettings.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Guest Network Settings\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addgns" tooltip-placement="left" tooltip="Add new Guest Network Settings">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" class="active">\n' +
    '            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="item in guestnetworksettings.gnsList">\n' +
    '          <td>\n' +
    '            <h4>Provider:</h4>\n' +
    '            <p>{{item.provider}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Description:</h4>\n' +
    '              <div class="seemore" ng-class="{show: show}">\n' +
    '                {{item.details | json}}\n' +
    '              </div>\n' +
    '            <a ng-click="show = true" ng-hide="show">See More</a>\n' +
    '            <a ng-click="show = false" ng-show="show">Hide</a>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="guestnetworksettings.deletegns(item.id)" tooltip-placement="left" tooltip="Delete">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ui-sref="admin.updategns({gnsid: item.id})" tooltip-placement="left" tooltip="Edit">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('guestnetworksettings/views/deletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Guest Network Setting?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="deleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="deleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editzone/views/editzone.tpl.html',
    '<div id="editzone">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Zone</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ZONE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="editzone.zone.name" placeholder="eg. Some zone" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editzone.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WLAN GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.wlangroup" placeholder="eg. zone covers conference centre main suite">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ACCESSPOINTS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body" ng-if="editzone.selectedaccesspoints.length > 0">\n' +
    '            <h5>Selected</h5>\n' +
    '            <ul id="double">\n' +
    '                <li class="iplisting" dir-paginate="ap in editzone.selectedaccesspoints | filter:apSearch | itemsPerPage: 15 track by $index">\n' +
    '                  <label>\n' +
    '                    <button class="x" ng-click="editzone.removeAp($index)">&#10006;</button> {{ap.ipaddress}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <h5>Available</h5>\n' +
    '            <ul id="double">\n' +
    '                <li class="iplisting" dir-paginate="ap in editzone.accesspoints | orderBy: \'ipaddress\'| filter:apSearch | itemsPerPage: 15">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox"  checklist-model="editzone.selectedaccesspoints" checklist-value="ap"> {{ap.ipaddress}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editzone.updateZone()" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editsplashtype/views/editsplashtype.tpl.html',
    '<div id="editsplashtype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Splashpage Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="editSplash" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': editSplash.name.$invalid && editsplashtype.submitted }">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="editsplashtype.splashtype.name" placeholder="eg. Video Ad" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="editSplash.name.$error" ng-if="editsplashtype.submitted">\n' +
    '              <p ng-message="required">name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': editSplash.description.$invalid && editsplashtype.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="editsplashtype.splashtype.description" placeholder="eg. Some Description" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="editSplash.description.$error" ng-if="editsplashtype.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editsplashtype.editSplash(!editSplash.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editrole/views/editrole.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Organisation Role</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editrole.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '          <button ng-click="editrole.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{editrole.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && editrole.updateOrganisationRole()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="editrole.organisationRole.name" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Name is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editvenue/views/editvenue.tpl.html',
    '<div id="newVenue">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit\n' +
    '        {{editvenue.venue.venueTitle}}\n' +
    '        Venue</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createVenue" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.venuename.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="venuename" name="venuename" ng-model="editvenue.venue.venueTitle" placeholder="eg. Some Venue" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.venuename.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">venue name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{editvenue.venue.imageuri}}" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="editvenue.selectImage()">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                  <button class="btn btn-danger" ng-click="editvenue.uploadImage()">\n' +
    '                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-show="!editvenue.venue.imageUrl && editvenue.submitted">\n' +
    '              <p>Image is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>EMAIL</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactEmail.$invalid && editvenue.submitted }">\n' +
    '              <input type="email" class="form-control" id="contactEmail" name="contactEmail" ng-model="editvenue.venue.contactEmail" placeholder="eg. name@email.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactEmail.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">email is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PHONE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactPhone.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="contactPhone" name="contactPhone" ng-model="editvenue.venue.contactPhone" placeholder="eg. 081 555 556" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactPhone.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">phone is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.description.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="editvenue.venue.description" placeholder="eg. description ..." required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.description.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOCATION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.location.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="location" name="location" ng-model="editvenue.venue.location" placeholder="eg. Address, County" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.location.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">location is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WEBSITE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.website.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="website" name="website" ng-model="editvenue.venue.website" placeholder="eg. someurl.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.website.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">website is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editvenue.updateVenue(!createVenue.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editslt/views/editslt.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Social Login Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editslt.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '          <button ng-click="editslt.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{editslt.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="editslt.slt.name" class="col-md-8 col-md-offset-2" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Name is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{editslt.slt.imageuri}}" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="editslt.selectImage()" tooltip-placement="left" tooltip="Select Image" name="selectimage">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                  <button class="btn btn-danger" ng-click="editslt.uploadImage()" tooltip-placement="left" tooltip="Upload Image" name="uploadimage">\n' +
    '                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-show="!editslt.slt.imageuri">\n' +
    '              <p>Image is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editslt.updateSLT()" class="btn btn-danger btn-md">Submit</button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editprovider/views/editprovider.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Provider</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="form" ng-submit="editprovider.updateProvider(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="organisationTitle" placeholder="Provider Name" ng-model="editprovider.providerDetails.name" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Provider name is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Details</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" placeholder="Details" ng-model="editprovider.providerDetails.details" required>\n' +
    '              <div ng-show="form.details.$invalid">\n' +
    '                <p>Details are required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editoperatorpwd/views/editoperatorpwd.tpl.html',
    '<div class="panel panel-default evcConf">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3 class="text-center">Update Operator Password</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div ng-show="editoperatorpwd.ErrorMessage">\n' +
    '      <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '        <button ng-click="editoperatorpwd.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '          <span aria-hidden="true">&times;</span>\n' +
    '        </button>\n' +
    '        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '        <span class="sr-only">Error:</span>\n' +
    '        {{editoperatorpwd.Message}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <form name="form" ng-submit="form.$valid && editoperatorpwd.updateOperatorPassword()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Password</h4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <input type="password" class="form-control" id="password" name="password" placeholder="Password" ng-model="editoperatorpwd.operatorDetails.password" required>\n' +
    '            <div ng-show="form.password.$invalid">\n' +
    '              <p>Password is required!</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Confirm Password</h4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <input type="password" class="form-control" id="password2" name="password2" placeholder="Confirm Password" ng-model="editoperatorpwd.operatorDetails.password2" same-as required>\n' +
    '            <div ng-show="form.password2.$error.required">\n' +
    '              <p>Confirm Password is required!</p>\n' +
    '            </div>\n' +
    '            <div ng-show="form.password2.$error.noMatch">\n' +
    '              <p>Passwords do not match!</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editorganisation/views/editorganisation.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Organisation</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="form" ng-submit="editorganisation.updateOrganisation(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Organisation Title</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="organisationTitle" name="organisationTitle" placeholder="Organisation\'s Title" ng-model="editorganisation.organisationDetails.organisationtitle" required>\n' +
    '              <div ng-show="form.organisationTitle.$invalid">\n' +
    '                <p>Organisation Title is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Contact Email</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="email" class="form-control" id="email" name="email" placeholder="A contact email" ng-model="editorganisation.organisationDetails.details.email" required>\n' +
    '              <div ng-show="form.email.$invalid">\n' +
    '                <p>Email is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Contact Phone</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="phone" name="phone" placeholder="Contact Number" ng-model="editorganisation.organisationDetails.details.phone" required>\n' +
    '              <div ng-show="form.phone.$invalid">\n' +
    '                <p>Phone number is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row tabrow">\n' +
    '          <label for="premium" class="control-label">\n' +
    '            <input type="checkbox" checked="false" id="premium" name="premium" ng-model="editorganisation.premium">\n' +
    '            Premium</label>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editnetworktype/views/editnetworktype.tpl.html',
    '<div id="editnetworktype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Network Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="editnetworkType" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="name" ng-model="editnetworktype.networkType.name" placeholder="eg. default" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.name.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="name" ng-model="editnetworktype.networkType.details.usergroup" placeholder="eg. Guest" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.name.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VLAN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="details" name="vlan" ng-model="editnetworktype.networkType.vlanid" placeholder="eg. 700" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.vlan.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Vlan is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NETWORK OPTIMISATION STRATEGIES</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-heading">\n' +
    '            <div class="row">\n' +
    '              <div class="col-md-8 col-md-offset-2">\n' +
    '                <div class="input-group">\n' +
    '                  <div class="input-group-addon">\n' +
    '                    <i class="fa fa-search"></i>\n' +
    '                  </div>\n' +
    '                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <ul class="">\n' +
    '                <li class="checkbox" ng-repeat="strategy in editnetworktype.ntstrategies">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" data-checklist-model="editnetworktype.selectedStrategies" checklist-value="strategy.id">\n' +
    '                    {{strategy.name}}\n' +
    '                    -\n' +
    '                    {{strategy.description}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editnetworktype.updateNetworkType(!editnetworkType.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editoperator/views/editoperator.tpl.html',
    '<div id="editoperator">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '        <h3 class="text-center">Update Operator</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createOperator" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>OPERATOR NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.operatorname.$invalid && editoperator.submitted }">\n' +
    '              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="editoperator.operator.name" placeholder="eg. First Last" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.operatorname.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">operator name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOGIN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.username.$invalid && editoperator.submitted }">\n' +
    '              <input type="email" class="form-control" id="username" name="username" ng-model="editoperator.operator.login" placeholder="eg. somename@someemail.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.username.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">login is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ROLE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && editoperator.submitted }">\n' +
    '              <select id="role" name="role" class="form-control" ng-model="editoperator.operator.role" ng-options="role.id as role.name for role in editoperator.roles" required>\n' +
    '                <option value="">Select A Role</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editoperator.operator.role == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Venue</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && newoperator.submitted }">\n' +
    '              <select id="venue" name="venue" class="form-control" ng-model="editoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in editoperator.venues" ng-change="editoperator.setVenue(editoperator.operator.details.venue)">\n' +
    '                <option value="">Select A Venue</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editoperator.updateOperator(!createOperator.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editclstype/views/editclstype.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center"> Edit Cloud Service Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editclstype.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '          <button ng-click="editclstype.dismiss()"\n' +
    '          type="button" class="close"\n' +
    '          aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{editclstype.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="editclstype.updateCLT(form.$valid)"\n' +
    '      role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control"\n' +
    '               id="name" name="name" placeholder="Name"\n' +
    '               ng-model="editclstype.cloudservicetypeDetails.name"\n' +
    '               class="col-md-8 col-md-offset-2" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Name is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Description</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control"\n' +
    '               id="description" name="description" placeholder="description"\n' +
    '               ng-model="editclstype.cloudservicetypeDetails.description"\n' +
    '               class="col-md-8 col-md-offset-2" required>\n' +
    '              <div ng-show="form.description.$invalid">\n' +
    '                <p>Description is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button  class="btn btn-danger btn-md">Submit</button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editinfrastructure/views/editinfrastructure.tpl.html',
    '<div id="infra">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Infrastructure</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createinfrastructure" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <select class="form-control" name="provider"\n' +
    '              ng-options="option.id as option.name for option in editinfrastructure.providerTypes"\n' +
    '              ng-model="editinfrastructure.infrastructure.provider"\n' +
    '              ng-change="editinfrastructure.setProvider(editinfrastructure.infrastructure.provider)">\n' +
    '            </select>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.provider.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Controller Address</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="editinfrastructure.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 3">Authentication</h4>\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 2">Site ID</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="editinfrastructure.infrastructure.authentication" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.authentication.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Authentication is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Username</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="editinfrastructure.infrastructure.username" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.username.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="editinfrastructure.infrastructure.password" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>SSID Limit</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="editinfrastructure.infrastructure.ssidlimit" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editinfrastructure.edit(!createinfrastructure.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editclservice/views/editclservice.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center"> Edit Cloud Service</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div ng-show="editclservice.ErrorMessage">\n' +
    '          <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '            <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '              <span aria-hidden="true">&times;</span>\n' +
    '            </button>\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <span class="sr-only">Error:</span>\n' +
    '            {{editclservice.Message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <form name="form" ng-submit="editclservice.udpateCloudService(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '          <div>\n' +
    '            <div class="panel panel-default panel-form">\n' +
    '              <div class="panel-heading clearfix">\n' +
    '                <h4>Cloud Service Type</h4>\n' +
    '              </div>\n' +
    '              <div class="panel-body">\n' +
    '                <div class="form-group">\n' +
    '                  <select class="form-control"\n' +
    '                  name="type" ng-model="editclservice.cloudserviceDetails.typeid"\n' +
    '                  ng-options="type.id as type.name for type in editclservice.cloudservicetypes"\n' +
    '                  ng-change="editclservice.setType(editclservice.cloudserviceDetails.typeid)" required>\n' +
    '                  </select>\n' +
    '                  <div ng-show="form.type.$invalid">\n' +
    '                    <p> Type is required!\n' +
    '                    </p>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-repeat="type in editclservice.cloudservicetypes" ng-show="type.id == editclservice.cloudserviceDetails.typeid" ng-if="type.name !== \'MailChimp\'">\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div class="panel panel-default panel-form">\n' +
    '                  <div class="panel-heading clearfix">\n' +
    '                    <h4>API key</h4>\n' +
    '                  </div>\n' +
    '                  <div class="panel-body">\n' +
    '                    <div class="form-group">\n' +
    '                      <input type="text" class="form-control" id="apikey" name="apikey" placeholder="apikey" ng-model="editclservice.cloudserviceDetails.apikey" class="col-md-8 col-md-offset-2" required>\n' +
    '                      <div ng-show="form.apikey.$invalid">\n' +
    '                        <p>API key is required!</p>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-repeat="type in editclservice.cloudservicetypes"\n' +
    '            ng-show="type.id == editclservice.cloudserviceDetails.typeid"\n' +
    '            ng-if="type.name === \'MailChimp\'">\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div class="panel-body">\n' +
    '                  <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '                    <div class="panel panel-default panel-form ">\n' +
    '                      <div class="panel-heading clearfix">\n' +
    '                        <h4>API KEY</h4>\n' +
    '                      </div>\n' +
    '                      <div class="panel-body">\n' +
    '                        <div class="form-group"\n' +
    '                        ng-class="{ \'has-error\': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">\n' +
    '                          <input type="text" class="form-control"\n' +
    '                          id="apiKey" name="apiKey"\n' +
    '                          ng-model="editclservice.cloudserviceDetails.apikey"\n' +
    '                          placeholder="eg. xxxxxxxxxx-xyz" required>\n' +
    '                        </div>\n' +
    '                        <div class="help-block" ng-messages="mailchimpSettings.apiKey.$error"\n' +
    '                        ng-if="editvenue.submitted">\n' +
    '                          <p ng-message="required">api key is required.</p>\n' +
    '                        </div>\n' +
    '                        <button type="button" ng-click="editclservice.updateApiKey(!mailchimpSettings.$invalid)"\n' +
    '                        class="btn btn-danger right">Update Api Key</button>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div ng-show="editclservice.ConfirmMessage">\n' +
    '                          <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{editclservice.Message}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div ng-show="editclservice.ErrorMessage">\n' +
    '                          <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="editclservice.dismissError()" type="button"\n' +
    '                            class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{editclservice.Message}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                </div>\n' +
    '                <div class="panel-body">\n' +
    '                  <form name="mailchimpSettings" novalidate>\n' +
    '                    <div class="panel panel-default panel-form">\n' +
    '                      <div class="panel-heading clearfix">\n' +
    '                        <h4>Lists </h4>\n' +
    '                      </div>\n' +
    '                      <div class="panel-body">\n' +
    '                        <p>Current List ID</p>\n' +
    '                        <br/>\n' +
    '                        <p>Choose new List from the dropdown menu or create a new list</p>\n' +
    '                        <div class="row">\n' +
    '                          <div class="col-xs-9">\n' +
    '                            <select class="form-control"\n' +
    '                            ng-model="editclservice.cloudserviceDetails.listidentifier">\n' +
    '                              <option ng-repeat="list in editclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                            </select>\n' +
    '                           </div>\n' +
    '                          <div class="col-xs-3">\n' +
    '                            <button ng-click="editclservice.updateList()" type="button" class="btn btn-danger right">Change List</button>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div class="row">\n' +
    '                          <div class="col-xs-12">\n' +
    '                            <button ng-click="editclservice.createNewList()" type="button" class="btn btn-info right">Create New List</button>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div ng-show="editclservice.ListConfirmMessage">\n' +
    '                          <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="editclservice.listDismiss()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{editclservice.ListMessage}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div ng-show="editclservice.ListErrorMessage">\n' +
    '                          <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="editclservice.listDismissError()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{editclservice.ListMessage}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <button class="btn btn-danger btn-md">Submit</button>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editcategory/views/editcategory.tpl.html',
    '<h2>Edit Strategy</h2>\n' +
    '<div ng-show="editcategory.ErrorMessage">\n' +
    '  <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '    <button ng-click="editcategory.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '      <span aria-hidden="true">&times;</span>\n' +
    '    </button>\n' +
    '    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '    <span class="sr-only">Error:</span>\n' +
    '    {{editcategory.Message}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<form name="form" role="form" novalidate>\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-body">\n' +
    '      <div class="form-group">\n' +
    '        <div class="col-xs-4">\n' +
    '          <label for="name" class="control-label pull-left">Name</label>\n' +
    '          <input type="text" class="form-control" id="name" name="name" placeholder="name" ng-model="editcategory.categoryDetails.name" required>\n' +
    '        </div>\n' +
    '        <div class="col-xs-4">\n' +
    '          <label for="description" class="control-label pull-left">Description</label>\n' +
    '          <input type="text" class="form-control" id="description" name="description" placeholder="a descritption about category" ng-model="editcategory.categoryDetails.description" required>\n' +
    '        </div>\n' +
    '        <div class="col-xs-4">\n' +
    '          <label for="type" class="control-label pull-left">Type</label>\n' +
    '          <select class="form-control" ng-model="type" ng-options="type.name for type in editcategory.types track by type.id"></select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      </br>\n' +
    '      </br>\n' +
    '      </br>\n' +
    '      <div class="form-group">\n' +
    '        <div class="col-xs-4">\n' +
    '          <label for="provider" class="control-label pull-left">Provider</label>\n' +
    '          <select class="form-control" ng-model="provider" ng-options="provider.name for provider in editcategory.providers track by provider.id"></select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </br>\n' +
    '  </br>\n' +
    '</br>\n' +
    '<div class="col-xs-4">\n' +
    '  <div ng-show="form.name.$invalid">\n' +
    '    <p>Name field is required!<p></div>\n' +
    '      <div ng-show="form.description.$invalid">\n' +
    '        <p>Description field is required!<p></div>\n' +
    '          <div ng-show="form.type.$invalid">\n' +
    '            <p>Type is required!<p></div>\n' +
    '              <div ng-show="form.provider.$invalid">\n' +
    '                <p>Provider is required!<p></div>\n' +
    '            </div>\n' +
    '            <div class="col-xs-4">\n' +
    '              <input type="checkbox" class="pull-right" checked="false" id="premium" name="premium" ng-model="editcategory.premium" style="margin-left: 5px;">\n' +
    '              <label for="premium" class="control-label pull-right">Premium:</label>\n' +
    '            </div>\n' +
    '            <div class="col-xs-4">\n' +
    '              <button class="btn btn-danger btn-block" ng-click="editcategory.updateCategory()">Update</button>\n' +
    '            </div>\n' +
    '          </br>\n' +
    '        </br>\n' +
    '      </br>\n' +
    '      <div class="col-xs-12 panel panel-default">\n' +
    '        <div class="panel-body">\n' +
    '          <h3>Network Filters</h3>\n' +
    '          <div class="row">\n' +
    '            <button type="button" class="btn btn-danger btn-md pull-right" ng-click="editcategory.addFilter()" tooltip-placement="left" tooltip="Add Filter">\n' +
    '              <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <table class="table" ng-show="editcategory.networkFilters.length > 0">\n' +
    '              <thead>\n' +
    '                <tr>\n' +
    '                  <th>Name</th>\n' +
    '                  <th>Description</th>\n' +
    '                  <th>Bandwidth Limit</th>\n' +
    '                  <th></th>\n' +
    '                </tr>\n' +
    '                <tbody>\n' +
    '                  <tr ng-repeat="filter in editcategory.networkFilters">\n' +
    '                    <td>{{filter.website}}</td>\n' +
    '                    <td>{{filter.details.description}}</td>\n' +
    '                    <td>{{filter.bandwidth}}\n' +
    '                      Mb</td>\n' +
    '                    <td>\n' +
    '                      <button class="btn btn-danger btn-md pull-right" ng-click="editcategory.deleteFilter(filter.id)">\n' +
    '                        <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                      </button>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </tbody>\n' +
    '              </thead>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <button button type="button" class="btn btn-danger pull-right mgn-top" ng-click="editcategory.done()">Done</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editap/views/editap.tpl.html',
    '<div id="addap">\n' +
    '  <div ng-show="editap.ErrorMessage">\n' +
    '    <div class="alert alert-danger" role="alert">\n' +
    '      <button ng-click="editap.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '        <span aria-hidden="true">&times;</span>\n' +
    '      </button>\n' +
    '      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '      <span class="sr-only">Error:</span> {{editap.Message}}\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Accesspoint</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createAccesspoint" class="col-md-8 col-md-offset-2" ng-submit="editap.updateAccesspoint(createAccesspoint.$valid)" role="form" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="editap.accesspoint.name" placeholder="eg. Name" ng-pattern="string" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.name.$invalid && editap.submitted">\n' +
    '              <p>Name is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>MAC ADDRESS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="hwAddr" name="hwAddr" ng-model="editap.accesspoint.hwAddr" placeholder="eg. 01:23:45:67:89:ab" ng-pattern="/^([a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}$)/" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.hwAddr.$invalid && editap.submitted">\n' +
    '              <p>Mac Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ipAddress</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="ipAddress" name="ipAddress" ng-model="editap.accesspoint.ipAddress" placeholder="eg. 10.37.23.126" ng-pattern=\'/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/\' ng-model-options="{ updateOn: \'blur\' }" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.ipAddress.$invalid && editap.submitted">\n' +
    '              <p>IP Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USERNAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="sshUsername" name="sshUsername" ng-model="editap.accesspoint.sshUsername" placeholder="eg. Accesspoint login username">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshUsername.$invalid && editap.submitted">\n' +
    '              <p>USERNAME is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="sshPassword" name="sshPassword" ng-model="editap.accesspoint.sshPassword" placeholder="eg. Accesspoint login password">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshPassword.$invalid && editap.submitted">\n' +
    '              <p>PASSWORD is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editadminpwd/views/editadminpwd.tpl.html',
    '<div>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Update Admin Password</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editadminpwd.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '          <button ng-click="editadminpwd.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{editadminpwd.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && editadminpwd.updateAdminPassword()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="form-group">\n' +
    '                <input type="password" class="form-control" id="password" name="password" placeholder="Password" ng-model="editadminpwd.adminDetails.password" required>\n' +
    '              </div>\n' +
    '              <div ng-show="form.password.$invalid">\n' +
    '                <p>Password is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Confirm Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password2" name="password2" placeholder="Confirm Password" ng-model="editadminpwd.adminDetails.password2" same-as required>\n' +
    '            </div>\n' +
    '            <div ng-show="form.password2.$error.required">\n' +
    '              <p>Confirm Password is required!</p>\n' +
    '            </div>\n' +
    '            <div ng-show="form.password2.$error.noMatch">\n' +
    '              <p>Passwords do not match!</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservicetypes/views/cloudtypedeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Type?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="cloudtypedeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="cloudtypedeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservicetypes/views/cloudservicetypes.tpl.html',
    '<div class="panel panel-default admin">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Cloud Service Types\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addcloudservicetype" tooltip-placement="left" tooltip="Add CloudService type">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Type" ng-model="categorySearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr <tr dir-paginate="type in cloudservicetypes.cloudservicetypes | filter:categorySearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{type.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Description:</h4>\n' +
    '            <p>{{type.description}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="cloudservicetypes.deleteEvent(type.id)" tooltip-placement="left" tooltip="Delete Cloud Service Type">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editclstype({typeid: type.id})" tooltip-placement="left" tooltip="Edit Cloud Service Type">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('editadmin/views/editadmin.tpl.html',
    '<div>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Admin</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editadmin.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '          <button ng-click="editadmin.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{editadmin.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && editadmin.updateAdmin()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Login</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="login" name="login" placeholder="Login" ng-model="editadmin.adminDetails.login" required>\n' +
    '              </div>\n' +
    '              <div ng-show="form.login.$invalid">\n' +
    '                <p>Login is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="editadmin.adminDetails.name" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Name is required!</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger btn-md">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservices/views/clservicedeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Service?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button"\n' +
    '  ng-click="clservicedeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button"\n' +
    '  ng-click="clservicedeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservices/views/cloudservices.tpl.html',
    '<div class="panel panel-default admin">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Cloud Service\n' +
    '        <button class="btn btn-md btn-danger pull-right"\n' +
    '        data-ui-sref="admin.addclservice({venueid: cloudservices.venueid, orgid: cloudservices.orgid})"\n' +
    '        tooltip-placement="left" tooltip="Add CloudService">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control"\n' +
    '          placeholder="Search Service" ng-model="categorySearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="services in cloudservices.cloudservices | filter:categorySearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Type:</h4>\n' +
    '            <p>{{services.type}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>APIKEY:</h4>\n' +
    '            <p>{{services.apikey}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md"\n' +
    '              ng-click="cloudservices.deleteEvent(services.id)" tooltip-placement="left" tooltip="Delete Cloud Service">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md"\n' +
    '              ui-sref="admin.editclservice({venueid: cloudservices.venueid, orgid: cloudservices.orgid, cloudservicesid: services.id})" tooltip-placement="left" tooltip="Edit Cloud Service">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('categories/views/orgdeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Organisation?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orgdeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orgdeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('categories/views/categories.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Network Optimisation Strategies\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addcategory" tooltip-placement="left" tooltip="New Strategy">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation" class="active">\n' +
    '            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" >\n' +
    '            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>\n' +
    '          </li>\n' +
    '          <li role="presentation">\n' +
    '            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Strategy" ng-model="categorySearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="category in categories.categories | filter:categorySearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{category.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Provider:</h4>\n' +
    '            <p>{{categories.getProviderName(category.provider)}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Description:</h4>\n' +
    '            <p>{{category.description}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="categories.deleteEvent(category.id)" tooltip-placement="left" tooltip="Delete">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editcategory({categoryid: category.id})" tooltip-placement="left" tooltip="Edit">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('categories/views/catdeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Strategy?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="catdeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="catdeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('analytics/views/analytics.tpl.html',
    '<div class="panel panel-default admin">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Performance Analytics</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-body">\n' +
    '      <label for="org" class="control-label pull-left">Organisation</label>\n' +
    '      <select class="form-control" ng-model="org" ng-options="org as org.organisationtitle for org in analytics.organisations" ng-change="analytics.getAllVenues(org.id)">\n' +
    '        <option value="">Select an Organisation</option>\n' +
    '      </select>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default" ng-show="analytics.venuesLoaded && !analytics.venuesLoadedError">\n' +
    '    <div class="panel-body">\n' +
    '      <label for="venue" class="control-label pull-left">Venue</label>\n' +
    '      <select class="form-control" ng-model="venue" ng-options="venue as venue.venuetitle for venue in analytics.venues" ng-change="analytics.getAllEvents(venue.id)">\n' +
    '        <option value="">Select a Venue</option>\n' +
    '      </select>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default" ng-show="analytics.eventsLoaded && !analytics.eventsLoadedError">\n' +
    '    <div class="panel-body">\n' +
    '      <label for="event" class="control-label pull-left">Event</label>\n' +
    '      <select class="form-control" ng-model="event" ng-options="event as event.name for event in analytics.events" ng-change="analytics.getAnalyticsByEvent(event.id)">\n' +
    '        <option value="">Select an Event</option>\n' +
    '      </select>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-show="analytics.ErrorMessage">\n' +
    '    <div class="alert alert-danger" role="alert">\n' +
    '      <button ng-click="analytics.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '        <span aria-hidden="true">&times;</span>\n' +
    '      </button>\n' +
    '      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '      <span class="sr-only">Error:</span> {{analytics.Message}}\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default" ng-show="analytics.analytics && !analytics.eventAnalyticsLoadedError && !analytics.schedulerCreateAnalyticsLoadedError && !analytics.schedulerCreateAvgSumAnalyticsLoadedError && !analytics.schedulerDeleteAnalyticsLoadedError && !analytics.schedulerDeleteAvgSumAnalyticsLoadedError">\n' +
    '    <div class="panel-body">\n' +
    '      <accordion close-others="false">\n' +
    '        <div class="col-xs-12 col-ms-6 col-sm-6">\n' +
    '          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.schedulerGraph">\n' +
    '            <accordion-heading>\n' +
    '              <h3 class="panel-title">\n' +
    '                Scheduler Create Events (m)\n' +
    '                <i class="fa fa-circle-o pull-right"></i>\n' +
    '              </h3>\n' +
    '            </accordion-heading>\n' +
    '            <canvas id="bar" class="chart chart-bar" chart-data="analytics.schedulerCreateData" chart-labels="analytics.schedulerCreateLabels"></canvas>\n' +
    '          </accordion-group>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-ms-6 col-sm-6">\n' +
    '          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.schedulerGraph">\n' +
    '            <accordion-heading>\n' +
    '              <h3 class="panel-title">\n' +
    '                Scheduler Delete Events (m)\n' +
    '                <i class="fa fa-circle-o pull-right"></i>\n' +
    '              </h3>\n' +
    '            </accordion-heading>\n' +
    '            <canvas id="bar" class="chart chart-bar" chart-data="analytics.schedulerDeleteData" chart-labels="analytics.schedulerDeleteLabels"></canvas>\n' +
    '          </accordion-group>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-ms-6 col-sm-6">\n' +
    '          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.methodsGraph">\n' +
    '            <accordion-heading>\n' +
    '              <h3 class="panel-title">\n' +
    '                API Methods Average times (ms)\n' +
    '                <i class="fa fa-circle-o pull-right"></i>\n' +
    '              </h3>\n' +
    '            </accordion-heading>\n' +
    '            <canvas id="bar" class="chart chart-bar" chart-data="analytics.methodData" chart-labels="analytics.methodLabels"></canvas>\n' +
    '          </accordion-group>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-ms-6 col-sm-6">\n' +
    '          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.methodsGraph">\n' +
    '            <accordion-heading>\n' +
    '              <h3 class="panel-title">\n' +
    '                Total Number of API Methods calls\n' +
    '                <i class="fa fa-circle-o pull-right"></i>\n' +
    '              </h3>\n' +
    '            </accordion-heading>\n' +
    '            <canvas id="bar" class="chart chart-bar" chart-data="analytics.methodNumberData" chart-labels="analytics.methodLabels"></canvas>\n' +
    '          </accordion-group>\n' +
    '        </div>\n' +
    '      </accordion>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('admins/views/admins.tpl.html',
    '<div class="panel panel-default admin">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>LiquidEdge Administrators\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addadmin" tooltip-placement="left" tooltip="Add Admin">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Admin" ng-model="adminSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="adminobj in admins.admins | filter:adminSearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Login:</h4>\n' +
    '            <p>{{adminobj.login}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>Name:</h4>\n' +
    '            <p>{{adminobj.name}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="admins.deleteEvent(adminobj.id)" tooltip-placement="left" tooltip="Delete Admin">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editadmin({adminid: adminobj.id})" tooltip-placement="left" tooltip="Edit Admin">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editadminpwd({adminid: adminobj.id})" tooltip-placement="left" tooltip="Update Password">\n' +
    '                <i class="fa fa-lock"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('admins/views/admindeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Admin?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="admindeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="admindeleteModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('admin/views/admin.tpl.html',
    '<div id="wrapper" ng-class="{\'toggled\' : toggle}">\n' +
    '  <!-- Sidebar -->\n' +
    '  <div id="sidebar-wrapper">\n' +
    '    <ul class="sidebar-nav">\n' +
    '      <li class="sidebar-header">\n' +
    '        <a data-ui-sref="admin.admins">\n' +
    '          <div class="row">\n' +
    '            <img class="modal-img" src="./images/login/liquidedgelogodark.png">\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.admins">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-user"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Admins\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.categories">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-wifi"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Network Opt\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.organisations" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-sitemap"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Organisations\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.organisationroles" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-users"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Organisation Roles\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.leimages" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-picture-o"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Images\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.sociallogins" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-twitter"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Social Login Types\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.analytics" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-bar-chart"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Analytics\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li>\n' +
    '        <a data-ui-sref="admin.cloudservicetypes" >\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-cloud"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Cloud Service Types\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '  </div>\n' +
    '  <!-- /#sidebar-wrapper -->\n' +
    '  <!-- Page Content -->\n' +
    '  <div id="page-content-wrapper">\n' +
    '    <nav class="navbar navbar-default navbar-static-top container-fluid">\n' +
    '      <div class="container-fluid">\n' +
    '        <div class="navbar-header">\n' +
    '          <a class="navbar-brand" data-ng-init=\'toggle = false\' ng-click="toggle = !toggle" href="">\n' +
    '            <i class="fa fa-bars"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <ul class="nav navbar-nav navbar-right">\n' +
    '          <li dropdown is-open="it.isOpen" class="dropdownWidth">\n' +
    '            <a href="" dropdown-toggle ng-disabled="isDisabled" role="button">{{admin.profileInfo.userName}} <span class="caret"></span>\n' +
    '            </a>\n' +
    '            <ul class="dropdown-menu options-menu">\n' +
    '              <li role="separator" class="divider"></li>\n' +
    '              <li>\n' +
    '                <a href="" ng-click="admin.logout()">\n' +
    '                  <i class="fa fa-sign-out"></i> Log Out</a>\n' +
    '              </li>\n' +
    '              <li role="separator" class="divider"></li>\n' +
    '            </ul>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </nav>\n' +
    '    <div class="container-fluid">\n' +
    '      <div data-ui-view></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addslt/views/addslt.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Social Login Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div ng-show="addslt.ErrorMessage">\n' +
    '          <div class="alert alert-danger" role="alert">\n' +
    '            <button ng-click="addslt.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '              <span aria-hidden="true">&times;</span>\n' +
    '            </button>\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <span class="sr-only">Error:</span>\n' +
    '            {{addslt.Message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <form name="createSLT" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Name</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="addslt.slt.name" required>\n' +
    '                <div ng-show="form.name.$invalid">\n' +
    '                  <p>Name is required!\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>IMAGE</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <div class="row">\n' +
    '                  <div class="col-md-4 col-md-offset-4">\n' +
    '                    <img ng-src="{{staticUrl}}{{addslt.slt.imageuri}}" class="img-responsive"/>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                  <div class="col-md-12">\n' +
    '                    <button class="btn btn-danger" ng-click="addslt.selectImage()" tooltip-placement="left" tooltip="Select Image" name="selectimage">\n' +
    '                      <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                    </button>\n' +
    '                    <button class="btn btn-danger" ng-click="addslt.uploadImage()" tooltip-placement="left" tooltip="Upload Image" name="uploadimage">\n' +
    '                      <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="help-block" ng-show="!addslt.slt.imageuri">\n' +
    '                <p>Image is required.</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <button ng-click="addslt.createSLT()" class="btn btn-danger btn-md">Add Social Login</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addsplashtype/views/addsplashtype.tpl.html',
    '<div id="addsplashtype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Splashpage Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createSplash" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createSplash.name.$invalid && addsplashtype.submitted }">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="addsplashtype.splashtype.name" placeholder="eg. Video Ad" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createSplash.name.$error" ng-if="addsplashtype.submitted">\n' +
    '              <p ng-message="required">name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createSplash.description.$invalid && addsplashtype.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="addsplashtype.splashtype.description" placeholder="eg. Some Description" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createSplash.description.$error" ng-if="addsplashtype.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addsplashtype.createSplash(!createSplash.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addzone/views/addzone.tpl.html',
    '<div id="addzone">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Zone</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ZONE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="addzone.zone.name" placeholder="eg. Some zone" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addzone.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WLAN Group</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.wlangroup" placeholder="eg. default">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Accesspoints</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <ul id="double">\n' +
    '                <li class="iplisting" dir-paginate="ap in addzone.accesspoints | orderBy: \'ipaddress\'| filter:apSearch | itemsPerPage: 15">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" checklist-model="addzone.selectedAccesspoints" checklist-value="ap.id"> {{ap.ipaddress}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addzone.createZone()" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addprovider/views/addprovider.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Provider</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addprovider.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addprovider.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{addprovider.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && addprovider.createProvider()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="providerName" name="providerName" placeholder="Provider name" ng-model="addprovider.providerDetails.name" required>\n' +
    '              <div ng-show="form.providerName.$invalid">\n' +
    '                <p>Provider Name is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Details</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" placeholder="Details" ng-model="addprovider.providerDetails.details" required>\n' +
    '              <div ng-show="form.details.$invalid">\n' +
    '                <p>Details are required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addgns/views/addgns.tpl.html',
    '<div id="addgns">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h2 class="text-center">Create Guest Network Settings</h2>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createGns" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select id="provider" name="provider" class="form-control" ng-model="addgns.gns.provider" ng-options="provider.id as provider.name for provider in addgns.providers">\n' +
    '              <option value="">Select A Provider</option>\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Settings</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <textarea type="text" class="form-control" id="details" name="details" ng-model="addgns.gns.details" placeholder="{ Controls: \'Whitelists\' }" required>\n' +
    '              </textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addgns.creategns()" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addcategory/views/addcategory.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3 class="text-center" ng-hide="addcategory.onSave">Add Strategy</h3>\n' +
    '    <h3 class="text-center" ng-show="addcategory.onSave">Add Filters to Strategy</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div ng-show="addcategory.ErrorMessage">\n' +
    '      <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '        <button ng-click="addcategory.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '          <span aria-hidden="true">&times;</span>\n' +
    '        </button>\n' +
    '        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '        <span class="sr-only">Error:</span> {{addcategory.Message}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <form name="form" ng-submit="form.$valid && addcategory.createCategory()" role="form" class="col-md-8 col-md-offset-2" novalidate ng-hide="addcategory.onSave">\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Name</4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <input type="text" class="form-control" id="name" name="name" placeholder="name" ng-model="addcategory.categoryDetails.name" required>\n' +
    '            <div ng-show="form.name.$invalid">\n' +
    '              <p>Name field is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Description</4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <input type="text" class="form-control" id="description" name="description" placeholder="a descritption about category" ng-model="addcategory.categoryDetails.description" required>\n' +
    '            <div ng-show="form.description.$invalid">\n' +
    '              <p>Description field is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Type</h4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <select name="type" id="type" ng-model="addcategory.selectedType" class="form-control" required>\n' +
    '              <option ng-repeat="type in addcategory.types" value="{{type.id}}">{{type.name}}</option>\n' +
    '            </select>\n' +
    '            <div ng-show="form.type.$invalid">\n' +
    '              <p>Type is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Provider</h4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <select name="provider" id="provider" ng-model="addcategory.selectedProvider" class="form-control" required>\n' +
    '              <option ng-repeat="provider in addcategory.providers" value="{{provider.id}}">{{provider.name}}</option>\n' +
    '            </select>\n' +
    '            <div ng-show="form.provider.$invalid">\n' +
    '              <p>Provider is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default panel-form">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '          <h4>Premium</h4>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="form-group">\n' +
    '            <label for="premium" class="control-label pull-left">Premium:</label>\n' +
    '            <input type="checkbox" class="pull-left" checked="false" id="premium" name="premium" ng-model="addcategory.premium" style="margin-left: 5px;">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <button type="submit" class="btn btn-danger">Save</button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 panel panel-default" ng-show="addcategory.onSave">\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row">\n' +
    '        <h3>{{addcategory.newCategory.name}}</h3>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <button button type="button" class="btn btn-danger pull-right" ng-click="addcategory.addFilter()">Add Filter</button>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <table class="table" ng-show="addcategory.networkFilters.length > 0">\n' +
    '          <thead>\n' +
    '            <tr>\n' +
    '              <th>Name</th>\n' +
    '              <th>Description</th>\n' +
    '              <th>Bandwidth Limit</th>\n' +
    '              <th></th>\n' +
    '            </tr>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="filter in addcategory.networkFilters">\n' +
    '                <td>{{filter.website}}</td>\n' +
    '                <td>{{filter.details.description}}</td>\n' +
    '                <td>{{filter.bandwidth}} Mb</td>\n' +
    '                <td>\n' +
    '                  <button class="btn btn-danger btn-sm pull-right" ng-click="addcategory.deleteFilter(filter.id)">\n' +
    '                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span> Delete</button>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </thead>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '      <div class="row" ng-show="addcategory.networkFilters.length > 0">\n' +
    '        <button button type="button" class="btn btn-danger pull-right" ng-click="addcategory.done()">Done</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addorganisation/views/addorganisation.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Organisation</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addorganisation.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addorganisation.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{addorganisation.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && addorganisation.createOrganisation()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Organisation Title</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="organisationTitle" name="organisationTitle" placeholder="Organisation\'s Title" ng-model="addorganisation.organisationDetails.organisationTitle" required>\n' +
    '              <div ng-show="form.organisationTitle.$invalid">\n' +
    '                <p>Organisation Title is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Contact Email</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="email" class="form-control" id="email" name="email" placeholder="Contact Email" ng-model="addorganisation.organisationDetails.email" required>\n' +
    '              <div ng-show="form.email.$invalid">\n' +
    '                <p>Email is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Contact Phone</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="phone" name="phone" placeholder="Contact Number" ng-model="addorganisation.organisationDetails.phone" required>\n' +
    '              <div ng-show="form.phone.$invalid">\n' +
    '                <p>Phone number is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row tabrow">\n' +
    '          <label for="premium" class="control-label">\n' +
    '          <input type="checkbox" checked="false" id="premium" name="premium" ng-model="addorganisation.premium">\n' +
    '          Premium</label>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addrole/views/addrole.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Organisation Role</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addrole.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addrole.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{addrole.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && addrole.createOrganisationRole()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="addrole.organisationRole.name" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p>Name is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addoperator/views/addoperator.tpl.html',
    '<div id="addoperator">\n' +
    '  <h2 class="text-center">Create Operator</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createOperator" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>OPERATOR NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.operatorname.$invalid && addoperator.submitted }">\n' +
    '              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="addoperator.operator.name" placeholder="eg. First Last" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.operatorname.$error" ng-if="addoperator.submitted">\n' +
    '              <p ng-message="required">operator name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOGIN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.username.$invalid && addoperator.submitted }">\n' +
    '              <input type="email" class="form-control" id="username" name="username" ng-model="addoperator.operator.login" placeholder="eg. somename@someemail.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.username.$error" ng-if="addoperator.submitted">\n' +
    '              <p ng-message="required">login is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.password.$invalid && addoperator.submitted }">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="addoperator.operator.password" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.password.$error" ng-if="addoperator.submitted">\n' +
    '              <p ng-message="required">password is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ROLE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && addoperator.submitted }">\n' +
    '              <select id="role" name="role" class="form-control" ng-model="addoperator.operator.roleId" ng-options="role.id as role.name for role in addoperator.roles" required>\n' +
    '                <option value="">Select A Role</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="addoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addoperator.operator.roleId == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Venue</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && newoperator.submitted }">\n' +
    '              <select id="venue" name="venue" class="form-control" ng-model="addoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in addoperator.venues" ng-change="addoperator.setVenue(addoperator.operator.details.venue)">\n' +
    '                <option value="">Select A Venue</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addoperator.createOrganisationOperator(!createOperator.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addclservice/views/addclservice.tpl.html',
    '<div id="cloudservices">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Cloud Service</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div ng-show="addclservice.ErrorMessage">\n' +
    '          <div class="alert alert-danger" role="alert">\n' +
    '            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '              <span aria-hidden="true">&times;</span>\n' +
    '            </button>\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <span class="sr-only">Error:</span>\n' +
    '            {{addclservice.Message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <form name="form" ng-submit="addclservice.createCloudService({venueid: addclservice.venueid})" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '          <div>\n' +
    '            <div class="panel panel-default panel-form">\n' +
    '              <div class="panel-heading clearfix">\n' +
    '                <h4>Cloud Service Type </h4>\n' +
    '              </div>\n' +
    '              <div class="panel-body">\n' +
    '                <div class="form-group">\n' +
    '                  <select name="type" id="type" ng-model="addclservice.selectedType" class="form-control" required>\n' +
    '                    <option ng-repeat="type in addclservice.cloudservicetypes" value="{{type.id}}">{{type.name}}</option>\n' +
    '                  </select>\n' +
    '                  <div ng-show="form.type.$invalid">\n' +
    '                    <p> Type is required!\n' +
    '                    </p>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-repeat="type in addclservice.cloudservicetypes" ng-show="type.id == addclservice.selectedType" ng-if="type.name !== \'MailChimp\'">\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div>\n' +
    '                  <div class="panel-heading clearfix">\n' +
    '                    <h4>API key</h4>\n' +
    '                  </div>\n' +
    '                  <div class="panel-body">\n' +
    '                    <div class="form-group">\n' +
    '                      <input type="text" class="form-control" id="apiKey" name="apiKey" placeholder="eg.: 0jG4xjFXTdrDqmRbIPau0v1DZnjdpNQ4Lu07qqg" ng-model="addclservice.cloudserviceDetails.apikey" required>\n' +
    '                      <div ng-show="form.apikey.$invalid">\n' +
    '                        <p>API key is required!\n' +
    '                        </p>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-repeat="type in addclservice.cloudservicetypes" ng-show="type.id == addclservice.selectedType" ng-if="type.name == \'MailChimp\'">\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div class="panel-body">\n' +
    '                  <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '                    <div class="panel panel-default panel-form ">\n' +
    '                      <div class="panel-heading clearfix">\n' +
    '                        <h4>API KEY Type:</h4>\n' +
    '                      </div>\n' +
    '                      <div class="panel-body">\n' +
    '                        <div class="form-group" ng-class="{ \'has-error\': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">\n' +
    '                          <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx-xyz" required>\n' +
    '                        </div>\n' +
    '                        <div class="help-block" ng-messages="mailchimpSettings.apiKey.$error" ng-if="editvenue.submitted">\n' +
    '                          <p ng-message="required">api key is required.</p>\n' +
    '                        </div>\n' +
    '                        <button type="button"\n' +
    '                        ng-click="addclservice.updateApiKey(!mailchimpSettings.$invalid)" class="btn btn-danger right">Update Api Key</button>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div ng-show="addclservice.ConfirmMessage">\n' +
    '                          <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{addclservice.Message}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div ng-show="addclservice.ErrorMessage">\n' +
    '                          <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="addclservice.dismissError()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{addclservice.Message}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                </div>\n' +
    '                <div class="panel-body">\n' +
    '                  <form name="mailchimpSettings" novalidate>\n' +
    '                    <div class="panel panel-default panel-form">\n' +
    '                      <div class="panel-heading clearfix">\n' +
    '                        <h4>Lists</h4>\n' +
    '                      </div>\n' +
    '                      <div class="panel-body">\n' +
    '                        <p>Current List ID:{{addclservice.listId}}</p>\n' +
    '                        <br/>\n' +
    '                        <p>Choose new List from the dropdown menu or create a new list</p>\n' +
    '                        <div class="row">\n' +
    '                          <div class="col-xs-9">\n' +
    '                            <select class="form-control" ng-model="addclservice.newListId">\n' +
    '                              <option ng-repeat="list in addclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                            </select>\n' +
    '                          </div>\n' +
    '                          <div class="col-xs-3">\n' +
    '                            <button ng-click="addclservice.updateList()" type="button" class="btn btn-danger right">Change List</button>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div class="row">\n' +
    '                          <div class="col-xs-12">\n' +
    '                            <button ng-click="addclservice.createNewList()" type="button" class="btn btn-info right">Create New List</button>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div>\n' +
    '                          </br>\n' +
    '                        </div>\n' +
    '                        <div ng-show="addclservice.ListConfirmMessage">\n' +
    '                          <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="addclservice.listDismiss()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{addclservice.ListMessage}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <div ng-show="addclservice.ListErrorMessage">\n' +
    '                          <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                            <button ng-click="addclservice.listDismissError()" type="button" class="close" aria-label="Close">\n' +
    '                              <span aria-hidden="true">&times;</span>\n' +
    '                            </button>\n' +
    '                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                            <span class="sr-only">Alert:</span>\n' +
    '                            {{addclservice.ListMessage}}\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                </div>\n' +
    '\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addcloudservicetype/views/addcloudservicetype.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Cloud Service Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addcloudservicetype.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addcloudservicetype.dismiss()"\n' +
    '          type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{addcloudservicetype.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit="form.$valid && addcloudservicetype.createCloudServiceType()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name"\n' +
    '              name="name" placeholder="Name"\n' +
    '              ng-model="addcloudservicetype.cloudservicetypeDetails.name" required>\n' +
    '              <div ng-show="form.name.$invalid">\n' +
    '                <p> Name is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Description</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="description"\n' +
    '              name="description" placeholder="Description"\n' +
    '               ng-model="addcloudservicetype.cloudservicetypeDetails.description" required>\n' +
    '              <div ng-show="form.description.$invalid">\n' +
    '                <p>Description is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addap/views/addap.tpl.html',
    '<div id="addap">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Accesspoint</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addap.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addap.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span> {{addap.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="createAccesspoint" class="col-md-8 col-md-offset-2" ng-submit="addap.createAccesspoint(createAccesspoint.$valid)" role="form" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="addap.accesspoint.name" placeholder="eg. Name" ng-pattern="string" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.name.$invalid && addap.submitted">\n' +
    '              <p>Name Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>MAC ADDRESS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="hwAddr" name="hwAddr" ng-model="addap.accesspoint.hwAddr" placeholder="eg. 01:23:45:67:89:ab" ng-pattern="/^([a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}$)/" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.hwAddr.$invalid && addap.submitted">\n' +
    '              <p>Mac Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ipAddress</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="ipAddress" name="ipAddress" ng-model="addap.accesspoint.ipAddress" placeholder="eg. 10.37.23.126" ng-pattern=\'/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/\' ng-model-options="{ updateOn: \'blur\' }" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.ipAddress.$invalid && addap.submitted">\n' +
    '              <p>IP Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USERNAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="sshUsername" name="sshUsername" ng-model="addap.accesspoint.sshUsername" placeholder="eg. Accesspoint login username">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshUsername.$invalid && addap.submitted">\n' +
    '              <p>USERNAME is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="sshPassword" name="sshPassword" ng-model="addap.accesspoint.sshPassword" placeholder="eg. Accesspoint login password">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshPassword.$invalid && addap.submitted">\n' +
    '              <p>PASSWORD is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>\n' +
    '    </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('addadmin/views/addadmin.tpl.html',
    '<div class="newitem">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Admin</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div ng-show="addadmin.ErrorMessage">\n' +
    '          <div class="alert alert-danger" role="alert">\n' +
    '            <button ng-click="addadmin.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '              <span aria-hidden="true">&times;</span>\n' +
    '            </button>\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <span class="sr-only">Error:</span>\n' +
    '            {{addadmin.Message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <form name="form" ng-submit="form.$valid && addadmin.createAdmin()" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Login</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="login" name="login" placeholder="Login" ng-model="addadmin.adminDetails.login" required>\n' +
    '              </div>\n' +
    '              <div ng-show="form.login.$invalid">\n' +
    '                <p>Login is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Password</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="password" class="form-control" id="password" name="password" placeholder="Password" ng-model="addadmin.adminDetails.password" required>\n' +
    '              </div>\n' +
    '              <div ng-show="form.password.$error.required">\n' +
    '                <p>Password is required!\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Confirm Password</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="password" class="form-control" id="password2" name="password2" placeholder="Confirm Password" ng-model="addadmin.adminDetails.password2" same-as required>\n' +
    '              </div>\n' +
    '              <div ng-show="form.password2.$error.required">\n' +
    '                <p>Confirm Password is required!\n' +
    '                  <p></div>\n' +
    '                  <div ng-show="form.password2.$error.noMatch">\n' +
    '                    <p>Passwords do not match!\n' +
    '                    </p>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div class="panel-heading clearfix">\n' +
    '                  <h4>Name</h4>\n' +
    '                </div>\n' +
    '                <div class="panel-body">\n' +
    '                  <div class="form-group">\n' +
    '                    <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="addadmin.adminDetails.name" required>\n' +
    '                  </div>\n' +
    '                  <div ng-show="form.name.$invalid">\n' +
    '                    <p>Name is required!\n' +
    '                    </p>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '            </div>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('add-infrastructure/views/add-infrastructure.tpl.html',
    '<div id="infra">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Infrastructure</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createinfrastructure" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select class="form-control" name="provider" ng-model="addInfrastructure.infrastructure.provider" ng-options="option.id as option.name for option in addInfrastructure.providerTypes" ng-change="addInfrastructure.setProvider(addInfrastructure.infrastructure.provider)"></select>\n' +
    '            <div ng-messages="createinfrastructure.provider.$error" ng-if="addInfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="addInfrastructure.providerSelected">\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Controller Address</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="addInfrastructure.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 3">Authentication</h4>\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 2">Site ID</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="authentication" name="authentication" ng-model="addInfrastructure.infrastructure.authentication" placeholder="">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Username</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="username" name="username" ng-model="addInfrastructure.infrastructure.username" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.username.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">username is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Password</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="password" class="form-control" id="password" name="password" ng-model="addInfrastructure.infrastructure.password" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.password.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>SSID Limit</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="addInfrastructure.infrastructure.ssidlimit" required>\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addInfrastructure.create(!createinfrastructure.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('liquidedgeLeadmin').run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/same-as-directive.tpl.html',
    '<div>{{sameAs.name}}</div>');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name venues
   * @description
   *
   */
  angular
    .module('venues', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:VenuesCtrl
   *
   * @description
   *
   */
  VenuesCtrl.$inject = ["Infrastructureapi", "$log", "$q", "$stateParams", "Organisation", "$state", "$modal"];
  angular
    .module('venues')
    .controller('VenuesCtrl', VenuesCtrl);

  function VenuesCtrl(Infrastructureapi, $log, $q, $stateParams, Organisation, $state, $modal) {
    var vm = this;
    var orgid = $stateParams.orgid;

    vm.venues = [];
    vm.zones = [];
    vm.aps = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.venueSelected = {
      selected: false,
      venue: 0,
      zone: ''
    };

    vm.getZones = function (id) {
      if (vm.venueSelected.venue === id) {
        vm.venueSelected.selected = false;
        vm.venueSelected.venue = 0;
        vm.venueSelected.zone = '';
        vm.zones =[];
        vm.aps = [];
      } else {
        vm.zones =[];
        vm.aps = [];
        Infrastructureapi.getZones(id, orgid).then(function (res) {
          vm.venueSelected.selected = true;
          vm.venueSelected.venue = id;
          vm.zones = res.data.zones;
          console.log(res);
        }, function(err){
          console.log(err);
        });
      }
    };

    vm.getAccessPoints = function (zone) {
      vm.aps = [];
      vm.venueSelected.zone = zone.name;
      Infrastructureapi.getApByZone(vm.venueSelected.venue, zone.id, orgid).then(function (res) {
        vm.aps = res.data.apoints;
      }, function(err){
        console.log(err);
      });
    };

    vm.setLimit = function(limit) {
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.createZone = function(id) {
      $state.go('admin.addzone', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.createAccesspoint = function(id) {
      $state.go('admin.addap', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.manageNetwork = function(id) {
      $state.go('admin.managenetwork', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.newvenue = function() {
      $state.go('admin.newvenue', {
        orgid: orgid
      });
    };

    vm.editvenue = function(id) {
      $state.go('admin.editvenue', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.externalServices = function(id) {
      console.log('venueid: ' + id);
      $state.go('admin.cloudservices', {
        venueid: id,
        orgid: orgid
      });
    };

    function getVenues(orgid) {
      Infrastructureapi.getVenues(orgid).success(function(data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
        $log.info(vm.venues);
      }).error(function() {
        $log.info('get venues error');
      });
    }

    function getOrg(orgid) {
      Organisation.getOrganisationById(orgid).then(function(res) {
        vm.organisation = res.data.org;
        //console.log(vm.organisation);
      }, function(err) {
        console.log(err);
      });
    }

    vm.deleteVenue = function(id) {

      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/deletevenuemodal.tpl.html',
        controller: 'DeletevenuemodalCtrl as deleteVenueModal'
      });

      newModalInstance.result.then(function() {
        Infrastructureapi.deleteVenue(orgid, id).success(function() {
          getVenues(orgid);
        }).error(function(err) {
          var failedModalInstance = $modal.open({
            templateUrl: 'venues/views/deletefailedmodal.tpl.html',
            controller: 'DeletevenuemodalCtrl as deleteVenueModal'
          });

          failedModalInstance.result.then(function() {
            $log.error(err);
          });
        });
      });
    };

    getVenues(orgid);
    getOrg(orgid);
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  DeletevenuemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('venues')
    .controller('DeletevenuemodalCtrl', DeletevenuemodalCtrl);

  function DeletevenuemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletevenuemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name updategns
   * @description
   *
   */
  angular
    .module('updategns', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name updategns.controller:UpdategnsCtrl
   *
   * @description
   *
   */
  UpdategnsCtrl.$inject = ["Provider", "GnsApi", "$stateParams", "$state"];
  angular
    .module('updategns')
    .controller('UpdategnsCtrl', UpdategnsCtrl);

  function UpdategnsCtrl(Provider, GnsApi, $stateParams, $state) {
    var vm = this;
    vm.ctrlName = 'UpdategnsCtrl';
    console.log($stateParams);

    vm.getProviders = function(){
      Provider.getAllProviders().then(function(res){
        vm.providers = res.data.providers;
      }, function(err){
        console.log(err);
      });
    };

    vm.getProviders();

    vm.getgns = function() {
      GnsApi.getGNSById($stateParams.gnsid).success(function(data){
        vm.gns = data.gns;
        vm.gns.details = JSON.stringify(vm.gns.details);
      });
    };

    vm.getgns();

    vm.updategns = function() {
      GnsApi.udpateGNS($stateParams.gnsid, vm.gns).then(function successCallback(response) {
        console.log(response);
        $state.go('admin.guestnetworksettings');
      }, function errorCallback(response) {
        vm.error = response.status+': Please Ensure Valid JSON.';
        vm.errorLink = 'https://jsonlint.com/';
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name splashtype
   * @description
   *
   */
  angular
    .module('splashtype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name splashtype.controller:SplashtypeCtrl
   *
   * @description
   *
   */
  SplashtypeCtrl.$inject = ["Splashtype", "$log", "$modal", "Provider"];
  angular
    .module('splashtype')
    .controller('SplashtypeCtrl', SplashtypeCtrl);

  function SplashtypeCtrl(Splashtype, $log, $modal, Provider) {
    var vm = this;

    vm.splashtypes = [];

    function getSplashTypes() {
      Splashtype.getAll().then(function (resp) {
        vm.splashtypes = resp.data.splashTypes;
        $log.info(resp.data.splashTypes);
      }, function (err) {
        $log.info(err);
      });
    }

    vm.deleteSplashType = function (id) {
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Splashpage type, are you sure?'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Splashtype.deleteSplashType(id).then(function () {
          getSplashTypes();
        }, function (err) {
          $log.info(err);
        });
      });
    };

    vm.setProviders = function (id) {

      Splashtype.getProviders(id).success(function (result) {
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/setprovidersmodal.tpl.html',
          controller: 'SetProviderModalCtrl as setprovidersmodal',
          resolve: {
            providers: function(){ return result.providers;}
          }
        });
        modalInstance.result.then(function (list) {
          var data = {
            providers: list
          };
          Splashtype.setProviders(id, data).success(function (result) {
            console.log(result);
          });
        }, function (err) {
          console.log(err);
        });
      });



    };

    getSplashTypes();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name sociallogins
   * @description
   *
   */
  angular
    .module('sociallogins', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SocialloginsCtrl
   *
   * @description
   *
   */
  SocialloginsCtrl.$inject = ["$state", "Slt", "$modal"];
  angular
    .module('sociallogins')
    .controller('SocialloginsCtrl', SocialloginsCtrl);

  function SocialloginsCtrl($state, Slt, $modal) {
    var vm = this;
    vm.ctrlName = 'SocialloginsCtrl';

    vm.sltsLoaded = false;
    vm.sltsLoadedError = false;
    vm.slts = [];

    Slt.getAllSLTs().success(function (data) {
      vm.slts = data.slts;
      console.log(vm.slts);
      vm.sltsLoaded = true;
    }).error(function (err) {
      vm.sltsLoadedError = true;
      vm.sltsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllSLTs() {
      Slt.getAllSLTs().success(function (data) {
        vm.slts = data.slts;
        vm.sltsLoaded = true;
      }).error(function (err) {
        vm.sltsLoadedError = true;
        vm.sltsLoaded = true;
      console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'sociallogins/views/sltdeletemodal.tpl.html',
        controller: 'SltdeletemodalCtrl as sltdeleteModal'
      });

      modalInstance.result.then(function () {
        Slt.deleteSLT(id).success(function () {
          reloadAllSLTs();
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.setProviders = function (id) {

      Slt.getProviders(id).success(function (result) {
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/setprovidersmodal.tpl.html',
          controller: 'SetProviderModalCtrl as setprovidersmodal',
          resolve: {
            providers: function(){ return result.providers;}
          }
        });
        modalInstance.result.then(function (list) {
          var data = {
            providers: list
          };
          Slt.setProviders(id, data).success(function (result) {
            console.log(result);
          });
        }, function (err) {
          console.log(err);
        });
      });



    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SltdeletemodalCtrl
   *
   * @description
   *
   */
  SltdeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('sociallogins')
    .controller('SltdeletemodalCtrl', SltdeletemodalCtrl);

  function SltdeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'SltdeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name selectimage
   * @description
   *
   */
  angular
    .module('selectimage', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name selectimage.controller:SelectimageCtrl
   *
   * @description
   *
   */
  SelectimageCtrl.$inject = ["$modalInstance", "Infrastructureapi", "message"];
  angular
    .module('selectimage')
    .controller('SelectimageCtrl', SelectimageCtrl);

  function SelectimageCtrl($modalInstance, Infrastructureapi, message) {
    var vm = this;

    console.log(message);

    Infrastructureapi.getImages(message.orgid).success(function (data) {
      vm.imageList = data.images;
      console.log(data.images);
    });

    vm.selectedImage = {};

    vm.ok = function () {
      $modalInstance.close(vm.selectedImage);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };



  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name providers
   * @description
   *
   */
  angular
    .module('providers', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name providers.controller:ProvidersCtrl
   *
   * @description
   *
   */
  ProvidersCtrl.$inject = ["$state", "Provider", "$modal"];
  angular
    .module('providers')
    .controller('ProvidersCtrl', ProvidersCtrl);

  function ProvidersCtrl($state, Provider, $modal) {
    var vm = this;
    vm.ctrlName = 'ProvidersCtrl';

    vm.providersLoaded = false;
    vm.providersLoadedError = false;
    vm.providers = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      vm.providersLoaded = true;
    }).error(function (err) {
      vm.providersLoadedError = true;
      vm.providersLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllProviders() {
      Provider.getAllProviders().success(function (data) {
        vm.providers = data.providers;
        vm.providersLoaded = true;
      }).error(function (err) {
        vm.providersLoadedError = true;
        vm.providersLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'providers/views/delprovider.tpl.html',
        controller: 'DelproviderCtrl as delprovider'
      });

      modalInstance.result.then(function () {
        Provider.deleteProvider(id).success(function () {
          reloadAllProviders();
        }).error(function (err) {
          console.log('Error: ', err);
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name providers.controller:DelproviderCtrl
   *
   * @description
   *
   */
  DelproviderCtrl.$inject = ["$modalInstance"];
  angular
    .module('providers')
    .controller('DelproviderCtrl', DelproviderCtrl);

  function DelproviderCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DelproviderCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name organisations
   * @description
   *
   */
  angular
    .module('organisations', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  OrgdeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('organisations')
    .controller('OrgdeletemodalCtrl', OrgdeletemodalCtrl);

  function OrgdeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OrgdeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrganisationsCtrl
   *
   * @description
   *
   */
  OrganisationsCtrl.$inject = ["$state", "Organisation", "$modal"];
  angular
    .module('organisations')
    .controller('OrganisationsCtrl', OrganisationsCtrl);

  function OrganisationsCtrl($state, Organisation, $modal) {
    var vm = this;
    vm.ctrlName = 'OrganisationsCtrl';

    vm.organisationsLoaded = false;
    vm.organisationsLoadedError = false;
    vm.organisations = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Organisation.getAllOrganisations().success(function (data) {
      vm.organisations = data.orgs;
      vm.organisationsLoaded = true;
    }).error(function (err) {
      vm.organisationsLoadedError = true;
      vm.organisationsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllOrganisations() {
      Organisation.getAllOrganisations().success(function (data) {
        vm.organisations = data.orgs;
        vm.organisationsLoaded = true;
      }).error(function (err) {
        vm.organisationsLoadedError = true;
        vm.organisationsLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'organisations/views/orgdeletemodal.tpl.html',
        controller: 'OrgdeletemodalCtrl as orgdeleteModal'
      });

      modalInstance.result.then(function () {
        Organisation.deleteOrganisation(id).success(function () {
          reloadAllOrganisations();
        }).error(function (err) {
          console.log('Error: ', err);
          if(err.message.code === '23503'){
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Organisation! One or more operator(s) depend on this Organisation.';
          }
          else{
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name orgInfrastructures
   * @description
   *
   */
  angular
    .module('orgInfrastructures', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  OrgInfraeditmodalCtrl.$inject = ["$modalInstance", "Infrastructureapi", "Provider", "$log", "organisation", "infrastructureId"];
  angular
    .module('orgInfrastructures')
    .controller('OrgInfraeditmodalCtrl', OrgInfraeditmodalCtrl);

  function OrgInfraeditmodalCtrl($modalInstance, Infrastructureapi, Provider, $log, organisation, infrastructureId) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.orgid = organisation;
    vm.infrastructureId = infrastructureId;
    vm.selectedProvider = 0;
    vm.providers = [];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      if(vm.matchProvider('xirrus', provider)){
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };


    Provider.getAllProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.editInfrastructure = function() {
      Infrastructureapi.updateOrgInfrastructure(vm.infrastructureId, vm.orgid, vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure = function() {
      Infrastructureapi.getOrgInfrastructure(vm.infrastructureId, vm.orgid).success(function(data) {
        console.log(data);
        vm.selectedProvider = parseInt(data.infrastructure.provider);
        vm.control.provider = true;
        vm.infrastructure = data.infrastructure;
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure();
    vm.ok = function () {
      vm.control.submitted = true;
      vm.editInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  OrgInfradeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('organisations')
    .controller('OrgInfradeletemodalCtrl', OrgInfradeletemodalCtrl);

  function OrgInfradeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OrgInfradeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orgInfrastructures.controller:OrgInfrastructuresCtrl
   *
   * @description
   *
   */
  OrgInfrastructuresCtrl.$inject = ["Infrastructureapi", "$log", "$q", "$stateParams", "Organisation", "$state", "$modal"];
  angular
    .module('orgInfrastructures')
    .controller('OrgInfrastructuresCtrl', OrgInfrastructuresCtrl);

  function OrgInfrastructuresCtrl(Infrastructureapi, $log, $q, $stateParams, Organisation, $state, $modal) {
    var vm = this;
    var orgid = $stateParams.orgid;
    vm.ctrlName = 'OrgInfrastructuresCtrl';

    vm.infrastructures = [];

    vm.getInfrastructures = function(){
      Infrastructureapi.getOrgInfrastructures(orgid).success(function(data) {
        vm.infrastructures = data.infrastructures;
        $log.info(data);
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };
    vm.createInfrastructure = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/newinfrastructuremodel.tpl.html',
        controller: 'NewInfrastructuremodalCtrl as newinfrastructuremodel',
        resolve: {
          organisation: function () {
           return orgid;
         }
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.deleteInfrastructure = function(id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfradeletemodal.tpl.html',
        controller: 'OrgInfradeletemodalCtrl as orginfradeleteModal'
      });

      newModalInstance.result.then(function() {
        Infrastructureapi.deleteOrgInfrastructure(id, orgid).success(function(data) {
          $log.info(data);
          vm.getInfrastructures();
        }).error(function() {
          $log.info('delete Infrastructure error');
        });
      });
    };

    vm.editInfrastructure = function (id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfraeditmodal.tpl.html',
        controller: 'OrgInfraeditmodalCtrl as orginfraeditModal',
        resolve: {
          organisation: function () {
           return orgid;
          },
          infrastructureId: id
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };




    vm.getInfrastructures();

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  NewInfrastructuremodalCtrl.$inject = ["$modalInstance", "Infrastructureapi", "Provider", "$log", "organisation"];
  angular
    .module('orgInfrastructures')
    .controller('NewInfrastructuremodalCtrl', NewInfrastructuremodalCtrl);

  function NewInfrastructuremodalCtrl($modalInstance, Infrastructureapi, Provider, $log, organisation) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.orgid = organisation;
    vm.selectedProvider = 0;
    vm.providers = [];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      if(vm.matchProvider('xirrus', provider)){
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };


    Provider.getAllProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.addInfrastructure = function() {
      Infrastructureapi.createOrgInfrastructure(vm.orgid, vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };




    vm.ok = function () {
      vm.control.submitted = true;
      vm.addInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name organisationroles
   * @description
   *
   */
  angular
    .module('organisationroles', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisationroles.controller:RoledeletemodalCtrl
   *
   * @description
   *
   */
  RoledeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('organisationroles')
    .controller('RoledeletemodalCtrl', RoledeletemodalCtrl);

  function RoledeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'RoledeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisationroles.controller:OrganisationrolesCtrl
   *
   * @description
   *
   */
  OrganisationrolesCtrl.$inject = ["$state", "Roles", "$modal"];
  angular
    .module('organisationroles')
    .controller('OrganisationrolesCtrl', OrganisationrolesCtrl);

  function OrganisationrolesCtrl($state, Roles, $modal) {
    var vm = this;
    vm.ctrlName = 'OrganisationrolesCtrl';

    vm.organisationRolesLoaded = false;
    vm.organisationRolesLoadedError = false;
    vm.organisationRoles = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Roles.getAllOrganisationRoles().success(function (data) {
      vm.organisationRoles = data.organisationRoles;
      vm.organisationRolesLoaded = true;
    }).error(function (err) {
      vm.organisationRolesLoadedError = true;
      vm.organisationRolesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllOrganisationRoles() {
      Roles.getAllOrganisationRoles().success(function (data) {
        vm.organisationRoles = data.organisationRoles;
        vm.organisationRolesLoaded = true;
      }).error(function (err) {
        vm.organisationRolesLoadedError = true;
        vm.organisationRolesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'organisationroles/views/roledeletemodal.tpl.html',
        controller: 'RoledeletemodalCtrl as roledeleteModal'
      });

      modalInstance.result.then(function () {
        Roles.deleteOrganisationRole(id).success(function () {
          reloadAllOrganisationRoles();
        }).error(function (err) {
            vm.ErrorMessage = true;
            if (err.message.code === "23503"){
              vm.Message = "Error: This Role id is referenced in Organisation Operators";
            }
            else{
              vm.Message = "Error:" + err.message.detail;
            }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name operators
   * @description
   *
   */
  angular
    .module('operators', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatorsCtrl
   *
   * @description
   *
   */
  OperatorsCtrl.$inject = ["$state", "Operator", "$modal", "$stateParams", "Organisation", "Roles"];
  angular
    .module('operators')
    .controller('OperatorsCtrl', OperatorsCtrl);

  function OperatorsCtrl($state, Operator, $modal, $stateParams, Organisation, Roles) {
    var vm = this;
    vm.ctrlName = 'OperatorsCtrl';

    vm.operatorsLoaded = false;
    vm.operatorsLoadedError = false;
    vm.operators = [];
    vm.orgid = $stateParams.organisationid;
    vm.rolesLookup = [];
    vm.roles = [];
    vm.rolesLoaded = false;
    vm.rolesLoadedError = false;

    vm.limits = [1,5,10,15,20,50,'all'];
    vm.all= 0;
    vm.limit = 0;

    vm.setLimit = function(limit){
      console.log(limit);
      if(limit === 'all'){
        vm.limit = vm.all;
      }
    };

    Organisation.getOrganisationById($stateParams.organisationid).then(function(res){
      vm.organisation = res.data.org;
    }, function(err){
      console.log(err);
    });

    Operator.getAllOperatorsByOrgId($stateParams.organisationid).success(function (data) {
      vm.operators = data.operators;
      vm.operatorsLoaded = true;
      vm.limit = vm.operators.length;
      vm.all = vm.operators.length;
    }).error(function (err) {
      vm.operatorsLoadedError = true;
      vm.operatorsLoaded = true;
      console.log('Error: ', err);
    });

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
      for (var i = 0; i < vm.roles.length; i++) {
        vm.rolesLookup[vm.roles[i].id] = vm.roles[i];
      }
      vm.rolesLoaded = true;
      vm.rolesLoadedError = false;
    }).error(function(err) {
      console.log('Error: ', err);
      vm.rolesLoaded = true;
      vm.rolesLoadedError = true;
    });

    function reloadAllOperators() {
      Operator.getAllOperatorsByOrgId($stateParams.organisationid).success(function (data) {
        vm.operators = data.operators;
        vm.operatorsLoaded = true;
      }).error(function (err) {
        vm.operatorsLoadedError = true;
        vm.operatorsLoaded = true;
        vm.operators = [];
        console.log('Error: ', err);
      });
    }

    vm.getRoleName = function (roleid) {
      if (!vm.rolesLoadedError && vm.rolesLoaded){
        return vm.rolesLookup[roleid].name;
      }
    };

    vm.deleteEvent = function (orgid, opid) {
      var modalInstance = $modal.open({
        templateUrl: 'operators/views/operatordelmodal.tpl.html',
        controller: 'OperatordelmodalCtrl as operatordelModal'
      });

      modalInstance.result.then(function () {
        Operator.deleteOperator(orgid, opid).success(function () {
          reloadAllOperators();
        });
      }, function (err) {
        console.log(err);
      });
    };

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatordelmodalCtrl
   *
   * @description
   *
   */
  OperatordelmodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('operators')
    .controller('OperatordelmodalCtrl', OperatordelmodalCtrl);

  function OperatordelmodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OperatordelmodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newvenue
   * @description
   *
   */
  angular
    .module('newvenue', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name newvenue.controller:NewvenueCtrl
   *
   * @description
   *
   */
  NewvenueCtrl.$inject = ["Infrastructureapi", "$modal", "$state", "$stateParams"];
  angular
    .module('newvenue')
    .controller('NewvenueCtrl', NewvenueCtrl);

  function NewvenueCtrl(Infrastructureapi, $modal, $state, $stateParams) {
    var vm = this;
    vm.venue = {};
    vm.submitted = false;
    var orgid = $stateParams.orgid;

    vm.status = {
      isopen: false
    };

    vm.updateSelection = function(position, images) {
      angular.forEach(images, function(image, index) {
        if (position !== index) {
          image.checked = false;
        } else {
          vm.venue.imageUrl = image.imageuri;
          vm.venue.imageId = image.id;
        }
      });
    };

    vm.selectImage = function() {

      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.venue.imageUrl = thisImage.imageuri;
        vm.venue.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageUrl = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.createVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        Infrastructureapi.createVenue(orgid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues', {
            orgid: orgid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };

    Infrastructureapi.getImages(orgid).success(function(data) {
      vm.imageList = data.images;
      for (var i = 0; i < vm.imageList.length; i++) {
        vm.imageList[i].checked = false;
      }
      console.log(vm.imageList);
    });

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newlist
   * @description
   *
   */
  angular
    .module('newlist', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newlist.controller:NewlistCtrl
   *
   * @description
   *
   */
    NewlistCtrl.$inject = ["Infrastructureapi", "$state", "$stateParams"];
  angular
    .module('newlist')
    .controller('NewlistCtrl', NewlistCtrl);

    function NewlistCtrl(Infrastructureapi, $state, $stateParams) {
      var vm = this;
      vm.ctrlName = 'NewlistCtrl';
      vm.list = {};
      vm.submitted = false;
      vm.venueid = $stateParams.venueid;
      vm.orgid = $stateParams.orgid;
      vm.createList = function(valid) {
        vm.submitted = true;
        if (valid) {
          vm.list.apiKey = $stateParams.apiKey;
          Infrastructureapi.createList(vm.list).success(function(data) {
            console.log(data);
            $state.go('admin.mailchimp', {
              venueid: vm.venueid,
              orgid: vm.orgid
            });
          }, function(err) {
            console.log(err);
          });
        }
      };
    }
  }());

(function () {
  'use strict';

  /* @ngdoc object
   * @name networktype
   * @description
   *
   */
  angular
    .module('networktype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name networktype.controller:NetworktypeCtrl
   *
   * @description
   *
   */
  NetworktypeCtrl.$inject = ["$stateParams", "Category", "NetworkType", "$log", "$state"];
  angular
    .module('networktype')
    .controller('NetworktypeCtrl', NetworktypeCtrl);

  function NetworktypeCtrl($stateParams, Category, NetworkType, $log, $state) {
    var vm = this;

    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;

    vm.networkType = {};
    vm.selectedStrategies = [];
    vm.provider = '';
    vm.submitted = false;

    vm.setProvider = function(){
      vm.provider = $stateParams.provider;
      if(vm.provider !== '1'){
        vm.networkType.vlanId = 700;
      } else {
        vm.networkType.vlanId = '';
      }
    };

    vm.createNetworkType = function (valid) {
      vm.networkType.networkStrategies = vm.selectedStrategies;
      //$log.info(vm.networkType);
      vm.submitted = true;
      if (valid) {
        NetworkType.createNetworkType(venueid, vm.networkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            orgid: orgid,
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };

    Category.getAllCategories().then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });

    vm.setProvider();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SltdeletemodalCtrl
   *
   * @description
   *
   */
  SetProviderModalCtrl.$inject = ["$modalInstance", "Provider", "providers"];
  angular
    .module('sociallogins')
    .controller('SetProviderModalCtrl', SetProviderModalCtrl);

  function SetProviderModalCtrl($modalInstance, Provider, providers) {
    var vm = this;
    vm.ctrlName = 'SetProviderModalCtrl';

    vm.selectedproviders = providers;
    vm.providers = [];

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      vm.setPreselected();
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.setPreselected = function() {
      var providerIndex = 0;
      var selectedIndex = 0;
      for (providerIndex = 0; providerIndex < vm.providers.length; providerIndex++) {
        for (selectedIndex = 0; selectedIndex < vm.selectedproviders.length; selectedIndex++) {
          if (vm.providers[providerIndex].id === vm.selectedproviders[selectedIndex].provider) {
            vm.providers[providerIndex].status = true;
          }
        }
      }
    };


    vm.select = function (index){
      vm.providers[index].status = true;
    };

    vm.deselect = function (index){
        vm.providers[index].status = false;
    };

    vm.ok = function () {
      var list = [];
      vm.providers.forEach(function(item){
        if (item.status) {
          list.push(item.id);
        }
      });
      $modalInstance.close(list);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name modalservice
   * @description
   *
   */
  angular
    .module('modalservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:ModalserviceCtrl
   *
   * @description
   *
   */
  ModalserviceCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('modalservice')
    .controller('ModalserviceCtrl', ModalserviceCtrl);

  function ModalserviceCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'ModalserviceCtrl';

    vm.message = message;

      vm.ok = function() {
          $modalInstance.close();
      };

      vm.cancel = function() {
          $modalInstance.dismiss();
      };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name manageimages
   * @description
   *
   */
  angular
    .module('manageimages', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name manageimages.controller:UploadmodalCtrl
   *
   * @description
   *
   */
  UploadmodalCtrl.$inject = ["$modalInstance", "Auth", "imageupload", "Upload", "$timeout", "message"];
  angular.module('manageimages').controller('UploadmodalCtrl', UploadmodalCtrl);

  function UploadmodalCtrl($modalInstance, Auth, imageupload, Upload, $timeout, message) {
    var vm = this,
      token = '',
      orgid = message.orgid;

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: imageupload + '/images',
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name manageimages.controller:ManageimagesCtrl
   *
   * @description
   *
   */
  ManageimagesCtrl.$inject = ["$modal", "Api"];
  angular
    .module('manageimages')
    .controller('ManageimagesCtrl', ManageimagesCtrl);

  function ManageimagesCtrl($modal, Api) {
    var vm = this;

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    function getImages() {
      Api.getImages().success(function (data) {
        vm.imageList = data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
        console.log(vm.imageList);
      });
    }

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: 'Note! Images associated with a Venue/Event cannot be deleted.'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Api.deleteImage(image.id).success(function (resp) {
          console.log(resp);
          getImages();
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        console.log('success');
        getImages();
      }, function () {
        console.log('canceled');
      });
    };

    getImages();

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name managenetwork
   * @description
   *
   */
  angular
    .module('managenetwork', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name managenetwork.controller:ManagenetworkCtrl
   *
   * @description
   *
   */
  ManagenetworkCtrl.$inject = ["Infrastructureapi", "$log", "$stateParams", "Organisation", "$state", "$modal", "NetworkType", "$cookies"];
  angular
    .module('managenetwork')
    .controller('ManagenetworkCtrl', ManagenetworkCtrl);

  function ManagenetworkCtrl(Infrastructureapi, $log, $stateParams, Organisation, $state, $modal, NetworkType, $cookies) {
    var vm = this;

    vm.zones = [];
    vm.accesspoints = [];
    vm.infrastructures = [];
    vm.networkTypes = [];
    vm.venue = {};

    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;

    vm.showZones = false;
    vm.showAPoints = false;
    vm.showTypes = false;
    vm.showInfra = true;

    vm.setNetworkView = function (view) {
      switch (view) {
      case 'zone':
        vm.showZones = true;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = false;
        break;
      case 'apoints':
        vm.showZones = false;
        vm.showAPoints = true;
        vm.showTypes = false;
        vm.showInfra = false;
        break;
      case 'types':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = true;
        vm.showInfra = false;
        break;
      case 'infra':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = true;
        break;
      }
    };

    if ($cookies.get('view')) {
      vm.setNetworkView($cookies.get('view'));
    }

    function getZones(venueid, orgid) {
      Infrastructureapi.getZones(venueid, orgid).then(function (res) {
        vm.zones = res.data.zones;
        //$log.info(vm.zones);
      }, function (err) {
        $log.info(err);
      });
    }

    function getAccessPoints(venueid, orgid) {
      Infrastructureapi.getAps(venueid, orgid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        $log.info(vm.accesspoints);
      }, function (err) {
        $log.info(err);
      });
    }

    function getVenue(orgid, venueid) {
      Infrastructureapi.getVenue(orgid, venueid).then(function (res) {
        vm.venue = res.data.venue;
      }, function (err) {
        $log.info(err);
      });
    }

    function getNetworkTypes(venueid) {
      NetworkType.getNetworkTypes(venueid).then(function (res) {
        vm.networkTypes = res.data.networkTypes;
        $log.info(vm.networkTypes);
      }, function (err) {
        $log.info(err);
      });
    }

    function getInfrastructures(venueid) {
      Infrastructureapi.getInfrastructures(venueid).then(function (res) {
        vm.infrastructures = res.data.infrastructures;
        $log.info(vm.infrastructures);
      }, function (err) {
        $log.info(err);
      });
    }

    vm.deleteZone = function (id) {
      $cookies.put('view', 'zone');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Zone, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Infrastructureapi.deleteZone(id, venueid, orgid).success(function (data) {
          $log.info(data);
          getZones(venueid, orgid);
        }).error(function () {
          vm.modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete zones with accesspoints attached',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return vm.modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {});
        });
      });
    };

    vm.deleteAccesspoint = function (id) {
      $cookies.put('view', 'apoints');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Access Point?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Infrastructureapi.deleteAp(id, venueid, orgid).success(function (data) {
          $log.info(data);
          getAccessPoints(venueid, orgid);
        }).error(function () {
          $log.info('get Zones error');
        });
      });
    };

    vm.createZone = function () {
      $cookies.put('view', 'zone');
      $state.go('admin.addzone', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.createAccesspoint = function () {
      $cookies.put('view', 'apoints');
      $state.go('admin.addap', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.createInfrastructure = function () {
      $cookies.put('view', 'infra');
      $state.go('admin.addInfrastructure', {
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.editInfrastructure = function (infraid) {
      $cookies.put('view', 'infra');
      $state.go('admin.editinfrastructure', {
        infraid: infraid,
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.editZone = function (zoneid) {
      $cookies.put('view', 'zone');
      $state.go('admin.editzone', {
        zoneid: zoneid,
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editAccesspoint = function (apid) {
      $cookies.put('view', 'apoints');
      $state.go('admin.editap', {
        apid: apid,
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.createNetworkType = function () {
      $cookies.put('view', 'types');
      $state.go('admin.networktype', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editNetworkType = function (id) {
      $cookies.put('view', 'types');
      $state.go('admin.editnetworktype', {
        venueid: venueid,
        orgid: orgid,
        typeid: id,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.deleteNetworkType = function (id) {
      $cookies.put('view', 'types');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        NetworkType.deleteNetworkType(id, venueid).then(function () {
          getNetworkTypes(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    getZones(venueid, orgid);
    getVenue(orgid, venueid);
    getAccessPoints(venueid, orgid);
    getNetworkTypes(venueid);
    getInfrastructures(venueid);
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mailchimp
   * @description
   *
   */
  angular
    .module('mailchimp', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mailchimp.controller:MailchimpCtrl
   *
   * @description
   *
   */
    MailchimpCtrl.$inject = ["Infrastructureapi", "$scope", "$log", "$stateParams", "$state"];
  angular
    .module('mailchimp')
    .controller('MailchimpCtrl', MailchimpCtrl);

    function MailchimpCtrl(Infrastructureapi, $scope, $log, $stateParams, $state) {
      var vm = this;
      vm.ctrlName = 'MailchimpCtrl';

      var venueid = $stateParams.venueid;
      vm.orgid = $stateParams.orgid;
      vm.venue = {};
      vm.venueDetails = {};
      vm.ConfirmMessage = false;
      vm.ErrorMessage = false;
      vm.Message = [];
      vm.ListConfirmMessage = false;
      vm.ListErrorMessage = false;
      vm.ListMessage = [];

      vm.getApiKey = function(){
        Infrastructureapi.getVenue(vm.orgid, venueid).then(function(resp) {
          $log.info(resp);
          vm.venue.venueTitle = resp.data.venue.venuetitle;
          vm.venue.imageUrl = resp.data.venue.imageurl;
          vm.venue.imageId = resp.data.venue.imageid;
          vm.venue.contactEmail = resp.data.venue.contactemail;
          vm.venue.contactPhone = resp.data.venue.contactphone;
          vm.venue.description = resp.data.venue.description;
          vm.venue.location = resp.data.venue.location;
          vm.venue.website =  resp.data.venue.website;
          if(resp.data.venue.hasOwnProperty('details')){
            vm.venueDetails = resp.data.venue.details;
            vm.venueDetails = JSON.parse(vm.venueDetails);
            if(vm.venueDetails.hasOwnProperty('mailchimp')){
              if(vm.venueDetails.mailchimp.hasOwnProperty('apiKey')){
                vm.apiKey = vm.venueDetails.mailchimp.apiKey;
                vm.getLists();
              }
              if(vm.venueDetails.mailchimp.hasOwnProperty('listId')){
                vm.listId = vm.venueDetails.mailchimp.listId;
                vm.newListId = vm.listId;
              }
            }
          } else {
            vm.venueDetails = {};
          }

        }, function(err) {
          $log.info(err);
        });
      };

      vm.getApiKey();

      vm.getLists = function(){
        vm.lists = [];
        Infrastructureapi.getLists(vm.apiKey).then(function(resp) {
          for(var i=0; i<resp.data.lists.length; i++){
            vm.lists.push({id: resp.data.lists[i].id, name: resp.data.lists[i].name});
          }
        }, function(err) {
          $log.info(err);
          vm.lists.length=0;
        });
      };

      vm.updateApiKey = function() {
        vm.submitted = true;
        if(vm.venueDetails.hasOwnProperty('mailchimp')){
          vm.venueDetails.mailchimp.apiKey = vm.apiKey;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }else{
          vm.venueDetails.mailchimp = {};
          vm.venueDetails.mailchimp.apiKey = vm.apiKey;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }

        Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          vm.ConfirmMessage = true;
          vm.Message = 'Api Key Updated';
          vm.getLists();
        }, function(err) {
          vm.Message = true;
          vm.Message = err.message;
          console.log('Error = ' + err);
        });
      };

      vm.updateList = function(){
        if(vm.venueDetails.hasOwnProperty('mailchimp')){
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }else{
          vm.venueDetails.mailchimp = {};
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }

        Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          vm.ListConfirmMessage = true;
          vm.ListMessage = 'List Updated';
          vm.getApiKey();
        }, function(err) {
          console.log('Error = ' + err);
        });
      };

      vm.dismiss = function () {
        vm.Message = {};
        vm.ConfirmMessage = false;
      };

      vm.dismissError = function () {
        vm.Message = {};
        vm.ErrorMessage = false;
      };

      vm.listDismiss = function () {
        vm.ListMessage = {};
        vm.ListConfirmMessage = false;
      };

      vm.listDismissError = function () {
        vm.ListMessage = {};
        vm.ListErrorMessage = false;
      };

      vm.createNewList = function(){
        $state.go('admin.newlist', {
          apiKey: vm.apiKey,
          venueid: venueid,
          orgid: vm.orgid
        });
      };
    }
  }());

(function () {
  'use strict';

  /* @ngdoc object
   * @name leimages
   * @description
   *
   */
  angular
    .module('leimages', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name leimages.controller:UploadmodalCtrl
   *
   * @description
   *
   */
  UploadmodalCtrl.$inject = ["$modalInstance", "Auth", "imageupload", "Upload", "$timeout"];
  angular
    .module('leimages')
    .controller('UploadmodalCtrl', UploadmodalCtrl);

  function UploadmodalCtrl($modalInstance, Auth, imageupload, Upload, $timeout) {
    var vm = this,
        token = '';
    vm.ctrlName = 'UploadmodalCtrl';

    vm.venues = [];

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: imageupload + '/images',
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            response.data.venue = vm.venueId;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name leimages.controller:LeimagesCtrl
   *
   * @description
   *
   */
  LeimagesCtrl.$inject = ["$modal", "Leimages"];
  angular
    .module('leimages')
    .controller('LeimagesCtrl', LeimagesCtrl);

  function LeimagesCtrl($modal, Leimages) {
    var vm = this;
    vm.ctrlName = 'LeimagesCtrl';

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.getImages = function () {
      Leimages.getImages().then(function (res) {
        vm.imageList = res.data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
      }, function (err) {
        console.log(err);
      });
    }

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: ''
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Leimages.deleteImage(image.id).success(function (resp) {
          console.log(resp);
          vm.getImages();
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log('IMG', image);
        vm.getImages();
      }, function () {
        console.log('canceled');
      });
    };
    vm.getImages();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name main
   * @description
   *
   */
  angular
    .module('main', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:MainCtrl
   *
   * @description
   *
   */
  MainCtrl.$inject = ["$rootScope", "Auth", "$state", "$cookies", "$modal"];
  angular
    .module('main')
    .controller('MainCtrl', MainCtrl);

  function MainCtrl($rootScope, Auth, $state, $cookies, $modal) {
    var vm = this;
    vm.ctrlName = 'MainCtrl';

    vm.launchLogin = function () {
      var modalInstance = $modal.open({
        templateUrl: 'main/views/login.tpl.html',
        controller: 'LoginmodalCtrl as modal'
      });

      modalInstance.result.then(function () {
        $state.go('admin.admins');
      }, function () {
        console.log('canceled');
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:LoginmodalCtrl
   *
   * @description
   *
   */
  LoginmodalCtrl.$inject = ["$modalInstance", "Auth", "$cookies"];
  angular
    .module('main')
    .controller('LoginmodalCtrl', LoginmodalCtrl);

  function LoginmodalCtrl($modalInstance, Auth, $cookies) {
    var vm = this;
    vm.ctrlName = 'LoginmodalCtrl';
    vm.loginDetails = {};

    vm.login = function () {
      Auth.login(vm.loginDetails.username, vm.loginDetails.password).success(function (data) {
        $cookies.putObject('leAdminData', data);
        Auth.setIslogged(true);
        $modalInstance.close();
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      console.log('Dismiss was called');
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  HomeCtrl.$inject = ["$rootScope", "Auth", "$cookies", "$state"];
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl($rootScope, Auth, $cookies, $state) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';

    $rootScope.bodyClass = 'home';
    vm.loginDetails={};

    vm.login = function () {
      console.log('called');
      Auth.login(vm.loginDetails.username, vm.loginDetails.password).success(function (data) {
        $cookies.putObject('leAdminData', data);
        Auth.setIslogged(true);
        $state.go('admin.admins');
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name guestnetworksettings
   * @description
   *
   */
  angular
    .module('guestnetworksettings', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name guestnetworksettings.controller:GuestnetworksettingsCtrl
   *
   * @description
   *
   */
  GuestnetworksettingsCtrl.$inject = ["GnsApi", "$modal"];
  angular
    .module('guestnetworksettings')
    .controller('GuestnetworksettingsCtrl', GuestnetworksettingsCtrl);

  function GuestnetworksettingsCtrl(GnsApi, $modal) {
    var vm = this;
    vm.ctrlName = 'GuestnetworksettingsCtrl';
    vm.gnsList = [];
    vm.getGNS = function(){
      GnsApi.getGNS().success(function(data){
        vm.gnsList = data.gns;
      });
    };
    vm.getGNS();

    vm.deletegns = function(id){
      var modalInstance = $modal.open({
        templateUrl: 'guestnetworksettings/views/deletemodal.tpl.html',
        controller: 'DeletemodalCtrl as deleteModal'
      });

      modalInstance.result.then(function () {
        GnsApi.deleteGNS(id).success(function(){
          vm.getGNS();
        });
      });
    };

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name categories.controller:CatdeletemodalCtrl
   *
   * @description
   *
   */
  DeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('guestnetworksettings')
    .controller('DeletemodalCtrl', DeletemodalCtrl);

  function DeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name filtercreatemodal
   * @description
   *
   */
  angular
    .module('filtercreatemodal', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name filtercreatemodal.controller:FiltercreatemodalCtrl
   *
   * @description
   *
   */
  FiltercreatemodalCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('filtercreatemodal')
    .controller('FiltercreatemodalCtrl', FiltercreatemodalCtrl);

  function FiltercreatemodalCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'FiltercreatemodalCtrl';

    vm.message = message;

    vm.priceSlider = {
      value: 0,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };

      vm.ok = function() {
          $modalInstance.close({'website':vm.filterSelected, 'bandwidth': vm.priceSlider.value.toString(), 'categoryId': vm.message.category, 'details':{'description': vm.description}});
      };

      vm.cancel = function() {
          $modalInstance.dismiss();
      };


  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editzone
   * @description
   *
   */
  angular
    .module('editzone', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editzone.controller:EditzoneCtrl
   *
   * @description
   *
   */
  EditzoneCtrl.$inject = ["Infrastructureapi", "$stateParams", "$state", "$log"];
  angular
    .module('editzone')
    .controller('EditzoneCtrl', EditzoneCtrl);

  function EditzoneCtrl(Infrastructureapi, $stateParams, $state, $log) {
    var vm = this;
    vm.ctrlName = 'EditzoneCtrl';

    var zoneid = $stateParams.zoneid;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedaccesspoints = [];
    vm.originalaccesspoints = [];
    vm.provider = $stateParams.provider;

    Infrastructureapi.getAps($stateParams.venueid, $stateParams.orgid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.removeAp = function(key){
      vm.selectedaccesspoints.splice(key, 1);
    };

    Infrastructureapi.getZone(zoneid, venueid, orgid).then(function(resp){
      vm.zone = resp.data.zone;
      vm.selectedaccesspoints = vm.zone.accesspoints;
      vm.zone.accesspoints.forEach(function(element) {
        vm.originalaccesspoints.push(element.id);
      });
      $log.info(vm.zone);
    }, function(err){
      $log.info(err);
    });

    vm.updateZone = function(){
      vm.zone.orgid = orgid;
      var addArray = [];
      vm.selectedaccesspoints.forEach(function(element) {
        addArray.push(element.id);
      });
      console.log(addArray);
      vm.apsToRemove = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: vm.originalaccesspoints
      };
      vm.apsToAdd = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: addArray
      };
      if (vm.apsToRemove.accessPoints.length < 1) {
        Infrastructureapi.updateApsZone(vm.apsToAdd).then(function(resp){
          $log.info(resp);
          Infrastructureapi.updateZone(zoneid, venueid, vm.zone).then(function(resp){
            $log.info(resp);
            $state.go('admin.managenetwork', {
              venueid: venueid,
              orgid: orgid
            });
          }, function(err){
            $log.info(err);
          });
        }, function(err){
          $log.info(err);
        });
      } else {
        Infrastructureapi.removeApsZone(vm.apsToRemove).then(function(resp){
          $log.info(resp);
          Infrastructureapi.updateApsZone(vm.apsToAdd).then(function(resp){
            $log.info(resp);
            Infrastructureapi.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }, function(err){
            $log.info(err);
          });
        }, function(err){
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editsplashtype
   * @description
   *
   */
  angular
    .module('editsplashtype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsplashtype.controller:EditsplashtypeCtrl
   *
   * @description
   *
   */
  EditsplashtypeCtrl.$inject = ["$state", "Splashtype", "$log", "$stateParams", "Provider"];
  angular
    .module('editsplashtype')
    .controller('EditsplashtypeCtrl', EditsplashtypeCtrl);

  function EditsplashtypeCtrl($state, Splashtype, $log, $stateParams, Provider) {
    var vm = this;

    vm.splashtype = {};
    var typeid = $stateParams.typeid;
    vm.submitted = false;

    Splashtype.getSplashType(typeid).then(function (resp) {
      vm.splashtype.name = resp.data.splashType.name;
      vm.splashtype.description = resp.data.splashType.description;
    }, function (err) {
      $log.info(err);
    });

    vm.editSplash = function (valid) {
      vm.submitted = true;
      console.log(vm.splashtype);
      if (valid) {
        Splashtype.updateSplashType(typeid, vm.splashtype).then(function () {
          $state.go('admin.splashtype');
        }, function (err) {
          $log.info(err);
        });
      }
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editrole
   * @description
   *
   */
  angular
    .module('editrole', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editrole.controller:EditroleCtrl
   *
   * @description
   *
   */
  EditroleCtrl.$inject = ["$state", "$stateParams", "Roles"];
  angular
    .module('editrole')
    .controller('EditroleCtrl', EditroleCtrl);

  function EditroleCtrl($state, $stateParams, Roles) {
    var vm = this;
    vm.ctrlName = 'EditroleCtrl';

    vm.ErrorMessage = false;
    vm.organisationRole = [];
    vm.Message = [];

    Roles.getOrganisationRoleById($stateParams.roleid).success(function (data) {
      vm.organisationRole = data.organisationRole;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateOrganisationRole = function () {
      var data = JSON.stringify({
        name: vm.organisationRole.name,
        details: {}
      });
      Roles.updateOrganisationRole(vm.organisationRole.id, data).success(function (data) {
        $state.go('admin.organisationroles');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editvenue
   * @description
   *
   */
  angular
    .module('editvenue', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenue.controller:EditvenueCtrl
   *
   * @description
   *
   */
  EditvenueCtrl.$inject = ["Infrastructureapi", "$modal", "$state", "$stateParams", "$log"];
  angular
    .module('editvenue')
    .controller('EditvenueCtrl', EditvenueCtrl);

  function EditvenueCtrl(Infrastructureapi, $modal, $state, $stateParams, $log) {
    var vm = this;
    vm.ctrlName = 'EditvenueCtrl';

    vm.venue = {};
    vm.submitted = false;
    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;

    vm.status = {
      isopen: false
    };

    vm.selectImage = function() {

      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.venue.imageUrl = thisImage.imageuri;
        vm.venue.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageuri = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.updateVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        console.log(vm.venue);
        Infrastructureapi.updateVenue(orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues', {
            orgid: orgid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };

    function getVenue(venueid, orgid) {
      Infrastructureapi.getVenue(orgid, venueid).then(function(resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageuri = resp.data.venue.imageuri;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website =  resp.data.venue.website;
        vm.venue.details = resp.data.venue.details;
      }, function(err) {
        $log.info(err);
      });
    }

    getVenue(venueid, orgid);
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editslt
   * @description
   *
   */
  angular
    .module('editslt', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editslt.controller:EditsltCtrl
   *
   * @description
   *
   */
  EditsltCtrl.$inject = ["$state", "$stateParams", "$modal", "Slt", "Provider"];
  angular
    .module('editslt')
    .controller('EditsltCtrl', EditsltCtrl);

  function EditsltCtrl($state, $stateParams, $modal, Slt, Provider) {
    var vm = this;
    vm.ctrlName = 'EditsltCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];

    vm.slt = {};

    vm.selectImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.slt.imageuri = thisImage.imageuri;
        vm.slt.imageid = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };
    vm.uploadImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        vm.slt.imageuri = image.imageUri;
        vm.slt.imageid = image.id;
      }, function() {
        console.log('canceled');
      });
    };

    Slt.getSLTById($stateParams.sltid).success(function (data) {
      vm.slt = data.slt;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateSLT = function () {
      var data = JSON.stringify({
        name: vm.slt.name,
        imageId: vm.slt.imageid,
        details: '{}'
      });
      console.log(data);
      Slt.updateSLT(vm.slt.id, data).success(function (data) {
        $state.go('admin.sociallogins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editorganisation
   * @description
   *
   */
  angular
    .module('editorganisation', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editorganisation.controller:EditorganisationCtrl
   *
   * @description
   *
   */
  EditorganisationCtrl.$inject = ["$state", "$stateParams", "Organisation"];
  angular
    .module('editorganisation')
    .controller('EditorganisationCtrl', EditorganisationCtrl);

  function EditorganisationCtrl($state, $stateParams, Organisation) {
    var vm = this;
    vm.ctrlName = 'EditorganisationCtrl';

    vm.ErrorMessage = false;
    vm.organisationDetails = [];
    vm.Message = [];
    vm.premium = false;

    Organisation.getOrganisationById($stateParams.organisationid).success(function (data) {
      console.log('...1org', data);
      vm.organisationDetails = data.org;
      vm.premium = data.org.premium;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateOrganisation = function () {
      var data = JSON.stringify({
        organisationTitle: vm.organisationDetails.organisationtitle,
        premium: vm.premium,
        details: {
          email: vm.organisationDetails.details.email,
          phone: vm.organisationDetails.details.phone
        }
      });
      Organisation.updateOrganisation(vm.organisationDetails.id, data).success(function (data) {
        $state.go('admin.organisations');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editprovider
   * @description
   *
   */
  angular
    .module('editprovider', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editprovider.controller:EditproviderCtrl
   *
   * @description
   *
   */
  EditproviderCtrl.$inject = ["$state", "$stateParams", "Provider"];
  angular
    .module('editprovider')
    .controller('EditproviderCtrl', EditproviderCtrl);

  function EditproviderCtrl($state, $stateParams, Provider) {
    var vm = this;
    vm.ctrlName = 'EditproviderCtrl';

    vm.ErrorMessage = false;
    vm.providerDetails = [];
    vm.Message = [];

    Provider.getProviderById($stateParams.providerid).success(function (data) {
      vm.providerDetails = data.provider;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateProvider = function () {
      var data = JSON.stringify({
        name: vm.providerDetails.name,
        details: vm.providerDetails.details
      });
      Provider.updateProvider(vm.providerDetails.id, data).success(function (data) {
        $state.go('admin.providers');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editoperatorpwd
   * @description
   *
   */
  angular
    .module('editoperatorpwd', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperatorpwd.controller:EditoperatorpwdCtrl
   *
   * @description
   *
   */
  EditoperatorpwdCtrl.$inject = ["$state", "Operator", "$stateParams"];
  angular
    .module('editoperatorpwd')
    .controller('EditoperatorpwdCtrl', EditoperatorpwdCtrl);

  function EditoperatorpwdCtrl($state, Operator, $stateParams) {
    var vm = this;
    vm.ctrlName = 'EditoperatorpwdCtrl';

    vm.ErrorMessage = false;
    vm.operatorDetails = [];
    vm.Message = [];
    vm.orgid = $stateParams.organisationid;
    vm.opid = $stateParams.opid;

    vm.updateOperatorPassword = function () {
      var data = JSON.stringify({
        password: vm.operatorDetails.password,
        orgId: vm.orgid
      });
      Operator.updateOperatorPassword(vm.opid, data).success(function (data) {
        $state.go('admin.operators', {'organisationid': vm.orgid});
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editoperator
   * @description
   *
   */
  angular
    .module('editoperator', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperator.controller:EditoperatorCtrl
   *
   * @description
   *
   */
  EditoperatorCtrl.$inject = ["$state", "Operator", "$stateParams", "Roles", "Infrastructureapi"];
  angular
    .module('editoperator')
    .controller('EditoperatorCtrl', EditoperatorCtrl);

  function EditoperatorCtrl($state, Operator, $stateParams, Roles, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'EditoperatorCtrl';

    vm.ErrorMessage = false;
    vm.operator = {};
    vm.orgid = $stateParams.organisationid;
    vm.opid = $stateParams.opid;
    vm.roles = [];

    Operator.getOperatorById(vm.orgid, vm.opid).success(function(data) {
      vm.operator = data.operator;
    }).error(function(err) {
      console.log(err);
    });

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
    }).error(function(err) {
      console.log(err);
    });

    Infrastructureapi.getVenues(vm.orgid).then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }
    };

    vm.updateOperator = function(valid) {
      vm.submitted = true;
      var thisOperator = vm.operator;
      thisOperator.roleId = vm.operator.role;
      thisOperator.orgId = vm.orgid;
      if (valid) {
        Operator.updateOperatorById(vm.opid, thisOperator).success(function() {
          $state.go('admin.operators', {
            'organisationid': vm.orgid
          });
        }).error(function(err) {
          console.log(err);
        });
      }
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editinfrastructure
   * @description
   *
   */
  angular
    .module('editinfrastructure', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editinfrastructure.controller:EditinfrastructureCtrl
   *
   * @description
   *
   */
  EditinfrastructureCtrl.$inject = ["Infrastructureapi", "Provider", "$stateParams", "$state", "$log"];
  angular
    .module('editinfrastructure')
    .controller('EditinfrastructureCtrl', EditinfrastructureCtrl);

  function EditinfrastructureCtrl(Infrastructureapi, Provider, $stateParams, $state, $log) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;
    vm.providerTypes = [];
    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      console.log(provider);
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if(provider === 1){
        vm.infrastructure.controlleraddress = 'Xirrus';
        console.log(vm.infrastructure.controlleraddress);
      } else {
        console.log(vm.infrastructure.controlleraddress);
      }
    };

    Provider.getAllProviders().then(function(res){
      console.log(res);
      vm.providerTypes = res.data.providers;
    }, function(err){
      console.log(err);
    });

    Infrastructureapi.getInfrastructure($stateParams.infraid, $stateParams.venueid).then(function (res) {
      $log.info(res);
      vm.infrastructure = res.data.infrastructure;
      vm.setProvider(vm.infrastructure.provider);
    }, function (err) {
      $log.error(err);
    });

    vm.edit = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      thisInfrastructure.details = '{}';
      vm.submitted = true;
      if (isValid) {
        Infrastructureapi.updateInfrastructure($stateParams.infraid, $stateParams.venueid, thisInfrastructure).then(function (res) {
          $log.info(res);
          $state.go('admin.managenetwork', {
            'orgid': $stateParams.orgid,
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          $log.error(err);
        });
      }
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editcategory
   * @description
   *
   */
  angular
    .module('editcategory', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editcategory.controller:EditcategoryCtrl
   *
   * @description
   *
   */
  EditcategoryCtrl.$inject = ["$state", "$stateParams", "Category", "Netfilter", "$modal", "$scope", "Provider"];
  angular
    .module('editcategory')
    .controller('EditcategoryCtrl', EditcategoryCtrl);

  function EditcategoryCtrl($state, $stateParams, Category, Netfilter, $modal, $scope, Provider) {
    var vm = this;
    vm.ctrlName = 'EditcategoryCtrl';
    vm.ErrorMessage = false;
    vm.xirrusFilters = [];
    vm.types = [];
    vm.categoryDetails = [];
    vm.Message = [];
    vm.networkFilters = [];
    vm.xirrusFiltersLoaded = false;
    vm.xirrusFiltersLoadedError = false;
    vm.categoryTypesLoaded = false;
    vm.categoryTypesLoadedError = false;
    vm.networkFiltersLoaded = false;
    vm.networkFiltersLoadedError = false;
    vm.selectedType = 0;
    vm.premium = false;
    vm.onSave = false;

    Netfilter.getlistOfXirrusFilters().success(function (data) {
      vm.xirrusFilters = data.xirrusFilters;
      vm.xirrusFiltersLoaded = true;
      vm.xirrusFiltersLoadedError = false;
      console.log(vm.xirrusFilters);
    }).error(function (err) {
      console.log(err);
      vm.xirrusFiltersLoaded = false;
      vm.xirrusFiltersLoadedError = false;
    });

    Netfilter.getlistOfCategoryTypes().success(function (data) {
      vm.types = data.categoryTypes;
      console.log('categoryTypes', data.categoryTypes);
      vm.categoryTypesLoaded = true;
      vm.categoryTypesLoadedError = false;
    }).error(function (err) {
      console.log(err);
      vm.categoryTypesLoaded = true;
      vm.categoryTypesLoadedError = true;
    });

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
    }).error(function (err) {
      console.log('Error: ', err);
    });

    Category.getCategoryById($stateParams.categoryid).success(function (data) {
      vm.categoryDetails = data.category;
      var typeObj = { id: vm.categoryDetails.type, description: '' };
      $scope.type = typeObj;
      var providerObj = { id: vm.categoryDetails.provider, description: '' };
      $scope.provider = providerObj;
      vm.premium = vm.categoryDetails.premium;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    Netfilter.getNetFilterByCategory($stateParams.categoryid).success(function (result) {
      vm.networkFilters = result.networkFilters;
      vm.networkFiltersLoaded = true;
      console.log(vm.networkFilters);
    }).error(function (err) {
      vm.networkFiltersLoadedError = true;
      vm.networkFiltersLoaded = true;
      console.log('Error: ', err);
    });

    vm.updateCategory = function () {
      var data = JSON.stringify({
        name: vm.categoryDetails.name,
        provider: $scope.provider.id,
        description: vm.categoryDetails.description,
        premium: vm.premium,
        categoryTypeId: $scope.type.id
      });
      Category.updateCategory(vm.categoryDetails.id, data).success(function (data) {
        console.log(data);
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    var getFilterList = function(id) {
      Netfilter.getFilterbyCategory(id).then(function(res) {
        console.log(res);
        vm.networkFilters = res.data.networkFilters;
      }, function(err) {
        console.log(err);
      });
    };

    vm.deleteFilter = function(id) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Filter, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function() {
        Netfilter.deleteNetFilter(id).then(function(res) {
          console.log(res);
          getFilterList($stateParams.categoryid);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.addFilter = function() {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Add Filters',
        category: $stateParams.categoryid,
        filters: vm.xirrusFilters
      };

      var modalInstance = $modal.open({
        templateUrl: 'filtercreatemodal/views/filtercreatemodal.tpl.html',
        controller: 'FiltercreatemodalCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function(data) {
        console.log(data);
        Netfilter.createNetFilter(data).then(function(res) {
          console.log(res);
          getFilterList(data.categoryId);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.done = function(){
      $state.go('admin.categories');
    };



  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editnetworktype
   * @description
   *
   */
  angular
    .module('editnetworktype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editnetworktype.controller:EditnetworktypeCtrl
   *
   * @description
   *
   */
  EditnetworktypeCtrl.$inject = ["$stateParams", "Category", "NetworkType", "$log", "$state"];
  angular
    .module('editnetworktype')
    .controller('EditnetworktypeCtrl', EditnetworktypeCtrl);

  function EditnetworktypeCtrl($stateParams, Category, NetworkType, $log, $state) {
    var vm = this;
    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;
    var typeid = $stateParams.typeid;

    vm.networkType = {};
    vm.submitted = false;
    vm.selectedStrategies = [];

    vm.setProvider = function(){
      vm.provider = $stateParams.provider;
    };

    vm.updateNetworkType = function (valid) {
      vm.submitted = true;
      console.log(vm.networkType);
      if (valid) {
        var thisNetworkType = {};
        thisNetworkType.name = vm.networkType.name;
        thisNetworkType.vlanId = vm.networkType.vlanid;
        thisNetworkType.details = vm.networkType.details;
        thisNetworkType.networkStrategies = vm.selectedStrategies;
        NetworkType.updateNetworkType(typeid, venueid, thisNetworkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            orgid: orgid,
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };

    NetworkType.getNetworkType(typeid, venueid).then(function (success) {
      $log.info(success);
      vm.networkType = success.data.networkType;
      for (var j = 0; j < vm.networkType.networkStrategies.length; j++) {
        vm.selectedStrategies.push(vm.networkType.networkStrategies[j].id);
      }
      console.log(vm.networkType);
      // console.log(vm.networkType.networkStrategies);
      // console.log(vm.ntstrategies);
    }, function (err) {
      $log.info(err);
    });

    Category.getAllCategories().then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });

    vm.setProvider();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editclstype
   * @description
   *
   */
  angular
    .module('editclstype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editclstype.controller:EditclstypeCtrl
   *
   * @description
   *
   */
  EditclstypeCtrl.$inject = ["$state", "$stateParams", "Cloudservicetype"];
  angular
    .module('editclstype')
    .controller('EditclstypeCtrl', EditclstypeCtrl);

  function EditclstypeCtrl($state, $stateParams, Cloudservicetype) {

    var vm = this;
    vm.ctrlName = 'EditclstypeCtrl';

    vm.ErrorMessage = false;
    vm.cloudservicetypeDetails = [];
    vm.Message = [];

    Cloudservicetype.getCloudServiceTypeById($stateParams.typeid).success(function (data) {
      vm.cloudservicetypeDetails = data.cloudServiceType;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateCLT = function () {
      var data = JSON.stringify({
        name: vm.cloudservicetypeDetails.name,
        description: vm.cloudservicetypeDetails.description
      });
      Cloudservicetype.udpateCloudServiceType(vm.cloudservicetypeDetails.id, data).success(function (data) {
        $state.go('admin.cloudservicetypes');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editclservice
   * @description
   *
   */
  angular
    .module('editclservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editclservice.controller:EditclserviceCtrl
   *
   * @description
   *
   */
  EditclserviceCtrl.$inject = ["$state", "$log", "$scope", "$stateParams", "Cloudservices", "Cloudservicetype", "Infrastructureapi"];
  angular
    .module('editclservice')
    .controller('EditclserviceCtrl', EditclserviceCtrl);

  function EditclserviceCtrl($state, $log, $scope, $stateParams, Cloudservices, Cloudservicetype, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'EditclserviceCtrl';

    vm.ErrorMessage = false;
    vm.cloudserviceDetails = {};
    vm.Message = [];
    vm.selectedType = '';
    vm.types = [];
    var venueid = $stateParams.venueid;
    var cloudservicesid = $stateParams.cloudservicesid;
    vm.orgid = $stateParams.orgid;
    vm.venue = {};
    vm.venueDetails = {};
    vm.ErrorMessage = false;
    vm.venueid = $stateParams.venueid;
    vm.ListConfirmMessage = false;
    vm.ListErrorMessage = false;
    vm.ListMessage = [];
    vm.ConfirmMessage = false;

    vm.setType = function (type) {
      vm.typeSelected = true;
      vm.selectedType = type;
    };

    vm.getApiKey = function () {
      Infrastructureapi.getVenue(vm.orgid, venueid).then(function (resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageurl;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website = resp.data.venue.website;
        if (resp.data.venue.hasOwnProperty('details')) {
          vm.venueDetails = resp.data.venue.details;
          vm.venueDetails = JSON.parse(vm.venueDetails);
          if (vm.venueDetails.hasOwnProperty('mailchimp')) {
            if (vm.venueDetails.mailchimp.hasOwnProperty('apiKey')) {
              vm.apiKey = vm.venueDetails.mailchimp.apiKey;
              vm.getLists();
            }
            if (vm.venueDetails.mailchimp.hasOwnProperty('listId')) {
              vm.listId = vm.venueDetails.mailchimp.listId;
              vm.newListId = vm.listId;
            }
          }
        } else {
          vm.venueDetails = {};
        }

      }, function (err) {
        $log.info(err);
      });
    };

    vm.getApiKey();

    vm.getLists = function () {
      vm.lists = [];
      Infrastructureapi.getLists(vm.cloudserviceDetails.apikey).then(function (resp) {
            for (var i = 0; i < resp.data.lists.length; i++) {
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name
          });
        }
      }, function (err) {
        $log.info(err);
        vm.lists.length = 0;
      });
    };

    vm.updateApiKey = function () {
      vm.submitted = true;

      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.apiKey = vm.cloudserviceDetails.apikey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.apiKey = vm.cloudserviceDetails.apikey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }
      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log( data);
        vm.ConfirmMessage = true;
        vm.Message = 'Api Key Updated';
        vm.getLists();
      }, function (err) {
        vm.Message = true;
        vm.Message = err.message;
        console.log('Error = ' + err);
      });
    };

    vm.updateList = function () {
      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.listId = vm.cloudserviceDetails.listidentifier;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.listId = vm.cloudserviceDetails.listidentifier;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }

      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log(data);
        vm.ListConfirmMessage = true;
        vm.ListMessage = 'List Updated';
        vm.getApiKey();
      }, function (err) {
        console.log('Error = ' + err);
      });
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid,
        orgid: vm.orgid
      });
    };

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      vm.cloudservicetypes = data.cloudServiceTypes;
    }).error(function (err) {
      console.log('Error: ', err);
    });

    Cloudservices.getCloudServiceById(venueid, cloudservicesid).success(function (data) {
      vm.cloudserviceDetails = data.cloudService;
      vm.setType(vm.cloudserviceDetails.typeid);
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.udpateCloudService = function () {
      var typeid = vm.selectedType;
      var arr = vm.cloudservicetypes;
      var element;
      vm.test = function (id) {
        arr.forEach(function (type) {
          if (parseInt(type.id) === parseInt(id)) {
            element = type.name;
          }
        });
        return element;
      };
      vm.test(typeid);
      var data = '';
      if (element !== 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.cloudserviceDetails.apikey,
          listidentifier: '',
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      } else if (element === 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.cloudserviceDetails.apikey,
          listidentifier: vm.cloudserviceDetails.listidentifier,
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      }
      Cloudservices.udpateCloudService(venueid, $stateParams.cloudservicesid, data).success(function (data) {
        console.log(data);
        $state.go('admin.cloudservices', {
          'cloudservicesid': $stateParams.cloudservicesid,
          'orgid': $stateParams.orgid,
          'venueid': $stateParams.venueid
        });
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.listDismiss = function () {
      vm.ListMessage = {};
      vm.ListConfirmMessage = false;
    };

    vm.listDismissError = function () {
      vm.ListMessage = {};
      vm.ListErrorMessage = false;
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editap
   * @description
   *
   */
  angular
    .module('editap', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editap.controller:EditapCtrl
   *
   * @description
   *
   */
  EditapCtrl.$inject = ["Infrastructureapi", "$stateParams", "$state", "$log"];
  angular
    .module('editap')
    .controller('EditapCtrl', EditapCtrl);

  function EditapCtrl(Infrastructureapi, $stateParams, $state, $log) {
    var vm = this;
    vm.ctrlName = 'EditapCtrl';

    var apid = $stateParams.apid;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.accesspoint = {};

    Infrastructureapi.getAp(apid, venueid, orgid).then(function (resp) {
      $log.info(resp);
      vm.accesspoint.provider = resp.data.apoint.provider;
      vm.accesspoint.name = resp.data.apoint.name;
      vm.accesspoint.hwAddr = resp.data.apoint.hwaddr;
      vm.accesspoint.ipAddress = resp.data.apoint.ipaddress;
      vm.accesspoint.sshUsername = resp.data.apoint.sshusername;
      vm.accesspoint.sshPassword = resp.data.apoint.sshpassword;
      $log.info(vm.accesspoint);
    }, function (err) {
      $log.info(err);
    });

    vm.updateAccesspoint = function (isValid) {
      vm.submitted = true;
      if (isValid) {
        vm.accesspoint.orgid = orgid;
        Infrastructureapi.updateAp(apid, venueid, vm.accesspoint).then(function (resp) {
          $log.info(resp);
          $state.go('admin.managenetwork', {
            venueid: venueid,
            orgid: orgid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editadminpwd
   * @description
   *
   */
  angular
    .module('editadminpwd', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editadminpwd.controller:EditadminpwdCtrl
   *
   * @description
   *
   */
  EditadminpwdCtrl.$inject = ["$state", "Leadmin", "$stateParams"];
  angular
    .module('editadminpwd')
    .controller('EditadminpwdCtrl', EditadminpwdCtrl);

  function EditadminpwdCtrl($state, Leadmin, $stateParams) {
    var vm = this;
    vm.ctrlName = 'EditadminpwdCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];
    vm.adminid = $stateParams.adminid;

    vm.updateAdminPassword = function () {
      var data = JSON.stringify({
        password: vm.adminDetails.password,
        adminId: vm.adminid
      });
      Leadmin.updateAdminPassword(vm.adminid, data).success(function (data) {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editadmin
   * @description
   *
   */
  angular
    .module('editadmin', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editadmin.controller:EditadminCtrl
   *
   * @description
   *
   */
  EditadminCtrl.$inject = ["$state", "$stateParams", "Leadmin"];
  angular
    .module('editadmin')
    .controller('EditadminCtrl', EditadminCtrl);

  function EditadminCtrl($state, $stateParams, Leadmin) {
    var vm = this;
    vm.ctrlName = 'EditadminCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];

    Leadmin.getAdminById($stateParams.adminid).success(function (data) {
      vm.adminDetails = data.admin;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateAdmin = function () {
      var data = JSON.stringify({
        login: vm.adminDetails.login,
        name: vm.adminDetails.name,
        details: {}
      });
      Leadmin.updateAdmin(vm.adminDetails.id, data).success(function (data) {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cloudservicetypes
   * @description
   *
   */
  angular
    .module('cloudservicetypes', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudtypedeletemodalCtrl
   *
   * @description
   *
   */
  CloudtypedeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('cloudservicetypes')
    .controller('CloudtypedeletemodalCtrl', CloudtypedeletemodalCtrl);

  function CloudtypedeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CloudtypedeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudservicetypesCtrl
   *
   * @description
   *
   */
  CloudservicetypesCtrl.$inject = ["$state", "Cloudservicetype", "$modal"];
  angular
    .module('cloudservicetypes')
    .controller('CloudservicetypesCtrl', CloudservicetypesCtrl);

  function CloudservicetypesCtrl($state, Cloudservicetype, $modal) {
    var vm = this;
    vm.ctrlName = 'CloudservicetypesCtrl';

    vm.cloudservicetypesLoaded = false;
    vm.cloudservicetypesLoadedError = false;
    vm.cloudservicetypes = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      vm.cloudservicetypes = data.cloudServiceTypes;
      vm.cloudservicetypesLoaded = true;
    }).error(function (err) {
      vm.cloudservicetypesLoadedError = true;
      vm.cloudservicetypesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllTypes() {
      Cloudservicetype.getCloudServiceTypes().success(function (data) {
        vm.cloudservicetypes = data.cloudServiceTypes;
        vm.cloudservicetypesLoaded = true;
      }).error(function (err) {
        vm.cloudservicetypesLoadedError = true;
        vm.cloudservicetypesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'cloudservicetypes/views/cloudtypedeletemodal.tpl.html',
        controller: 'CloudtypedeletemodalCtrl as cloudtypedeleteModal'
      });

      modalInstance.result.then(function () {

        Cloudservicetype.deleteCloudServiceType(id).success(function () {
          reloadAllTypes();
        }).error(function (err) {
          console.log('Error: ', err);
          if (err.message.code === '23503') {
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Cloud Service Type!';
          } else {
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cloudservices
   * @description
   *
   */
  angular
    .module('cloudservices', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudtypedeletemodalCtrl
   *
   * @description
   *
   */
  ClservicedeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('cloudservices')
    .controller('ClservicedeletemodalCtrl', ClservicedeletemodalCtrl);

  function ClservicedeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CloudtypedeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservices.controller:CloudservicesCtrl
   *
   * @description
   *
   */
  CloudservicesCtrl.$inject = ["$state", "Cloudservices", "$modal", "$stateParams"];
  angular
    .module('cloudservices')
    .controller('CloudservicesCtrl', CloudservicesCtrl);

  function CloudservicesCtrl($state, Cloudservices, $modal, $stateParams) {
    var vm = this;
    vm.ctrlName = 'CloudservicesCtrl';
    vm.orgid = $stateParams.orgid;
    vm.cloudservicesLoaded = false;
    vm.cloudservicesLoadedError = false;
    vm.cloudservices = [];
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.venueid = $stateParams.venueid;

    Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
      vm.cloudservices = data.cloudServices;
      vm.cloudservicesLoaded = true;
    }).error(function (err) {
      vm.cloudservicesLoadedError = true;
      vm.cloudservicesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAll() {
      Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
        vm.cloudservices = data.cloudServices;
        vm.cloudservicesLoaded = true;
      }).error(function (err) {
        vm.cloudservicesLoadedError = true;
        vm.cloudservicesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (cloudservicesid) {

      var modalInstance = $modal.open({
        templateUrl: 'cloudservices/views/clservicedeletemodal.tpl.html',
        controller: 'ClservicedeletemodalCtrl as clservicedeleteModal'
      });

      modalInstance.result.then(function () {

        Cloudservices.deleteCloudService(vm.venueid, cloudservicesid).success(function () {
          reloadAll(vm.venueid, cloudservicesid);
        }).error(function (err) {
          console.log('Delete Error: ', err);
          if (err.message.code === '23503') {
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Cloud Service!';
          } else {
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name admin
   * @description
   *
   */
  angular
    .module('admin', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admin.controller:AdminCtrl
   *
   * @description
   *
   */
  AdminCtrl.$inject = ["$rootScope", "Auth", "$cookies", "$state"];
  angular
    .module('admin')
    .controller('AdminCtrl', AdminCtrl);

  function AdminCtrl($rootScope, Auth, $cookies, $state) {
    var vm = this;
    var authData = null;
    vm.ctrlName = 'AdminCtrl';

    $rootScope.bodyClass = 'admin';

    authData = Auth.getAuthData();
    //console.log(authData);

    vm.profileInfo = {
      userName: authData.admin.login,
      userImg: './images/batman.jpg'
    };

    vm.logout = function () {
      $cookies.remove('leData');
      Auth.setIslogged(false);
      $state.go('main.home');
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name categories
   * @description
   *
   */
  angular
    .module('categories', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name categories.controller:CategoriesCtrl
   *
   * @description
   *
   */
  CategoriesCtrl.$inject = ["$state", "Category", "$modal", "Provider"];
  angular
    .module('categories')
    .controller('CategoriesCtrl', CategoriesCtrl);

  function CategoriesCtrl($state, Category, $modal, Provider) {
    var vm = this;
    vm.ctrlName = 'CategoriesCtrl';

    vm.categoriesLoaded = false;
    vm.categoriesLoadedError = false;
    vm.categories = [];
    vm.providers = [];
    vm.providerLookup = [];
    vm.providersLoadedError = false;
    vm.providersLoaded = false;

    Category.getAllCategories().success(function (data) {
      vm.categories = data.categories;
      vm.categoriesLoaded = true;
    }).error(function (err) {
      vm.categoriesLoadedError = true;
      vm.categoriesLoaded = true;
      console.log('Error: ', err);
    });

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
      for (var i = 0; i < vm.providers.length; i++) {
        vm.providerLookup[vm.providers[i].id] = vm.providers[i];
      }
      vm.providersLoadedError = false;
      vm.providersLoaded = true;
    }).error(function (err) {
      console.log('Error: ', err);
      vm.providersLoadedError = true;
      vm.providersLoaded = true;
    });

    vm.getProviderName = function (providerid) {
      if (!vm.providersLoadedError && vm.providersLoaded)
        return vm.providerLookup[providerid].name;
    };

    function reloadAllCategories() {
      Category.getAllCategories().success(function (data) {
        vm.categories = data.categories;
        vm.categoriesLoaded = true;
      }).error(function (err) {
        vm.categoriesLoadedError = true;
        vm.categoriesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'categories/views/catdeletemodal.tpl.html',
        controller: 'CatdeletemodalCtrl as catdeleteModal'
      });

      modalInstance.result.then(function () {
        Category.deleteCategory(id).then(function () {
          reloadAllCategories();
        }, function(err){
          console.log(err);
          var modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete strategy, strategies cannot be deleted if associated with an event',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function() {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function() {

          });
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name categories.controller:CatdeletemodalCtrl
   *
   * @description
   *
   */
  CatdeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('categories')
    .controller('CatdeletemodalCtrl', CatdeletemodalCtrl);

  function CatdeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CatdeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name admins
   * @description
   *
   */
  angular
    .module('admins', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admins.controller:AdminsCtrl
   *
   * @description
   *
   */
  AdminsCtrl.$inject = ["$state", "Leadmin", "$modal"];
  angular
    .module('admins')
    .controller('AdminsCtrl', AdminsCtrl);

  function AdminsCtrl($state, Leadmin, $modal) {
    var vm = this;
    vm.ctrlName = 'AdminsCtrl';

    vm.adminsLoaded = false;
    vm.adminsLoadedError = false;
    vm.admins = [];

    Leadmin.getAllAdmins().success(function (data) {
      vm.admins = data.admins;
      vm.adminsLoaded = true;
    }).error(function (err) {
      vm.adminsLoadedError = true;
      vm.adminsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllAdmins() {
      Leadmin.getAllAdmins().success(function (data) {
        vm.admins = data.admins;
        vm.adminsLoaded = true;
      }).error(function (err) {
        vm.adminsLoadedError = true;
        vm.adminsLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'admins/views/admindeletemodal.tpl.html',
        controller: 'AdmindeletemodalCtrl as admindeleteModal'
      });

      modalInstance.result.then(function () {
        Leadmin.deleteAdmin(id).success(function () {
          reloadAllAdmins();
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admins.controller:AdmindeletemodalCtrl
   *
   * @description
   *
   */
  AdmindeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('admins')
    .controller('AdmindeletemodalCtrl', AdmindeletemodalCtrl);

  function AdmindeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'AdmindeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name analytics
   * @description
   *
   */
  angular
    .module('analytics', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name analytics.controller:AnalyticsCtrl
   *
   * @description
   *
   */
  AnalyticsCtrl.$inject = ["$state", "Analytics", "$modal"];
  angular
    .module('analytics')
    .controller('AnalyticsCtrl', AnalyticsCtrl);

  function AnalyticsCtrl($state, Analytics, $modal) {
    var vm = this;
    vm.ctrlName = 'AnalyticsCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];

    vm.schedulerLabels = [];
    vm.schedulerData = [];
    vm.schedulerSeries = [];

    vm.schedulerCreateLabels = [];
    vm.schedulerCreateData = [];
    vm.schedulerCreateSeries = [];

    vm.schedulerDeleteLabels = [];
    vm.schedulerDeleteData = [];
    vm.schedulerDeleteSeries = [];

    vm.methodLabels = [];
    vm.methodData = [];
    vm.methodSeries = [];

    vm.methodNumberLabels = [];
    vm.methodNumberData = [];
    vm.methodNumberSeries = [];

    vm.organisationsLoaded = false;
    vm.organisationsLoadedError = false;
    vm.organisations = [];

    vm.orgId = null;
    vm.venueId = null;
    vm.eventId = null;

    vm.venuesLoaded = false;
    vm.venuesLoadedError = false;
    vm.venues = [];

    vm.eventsLoaded = false;
    vm.eventsLoadedError = false;
    vm.events = [];
    vm.analytics = false;

    vm.eventAnalyticsLoaded = false;
    vm.eventAnalyticsLoadedError = false;
    vm.eventAnalytics = [];

    vm.schedulerAnalyticsLoaded = false;
    vm.schedulerAnalyticsLoadedError = false;
    vm.schedulerMinMaxAnalytics = [];
    vm.schedulerAvgSumAnalytics = [];
    vm.schedulerAvgSumAnalyticsLoaded = false;
    vm.schedulerAvgSumAnalyticsLoadedError = false;

    vm.schedulerCreateAnalyticsLoaded = false;
    vm.schedulerCreateAnalyticsLoadedError = false;
    vm.schedulerCreateMinMaxAnalytics = [];
    vm.schedulerCreateAvgSumAnalytics = [];
    vm.schedulerCreateAvgSumAnalyticsLoaded = false;
    vm.schedulerCreateAvgSumAnalyticsLoadedError = false;

    vm.schedulerDeleteAnalyticsLoaded = false;
    vm.schedulerDeleteAnalyticsLoadedError = false;
    vm.schedulerDeleteMinMaxAnalytics = [];
    vm.schedulerDeleteAvgSumAnalytics = [];
    vm.schedulerDeleteAvgSumAnalyticsLoaded = false;
    vm.schedulerDeleteAvgSumAnalyticsLoadedError = false;

    vm.methodNumberData = [];

    Analytics.getAllOrganisations().success(function (data) {
      vm.organisations = data.orgs;
      vm.organisationsLoaded = true;
      vm.organisationsLoadedError = false;
      vm.venuesLoaded = false;
      vm.venuesLoadedError = false;
      vm.resetVariablesAnalytics();
      vm.dismiss();
    }).error(function (err) {
      vm.organisationsLoadedError = true;
      vm.organisationsLoaded = true;
      console.log('Error: ', err);
      vm.Message = err;
      vm.ErrorMessage = true;
    });

    vm.getAllVenues = function (orgId) {
      vm.orgId = orgId;
      vm.venuesLoaded = false;
      vm.venuesLoadedError = false;
      vm.eventsLoaded = false;
      vm.eventsLoadedError = false;
      vm.venues = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      if (orgId > 0){
        Analytics.getAllVenues(orgId).success(function (data) {
          vm.venues = data.performanceObj;
          vm.venuesLoaded = true;
          vm.venuesLoadedError = false;
        }).error(function (err) {
          vm.venuesLoadedError = true;
          vm.venuesLoaded = true;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getAllEvents = function (venueId) {
      vm.venueId = venueId;
      vm.eventsLoaded = false;
      vm.eventsLoadedError = false;
      vm.events = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      if (venueId > 0){
        Analytics.getAllEvents(vm.orgId, venueId).success(function (data) {
          vm.events = data.performanceObj;
          vm.eventsLoaded = true;
          vm.eventsLoadedError = false;
        }).error(function (err) {
          vm.eventsLoadedError = true;
          vm.eventsLoaded = true;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getAnalyticsByEvent = function (eventId) {
      vm.eventId = eventId;
      vm.eventAnalytics = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      var data = JSON.stringify({
        orgId: vm.orgId,
        venueId: vm.venueId,
        eventId: eventId
      });
      if (eventId > 0){
        vm.setCreateSchedulerAnalytics(eventId);
        vm.setDeleteSchedulerAnalytics(eventId);
        Analytics.getEventAnalytics(data).success(function (data) {
          vm.eventAnalytics = data.performanceObj;
          vm.eventAnalyticsLoaded = true;
          vm.analytics = true;
          vm.methodData = [];
          // vm.schedulerLabels = [];
          // var schedulerDataTemp = [];
          var methodDataTemp = [];
          var methodNumberDataTemp = [];
          vm.methodNumberData = [];
          // vm.schedulerData = [];
          vm.methodLabels = ["GET", "POST", "PUT", "DELETE"];
          var getMethod = 0;
          var postMethod = 0;
          var deleteMethod = 0;
          var putMethod = 0;
          var getMethodNumber = 0;
          var postMethodNumber = 0;
          var deleteMethodNumber = 0;
          var putMethodNumber = 0;
          for(var i=0; i<vm.eventAnalytics.length; i++){
            if (vm.eventAnalytics[i].details.componentId === "enduser-rest-api"){
              var methodVariable = vm.eventAnalytics[i].details.information.method;
              if (methodVariable === "GET" || methodVariable === "get" || methodVariable === "Get"){
                getMethod = getMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                getMethodNumber++;
              }
              if (methodVariable === "POST" || methodVariable === "post" || methodVariable === "Post"){
                postMethod = postMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                postMethodNumber++;
              }
              if (methodVariable === "PUT" || methodVariable === "put" || methodVariable === "Put"){
                putMethod = putMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                putMethodNumber++;
              }
              if (methodVariable === "DELETE" || methodVariable === "delete" || methodVariable === "Delete"){
                deleteMethod = deleteMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                deleteMethodNumber++;
              }
            }
            // else{
            //   vm.schedulerLabels.push(vm.eventAnalytics[i].details.componentId);
            //   schedulerDataTemp.push(vm.eventAnalytics[i].details.timingInfo.duration);
            // }
          }

          var getDivNumGET = getMethod/getMethodNumber;
          if (isNaN(getDivNumGET))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumGET);
          var getDivNumPOST = postMethod/postMethodNumber;
          if (isNaN(getDivNumPOST))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumPOST);
          var getDivNumPUT = putMethod/putMethodNumber;
          if (isNaN(getDivNumPUT))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumPUT);
          var getDivNumDELETE = deleteMethod/deleteMethodNumber;
          if (isNaN(getDivNumDELETE))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumDELETE);

          vm.methodData.push(methodDataTemp);
          methodNumberDataTemp.push(getMethodNumber);
          methodNumberDataTemp.push(postMethodNumber);
          methodNumberDataTemp.push(putMethodNumber);
          methodNumberDataTemp.push(deleteMethodNumber);
          vm.methodNumberData.push(methodNumberDataTemp);
          // vm.schedulerData.push(schedulerDataTemp);
        }).error(function (err) {
          vm.eventAnalyticsLoadedError = true;
          vm.eventAnalyticsLoaded = true;
          vm.analytics = false;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getMinutes = function (timeMS) {
      var num = Number(timeMS);
      var seconds = Math.floor(num / 1000);
      var minutes = Math.floor(seconds / 60);
      return minutes;
    };

    vm.setCreateSchedulerAnalytics = function (eventId) {
      vm.schedulerCreateLabels = [];
      var schedulerCreateDataTemp = [];
      vm.schedulerCreateData = [];
      vm.schedulerCreateMinMaxAnalytics = [];
      vm.schedulerCreateAvgSumAnalytics = [];
      Analytics.getCreateMinMaxAnalytics(eventId).success(function (data) {
        if (data.rowCount > 0){
          vm.schedulerCreateMinMaxAnalytics = data.performanceObj;
          var minData = vm.schedulerCreateMinMaxAnalytics[0].getCreateMINMAXAnalytics;
          var maxData = vm.schedulerCreateMinMaxAnalytics[1].getCreateMINMAXAnalytics;
          minData = minData.substring(1, minData.length - 1);
          maxData = maxData.substring(1, maxData.length - 1);
          var minDataSplitted = minData.split(",");
          var maxDataSplitted = maxData.split(",");
          vm.schedulerCreateLabels.push("MIN");
          vm.schedulerCreateLabels.push("MAX");
          schedulerCreateDataTemp.push(vm.getMinutes(minDataSplitted[1]));
          schedulerCreateDataTemp.push(vm.getMinutes(maxDataSplitted[1]));
          vm.schedulerCreateAnalyticsLoaded = true;
          vm.schedulerCreateAnalyticsLoadedError = false;
          vm.analytics = true;
          Analytics.getCreateAvgSumAnalytics(eventId).success(function (data) {
            vm.schedulerCreateAvgSumAnalytics = data.performanceObj;
            var avgData = vm.schedulerCreateAvgSumAnalytics[0].getCreateAVGSUMAnalytics;
            var sumData = vm.schedulerCreateAvgSumAnalytics[1].getCreateAVGSUMAnalytics;
            vm.schedulerCreateLabels.push("AVG");
            vm.schedulerCreateLabels.push("TOTAL");
            schedulerCreateDataTemp.push(vm.getMinutes(avgData));
            schedulerCreateDataTemp.push(vm.getMinutes(sumData));
            vm.schedulerCreateData.push(schedulerCreateDataTemp);
            vm.schedulerCreateAvgSumAnalyticsLoaded = true;
            vm.schedulerCreateAvgSumAnalyticsLoadedError = false;
            vm.analytics = true;
          }).error(function (err) {
            vm.schedulerCreateAvgSumAnalyticsLoaded = false;
            vm.schedulerCreateAvgSumAnalyticsLoadedError = true;
            vm.analytics = false;
            vm.Message = err;
            vm.ErrorMessage = true;
          });
        }
      }).error(function (err) {
        vm.schedulerCreateAnalyticsLoaded = false;
        vm.schedulerCreateAnalyticsLoadedError = true;
        vm.analytics = false;
        vm.Message = err;
        vm.ErrorMessage = true;
      });
    };

    vm.setDeleteSchedulerAnalytics = function (eventId) {
      vm.schedulerDeleteLabels = [];
      var schedulerDeleteDataTemp = [];
      vm.schedulerDeleteData = [];
      vm.schedulerDeleteMinMaxAnalytics = [];
      vm.schedulerDeleteAvgSumAnalytics = [];
      Analytics.getDeleteMinMaxAnalytics(eventId).success(function (data) {
        if (data.rowCount > 0){
          vm.schedulerDeleteMinMaxAnalytics = data.performanceObj;
          var minData = vm.schedulerDeleteMinMaxAnalytics[0].getDeleteMINMAXAnalytics;
          var maxData = vm.schedulerDeleteMinMaxAnalytics[1].getDeleteMINMAXAnalytics;
          minData = minData.substring(1, minData.length - 1);
          maxData = maxData.substring(1, maxData.length - 1);
          var minDataSplitted = minData.split(",");
          var maxDataSplitted = maxData.split(",");
          vm.schedulerDeleteLabels.push("MIN");
          vm.schedulerDeleteLabels.push("MAX");
          schedulerDeleteDataTemp.push(vm.getMinutes(minDataSplitted[1]));
          schedulerDeleteDataTemp.push(vm.getMinutes(maxDataSplitted[1]));
          vm.schedulerDeleteAnalyticsLoaded = true;
          vm.schedulerDeleteAnalyticsLoadedError = false;
          vm.analytics = true;
          Analytics.getDeleteAvgSumAnalytics(eventId).success(function (data) {
            vm.schedulerDeleteAvgSumAnalytics = data.performanceObj;
            console.log(vm.schedulerCreateAvgSumAnalytics);
            var avgData = vm.schedulerDeleteAvgSumAnalytics[0].getDeleteAVGSUMAnalytics;
            var sumData = vm.schedulerDeleteAvgSumAnalytics[1].getDeleteAVGSUMAnalytics;
            vm.schedulerDeleteLabels.push("AVG");
            vm.schedulerDeleteLabels.push("TOTAL");
            schedulerDeleteDataTemp.push(vm.getMinutes(avgData));
            schedulerDeleteDataTemp.push(vm.getMinutes(sumData));
            vm.schedulerDeleteData.push(schedulerDeleteDataTemp);
            vm.schedulerDeleteAvgSumAnalyticsLoaded = true;
            vm.schedulerDeleteAvgSumAnalyticsLoadedError = false;
            vm.analytics = true;
          }).error(function (err) {
            vm.schedulerDeleteAvgSumAnalyticsLoaded = false;
            vm.schedulerDeleteAvgSumAnalyticsLoadedError = true;
            vm.analytics = false;
            vm.Message = err;
            vm.ErrorMessage = true;
          });
        }
      }).error(function (err) {
        vm.schedulerDeleteAnalyticsLoaded = false;
        vm.schedulerDeleteAnalyticsLoadedError = true;
        vm.analytics = false;
        vm.Message = err;
        vm.ErrorMessage = true;
      });
    };

    vm.resetVariablesAnalytics = function () {
      vm.eventAnalytics = [];
      vm.eventAnalyticsLoaded = false;
      vm.schedulerCreateLabels = [];
      vm.schedulerCreateData = [];
      vm.schedulerDeleteLabels = [];
      vm.schedulerDeleteData = [];
      vm.analytics = false;
      vm.methodLabels = [];
      vm.methodData = [];
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.status = {
      schedulerGraph: true,
      methodsGraph: true
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addorganisation
   * @description
   *
   */
  angular
    .module('addorganisation', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addorganisation.controller:AddorganisationCtrl
   *
   * @description
   *
   */
  AddorganisationCtrl.$inject = ["$state", "Organisation"];
  angular
    .module('addorganisation')
    .controller('AddorganisationCtrl', AddorganisationCtrl);

  function AddorganisationCtrl($state, Organisation) {
    var vm = this;
    vm.ctrlName = 'AddorganisationCtrl';

    vm.ErrorMessage = false;
    vm.organisationDetails = [];
    vm.Message = [];
    vm.premium = false;

    vm.createOrganisation = function () {
      var data = JSON.stringify({
        organisationTitle: vm.organisationDetails.organisationTitle,
        premium: vm.premium,
        details: {
          email: vm.organisationDetails.email,
          phone: vm.organisationDetails.phone
        }
      });
      Organisation.createOrganisation(data).success(function () {
        $state.go('admin.organisations');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addsplashtype
   * @description
   *
   */
  angular
    .module('addsplashtype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addsplashtype.controller:AddsplashtypeCtrl
   *
   * @description
   *
   */
  AddsplashtypeCtrl.$inject = ["$state", "Splashtype", "$log", "Provider"];
  angular
    .module('addsplashtype')
    .controller('AddsplashtypeCtrl', AddsplashtypeCtrl);

  function AddsplashtypeCtrl($state, Splashtype, $log, Provider) {
    var vm = this;
    vm.ctrlName = 'AddsplashtypeCtrl';

    vm.splashtype = {};
    vm.submitted = false;

    vm.createSplash = function (valid) {
      vm.submitted = true;
      if (valid) {
        Splashtype.createSplashType(vm.splashtype).then(function () {
          $state.go('admin.splashtype');
        }, function (err) {
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addzone
   * @description
   *
   */
  angular
    .module('addzone', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addzone.controller:AddzoneCtrl
   *
   * @description
   *
   */
  AddzoneCtrl.$inject = ["Infrastructureapi", "$stateParams", "$log", "$state"];
  angular
    .module('addzone')
    .controller('AddzoneCtrl', AddzoneCtrl);

  function AddzoneCtrl(Infrastructureapi, $stateParams, $log, $state) {
    var vm = this;
    vm.status = {
      isopen: false
    };
    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedAccesspoints = [];
    vm.provider = $stateParams.provider;
    var zoneId = 0;

    Infrastructureapi.getAps($stateParams.venueid, $stateParams.orgid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.createZone = function() {
      //console.log(vm.selectedAccesspoints);
      vm.zone.orgid = $stateParams.orgid;
      Infrastructureapi.createZone($stateParams.venueid, vm.zone).success(function(data) {
        //console.log(data);
        zoneId = data.id;
        console.log(vm.selectedAccesspoints);
        if (vm.selectedAccesspoints.length > 0) {
          var strSelectedAccesspoints = vm.selectedAccesspoints;
          var apZoneUpateObj = {
            venueId: $stateParams.venueid,
            zoneId: zoneId,
            orgId: $stateParams.orgid,
            accessPoints: strSelectedAccesspoints
          };
          console.log(apZoneUpateObj);
          Infrastructureapi.updateApsZone(apZoneUpateObj).success(function(data) {
            console.log(data);
            $state.go('admin.managenetwork', {
              'orgid': $stateParams.orgid,
              'venueid': $stateParams.venueid
            });
          }).error(function(err) {
            console.log(err);
          });
        } else {
          $state.go('admin.managenetwork', {
            'orgid': $stateParams.orgid,
            'venueid': $stateParams.venueid
          });
        }
      }, function(err) {
        console.log(err);
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addslt
   * @description
   *
   */
  angular
    .module('addslt', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addslt.controller:AddsltCtrl
   *
   * @description
   *
   */
  AddsltCtrl.$inject = ["$state", "$modal", "Slt", "Provider"];
  angular
    .module('addslt')
    .controller('AddsltCtrl', AddsltCtrl);

  function AddsltCtrl($state, $modal, Slt, Provider) {
    var vm = this;
    vm.ctrlName = 'AddsltCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];
    vm.slt = {};

    vm.selectImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.slt.imageuri = thisImage.imageuri;
        vm.slt.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };
    vm.uploadImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        vm.slt.imageuri = image.imageUri;
        vm.slt.imageId = image.id;
      }, function() {
        console.log('canceled');
      });
    };


    vm.createSLT = function () {
      var data = JSON.stringify({
        name: vm.slt.name,
        imageId: vm.slt.imageId,
        details: '{}'
      });
      Slt.createSLT(data).success(function (data) {
        $state.go('admin.sociallogins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addrole
   * @description
   *
   */
  angular
    .module('addrole', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addrole.controller:AddroleCtrl
   *
   * @description
   *
   */
  AddroleCtrl.$inject = ["$state", "Roles"];
  angular
    .module('addrole')
    .controller('AddroleCtrl', AddroleCtrl);

  function AddroleCtrl($state, Roles) {
    var vm = this;
    vm.ctrlName = 'AddroleCtrl';

    vm.ErrorMessage = false;
    vm.organisationRole = [];
    vm.Message = [];

    vm.createOrganisationRole = function () {
      var data = JSON.stringify({
        name: vm.organisationRole.name,
        details: {}
      });
      Roles.createOrganisationRole(data).success(function (data) {
        $state.go('admin.organisationroles');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addprovider
   * @description
   *
   */
  angular
    .module('addprovider', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addprovider.controller:AddproviderCtrl
   *
   * @description
   *
   */
  AddproviderCtrl.$inject = ["$state", "Provider"];
  angular
    .module('addprovider')
    .controller('AddproviderCtrl', AddproviderCtrl);

  function AddproviderCtrl($state, Provider) {
    var vm = this;
    vm.ctrlName = 'AddproviderCtrl';

    vm.ErrorMessage = false;
    vm.providerDetails = [];
    vm.Message = [];

    vm.createProvider = function () {
      var data = JSON.stringify({
        name: vm.providerDetails.name,
        details: vm.providerDetails.details
      });
      Provider.createProvider(data).success(function () {
        $state.go('admin.providers');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addgns
   * @description
   *
   */
  angular
    .module('addgns', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addgns.controller:AddgnsCtrl
   *
   * @description
   *
   */
  AddgnsCtrl.$inject = ["Provider", "GnsApi", "$stateParams", "$log", "$state"];
  angular
    .module('addgns')
    .controller('AddgnsCtrl', AddgnsCtrl);

  function AddgnsCtrl(Provider, GnsApi,$stateParams, $log, $state) {
    var vm = this;
    vm.ctrlName = 'AddgnsCtrl';
    vm.providers = [];

    vm.getProviders = function(){
      Provider.getAllProviders().then(function(res){
        vm.providers = res.data.providers;
      }, function(err){
        console.log(err);
      });
    };

    vm.getProviders();

    vm.creategns = function() {
      GnsApi.createGNSType(vm.gns).success(function(){
        $state.go('admin.guestnetworksettings');
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addcloudservicetype
   * @description
   *
   */
  angular
    .module('addcloudservicetype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addcloudservicetype.controller:AddcloudservicetypeCtrl
   *
   * @description
   *
   */
  AddcloudservicetypeCtrl.$inject = ["$state", "Cloudservicetype"];
  angular
    .module('addcloudservicetype')
    .controller('AddcloudservicetypeCtrl', AddcloudservicetypeCtrl);

  function AddcloudservicetypeCtrl($state, Cloudservicetype) {
    var vm = this;
    vm.ctrlName = 'AddcloudservicetypeCtrl';

    vm.ErrorMessage = false;
    vm.cloudservicetypeDetails = [];
    vm.Message = [];

    vm.createCloudServiceType = function () {
      var data = JSON.stringify({
        name: vm.cloudservicetypeDetails.name,
        description: vm.cloudservicetypeDetails.description
      });
      Cloudservicetype.createCloudServiceType(data).success(function () {
        $state.go('admin.cloudservicetypes');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addoperator
   * @description
   *
   */
  angular
    .module('addoperator', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addoperator.controller:AddoperatorCtrl
   *
   * @description
   *
   */
  AddoperatorCtrl.$inject = ["$state", "Operator", "Roles", "$stateParams", "Infrastructureapi"];
  angular
    .module('addoperator')
    .controller('AddoperatorCtrl', AddoperatorCtrl);

  function AddoperatorCtrl($state, Operator, Roles, $stateParams, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'AddoperatorCtrl';

    vm.ErrorMessage = false;
    vm.operator = {};
    vm.submitted = false;
    vm.Message = '';
    vm.orgid = $stateParams.organisationid;
    vm.roles = [];

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
    }).error(function(err) {
      console.log(err);
    });

    Infrastructureapi.getVenues(vm.orgid).then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }
    };

    vm.createOrganisationOperator = function(valid) {
      vm.submitted = true;
      if (valid) {
        Operator.createOrganisationOperator(vm.orgid, vm.operator).success(function(data) {
          console.log(data);
          $state.go('admin.operators', {
            'organisationid': vm.orgid
          });
        }).error(function(err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }
    };


    vm.dismiss = function() {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addcategory
   * @description
   *
   */
  angular
    .module('addcategory', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addcategory.controller:AddcategoryCtrl
   *
   * @description
   *
   */
  AddcategoryCtrl.$inject = ["$state", "Category", "Netfilter", "$modal", "Provider"];
  angular
    .module('addcategory')
    .controller('AddcategoryCtrl', AddcategoryCtrl);

  function AddcategoryCtrl($state, Category, Netfilter, $modal, Provider) {
    var vm = this;
    vm.ctrlName = 'AddcategoryCtrl';
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.selectedType;
    vm.selectedProvider;
    vm.types = [];
    vm.xirrusFilters = [];
    vm.premium = false;
    vm.onSave = false;
    vm.providers = [];

    Netfilter.getlistOfXirrusFilters().success(function(data) {
      vm.xirrusFilters = data.xirrusFilters;
      console.log(vm.xirrusFilters);
    }).error(function(err) {
      console.log(err);
    });

    Netfilter.getlistOfCategoryTypes().success(function(data) {
      vm.types = data.categoryTypes;
      console.log('categoryTypes', data.categoryTypes);
    }).error(function(err) {
      console.log(err);
    });

    var getCategory = function(id) {
      Category.getCategoryById(id).then(function(res) {
        vm.newCategory = res.data.category;
      }, function(err) {
        console.log(err);
      });
    };

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.createCategory = function() {
      var data = JSON.stringify({
        name: vm.categoryDetails.name,
        provider: vm.selectedProvider,
        description: vm.categoryDetails.description,
        premium: vm.premium,
        categoryTypeId: vm.selectedType
      });
      Category.createCategory(data).success(function(result) {
        console.log(result);
        vm.onSave = true;
        getCategory(result.id);
      }).error(function(err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
        console.log(err);
      });
    };

    var getFilterList = function(id) {
      Netfilter.getFilterbyCategory(id).then(function(res) {
        console.log(res);
        vm.networkFilters = res.data.networkFilters;
      }, function(err) {
        console.log(err);
      });
    };

    vm.deleteFilter = function(id) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Filter, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function() {
        Netfilter.deleteNetFilter(id).then(function(res) {
          console.log(res);
          getFilterList(vm.newCategory.id);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.addFilter = function() {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Add Filters',
        category: vm.newCategory.id,
        filters: vm.xirrusFilters
      };

      var modalInstance = $modal.open({
        templateUrl: 'filtercreatemodal/views/filtercreatemodal.tpl.html',
        controller: 'FiltercreatemodalCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function(data) {
        console.log(data);
        Netfilter.createNetFilter(data).then(function(res) {
          console.log(res);
          getFilterList(data.categoryId);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.done = function(){
      $state.go('admin.categories');
    };

    vm.dismiss = function() {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addclservice
   * @description
   *
   */
  angular
    .module('addclservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addclservice.controller:AddclserviceCtrl
   *
   * @description
   *
   */
  AddclserviceCtrl.$inject = ["$state", "$log", "$scope", "Cloudservices", "$stateParams", "Cloudservicetype", "Infrastructureapi"];
  angular
    .module('addclservice')
    .controller('AddclserviceCtrl', AddclserviceCtrl);

  function AddclserviceCtrl($state, $log, $scope, Cloudservices, $stateParams, Cloudservicetype, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'AddclserviceCtrl';
    var venueid = $stateParams.venueid;
    //var orgid = $stateParams.orgid;
    vm.orgid = $stateParams.orgid;
    vm.venue = {};
    vm.venueDetails = {};
    vm.ErrorMessage = false;
    vm.cloudserviceDetails = [];
    vm.cloudservicetypes = [];
    vm.Message = [];
    vm.selectedType = '';
    vm.types = [];
    vm.venueid = $stateParams.venueid;
    vm.ListConfirmMessage = false;
    vm.ListErrorMessage = false;
    vm.ListMessage = [];
    vm.ConfirmMessage = false;

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      vm.cloudservicetypes = data.cloudServiceTypes;
      vm.cloudservicetypesLoaded = true;
    }).error(function (err) {
      vm.cloudservicetypesLoadedError = true;
      vm.cloudservicetypesLoaded = true;
      console.log('Error: ', err);
    });

    vm.getApiKey = function () {
      Infrastructureapi.getVenue(vm.orgid, venueid).then(function (resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageurl;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website = resp.data.venue.website;
        if (resp.data.venue.hasOwnProperty('details')) {
          vm.venueDetails = resp.data.venue.details;
          vm.venueDetails = JSON.parse(vm.venueDetails);
          if (vm.venueDetails.hasOwnProperty('mailchimp')) {
            if (vm.venueDetails.mailchimp.hasOwnProperty('apiKey')) {
              vm.apiKey = vm.venueDetails.mailchimp.apiKey;
              vm.getLists();
            }
            if (vm.venueDetails.mailchimp.hasOwnProperty('listId')) {
              vm.listId = vm.venueDetails.mailchimp.listId;
              vm.newListId = vm.listId;
            }
          }
        } else {
          vm.venueDetails = {};
        }

      }, function (err) {
        $log.info(err);
      });
    };

    vm.getApiKey();

    vm.getLists = function () {

      vm.lists = [];
      Infrastructureapi.getLists(vm.apiKey).then(function (resp) {
        for (var i = 0; i < resp.data.lists.length; i++) {
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name
          });
        }
      }, function (err) {
        $log.info(err);
        vm.lists.length = 0;
      });
    };

    vm.updateApiKey = function () {
      vm.submitted = true;
      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }
      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log(data);
        vm.ConfirmMessage = true;
        vm.Message = 'Api Key Updated';
        vm.getLists();
      }, function (err) {
        vm.Message = true;
        vm.Message = err.message;
        console.log('Error = ' + err);
      });
    };

    vm.updateList = function () {
      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.listId = vm.newListId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.listId = vm.newListId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }

      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log(data);
        vm.ListConfirmMessage = true;
        vm.ListMessage = 'List Updated';
        vm.getApiKey();
      }, function (err) {
        console.log('Error = ' + err);
      });
    };

    vm.createCloudService = function () {
      var typeid = vm.selectedType;
      var arr = vm.cloudservicetypes;
      var element;
      vm.test = function (id) {
        arr.forEach(function (type) {
          if (parseInt(type.id) === parseInt(id)) {
            element = type.name;
          }
        });
        return element;
      };
      vm.test(typeid);

      var data = '';
      if (element !== 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.cloudserviceDetails.apikey,
          listidentifier: '',
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      } else if (element === 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.apiKey,
          listidentifier: vm.newListId,
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      }
      Cloudservices.createCloudService($stateParams.venueid, data).success(function () {
        $state.go('admin.cloudservices', {
          'venueid': $stateParams.venueid,
          'orgid': $stateParams.orgid

        });
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid,
        orgid: vm.orgid
      });
    };
    vm.listDismiss = function () {
      vm.ListMessage = {};
      vm.ListConfirmMessage = false;
    };

    vm.listDismissError = function () {
      vm.ListMessage = {};
      vm.ListErrorMessage = false;
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addInfrastructure
   * @description
   *
   */
  angular
    .module('addInfrastructure', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addInfrastructure.controller:AddInfrastructureCtrl
   *
   * @description
   *
   */
  AddInfrastructureCtrl.$inject = ["Infrastructureapi", "Provider", "$stateParams", "$log", "$state"];
  angular
    .module('addInfrastructure')
    .controller('AddInfrastructureCtrl', AddInfrastructureCtrl);

  function AddInfrastructureCtrl(Infrastructureapi, Provider, $stateParams, $log, $state) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;
    vm.providerSelected = false;

    vm.providerTypes = [];

    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if(provider === 1){
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };

    Provider.getAllProviders().then(function(res){
      vm.providerTypes = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.create = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      thisInfrastructure.details = '{}';
      vm.submitted = true;
      if (isValid) {
        Infrastructureapi.createInfrastructure($stateParams.venueid, thisInfrastructure).then(function (res) {
          $log.info(res);
          $state.go('admin.managenetwork', {
            'orgid': $stateParams.orgid,
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          $log.console.error(err);
        });

      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addap
   * @description
   *
   */
  angular
    .module('addap', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addap.controller:AddapCtrl
   *
   * @description
   *
   */
  AddapCtrl.$inject = ["Infrastructureapi", "Provider", "$stateParams", "$log", "$state"];
  angular
    .module('addap')
    .controller('AddapCtrl', AddapCtrl);

  function AddapCtrl(Infrastructureapi, Provider, $stateParams, $log, $state) {
    var vm = this;
    vm.accesspoint = {};
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.submitted = false;


    vm.dismiss = function() {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.setProvider = function () {
      vm.accesspoint.provider = $stateParams.provider;
    };


    vm.createAccesspoint = function(isValid) {
      vm.submitted = true;
      if (isValid) {
        Infrastructureapi.createAp($stateParams.venueid, $stateParams.orgid, vm.accesspoint).success(function(data) {
          console.log(data);
          $state.go('admin.managenetwork', {
            'orgid': $stateParams.orgid,
            'venueid': $stateParams.venueid
          });
        }).error(function(err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }
    };
    vm.setProvider();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addadmin
   * @description
   *
   */
  angular
    .module('addadmin', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addadmin.controller:AddadminCtrl
   *
   * @description
   *
   */
  AddadminCtrl.$inject = ["$state", "Leadmin"];
  angular
    .module('addadmin')
    .controller('AddadminCtrl', AddadminCtrl);

  function AddadminCtrl($state, Leadmin) {
    var vm = this;
    vm.ctrlName = 'AddadminCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];

    vm.createAdmin = function () {
      var data = JSON.stringify({
        login: vm.adminDetails.login,
        password: vm.adminDetails.password,
        name: vm.adminDetails.name,
        details: {}
      });
      Leadmin.createAdmin(data).success(function () {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('venues')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.venues', {
        url: '/venues/:orgid',
        templateUrl: 'venues/views/venues.tpl.html',
        controller: 'VenuesCtrl',
        controllerAs: 'venues'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('updategns')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.updategns', {
        url: '/updategns/:gnsid',
        templateUrl: 'updategns/views/updategns.tpl.html',
        controller: 'UpdategnsCtrl',
        controllerAs: 'updategns'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('splashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.splashtype', {
        url: '/splashtype',
        templateUrl: 'splashtype/views/splashtype.tpl.html',
        controller: 'SplashtypeCtrl',
        controllerAs: 'splashtype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('sociallogins')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.sociallogins', {
        url: '/sociallogins',
        templateUrl: 'sociallogins/views/sociallogins.tpl.html',
        controller: 'SocialloginsCtrl',
        controllerAs: 'sociallogins'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('providers')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.providers', {
        url: '/providers',
        templateUrl: 'providers/views/providers.tpl.html',
        controller: 'ProvidersCtrl',
        controllerAs: 'providers'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('organisations')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.organisations', {
        url: '/organisations',
        templateUrl: 'organisations/views/organisations.tpl.html',
        controller: 'OrganisationsCtrl',
        controllerAs: 'organisations'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('orgInfrastructures')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.orgInfrastructures', {
        url: '/org-infrastructures/:orgid',
        templateUrl: 'org-infrastructures/views/org-infrastructures.tpl.html',
        controller: 'OrgInfrastructuresCtrl',
        controllerAs: 'orgInfrastructures'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('organisationroles')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.organisationroles', {
        url: '/organisationroles',
        templateUrl: 'organisationroles/views/organisationroles.tpl.html',
        controller: 'OrganisationrolesCtrl',
        controllerAs: 'organisationroles'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('operators')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.operators', {
        url: '/operators/:organisationid',
        templateUrl: 'operators/views/operators.tpl.html',
        controller: 'OperatorsCtrl',
        controllerAs: 'operators'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('newvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newvenue', {
        url: '/newvenue/:orgid',
        templateUrl: 'newvenue/views/newvenue.tpl.html',
        controller: 'NewvenueCtrl',
        controllerAs: 'newvenue'
      });
  }
}());

(function () {
  'use strict';

    config.$inject = ["$stateProvider"];
  angular
    .module('newlist')
    .config(config);

    function config($stateProvider) {
      $stateProvider
        .state('admin.newlist', {
          url: '/newlist/:apiKey/:venueid/:orgid',
          templateUrl: 'newlist/views/newlist.tpl.html',
          controller: 'NewlistCtrl',
          controllerAs: 'newlist'
        });
    }
  }());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('networktype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.networktype', {
        url: '/networktype/:venueid/:orgid/:provider',
        templateUrl: 'networktype/views/networktype.tpl.html',
        controller: 'NetworktypeCtrl',
        controllerAs: 'networktype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('managenetwork')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.managenetwork', {
        url: '/managenetwork/:venueid/:orgid',
        templateUrl: 'managenetwork/views/managenetwork.tpl.html',
        controller: 'ManagenetworkCtrl',
        controllerAs: 'managenetwork'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('manageimages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.manageimages', {
        url: '/manageimages',
        templateUrl: 'manageimages/views/manageimages.tpl.html',
        controller: 'ManageimagesCtrl',
        controllerAs: 'manageimages'
      });
  }
}());

(function () {
  'use strict';

    config.$inject = ["$stateProvider"];
  angular
    .module('mailchimp')
    .config(config);

    function config($stateProvider) {
      $stateProvider
        .state('admin.mailchimp', {
          url: '/mailchimp/:venueid/:orgid',
          templateUrl: 'mailchimp/views/mailchimp.tpl.html',
          controller: 'MailchimpCtrl',
          controllerAs: 'mailchimp'
        });
    }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('main')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/',
        templateUrl: 'main/views/main.tpl.html',
        controller: 'MainCtrl',
        controllerAs: 'main',
        data: {
          requireLogin: false
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('leimages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.leimages', {
        url: '/leimages',
        templateUrl: 'leimages/views/leimages.tpl.html',
        controller: 'LeimagesCtrl',
        controllerAs: 'leimages'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main.home', {
        url: 'home',
        templateUrl: 'home/views/home.tpl.html',
        controller: 'HomeCtrl',
        controllerAs: 'home'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('guestnetworksettings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.guestnetworksettings', {
        url: '/guestnetworksettings',
        templateUrl: 'guestnetworksettings/views/guestnetworksettings.tpl.html',
        controller: 'GuestnetworksettingsCtrl',
        controllerAs: 'guestnetworksettings'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Splashtype
   *
   * @description
   *
   */
  Splashtype.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Splashtype', Splashtype);

  function Splashtype(baseurl, $http, Auth) {
    var SplashtypeBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    SplashtypeBase.getAll = function () {
      return $http.get(baseurl + '/splashtypes/', {
        headers: {
          'x-access-token': token
        }
      });
    };

    SplashtypeBase.getSplashType = function (id) {
      return $http.get(baseurl + '/splashtypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SplashtypeBase.createSplashType = function (data) {
      return $http.post(baseurl + '/splashtypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SplashtypeBase.updateSplashType = function (id, data) {
      return $http.put(baseurl + '/splashtypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SplashtypeBase.deleteSplashType = function (id) {
      return $http.delete(baseurl + '/splashtypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    SplashtypeBase.getProviders = function (id) {
      return $http.get(baseurl + '/splashtypes/' + id + '/providers/', {
        headers: {
          'x-access-token': token
        }
      });
    };
    SplashtypeBase.setProviders = function (id, data) {
      return $http.post(baseurl + '/splashtypes/' + id + '/providers/', data,{
        headers: {
          'x-access-token': token
        }
      });
    };


    return SplashtypeBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Slt
   *
   * @description
   *
   */
  Slt.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Slt', Slt);

  function Slt(baseurl, $http, Auth) {
    var SltBase = {},
        token = '';
    SltBase.someValue = 'Slt';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    SltBase.getAllSLTs = function () {
      return $http.get(baseurl + '/socialLoginTypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.createSLT = function (data) {
      return $http.post(baseurl + '/socialLoginTypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SltBase.getSLTById = function (id) {
      return $http.get(baseurl + '/sociallogintypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.updateSLT = function (id, data) {
      return $http.put(baseurl + '/socialLoginTypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SltBase.deleteSLT = function (id) {
      return $http.delete(baseurl + '/socialLoginTypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SltBase.getProviders = function (id) {
      return $http.get(baseurl + '/socialLoginTypes/' + id + '/providers/', {
        headers: {
          'x-access-token': token
        }
      });
    };
    SltBase.setProviders = function (id, data) {
      return $http.post(baseurl + '/socialLoginTypes/' + id + '/providers/', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    return SltBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Roles
   *
   * @description
   *
   */
  Roles.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Roles', Roles);

  function Roles(baseurl, $http, Auth) {
    var RolesBase = {},
        token = '';

    RolesBase.someValue = 'Roles';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    RolesBase.getAllOrganisationRoles = function () {
      return $http.get(baseurl + '/roles', {
        headers: {
          'x-access-token': token
        }
      });
    };

    RolesBase.createOrganisationRole = function (data) {
      return $http.post(baseurl + '/roles/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    RolesBase.getOrganisationRoleById = function (id) {
      return $http.get(baseurl + '/roles/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    RolesBase.updateOrganisationRole = function (id, data) {
      return $http.put(baseurl + '/roles/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    RolesBase.deleteOrganisationRole = function (id) {
      return $http.delete(baseurl + '/roles/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return RolesBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Provider
   *
   * @description
   *
   */
  Provider.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Provider', Provider);

  function Provider(baseurl, $http, Auth) {
    var ProviderBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    ProviderBase.getAllProviders = function () {
      return $http.get(baseurl + '/providers', {
        headers: {
          'x-access-token': token
        }
      });
    };

    ProviderBase.createProvider = function (data) {
      return $http.post(baseurl + '/providers', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ProviderBase.getProviderById = function (id) {
      return $http.get(baseurl + '/providers/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ProviderBase.updateProvider = function (id, data) {
      return $http.put(baseurl + '/providers/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ProviderBase.deleteProvider = function (id) {
      return $http.delete(baseurl + '/providers/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return ProviderBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Organisation
   *
   * @description
   *
   */
  Organisation.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Organisation', Organisation);

  function Organisation(baseurl, $http, Auth) {
    var OrganisationBase = {},
        token = '';
    OrganisationBase.someValue = 'Organisation';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    OrganisationBase.getAllOrganisations = function () {
      return $http.get(baseurl + '/organisations', {
        headers: {
          'x-access-token': token
        }
      });
    };

    OrganisationBase.createOrganisation = function (data) {
      return $http.post(baseurl + '/organisations', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    OrganisationBase.getOrganisationById = function (id) {
      return $http.get(baseurl + '/organisations/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    OrganisationBase.updateOrganisation = function (id, data) {
      return $http.put(baseurl + '/organisations/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    OrganisationBase.deleteOrganisation = function (id) {
      return $http.delete(baseurl + '/organisations/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return OrganisationBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Operator
   *
   * @description
   *
   */
  Operator.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Operator', Operator);

  function Operator(baseurl, $http, Auth) {
    var OperatorBase = {},
        token = '';
    OperatorBase.someValue = 'Operator';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    OperatorBase.getAllOperatorsByOrgId = function (id) {
      return $http.get(baseurl + '/operators/organisations/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    OperatorBase.createOrganisationOperator = function (id, data) {
      return $http.post(baseurl + '/operators/organisations/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    OperatorBase.getOperatorById = function (orgid, opid) {
      return $http.get(baseurl + '/operators/' + opid + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    OperatorBase.updateOperatorById = function (id, data) {
      return $http.put(baseurl + '/operators/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    OperatorBase.updateOperatorPassword = function (id, data) {
      return $http.put(baseurl + '/operators/' + id + '?password=true', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    OperatorBase.deleteOperator = function (orgid, opid) {
      return $http.delete(baseurl + '/operators/' + opid + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return OperatorBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:NetworkType
   *
   * @description
   *
   */
  NetworkType.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('NetworkType', NetworkType);

  function NetworkType(baseurl, $http, Auth) {
    var NetworkTypeBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    NetworkTypeBase.createNetworkType = function (venueid, data) {
      return $http.post(baseurl + '/networktype/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.getNetworkTypes = function (venueid) {
      return $http.get(baseurl + '/networktype/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.getNetworkType = function (typeid, venueid) {
      return $http.get(baseurl + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.updateNetworkType = function (typeid, venueid, data) {
      return $http.put(baseurl + '/networktype/' + typeid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.deleteNetworkType = function (typeid, venueid) {
      return $http.delete(baseurl + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    return NetworkTypeBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Netfilter
   *
   * @description
   *
   */
  Netfilter.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Netfilter', Netfilter);

  function Netfilter(baseurl, $http, Auth) {
    var NetfilterBase = {},
        token = '';
    NetfilterBase.someValue = 'Netfilter';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    NetfilterBase.getAllNetFilters = function () {
      return $http.get(baseurl + '/networkFilters', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.createNetFilter = function (data) {
      return $http.post(baseurl + '/networkFilters', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetfilterBase.getNetFilterById = function (id) {
      return $http.get(baseurl + '/networkFilters/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getFilterbyCategory = function (id) {
      return $http.get(baseurl + '/networkfilters/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.updateNetFilter = function (id, data) {
      return $http.put(baseurl + '/networkFilters/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetfilterBase.deleteNetFilter = function (id) {
      return $http.delete(baseurl + '/networkFilters/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getlistOfXirrusFilters = function () {
      return $http.get(baseurl + '/xirrus', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getlistOfCategoryTypes = function () {
      return $http.get(baseurl + '/categorytypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getNetFilterByCategory = function (id) {
      return $http.get(baseurl + '/networkfilters/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return NetfilterBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Leimages
   *
   * @description
   *
   */
  Leimages.$inject = ["Auth", "baseurl", "$http"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Leimages', Leimages);

  function Leimages(Auth, baseurl, $http) {
    var LeimagesBase = {};

    LeimagesBase.getImages = function () {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + '/images/', {
        headers: {
          'x-access-token': token
        }
      });
    };

    LeimagesBase.deleteImage = function (id) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return LeimagesBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Leadmin
   *
   * @description
   *
   */
  Leadmin.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Leadmin', Leadmin);

  function Leadmin(baseurl, $http, Auth) {
    var LeadminBase = {},
        token = '';
    LeadminBase.someValue = 'Leadmin';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    LeadminBase.getAllAdmins = function () {
      return $http.get(baseurl + '/leAdmins', {
        headers: {
          'x-access-token': token
        }
      });
    };

    LeadminBase.createAdmin = function (data) {
      return $http.post(baseurl + '/leAdmins', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    LeadminBase.getAdminById = function (id) {
      return $http.get(baseurl + '/leAdmins/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    LeadminBase.updateAdmin = function (id, data) {
      return $http.put(baseurl + '/leAdmins/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    LeadminBase.updateAdminPassword = function (id, data) {
      return $http.put(baseurl + '/leAdmins/' + id + '?password=true', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    LeadminBase.deleteAdmin = function (id) {
      return $http.delete(baseurl + '/leAdmins/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return LeadminBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Infrastructureapi
   *
   * @description
   *
   */
  Infrastructureapi.$inject = ["Auth", "$http", "baseurl", "operatorbaseurl", "imageupload"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Infrastructureapi', Infrastructureapi);

  function Infrastructureapi(Auth, $http, baseurl, operatorbaseurl, imageupload) {
    var InfrastructureapiBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    // Venues

    InfrastructureapiBase.createVenue = function (orgid, data) {
      return $http.post(baseurl + '/venues/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateVenue = function (orgid, id, data) {
      return $http.put(baseurl + '/venues/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getVenue = function (orgid, id) {
      return $http.get(baseurl + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteVenue = function (orgid, id) {
      return $http.delete(baseurl + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getVenues = function (orgid) {
      return $http.get(baseurl + '/venues/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // AccessPoints

    InfrastructureapiBase.createAp = function (venueId, orgid, data) {
      data.orgid = orgid;
      return $http.post(baseurl + '/apoints/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateAp = function (id, venueId, data) {
      var orgid = data.orgid;
      return $http.put(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getAp = function (id, venueId, orgid) {
      return $http.get(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteAp = function (id, venueId, orgid) {
      return $http.delete(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getAps = function (venueId, orgid) {
      return $http.get(baseurl + '/apoints/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    InfrastructureapiBase.getApByZone = function (venueId, zoneId, orgid) {
      return $http.get(baseurl + '/apoints/venues/' + venueId + '/zones/' + zoneId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateApsZone = function (data) {
      return $http.post(baseurl + '/apoints/addtozone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    InfrastructureapiBase.removeApsZone = function (data) {
      return $http.post(baseurl + '/apoints/removefromzone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Zones

    InfrastructureapiBase.createZone = function (venueId, data) {
      var orgid = data.orgid;
      return $http.post(baseurl + '/zones/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateZone = function (id, venueId, data) {
      //var token = Auth.getAuthData().token;
      var orgid = data.orgid;
      return $http.put(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getZone = function (id, venueId, orgid) {
      return $http.get(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteZone = function (id, venueId, orgid) {
      return $http.delete(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getZones = function (venueId, orgid) {
      return $http.get(baseurl + '/zones/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Images

    InfrastructureapiBase.updateImage = function (id, orgid, data) {
      return $http.post(imageupload + '/images/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getImage = function (id, orgid) {
      return $http.get(imageupload + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteImage = function (id, orgid) {
      return $http.delete(imageupload + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getImages = function (orgid) {
      return $http.get(imageupload + '/images', {
        headers: {
          'x-access-token': token
        }
      });
    };

    // infrastructures
    InfrastructureapiBase.createInfrastructure = function (venueid, data) {
      return $http.post(baseurl + '/infrastructure/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateInfrastructure = function (id, venueid, data) {
      return $http.put(baseurl + '/infrastructure/' + id + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getInfrastructure = function (id, venueid) {
      return $http.get(baseurl + '/infrastructure/' + id + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getInfrastructures = function (venueid) {
      return $http.get(baseurl + '/infrastructure/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // OrgInfrastructure

    InfrastructureapiBase.createOrgInfrastructure = function (orgid, data) {
      return $http.post(baseurl + '/infrastructure/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateOrgInfrastructure = function (id, orgid, data) {
      return $http.put(baseurl + '/infrastructure/' + id + '/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getOrgInfrastructure = function (id, orgid) {
      return $http.get(baseurl + '/infrastructure/' + id + '/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getOrgInfrastructures = function (orgid) {
      return $http.get(baseurl + '/infrastructure/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteOrgInfrastructure = function (id, orgid) {
      return $http.delete(baseurl + '/infrastructure/'+id+'/organisation/'+orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Mailchimp

    InfrastructureapiBase.createList = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(operatorbaseurl + '/mailchimp/lists/', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getLists = function (apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(operatorbaseurl + '/mailchimp/lists/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getListById = function (listid, apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(operatorbaseurl + '/mailchimp/lists/' + listid + '/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return InfrastructureapiBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:GnsApi
   *
   * @description
   *
   */
  GnsApi.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('GnsApi', GnsApi);

  function GnsApi(baseurl, $http, Auth) {
    var GnsApiBase = {},
      token = '';
    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }
    GnsApiBase.someValue = 'GnsApi';

    GnsApiBase.getGNS = function () {
      return $http.get(baseurl + '/gns', {
        headers: {
          'x-access-token': token
        }
      });
    };

    GnsApiBase.createGNSType = function (data) {
      return $http.post(baseurl + '/gns/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    GnsApiBase.getGNSById = function (id) {
      return $http.get(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    GnsApiBase.getGNSByProvider = function (id) {
      return $http.get(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    GnsApiBase.udpateGNS = function (id, data) {
      return $http.put(baseurl + '/gns/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    GnsApiBase.deleteGNS = function (id) {
      return $http.delete(baseurl + '/gns/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return GnsApiBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservicetypes.factory:Cloudservicetype
   *
   * @description
   *
   */
  Cloudservicetype.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Cloudservicetype', Cloudservicetype);

  function Cloudservicetype(baseurl, $http, Auth) {
    var CloudservicetypeBase = {},
      token = '';
    CloudservicetypeBase.someValue = 'Cloudservicetype';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicetypeBase.getCloudServiceTypes = function () {
      return $http.get(baseurl + '/cloudservicetypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.createCloudServiceType = function (data) {
      return $http.post(baseurl + '/cloudservicetypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.getCloudServiceTypeById = function (id) {
      return $http.get(baseurl + '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.udpateCloudServiceType = function (id, data) {
      return $http.put(baseurl + '/cloudservicetypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.deleteCloudServiceType = function (id) {
      return $http.delete(baseurl + '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicetypeBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservices.factory:Cloudservices
   *
   * @description
   *
   */
  Cloudservices.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Cloudservices', Cloudservices);

  function Cloudservices(baseurl, $http, Auth) {
    var CloudservicesBase = {},
      token = '';
    CloudservicesBase.someValue = 'Cloudservices';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicesBase.getCloudServices = function (venueid) {
      return $http.get(baseurl + '/venues/' + venueid + '/cloudservices', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.createCloudService = function (venueid, data) {
      return $http.post(baseurl + '/venues/' + venueid + '/cloudservices/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.getCloudServiceById = function (venueid, cloudservicesid) {
      return $http.get(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.udpateCloudService = function (venueid, cloudservicesid, data) {
      return $http.put(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.deleteCloudService = function (venueid, cloudservicesid) {
      return $http.delete(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicesBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Category
   *
   * @description
   *
   */
  Category.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Category', Category);

  function Category(baseurl, $http, Auth) {
    var CategoryBase = {},
        token = '';

    CategoryBase.factoryName = 'Category';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CategoryBase.getAllCategories = function () {
      return $http.get(baseurl + '/categories', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.createCategory = function (data) {
      return $http.post(baseurl + '/categories', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CategoryBase.deleteCategory = function (id) {
      return $http.delete(baseurl + '/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.getCategoryById = function (id) {
      return $http.get(baseurl + '/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CategoryBase.updateCategory = function (id, data) {
      return $http.put(baseurl + '/categories/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    return CategoryBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Auth
   *
   * @description
   *
   */
  Auth.$inject = ["$cookies", "$http", "baseurl"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Auth', Auth)
    .run(["$rootScope", "Auth", "$cookies", "imgurl", function ($rootScope, Auth, $cookies, imgurl) {
      var rootScope = $rootScope;

      rootScope.staticUrl = imgurl + '/';

      rootScope.$on('$stateChangeStart', function (event, toState) {
        var requireLogin = toState.data.requireLogin;
        if ($cookies.get('leAdminData')) {
          Auth.setIslogged(true);
        }
        if (requireLogin && Auth.getIslogged() === false) {
          event.preventDefault();
        }
      });
    }]);

  function Auth($cookies, $http, baseurl) {
    var AuthBase = {},
      isLogged = false;

    AuthBase.factoryName = 'Auth';

    AuthBase.getIslogged = function () {
      return isLogged;
    };

    AuthBase.setIslogged = function (state) {
      isLogged = state;
    };

    AuthBase.getAuthData = function () {
      var data;

      if ($cookies.get('leAdminData')) {
        data = $cookies.getObject('leAdminData');
      }

      return data;
    };

    AuthBase.login = function (username, password) {
      var data = JSON.stringify({
        login: username,
        password: password
      });
      return $http.post(baseurl + '/leadmins/session', data, {
        headers: {
          Authorization: 'Basic ',
          'Content-Type': 'application/json'
        }
      });
    };
    return AuthBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Analytics
   *
   * @description
   *
   */
  Analytics.$inject = ["baseurl", "$http", "Auth"];
  angular
    .module('liquidedgeLeadmin')
    .factory('Analytics', Analytics);

  function Analytics(baseurl, $http, Auth) {
    var AnalyticsBase = {},
        token = '';

    AnalyticsBase.someValue = 'Analytics';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    AnalyticsBase.getAllOrganisations = function () {
      return $http.get(baseurl + '/organisations', {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAllVenues = function (orgId) {
      return $http.get(baseurl + '/performance/venues/' + orgId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAllEvents = function (orgId, venueId) {
      return $http.get(baseurl + '/performance/venues/' + orgId + '/events/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getEventAnalytics = function (data) {
      return $http.post(baseurl + '/performance/listbyevent', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    AnalyticsBase.getMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/minmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getCreateMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/createminmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getDeleteMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/deleteminmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/avgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getCreateAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/createavgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getDeleteAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/deleteavgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return AnalyticsBase;
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editzone', {
        url: '/editzone/:zoneid/:venueid/:orgid/:provider',
        templateUrl: 'editzone/views/editzone.tpl.html',
        controller: 'EditzoneCtrl',
        controllerAs: 'editzone'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editsplashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editsplashtype', {
        url: '/editsplashtype/:typeid',
        templateUrl: 'editsplashtype/views/editsplashtype.tpl.html',
        controller: 'EditsplashtypeCtrl',
        controllerAs: 'editsplashtype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editvenue', {
        url: '/editvenue/:venueid/:orgid',
        templateUrl: 'editvenue/views/editvenue.tpl.html',
        controller: 'EditvenueCtrl',
        controllerAs: 'editvenue'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editrole')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editrole', {
        url: '/editrole/:roleid',
        templateUrl: 'editrole/views/editrole.tpl.html',
        controller: 'EditroleCtrl',
        controllerAs: 'editrole'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editslt')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editslt', {
        url: '/editslt/:sltid',
        templateUrl: 'editslt/views/editslt.tpl.html',
        controller: 'EditsltCtrl',
        controllerAs: 'editslt'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editprovider')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editprovider', {
        url: '/editprovider/:providerid',
        templateUrl: 'editprovider/views/editprovider.tpl.html',
        controller: 'EditproviderCtrl',
        controllerAs: 'editprovider'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editorganisation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editorganisation', {
        url: '/editorganisation/:organisationid',
        templateUrl: 'editorganisation/views/editorganisation.tpl.html',
        controller: 'EditorganisationCtrl',
        controllerAs: 'editorganisation'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editoperatorpwd')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editoperatorpwd', {
        url: '/editoperatorpwd/:organisationid/operator/:opid',
        templateUrl: 'editoperatorpwd/views/editoperatorpwd.tpl.html',
        controller: 'EditoperatorpwdCtrl',
        controllerAs: 'editoperatorpwd'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editoperator', {
        url: '/editoperator/:organisationid/operator/:opid',
        templateUrl: 'editoperator/views/editoperator.tpl.html',
        controller: 'EditoperatorCtrl',
        controllerAs: 'editoperator'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editinfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editinfrastructure', {
        url: '/editinfrastructure/:infraid/:venueid/:orgid',
        templateUrl: 'editinfrastructure/views/editinfrastructure.tpl.html',
        controller: 'EditinfrastructureCtrl',
        controllerAs: 'editinfrastructure'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editnetworktype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editnetworktype', {
        url: '/editnetworktype/:venueid/:typeid/:orgid/:provider',
        templateUrl: 'editnetworktype/views/editnetworktype.tpl.html',
        controller: 'EditnetworktypeCtrl',
        controllerAs: 'editnetworktype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editclstype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editclstype', {
        url: '/editclstype/:typeid',
        templateUrl: 'editclstype/views/editclstype.tpl.html',
        controller: 'EditclstypeCtrl',
        controllerAs: 'editclstype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editclservice', {
        url: '/editclservice/:venueid/:orgid/:cloudservicesid',
        templateUrl: 'editclservice/views/editclservice.tpl.html',
        controller: 'EditclserviceCtrl',
        controllerAs: 'editclservice'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editcategory')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editcategory', {
        url: '/editcategory/:categoryid',
        templateUrl: 'editcategory/views/editcategory.tpl.html',
        controller: 'EditcategoryCtrl',
        controllerAs: 'editcategory'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editap', {
        url: '/editap/:apid/:venueid/:orgid',
        templateUrl: 'editap/views/editap.tpl.html',
        controller: 'EditapCtrl',
        controllerAs: 'editap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editadminpwd')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editadminpwd', {
        url: '/editadminpwd/:adminid',
        templateUrl: 'editadminpwd/views/editadminpwd.tpl.html',
        controller: 'EditadminpwdCtrl',
        controllerAs: 'editadminpwd'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editadmin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editadmin', {
        url: '/editadmin/:adminid',
        templateUrl: 'editadmin/views/editadmin.tpl.html',
        controller: 'EditadminCtrl',
        controllerAs: 'editadmin'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name liquidedgeLeadmin.directive:sameAs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="liquidedgeLeadmin">
       <file name="index.html">
        <same-as></same-as>
       </file>
     </example>
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .directive('sameAs', sameAs);

  function sameAs() {
    return {
      restrict: 'EA',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        ngModel.$parsers.unshift(function (viewValue, $scope) {
          var noMatch = viewValue !== scope.form.password.$viewValue;
          ngModel.$setValidity('noMatch', !noMatch);
          return !noMatch;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:operatorbaseurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('operatorbaseurl', 'https://api.liquidedge.eu/operator-api/operator-web/v1');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:imgurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('imgurl', 'https://api.liquidedge.eu/admin-api');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:imageupload
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('imageupload', 'https://api.liquidedge.eu/admin-api/admin-web/v1');
}());

(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:baseurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('baseurl', 'https://api.liquidedge.eu/admin-api/admin-web/v1');

}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('cloudservicetypes')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.cloudservicetypes', {
        url: '/cloudservicetypes',
        templateUrl: 'cloudservicetypes/views/cloudservicetypes.tpl.html',
        controller: 'CloudservicetypesCtrl',
        controllerAs: 'cloudservicetypes'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('cloudservices')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.cloudservices', {
        url: '/cloudservices/:venueid/:orgid/:cloudservicesid',
        templateUrl: 'cloudservices/views/cloudservices.tpl.html',
        controller: 'CloudservicesCtrl',
        controllerAs: 'cloudservices'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('categories')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.categories', {
        url: '/categories',
        templateUrl: 'categories/views/categories.tpl.html',
        controller: 'CategoriesCtrl',
        controllerAs: 'categories'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('analytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.analytics', {
        url: '/analytics',
        templateUrl: 'analytics/views/analytics.tpl.html',
        controller: 'AnalyticsCtrl',
        controllerAs: 'analytics'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('admins')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.admins', {
        url: '/admins',
        templateUrl: 'admins/views/admins.tpl.html',
        controller: 'AdminsCtrl',
        controllerAs: 'admins'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('admin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin', {
        url: '/admin',
        templateUrl: 'admin/views/admin.tpl.html',
        controller: 'AdminCtrl',
        controllerAs: 'admin',
        data: {
          requireLogin: true
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addsplashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addsplashtype', {
        url: '/addsplashtype',
        templateUrl: 'addsplashtype/views/addsplashtype.tpl.html',
        controller: 'AddsplashtypeCtrl',
        controllerAs: 'addsplashtype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addzone', {
        url: '/addzone/:venueid/:orgid/:provider',
        templateUrl: 'addzone/views/addzone.tpl.html',
        controller: 'AddzoneCtrl',
        controllerAs: 'addzone'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addslt')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addslt', {
        url: '/addslt',
        templateUrl: 'addslt/views/addslt.tpl.html',
        controller: 'AddsltCtrl',
        controllerAs: 'addslt'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addrole')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addrole', {
        url: '/addrole',
        templateUrl: 'addrole/views/addrole.tpl.html',
        controller: 'AddroleCtrl',
        controllerAs: 'addrole'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addprovider')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addprovider', {
        url: '/addprovider',
        templateUrl: 'addprovider/views/addprovider.tpl.html',
        controller: 'AddproviderCtrl',
        controllerAs: 'addprovider'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addorganisation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addorganisation', {
        url: '/addorganisation',
        templateUrl: 'addorganisation/views/addorganisation.tpl.html',
        controller: 'AddorganisationCtrl',
        controllerAs: 'addorganisation'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addoperator', {
        url: '/addoperator/:organisationid',
        templateUrl: 'addoperator/views/addoperator.tpl.html',
        controller: 'AddoperatorCtrl',
        controllerAs: 'addoperator'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addgns')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addgns', {
        url: '/addgns',
        templateUrl: 'addgns/views/addgns.tpl.html',
        controller: 'AddgnsCtrl',
        controllerAs: 'addgns'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addcloudservicetype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addcloudservicetype', {
        url: '/addcloudservicetype',
        templateUrl: 'addcloudservicetype/views/addcloudservicetype.tpl.html',
        controller: 'AddcloudservicetypeCtrl',
        controllerAs: 'addcloudservicetype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addclservice', {
        url: '/addclservice/:venueid/:orgid',
        templateUrl: 'addclservice/views/addclservice.tpl.html',
        controller: 'AddclserviceCtrl',
        controllerAs: 'addclservice'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addcategory')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addcategory', {
        url: '/addcategory',
        templateUrl: 'addcategory/views/addcategory.tpl.html',
        controller: 'AddcategoryCtrl',
        controllerAs: 'addcategory'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addap', {
        url: '/addap/:venueid/:orgid/:provider',
        templateUrl: 'addap/views/addap.tpl.html',
        controller: 'AddapCtrl',
        controllerAs: 'addap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addadmin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addadmin', {
        url: '/addadmin',
        templateUrl: 'addadmin/views/addadmin.tpl.html',
        controller: 'AddadminCtrl',
        controllerAs: 'addadmin'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addInfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addInfrastructure', {
        url: '/add-infrastructure/:venueid/:orgid',
        templateUrl: 'add-infrastructure/views/add-infrastructure.tpl.html',
        controller: 'AddInfrastructureCtrl',
        controllerAs: 'addInfrastructure'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$urlRouterProvider"];
  angular
    .module('liquidedgeLeadmin')
    .config(config);

  function config($urlRouterProvider) {
    $urlRouterProvider.otherwise('/home');
  }
}());
