(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editorganisation.controller:EditorganisationCtrl
   *
   * @description
   *
   */
  angular
    .module('editorganisation')
    .controller('EditorganisationCtrl', EditorganisationCtrl);

  function EditorganisationCtrl($state, $stateParams, Organisation) {
    var vm = this;
    vm.ctrlName = 'EditorganisationCtrl';

    vm.ErrorMessage = false;
    vm.organisationDetails = [];
    vm.Message = [];
    vm.premium = false;

    Organisation.getOrganisationById($stateParams.organisationid).success(function (data) {
      console.log('...1org', data);
      vm.organisationDetails = data.org;
      vm.premium = data.org.premium;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateOrganisation = function () {
      var data = JSON.stringify({
        organisationTitle: vm.organisationDetails.organisationtitle,
        premium: vm.premium,
        details: {
          email: vm.organisationDetails.details.email,
          phone: vm.organisationDetails.details.phone
        }
      });
      Organisation.updateOrganisation(vm.organisationDetails.id, data).success(function (data) {
        $state.go('admin.organisations');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
