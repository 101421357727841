(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addcloudservicetype.controller:AddcloudservicetypeCtrl
   *
   * @description
   *
   */
  angular
    .module('addcloudservicetype')
    .controller('AddcloudservicetypeCtrl', AddcloudservicetypeCtrl);

  function AddcloudservicetypeCtrl($state, Cloudservicetype) {
    var vm = this;
    vm.ctrlName = 'AddcloudservicetypeCtrl';

    vm.ErrorMessage = false;
    vm.cloudservicetypeDetails = [];
    vm.Message = [];

    vm.createCloudServiceType = function () {
      var data = JSON.stringify({
        name: vm.cloudservicetypeDetails.name,
        description: vm.cloudservicetypeDetails.description
      });
      Cloudservicetype.createCloudServiceType(data).success(function () {
        $state.go('admin.cloudservicetypes');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
