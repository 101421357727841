<div id="cloudservices">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Cloud Service</h3>
    </div>
    <div class="panel-body">
      <div class="col-md-8 col-md-offset-2">
        <div ng-show="addclservice.ErrorMessage">
          <div class="alert alert-danger" role="alert">
            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <span class="sr-only">Error:</span>
            {{addclservice.Message}}
          </div>
        </div>

        <form name="form" ng-submit="addclservice.createCloudService({venueid: addclservice.venueid})" role="form" class="col-md-8 col-md-offset-2" novalidate>
          <div>
            <div class="panel panel-default panel-form">
              <div class="panel-heading clearfix">
                <h4>Cloud Service Type </h4>
              </div>
              <div class="panel-body">
                <div class="form-group">
                  <select name="type" id="type" ng-model="addclservice.selectedType" class="form-control" required>
                    <option ng-repeat="type in addclservice.cloudservicetypes" value="{{type.id}}">{{type.name}}</option>
                  </select>
                  <div ng-show="form.type.$invalid">
                    <p> Type is required!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div ng-repeat="type in addclservice.cloudservicetypes" ng-show="type.id == addclservice.selectedType" ng-if="type.name !== 'MailChimp'">
              <div class="panel panel-default panel-form">
                <div>
                  <div class="panel-heading clearfix">
                    <h4>API key</h4>
                  </div>
                  <div class="panel-body">
                    <div class="form-group">
                      <input type="text" class="form-control" id="apiKey" name="apiKey" placeholder="eg.: 0jG4xjFXTdrDqmRbIPau0v1DZnjdpNQ4Lu07qqg" ng-model="addclservice.cloudserviceDetails.apikey" required>
                      <div ng-show="form.apikey.$invalid">
                        <p>API key is required!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ng-repeat="type in addclservice.cloudservicetypes" ng-show="type.id == addclservice.selectedType" ng-if="type.name == 'MailChimp'">
              <div class="panel panel-default panel-form">
                <div class="panel-body">
                  <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>
                    <div class="panel panel-default panel-form ">
                      <div class="panel-heading clearfix">
                        <h4>API KEY Type:</h4>
                      </div>
                      <div class="panel-body">
                        <div class="form-group" ng-class="{ 'has-error': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">
                          <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx-xyz" required>
                        </div>
                        <div class="help-block" ng-messages="mailchimpSettings.apiKey.$error" ng-if="editvenue.submitted">
                          <p ng-message="required">api key is required.</p>
                        </div>
                        <button type="button"
                        ng-click="addclservice.updateApiKey(!mailchimpSettings.$invalid)" class="btn btn-danger right">Update Api Key</button>
                        <div>
                          </br>
                        </div>
                        <div ng-show="addclservice.ConfirmMessage">
                          <div class="alert alert-success" role="alert" style="width: 40%;">
                            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{addclservice.Message}}
                          </div>
                        </div>
                        <div ng-show="addclservice.ErrorMessage">
                          <div class="alert alert-danger" role="alert" style="width: 40%;">
                            <button ng-click="addclservice.dismissError()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{addclservice.Message}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="panel-body">
                  <form name="mailchimpSettings" novalidate>
                    <div class="panel panel-default panel-form">
                      <div class="panel-heading clearfix">
                        <h4>Lists</h4>
                      </div>
                      <div class="panel-body">
                        <p>Current List ID:{{addclservice.listId}}</p>
                        <br/>
                        <p>Choose new List from the dropdown menu or create a new list</p>
                        <div class="row">
                          <div class="col-xs-9">
                            <select class="form-control" ng-model="addclservice.newListId">
                              <option ng-repeat="list in addclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>
                            </select>
                          </div>
                          <div class="col-xs-3">
                            <button ng-click="addclservice.updateList()" type="button" class="btn btn-danger right">Change List</button>
                          </div>
                        </div>
                        <div>
                          </br>
                        </div>
                        <div class="row">
                          <div class="col-xs-12">
                            <button ng-click="addclservice.createNewList()" type="button" class="btn btn-info right">Create New List</button>
                          </div>
                        </div>
                        <div>
                          </br>
                        </div>
                        <div ng-show="addclservice.ListConfirmMessage">
                          <div class="alert alert-success" role="alert" style="width: 40%;">
                            <button ng-click="addclservice.listDismiss()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{addclservice.ListMessage}}
                          </div>
                        </div>
                        <div ng-show="addclservice.ListErrorMessage">
                          <div class="alert alert-danger" role="alert" style="width: 40%;">
                            <button ng-click="addclservice.listDismissError()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{addclservice.ListMessage}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>

            </div>

            <button type="submit" class="btn btn-danger">Submit</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
