<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Venues
        <button class="btn btn-danger btn-md pull-right" ng-click="venues.newvenue()" tooltip-placement="left" tooltip="Add Venue">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="venues.limit" ng-options="limit for limit in venues.limits" ng-change="venues.setLimit(venues.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="venue in venues.venues | filter:venueSearch | limitTo:venues.limit" ng-hide="venues.venueSelected.selected && venues.venueSelected.venue !== venue.id">
          <td class="venueIMG">
            <div>
              <img ng-src="{{staticUrl}}{{venue.imageuri}}" class="img-responsive"/>
            </div>
          </td>
          <td>
            <h4>
              {{venue.venuetitle}}</h4>
            <h4>
              {{venue.location}}</h4>
          </td>
          <td>
            <h4>Mail:
              <a ng-href="mailto:{{venue.contactemail}}">{{venue.contactemail}}</a>
            </h4>
            <h4>Phone:
              {{venue.contactphone}}</h4>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="venues.deleteVenue(venue.id)" tooltip-placement="left" tooltip="Delete Venue">
                <span class="fa fa-trash-o" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ng-click="venues.editvenue(venue.id)" tooltip-placement="left" tooltip="Edit Venue">
                <span class="fa fa-pencil-square-o" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ng-click="venues.manageNetwork(venue.id)" tooltip-placement="left" tooltip="Manage Network">
                <i class="fa fa-wifi" aria-hidden="true"></i>
              </button>
              <button class="btn btn-danger btn-md" ng-click="venues.externalServices(venue.id)" tooltip-placement="left" tooltip="Cloud Services">
                <i class="fa fa-cloud" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row" ng-show="venues.venueSelected.venue > 0">
  <div class="col-md-6" ng-show="venues.zones.length > 0">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">
            </div>
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div dir-paginate="zone in venues.zones | filter:zoneSearch | itemsPerPage: 10" pagination-id="zones">
          <div class="row">
            <div class="col-md-3">
              <h4>Zone:
                {{zone.id}}</h4>
            </div>
            <div class="col-md-3">
              <h4>{{zone.name}}</h4>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary btn-md pull-right" ng-click="venues.getAccessPoints(zone)">
                Access Points</button>
            </div>
          </div>
        </div>
        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="zones"></dir-pagination-controls>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input type="text" class="form-control" placeholder="Search Access Points" ng-model="apSearch">
            </div>
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div ng-hide="venues.aps.length > 0">
          <p>No zone selected, or, selected zone has no access points assigned</p>
        </div>
        <div ng-show="venues.aps.length > 0">
          <div>
            <h4>{{venues.venueSelected.zone}}</h4>
          </div>
          <div class="row">
            <div dir-paginate="ap in venues.aps | filter:apSearch | itemsPerPage: 10" pagination-id="aps">
              <div class="col-md-4">
                <h4>AP:
                  {{ap.id}}</h4>
              </div>
              <div class="col-md-4">
                <h4>IP:
                  {{ap.ipaddress}}</h4>
              </div>
              <div class="col-md-4">
                <h4>MAC:
                  {{ap.hwaddr}}</h4>
              </div>
            </div>
            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="aps"></dir-pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
