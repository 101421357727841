(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addslt.controller:AddsltCtrl
   *
   * @description
   *
   */
  angular
    .module('addslt')
    .controller('AddsltCtrl', AddsltCtrl);

  function AddsltCtrl($state, $modal, Slt, Provider) {
    var vm = this;
    vm.ctrlName = 'AddsltCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];
    vm.slt = {};

    vm.selectImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.slt.imageuri = thisImage.imageuri;
        vm.slt.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };
    vm.uploadImage = function() {
      vm.modalMessage = {};
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function(image) {
        vm.slt.imageuri = image.imageUri;
        vm.slt.imageId = image.id;
      }, function() {
        console.log('canceled');
      });
    };


    vm.createSLT = function () {
      var data = JSON.stringify({
        name: vm.slt.name,
        imageId: vm.slt.imageId,
        details: '{}'
      });
      Slt.createSLT(data).success(function (data) {
        $state.go('admin.sociallogins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
