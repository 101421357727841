(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsplashtype.controller:EditsplashtypeCtrl
   *
   * @description
   *
   */
  angular
    .module('editsplashtype')
    .controller('EditsplashtypeCtrl', EditsplashtypeCtrl);

  function EditsplashtypeCtrl($state, Splashtype, $log, $stateParams, Provider) {
    var vm = this;

    vm.splashtype = {};
    var typeid = $stateParams.typeid;
    vm.submitted = false;

    Splashtype.getSplashType(typeid).then(function (resp) {
      vm.splashtype.name = resp.data.splashType.name;
      vm.splashtype.description = resp.data.splashType.description;
    }, function (err) {
      $log.info(err);
    });

    vm.editSplash = function (valid) {
      vm.submitted = true;
      console.log(vm.splashtype);
      if (valid) {
        Splashtype.updateSplashType(typeid, vm.splashtype).then(function () {
          $state.go('admin.splashtype');
        }, function (err) {
          $log.info(err);
        });
      }
    };

  }
}());
