(function () {
  'use strict';

  angular
    .module('organisations')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.organisations', {
        url: '/organisations',
        templateUrl: 'organisations/views/organisations.tpl.html',
        controller: 'OrganisationsCtrl',
        controllerAs: 'organisations'
      });
  }
}());
