(function () {
  'use strict';

  angular
    .module('editclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editclservice', {
        url: '/editclservice/:venueid/:orgid/:cloudservicesid',
        templateUrl: 'editclservice/views/editclservice.tpl.html',
        controller: 'EditclserviceCtrl',
        controllerAs: 'editclservice'
      });
  }
}());
