<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Images
        <button class="btn btn-md btn-danger pull-right mgn10r" ng-click="leimages.uploadImage()" tooltip-placement="left" tooltip="Add Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="leimages.limit" ng-options="limit for limit in leimages.limits" ng-change="leimages.setLimit(leimages.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="image in leimages.imageList | filter:imageSearch | limitTo:leimages.limit">
          <td class="evntIMG">
            <div>
              <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />
            </div>
          </td>
          <td>
            <p>{{image.details.description}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="leimages.deleteImage(image)" tooltip-placement="left" tooltip="Delete Image">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
