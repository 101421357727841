<div id="addoperator">
  <h2 class="text-center">Create Operator</h2>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
    </div>
    <div class="panel-body">
      <form name="createOperator" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>OPERATOR NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.operatorname.$invalid && addoperator.submitted }">
              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="addoperator.operator.name" placeholder="eg. First Last" required>
            </div>
            <div class="help-block" ng-messages="createOperator.operatorname.$error" ng-if="addoperator.submitted">
              <p ng-message="required">operator name is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>LOGIN</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.username.$invalid && addoperator.submitted }">
              <input type="email" class="form-control" id="username" name="username" ng-model="addoperator.operator.login" placeholder="eg. somename@someemail.com" required>
            </div>
            <div class="help-block" ng-messages="createOperator.username.$error" ng-if="addoperator.submitted">
              <p ng-message="required">login is required.</p>
              <p ng-message="email">This needs to be a valid email</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>PASSWORD</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.password.$invalid && addoperator.submitted }">
              <input type="password" class="form-control" id="password" name="password" ng-model="addoperator.operator.password" required>
            </div>
            <div class="help-block" ng-messages="createOperator.password.$error" ng-if="addoperator.submitted">
              <p ng-message="required">password is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ROLE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.role.$invalid && addoperator.submitted }">
              <select id="role" name="role" class="form-control" ng-model="addoperator.operator.roleId" ng-options="role.id as role.name for role in addoperator.roles" required>
                <option value="">Select A Role</option>
              </select>
            </div>
            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="addoperator.submitted">
              <p ng-message="required">role is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="addoperator.operator.roleId == 2">
          <div class="panel-heading clearfix">
            <h4>Venue</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createOperator.role.$invalid && newoperator.submitted }">
              <select id="venue" name="venue" class="form-control" ng-model="addoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in addoperator.venues" ng-change="addoperator.setVenue(addoperator.operator.details.venue)">
                <option value="">Select A Venue</option>
              </select>
            </div>

            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">
              <p ng-message="required">role is required.</p>
            </div>
          </div>
        </div>
        <button ng-click="addoperator.createOrganisationOperator(!createOperator.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
