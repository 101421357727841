(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:MainCtrl
   *
   * @description
   *
   */
  angular
    .module('main')
    .controller('MainCtrl', MainCtrl);

  function MainCtrl($rootScope, Auth, $state, $cookies, $modal) {
    var vm = this;
    vm.ctrlName = 'MainCtrl';

    vm.launchLogin = function () {
      var modalInstance = $modal.open({
        templateUrl: 'main/views/login.tpl.html',
        controller: 'LoginmodalCtrl as modal'
      });

      modalInstance.result.then(function () {
        $state.go('admin.admins');
      }, function () {
        console.log('canceled');
      });
    };
  }
}());
