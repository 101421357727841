(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addorganisation.controller:AddorganisationCtrl
   *
   * @description
   *
   */
  angular
    .module('addorganisation')
    .controller('AddorganisationCtrl', AddorganisationCtrl);

  function AddorganisationCtrl($state, Organisation) {
    var vm = this;
    vm.ctrlName = 'AddorganisationCtrl';

    vm.ErrorMessage = false;
    vm.organisationDetails = [];
    vm.Message = [];
    vm.premium = false;

    vm.createOrganisation = function () {
      var data = JSON.stringify({
        organisationTitle: vm.organisationDetails.organisationTitle,
        premium: vm.premium,
        details: {
          email: vm.organisationDetails.email,
          phone: vm.organisationDetails.phone
        }
      });
      Organisation.createOrganisation(data).success(function () {
        $state.go('admin.organisations');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
