(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SltdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('sociallogins')
    .controller('SltdeletemodalCtrl', SltdeletemodalCtrl);

  function SltdeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'SltdeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());
