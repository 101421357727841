(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name categories.controller:CatdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('guestnetworksettings')
    .controller('DeletemodalCtrl', DeletemodalCtrl);

  function DeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
