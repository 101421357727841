(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orgInfrastructures.controller:OrgInfrastructuresCtrl
   *
   * @description
   *
   */
  angular
    .module('orgInfrastructures')
    .controller('OrgInfrastructuresCtrl', OrgInfrastructuresCtrl);

  function OrgInfrastructuresCtrl(Infrastructureapi, $log, $q, $stateParams, Organisation, $state, $modal) {
    var vm = this;
    var orgid = $stateParams.orgid;
    vm.ctrlName = 'OrgInfrastructuresCtrl';

    vm.infrastructures = [];

    vm.getInfrastructures = function(){
      Infrastructureapi.getOrgInfrastructures(orgid).success(function(data) {
        vm.infrastructures = data.infrastructures;
        $log.info(data);
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };
    vm.createInfrastructure = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/newinfrastructuremodel.tpl.html',
        controller: 'NewInfrastructuremodalCtrl as newinfrastructuremodel',
        resolve: {
          organisation: function () {
           return orgid;
         }
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.deleteInfrastructure = function(id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfradeletemodal.tpl.html',
        controller: 'OrgInfradeletemodalCtrl as orginfradeleteModal'
      });

      newModalInstance.result.then(function() {
        Infrastructureapi.deleteOrgInfrastructure(id, orgid).success(function(data) {
          $log.info(data);
          vm.getInfrastructures();
        }).error(function() {
          $log.info('delete Infrastructure error');
        });
      });
    };

    vm.editInfrastructure = function (id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfraeditmodal.tpl.html',
        controller: 'OrgInfraeditmodalCtrl as orginfraeditModal',
        resolve: {
          organisation: function () {
           return orgid;
          },
          infrastructureId: id
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };




    vm.getInfrastructures();

  }
}());
