(function () {
  'use strict';

  /* @ngdoc object
   * @name editcategory
   * @description
   *
   */
  angular
    .module('editcategory', [
      'ui.router'
    ]);
}());
