(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editrole.controller:EditroleCtrl
   *
   * @description
   *
   */
  angular
    .module('editrole')
    .controller('EditroleCtrl', EditroleCtrl);

  function EditroleCtrl($state, $stateParams, Roles) {
    var vm = this;
    vm.ctrlName = 'EditroleCtrl';

    vm.ErrorMessage = false;
    vm.organisationRole = [];
    vm.Message = [];

    Roles.getOrganisationRoleById($stateParams.roleid).success(function (data) {
      vm.organisationRole = data.organisationRole;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateOrganisationRole = function () {
      var data = JSON.stringify({
        name: vm.organisationRole.name,
        details: {}
      });
      Roles.updateOrganisationRole(vm.organisationRole.id, data).success(function (data) {
        $state.go('admin.organisationroles');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      vm.ErrorMessage = false;
    };

  }
}());
