<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div class="scrollbox">
    <table class="table table-responsive eventsTable">
      <thead>
        <tr>
          <th>Id</th>
          <th>Provider</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody ng-repeat="provider in setprovidersmodal.providers  track by $index">
        <tr>
          <td>
            {{provider.id}}
          </td>
          <td>
            {{provider.name}}
          </td>
          <td>
            <button class="btn btn-danger btn-sm" ng-hide="provider.status" ng-click="setprovidersmodal.select($index)">Select</button>
            <button class="btn btn-success btn-sm" ng-show="provider.status" ng-click="setprovidersmodal.deselect($index)">Select <i class="fa fa-check" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="setprovidersmodal.ok()">Save</button>
  <button class="btn btn-warning" type="button" ng-click="setprovidersmodal.cancel()">Cancel</button>
</div>
