(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name selectimage.controller:SelectimageCtrl
   *
   * @description
   *
   */
  angular
    .module('selectimage')
    .controller('SelectimageCtrl', SelectimageCtrl);

  function SelectimageCtrl($modalInstance, Infrastructureapi, message) {
    var vm = this;

    console.log(message);

    Infrastructureapi.getImages(message.orgid).success(function (data) {
      vm.imageList = data.images;
      console.log(data.images);
    });

    vm.selectedImage = {};

    vm.ok = function () {
      $modalInstance.close(vm.selectedImage);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };



  }
}());
