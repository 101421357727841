<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h2>{{operators.organisation.organisationtitle}}
        Operators
        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addoperator({organisationid: operators.orgid})" tooltip-placement="left" tooltip="Add Operator">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h2>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search Operators" ng-model="operatorSearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="operators.limit" ng-options="limit for limit in operators.limits" ng-change="operators.setLimit(operators.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Username</th>
          <th>Venue</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr ng-repeat="operator in operators.operators | filter:operatorSearch | limitTo:operators.limit">
          <td>{{operator.name}}</td>
          <td>{{operator.login}}</td>
          <td><span ng-show="operator.role === 2">{{operator.details.venueName}}</span><span ng-show="operator.role === 1">Admin</span></td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="operators.deleteEvent(operator.organisation, operator.id)" tooltip-placement="left" tooltip="Delete Operator">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <a class="btn btn-danger btn-md" ui-sref="admin.editoperator({organisationid:operators.orgid, opid: operator.id})" tooltip-placement="left" tooltip="Edit Operator">
                <i class="fa fa-pencil-square-o"></i>
              </a>
              <a class="btn btn-danger btn-md" ui-sref="admin.editoperatorpwd({organisationid:operators.orgid, opid: operator.id})" tooltip-placement="left" tooltip="Edit Password">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
