(function () {
  'use strict';

  angular
    .module('addclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addclservice', {
        url: '/addclservice/:venueid/:orgid',
        templateUrl: 'addclservice/views/addclservice.tpl.html',
        controller: 'AddclserviceCtrl',
        controllerAs: 'addclservice'
      });
  }
}());
