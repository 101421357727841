<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Admin</h3>
    </div>
    <div class="panel-body">
      <div class="col-md-8 col-md-offset-2">
        <div ng-show="addadmin.ErrorMessage">
          <div class="alert alert-danger" role="alert">
            <button ng-click="addadmin.dismiss()" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <span class="sr-only">Error:</span>
            {{addadmin.Message}}
          </div>
        </div>
        <form name="form" ng-submit="form.$valid && addadmin.createAdmin()" role="form" class="col-md-8 col-md-offset-2" novalidate>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Login</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <input type="text" class="form-control" id="login" name="login" placeholder="Login" ng-model="addadmin.adminDetails.login" required>
              </div>
              <div ng-show="form.login.$invalid">
                <p>Login is required!
                </p>
              </div>
            </div>
          </div>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Password</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <input type="password" class="form-control" id="password" name="password" placeholder="Password" ng-model="addadmin.adminDetails.password" required>
              </div>
              <div ng-show="form.password.$error.required">
                <p>Password is required!
                </p>
              </div>
            </div>
          </div>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Confirm Password</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <input type="password" class="form-control" id="password2" name="password2" placeholder="Confirm Password" ng-model="addadmin.adminDetails.password2" same-as required>
              </div>
              <div ng-show="form.password2.$error.required">
                <p>Confirm Password is required!
                  <p></div>
                  <div ng-show="form.password2.$error.noMatch">
                    <p>Passwords do not match!
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel panel-default panel-form">
                <div class="panel-heading clearfix">
                  <h4>Name</h4>
                </div>
                <div class="panel-body">
                  <div class="form-group">
                    <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="addadmin.adminDetails.name" required>
                  </div>
                  <div ng-show="form.name.$invalid">
                    <p>Name is required!
                    </p>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
