(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name managenetwork.controller:ManagenetworkCtrl
   *
   * @description
   *
   */
  angular
    .module('managenetwork')
    .controller('ManagenetworkCtrl', ManagenetworkCtrl);

  function ManagenetworkCtrl(Infrastructureapi, $log, $stateParams, Organisation, $state, $modal, NetworkType, $cookies) {
    var vm = this;

    vm.zones = [];
    vm.accesspoints = [];
    vm.infrastructures = [];
    vm.networkTypes = [];
    vm.venue = {};

    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;

    vm.showZones = false;
    vm.showAPoints = false;
    vm.showTypes = false;
    vm.showInfra = true;

    vm.setNetworkView = function (view) {
      switch (view) {
      case 'zone':
        vm.showZones = true;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = false;
        break;
      case 'apoints':
        vm.showZones = false;
        vm.showAPoints = true;
        vm.showTypes = false;
        vm.showInfra = false;
        break;
      case 'types':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = true;
        vm.showInfra = false;
        break;
      case 'infra':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = true;
        break;
      }
    };

    if ($cookies.get('view')) {
      vm.setNetworkView($cookies.get('view'));
    }

    function getZones(venueid, orgid) {
      Infrastructureapi.getZones(venueid, orgid).then(function (res) {
        vm.zones = res.data.zones;
        //$log.info(vm.zones);
      }, function (err) {
        $log.info(err);
      });
    }

    function getAccessPoints(venueid, orgid) {
      Infrastructureapi.getAps(venueid, orgid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        $log.info(vm.accesspoints);
      }, function (err) {
        $log.info(err);
      });
    }

    function getVenue(orgid, venueid) {
      Infrastructureapi.getVenue(orgid, venueid).then(function (res) {
        vm.venue = res.data.venue;
      }, function (err) {
        $log.info(err);
      });
    }

    function getNetworkTypes(venueid) {
      NetworkType.getNetworkTypes(venueid).then(function (res) {
        vm.networkTypes = res.data.networkTypes;
        $log.info(vm.networkTypes);
      }, function (err) {
        $log.info(err);
      });
    }

    function getInfrastructures(venueid) {
      Infrastructureapi.getInfrastructures(venueid).then(function (res) {
        vm.infrastructures = res.data.infrastructures;
        $log.info(vm.infrastructures);
      }, function (err) {
        $log.info(err);
      });
    }

    vm.deleteZone = function (id) {
      $cookies.put('view', 'zone');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Zone, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Infrastructureapi.deleteZone(id, venueid, orgid).success(function (data) {
          $log.info(data);
          getZones(venueid, orgid);
        }).error(function () {
          vm.modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete zones with accesspoints attached',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return vm.modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {});
        });
      });
    };

    vm.deleteAccesspoint = function (id) {
      $cookies.put('view', 'apoints');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Access Point?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Infrastructureapi.deleteAp(id, venueid, orgid).success(function (data) {
          $log.info(data);
          getAccessPoints(venueid, orgid);
        }).error(function () {
          $log.info('get Zones error');
        });
      });
    };

    vm.createZone = function () {
      $cookies.put('view', 'zone');
      $state.go('admin.addzone', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.createAccesspoint = function () {
      $cookies.put('view', 'apoints');
      $state.go('admin.addap', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.createInfrastructure = function () {
      $cookies.put('view', 'infra');
      $state.go('admin.addInfrastructure', {
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.editInfrastructure = function (infraid) {
      $cookies.put('view', 'infra');
      $state.go('admin.editinfrastructure', {
        infraid: infraid,
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.editZone = function (zoneid) {
      $cookies.put('view', 'zone');
      $state.go('admin.editzone', {
        zoneid: zoneid,
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editAccesspoint = function (apid) {
      $cookies.put('view', 'apoints');
      $state.go('admin.editap', {
        apid: apid,
        venueid: venueid,
        orgid: orgid
      });
    };

    vm.createNetworkType = function () {
      $cookies.put('view', 'types');
      $state.go('admin.networktype', {
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editNetworkType = function (id) {
      $cookies.put('view', 'types');
      $state.go('admin.editnetworktype', {
        venueid: venueid,
        orgid: orgid,
        typeid: id,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.deleteNetworkType = function (id) {
      $cookies.put('view', 'types');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        NetworkType.deleteNetworkType(id, venueid).then(function () {
          getNetworkTypes(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    getZones(venueid, orgid);
    getVenue(orgid, venueid);
    getAccessPoints(venueid, orgid);
    getNetworkTypes(venueid);
    getInfrastructures(venueid);
  }
}());
