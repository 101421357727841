<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
  <p>Enter your account details in the form below</p>
</div>
<div class="modal-body">
  <div ng-show="modal.message.type">
      <div class="alert alert-danger" role="alert">
          <button ng-click="modal.dismiss()" type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span> {{modal.message.msg}}
      </div>
  </div>
  <form name="form" ng-submit="modal.login()" role="form">
    <div class="form-group">
      <label for="username" class="control-label pull-left">LOGIN</label>
      <input type="text" class="form-control" id="username" placeholder="Username" ng-model="modal.loginDetails.username">
    </div>
    <div class="form-group">
      <label for="password" class="control-label pull-left">PASSWORD</label>
      <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="modal.loginDetails.password">
    </div>
    <div class="form-group" ng-hide="true">
      <label class="checkbox-inline pull-left">
        <input type="checkbox"> Keep me signed in
      </label>
      <label class="checkbox-inline pull-right modal-link">
        <a href="">Forgot Password?</a>
      </label>
    </div>
    <button type="submit" class="btn btn-danger btn-block">Login</button>
  </form>
</div>
