(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('venues')
    .controller('DeletevenuemodalCtrl', DeletevenuemodalCtrl);

  function DeletevenuemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletevenuemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
