<div class="container">
  <div class="row mgn-btm-80"></div>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <div class="mgn-btm-20">
        <img alt="Brand" src="./images/liquidedge-logo-small.png">
      </div>
      <form name="form" ng-submit="home.login()" role="form">
        <div class="form-group">
          <label for="username" class="control-label pull-left">LOGIN</label>
          <input type="text" class="form-control" id="username" placeholder="Username" ng-model="home.loginDetails.username">
        </div>
        <div class="form-group">
          <label for="password" class="control-label pull-left">PASSWORD</label>
          <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="home.loginDetails.password">
        </div>
        <div class="form-group" ng-hide="true">
          <label class="checkbox-inline pull-left">
            <input type="checkbox"> Keep me signed in
          </label>
          <label class="checkbox-inline pull-right modal-link">
            <a href="">Forgot Password?</a>
          </label>
        </div>
        <button type="submit" class="btn btn-danger btn-small pull-right">Login</button>
      </form>
    </div>
  </div>
</div>
