(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name leimages.controller:LeimagesCtrl
   *
   * @description
   *
   */
  angular
    .module('leimages')
    .controller('LeimagesCtrl', LeimagesCtrl);

  function LeimagesCtrl($modal, Leimages) {
    var vm = this;
    vm.ctrlName = 'LeimagesCtrl';

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.getImages = function () {
      Leimages.getImages().then(function (res) {
        vm.imageList = res.data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
      }, function (err) {
        console.log(err);
      });
    }

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: ''
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Leimages.deleteImage(image.id).success(function (resp) {
          console.log(resp);
          vm.getImages();
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log('IMG', image);
        vm.getImages();
      }, function () {
        console.log('canceled');
      });
    };
    vm.getImages();
  }
}());
