<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center"> Edit Cloud Service Type</h3>
    </div>
    <div class="panel-body">
      <div ng-show="editclstype.ErrorMessage">
        <div class="alert alert-danger" role="alert" style="width: 40%;">
          <button ng-click="editclstype.dismiss()"
          type="button" class="close"
          aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{editclstype.Message}}
        </div>
      </div>
      <form name="form" ng-submit="editclstype.updateCLT(form.$valid)"
      role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control"
               id="name" name="name" placeholder="Name"
               ng-model="editclstype.cloudservicetypeDetails.name"
               class="col-md-8 col-md-offset-2" required>
              <div ng-show="form.name.$invalid">
                <p>Name is required!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Description</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control"
               id="description" name="description" placeholder="description"
               ng-model="editclstype.cloudservicetypeDetails.description"
               class="col-md-8 col-md-offset-2" required>
              <div ng-show="form.description.$invalid">
                <p>Description is required!</p>
              </div>
            </div>
          </div>
        </div>
        <button  class="btn btn-danger btn-md">Submit</button>
      </div>
    </form>
  </div>
</div>
