(function () {
  'use strict';

  angular
    .module('analytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.analytics', {
        url: '/analytics',
        templateUrl: 'analytics/views/analytics.tpl.html',
        controller: 'AnalyticsCtrl',
        controllerAs: 'analytics'
      });
  }
}());
