(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('orgInfrastructures')
    .controller('NewInfrastructuremodalCtrl', NewInfrastructuremodalCtrl);

  function NewInfrastructuremodalCtrl($modalInstance, Infrastructureapi, Provider, $log, organisation) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.orgid = organisation;
    vm.selectedProvider = 0;
    vm.providers = [];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      if(vm.matchProvider('xirrus', provider)){
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };


    Provider.getAllProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.addInfrastructure = function() {
      Infrastructureapi.createOrgInfrastructure(vm.orgid, vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };




    vm.ok = function () {
      vm.control.submitted = true;
      vm.addInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
