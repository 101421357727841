(function () {
  'use strict';

  angular
    .module('addoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addoperator', {
        url: '/addoperator/:organisationid',
        templateUrl: 'addoperator/views/addoperator.tpl.html',
        controller: 'AddoperatorCtrl',
        controllerAs: 'addoperator'
      });
  }
}());
