<div id="editsplashtype">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit Splashpage Type</h3>
    </div>
    <div class="panel-body">
      <form name="editSplash" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': editSplash.name.$invalid && editsplashtype.submitted }">
              <input type="text" class="form-control" id="name" name="name" ng-model="editsplashtype.splashtype.name" placeholder="eg. Video Ad" required>
            </div>
            <div class="help-block" ng-messages="editSplash.name.$error" ng-if="editsplashtype.submitted">
              <p ng-message="required">name is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': editSplash.description.$invalid && editsplashtype.submitted }">
              <input type="text" class="form-control" id="description" name="description" ng-model="editsplashtype.splashtype.description" placeholder="eg. Some Description" required>
            </div>
            <div class="help-block" ng-messages="editSplash.description.$error" ng-if="editsplashtype.submitted">
              <p ng-message="required">description is required.</p>
            </div>
          </div>
        </div>
        <button ng-click="editsplashtype.editSplash(!editSplash.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
