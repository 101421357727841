(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editclstype.controller:EditclstypeCtrl
   *
   * @description
   *
   */
  angular
    .module('editclstype')
    .controller('EditclstypeCtrl', EditclstypeCtrl);

  function EditclstypeCtrl($state, $stateParams, Cloudservicetype) {

    var vm = this;
    vm.ctrlName = 'EditclstypeCtrl';

    vm.ErrorMessage = false;
    vm.cloudservicetypeDetails = [];
    vm.Message = [];

    Cloudservicetype.getCloudServiceTypeById($stateParams.typeid).success(function (data) {
      vm.cloudservicetypeDetails = data.cloudServiceType;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateCLT = function () {
      var data = JSON.stringify({
        name: vm.cloudservicetypeDetails.name,
        description: vm.cloudservicetypeDetails.description
      });
      Cloudservicetype.udpateCloudServiceType(vm.cloudservicetypeDetails.id, data).success(function (data) {
        $state.go('admin.cloudservicetypes');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

  }
}());
