(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Netfilter
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Netfilter', Netfilter);

  function Netfilter(baseurl, $http, Auth) {
    var NetfilterBase = {},
        token = '';
    NetfilterBase.someValue = 'Netfilter';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    NetfilterBase.getAllNetFilters = function () {
      return $http.get(baseurl + '/networkFilters', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.createNetFilter = function (data) {
      return $http.post(baseurl + '/networkFilters', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetfilterBase.getNetFilterById = function (id) {
      return $http.get(baseurl + '/networkFilters/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getFilterbyCategory = function (id) {
      return $http.get(baseurl + '/networkfilters/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.updateNetFilter = function (id, data) {
      return $http.put(baseurl + '/networkFilters/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetfilterBase.deleteNetFilter = function (id) {
      return $http.delete(baseurl + '/networkFilters/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getlistOfXirrusFilters = function () {
      return $http.get(baseurl + '/xirrus', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getlistOfCategoryTypes = function () {
      return $http.get(baseurl + '/categorytypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    NetfilterBase.getNetFilterByCategory = function (id) {
      return $http.get(baseurl + '/networkfilters/categories/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return NetfilterBase;
  }
}());
