(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Splashtype
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Splashtype', Splashtype);

  function Splashtype(baseurl, $http, Auth) {
    var SplashtypeBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    SplashtypeBase.getAll = function () {
      return $http.get(baseurl + '/splashtypes/', {
        headers: {
          'x-access-token': token
        }
      });
    };

    SplashtypeBase.getSplashType = function (id) {
      return $http.get(baseurl + '/splashtypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    SplashtypeBase.createSplashType = function (data) {
      return $http.post(baseurl + '/splashtypes/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SplashtypeBase.updateSplashType = function (id, data) {
      return $http.put(baseurl + '/splashtypes/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    SplashtypeBase.deleteSplashType = function (id) {
      return $http.delete(baseurl + '/splashtypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    SplashtypeBase.getProviders = function (id) {
      return $http.get(baseurl + '/splashtypes/' + id + '/providers/', {
        headers: {
          'x-access-token': token
        }
      });
    };
    SplashtypeBase.setProviders = function (id, data) {
      return $http.post(baseurl + '/splashtypes/' + id + '/providers/', data,{
        headers: {
          'x-access-token': token
        }
      });
    };


    return SplashtypeBase;
  }
}());
