<div id="addzone">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Create Zone</h3>
    </div>
    <div class="panel-body">
      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ZONE NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="addzone.zone.name" placeholder="eg. Some zone" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="addzone.provider == 2">
          <div class="panel-heading clearfix">
            <h4>WLAN Group</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.wlangroup" placeholder="eg. default">
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Accesspoints</h4>
          </div>
          <div class="panel-body">
            <ul id="double">
                <li class="iplisting" dir-paginate="ap in addzone.accesspoints | orderBy: 'ipaddress'| filter:apSearch | itemsPerPage: 15">
                  <label>
                    <input type="checkbox" checklist-model="addzone.selectedAccesspoints" checklist-value="ap.id"> {{ap.ipaddress}}
                  </label>
                </li>
            </ul>
            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
          </div>
        </div>
        <button ng-click="addzone.createZone()" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
