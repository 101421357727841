(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editcategory.controller:EditcategoryCtrl
   *
   * @description
   *
   */
  angular
    .module('editcategory')
    .controller('EditcategoryCtrl', EditcategoryCtrl);

  function EditcategoryCtrl($state, $stateParams, Category, Netfilter, $modal, $scope, Provider) {
    var vm = this;
    vm.ctrlName = 'EditcategoryCtrl';
    vm.ErrorMessage = false;
    vm.xirrusFilters = [];
    vm.types = [];
    vm.categoryDetails = [];
    vm.Message = [];
    vm.networkFilters = [];
    vm.xirrusFiltersLoaded = false;
    vm.xirrusFiltersLoadedError = false;
    vm.categoryTypesLoaded = false;
    vm.categoryTypesLoadedError = false;
    vm.networkFiltersLoaded = false;
    vm.networkFiltersLoadedError = false;
    vm.selectedType = 0;
    vm.premium = false;
    vm.onSave = false;

    Netfilter.getlistOfXirrusFilters().success(function (data) {
      vm.xirrusFilters = data.xirrusFilters;
      vm.xirrusFiltersLoaded = true;
      vm.xirrusFiltersLoadedError = false;
      console.log(vm.xirrusFilters);
    }).error(function (err) {
      console.log(err);
      vm.xirrusFiltersLoaded = false;
      vm.xirrusFiltersLoadedError = false;
    });

    Netfilter.getlistOfCategoryTypes().success(function (data) {
      vm.types = data.categoryTypes;
      console.log('categoryTypes', data.categoryTypes);
      vm.categoryTypesLoaded = true;
      vm.categoryTypesLoadedError = false;
    }).error(function (err) {
      console.log(err);
      vm.categoryTypesLoaded = true;
      vm.categoryTypesLoadedError = true;
    });

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
    }).error(function (err) {
      console.log('Error: ', err);
    });

    Category.getCategoryById($stateParams.categoryid).success(function (data) {
      vm.categoryDetails = data.category;
      var typeObj = { id: vm.categoryDetails.type, description: '' };
      $scope.type = typeObj;
      var providerObj = { id: vm.categoryDetails.provider, description: '' };
      $scope.provider = providerObj;
      vm.premium = vm.categoryDetails.premium;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    Netfilter.getNetFilterByCategory($stateParams.categoryid).success(function (result) {
      vm.networkFilters = result.networkFilters;
      vm.networkFiltersLoaded = true;
      console.log(vm.networkFilters);
    }).error(function (err) {
      vm.networkFiltersLoadedError = true;
      vm.networkFiltersLoaded = true;
      console.log('Error: ', err);
    });

    vm.updateCategory = function () {
      var data = JSON.stringify({
        name: vm.categoryDetails.name,
        provider: $scope.provider.id,
        description: vm.categoryDetails.description,
        premium: vm.premium,
        categoryTypeId: $scope.type.id
      });
      Category.updateCategory(vm.categoryDetails.id, data).success(function (data) {
        console.log(data);
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    var getFilterList = function(id) {
      Netfilter.getFilterbyCategory(id).then(function(res) {
        console.log(res);
        vm.networkFilters = res.data.networkFilters;
      }, function(err) {
        console.log(err);
      });
    };

    vm.deleteFilter = function(id) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Filter, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function() {
        Netfilter.deleteNetFilter(id).then(function(res) {
          console.log(res);
          getFilterList($stateParams.categoryid);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.addFilter = function() {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Add Filters',
        category: $stateParams.categoryid,
        filters: vm.xirrusFilters
      };

      var modalInstance = $modal.open({
        templateUrl: 'filtercreatemodal/views/filtercreatemodal.tpl.html',
        controller: 'FiltercreatemodalCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function(data) {
        console.log(data);
        Netfilter.createNetFilter(data).then(function(res) {
          console.log(res);
          getFilterList(data.categoryId);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.done = function(){
      $state.go('admin.categories');
    };



  }
}());
