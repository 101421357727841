(function () {
  'use strict';

  angular
    .module('providers')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.providers', {
        url: '/providers',
        templateUrl: 'providers/views/providers.tpl.html',
        controller: 'ProvidersCtrl',
        controllerAs: 'providers'
      });
  }
}());
