(function () {
  'use strict';

  angular
    .module('sociallogins')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.sociallogins', {
        url: '/sociallogins',
        templateUrl: 'sociallogins/views/sociallogins.tpl.html',
        controller: 'SocialloginsCtrl',
        controllerAs: 'sociallogins'
      });
  }
}());
