(function () {
  'use strict';

  angular
    .module('addcategory')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addcategory', {
        url: '/addcategory',
        templateUrl: 'addcategory/views/addcategory.tpl.html',
        controller: 'AddcategoryCtrl',
        controllerAs: 'addcategory'
      });
  }
}());
