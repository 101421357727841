(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name analytics.controller:AnalyticsCtrl
   *
   * @description
   *
   */
  angular
    .module('analytics')
    .controller('AnalyticsCtrl', AnalyticsCtrl);

  function AnalyticsCtrl($state, Analytics, $modal) {
    var vm = this;
    vm.ctrlName = 'AnalyticsCtrl';

    vm.ErrorMessage = false;
    vm.Message = [];

    vm.schedulerLabels = [];
    vm.schedulerData = [];
    vm.schedulerSeries = [];

    vm.schedulerCreateLabels = [];
    vm.schedulerCreateData = [];
    vm.schedulerCreateSeries = [];

    vm.schedulerDeleteLabels = [];
    vm.schedulerDeleteData = [];
    vm.schedulerDeleteSeries = [];

    vm.methodLabels = [];
    vm.methodData = [];
    vm.methodSeries = [];

    vm.methodNumberLabels = [];
    vm.methodNumberData = [];
    vm.methodNumberSeries = [];

    vm.organisationsLoaded = false;
    vm.organisationsLoadedError = false;
    vm.organisations = [];

    vm.orgId = null;
    vm.venueId = null;
    vm.eventId = null;

    vm.venuesLoaded = false;
    vm.venuesLoadedError = false;
    vm.venues = [];

    vm.eventsLoaded = false;
    vm.eventsLoadedError = false;
    vm.events = [];
    vm.analytics = false;

    vm.eventAnalyticsLoaded = false;
    vm.eventAnalyticsLoadedError = false;
    vm.eventAnalytics = [];

    vm.schedulerAnalyticsLoaded = false;
    vm.schedulerAnalyticsLoadedError = false;
    vm.schedulerMinMaxAnalytics = [];
    vm.schedulerAvgSumAnalytics = [];
    vm.schedulerAvgSumAnalyticsLoaded = false;
    vm.schedulerAvgSumAnalyticsLoadedError = false;

    vm.schedulerCreateAnalyticsLoaded = false;
    vm.schedulerCreateAnalyticsLoadedError = false;
    vm.schedulerCreateMinMaxAnalytics = [];
    vm.schedulerCreateAvgSumAnalytics = [];
    vm.schedulerCreateAvgSumAnalyticsLoaded = false;
    vm.schedulerCreateAvgSumAnalyticsLoadedError = false;

    vm.schedulerDeleteAnalyticsLoaded = false;
    vm.schedulerDeleteAnalyticsLoadedError = false;
    vm.schedulerDeleteMinMaxAnalytics = [];
    vm.schedulerDeleteAvgSumAnalytics = [];
    vm.schedulerDeleteAvgSumAnalyticsLoaded = false;
    vm.schedulerDeleteAvgSumAnalyticsLoadedError = false;

    vm.methodNumberData = [];

    Analytics.getAllOrganisations().success(function (data) {
      vm.organisations = data.orgs;
      vm.organisationsLoaded = true;
      vm.organisationsLoadedError = false;
      vm.venuesLoaded = false;
      vm.venuesLoadedError = false;
      vm.resetVariablesAnalytics();
      vm.dismiss();
    }).error(function (err) {
      vm.organisationsLoadedError = true;
      vm.organisationsLoaded = true;
      console.log('Error: ', err);
      vm.Message = err;
      vm.ErrorMessage = true;
    });

    vm.getAllVenues = function (orgId) {
      vm.orgId = orgId;
      vm.venuesLoaded = false;
      vm.venuesLoadedError = false;
      vm.eventsLoaded = false;
      vm.eventsLoadedError = false;
      vm.venues = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      if (orgId > 0){
        Analytics.getAllVenues(orgId).success(function (data) {
          vm.venues = data.performanceObj;
          vm.venuesLoaded = true;
          vm.venuesLoadedError = false;
        }).error(function (err) {
          vm.venuesLoadedError = true;
          vm.venuesLoaded = true;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getAllEvents = function (venueId) {
      vm.venueId = venueId;
      vm.eventsLoaded = false;
      vm.eventsLoadedError = false;
      vm.events = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      if (venueId > 0){
        Analytics.getAllEvents(vm.orgId, venueId).success(function (data) {
          vm.events = data.performanceObj;
          vm.eventsLoaded = true;
          vm.eventsLoadedError = false;
        }).error(function (err) {
          vm.eventsLoadedError = true;
          vm.eventsLoaded = true;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getAnalyticsByEvent = function (eventId) {
      vm.eventId = eventId;
      vm.eventAnalytics = [];
      vm.resetVariablesAnalytics();
      vm.dismiss();
      var data = JSON.stringify({
        orgId: vm.orgId,
        venueId: vm.venueId,
        eventId: eventId
      });
      if (eventId > 0){
        vm.setCreateSchedulerAnalytics(eventId);
        vm.setDeleteSchedulerAnalytics(eventId);
        Analytics.getEventAnalytics(data).success(function (data) {
          vm.eventAnalytics = data.performanceObj;
          vm.eventAnalyticsLoaded = true;
          vm.analytics = true;
          vm.methodData = [];
          // vm.schedulerLabels = [];
          // var schedulerDataTemp = [];
          var methodDataTemp = [];
          var methodNumberDataTemp = [];
          vm.methodNumberData = [];
          // vm.schedulerData = [];
          vm.methodLabels = ["GET", "POST", "PUT", "DELETE"];
          var getMethod = 0;
          var postMethod = 0;
          var deleteMethod = 0;
          var putMethod = 0;
          var getMethodNumber = 0;
          var postMethodNumber = 0;
          var deleteMethodNumber = 0;
          var putMethodNumber = 0;
          for(var i=0; i<vm.eventAnalytics.length; i++){
            if (vm.eventAnalytics[i].details.componentId === "enduser-rest-api"){
              var methodVariable = vm.eventAnalytics[i].details.information.method;
              if (methodVariable === "GET" || methodVariable === "get" || methodVariable === "Get"){
                getMethod = getMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                getMethodNumber++;
              }
              if (methodVariable === "POST" || methodVariable === "post" || methodVariable === "Post"){
                postMethod = postMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                postMethodNumber++;
              }
              if (methodVariable === "PUT" || methodVariable === "put" || methodVariable === "Put"){
                putMethod = putMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                putMethodNumber++;
              }
              if (methodVariable === "DELETE" || methodVariable === "delete" || methodVariable === "Delete"){
                deleteMethod = deleteMethod + parseInt(vm.eventAnalytics[i].details.timingInfo.duration);
                deleteMethodNumber++;
              }
            }
            // else{
            //   vm.schedulerLabels.push(vm.eventAnalytics[i].details.componentId);
            //   schedulerDataTemp.push(vm.eventAnalytics[i].details.timingInfo.duration);
            // }
          }

          var getDivNumGET = getMethod/getMethodNumber;
          if (isNaN(getDivNumGET))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumGET);
          var getDivNumPOST = postMethod/postMethodNumber;
          if (isNaN(getDivNumPOST))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumPOST);
          var getDivNumPUT = putMethod/putMethodNumber;
          if (isNaN(getDivNumPUT))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumPUT);
          var getDivNumDELETE = deleteMethod/deleteMethodNumber;
          if (isNaN(getDivNumDELETE))
            methodDataTemp.push(0);
          else
            methodDataTemp.push(getDivNumDELETE);

          vm.methodData.push(methodDataTemp);
          methodNumberDataTemp.push(getMethodNumber);
          methodNumberDataTemp.push(postMethodNumber);
          methodNumberDataTemp.push(putMethodNumber);
          methodNumberDataTemp.push(deleteMethodNumber);
          vm.methodNumberData.push(methodNumberDataTemp);
          // vm.schedulerData.push(schedulerDataTemp);
        }).error(function (err) {
          vm.eventAnalyticsLoadedError = true;
          vm.eventAnalyticsLoaded = true;
          vm.analytics = false;
          console.log('Error: ', err);
          vm.Message = err;
          vm.ErrorMessage = true;
        });
      }
    };

    vm.getMinutes = function (timeMS) {
      var num = Number(timeMS);
      var seconds = Math.floor(num / 1000);
      var minutes = Math.floor(seconds / 60);
      return minutes;
    };

    vm.setCreateSchedulerAnalytics = function (eventId) {
      vm.schedulerCreateLabels = [];
      var schedulerCreateDataTemp = [];
      vm.schedulerCreateData = [];
      vm.schedulerCreateMinMaxAnalytics = [];
      vm.schedulerCreateAvgSumAnalytics = [];
      Analytics.getCreateMinMaxAnalytics(eventId).success(function (data) {
        if (data.rowCount > 0){
          vm.schedulerCreateMinMaxAnalytics = data.performanceObj;
          var minData = vm.schedulerCreateMinMaxAnalytics[0].getCreateMINMAXAnalytics;
          var maxData = vm.schedulerCreateMinMaxAnalytics[1].getCreateMINMAXAnalytics;
          minData = minData.substring(1, minData.length - 1);
          maxData = maxData.substring(1, maxData.length - 1);
          var minDataSplitted = minData.split(",");
          var maxDataSplitted = maxData.split(",");
          vm.schedulerCreateLabels.push("MIN");
          vm.schedulerCreateLabels.push("MAX");
          schedulerCreateDataTemp.push(vm.getMinutes(minDataSplitted[1]));
          schedulerCreateDataTemp.push(vm.getMinutes(maxDataSplitted[1]));
          vm.schedulerCreateAnalyticsLoaded = true;
          vm.schedulerCreateAnalyticsLoadedError = false;
          vm.analytics = true;
          Analytics.getCreateAvgSumAnalytics(eventId).success(function (data) {
            vm.schedulerCreateAvgSumAnalytics = data.performanceObj;
            var avgData = vm.schedulerCreateAvgSumAnalytics[0].getCreateAVGSUMAnalytics;
            var sumData = vm.schedulerCreateAvgSumAnalytics[1].getCreateAVGSUMAnalytics;
            vm.schedulerCreateLabels.push("AVG");
            vm.schedulerCreateLabels.push("TOTAL");
            schedulerCreateDataTemp.push(vm.getMinutes(avgData));
            schedulerCreateDataTemp.push(vm.getMinutes(sumData));
            vm.schedulerCreateData.push(schedulerCreateDataTemp);
            vm.schedulerCreateAvgSumAnalyticsLoaded = true;
            vm.schedulerCreateAvgSumAnalyticsLoadedError = false;
            vm.analytics = true;
          }).error(function (err) {
            vm.schedulerCreateAvgSumAnalyticsLoaded = false;
            vm.schedulerCreateAvgSumAnalyticsLoadedError = true;
            vm.analytics = false;
            vm.Message = err;
            vm.ErrorMessage = true;
          });
        }
      }).error(function (err) {
        vm.schedulerCreateAnalyticsLoaded = false;
        vm.schedulerCreateAnalyticsLoadedError = true;
        vm.analytics = false;
        vm.Message = err;
        vm.ErrorMessage = true;
      });
    };

    vm.setDeleteSchedulerAnalytics = function (eventId) {
      vm.schedulerDeleteLabels = [];
      var schedulerDeleteDataTemp = [];
      vm.schedulerDeleteData = [];
      vm.schedulerDeleteMinMaxAnalytics = [];
      vm.schedulerDeleteAvgSumAnalytics = [];
      Analytics.getDeleteMinMaxAnalytics(eventId).success(function (data) {
        if (data.rowCount > 0){
          vm.schedulerDeleteMinMaxAnalytics = data.performanceObj;
          var minData = vm.schedulerDeleteMinMaxAnalytics[0].getDeleteMINMAXAnalytics;
          var maxData = vm.schedulerDeleteMinMaxAnalytics[1].getDeleteMINMAXAnalytics;
          minData = minData.substring(1, minData.length - 1);
          maxData = maxData.substring(1, maxData.length - 1);
          var minDataSplitted = minData.split(",");
          var maxDataSplitted = maxData.split(",");
          vm.schedulerDeleteLabels.push("MIN");
          vm.schedulerDeleteLabels.push("MAX");
          schedulerDeleteDataTemp.push(vm.getMinutes(minDataSplitted[1]));
          schedulerDeleteDataTemp.push(vm.getMinutes(maxDataSplitted[1]));
          vm.schedulerDeleteAnalyticsLoaded = true;
          vm.schedulerDeleteAnalyticsLoadedError = false;
          vm.analytics = true;
          Analytics.getDeleteAvgSumAnalytics(eventId).success(function (data) {
            vm.schedulerDeleteAvgSumAnalytics = data.performanceObj;
            console.log(vm.schedulerCreateAvgSumAnalytics);
            var avgData = vm.schedulerDeleteAvgSumAnalytics[0].getDeleteAVGSUMAnalytics;
            var sumData = vm.schedulerDeleteAvgSumAnalytics[1].getDeleteAVGSUMAnalytics;
            vm.schedulerDeleteLabels.push("AVG");
            vm.schedulerDeleteLabels.push("TOTAL");
            schedulerDeleteDataTemp.push(vm.getMinutes(avgData));
            schedulerDeleteDataTemp.push(vm.getMinutes(sumData));
            vm.schedulerDeleteData.push(schedulerDeleteDataTemp);
            vm.schedulerDeleteAvgSumAnalyticsLoaded = true;
            vm.schedulerDeleteAvgSumAnalyticsLoadedError = false;
            vm.analytics = true;
          }).error(function (err) {
            vm.schedulerDeleteAvgSumAnalyticsLoaded = false;
            vm.schedulerDeleteAvgSumAnalyticsLoadedError = true;
            vm.analytics = false;
            vm.Message = err;
            vm.ErrorMessage = true;
          });
        }
      }).error(function (err) {
        vm.schedulerDeleteAnalyticsLoaded = false;
        vm.schedulerDeleteAnalyticsLoadedError = true;
        vm.analytics = false;
        vm.Message = err;
        vm.ErrorMessage = true;
      });
    };

    vm.resetVariablesAnalytics = function () {
      vm.eventAnalytics = [];
      vm.eventAnalyticsLoaded = false;
      vm.schedulerCreateLabels = [];
      vm.schedulerCreateData = [];
      vm.schedulerDeleteLabels = [];
      vm.schedulerDeleteData = [];
      vm.analytics = false;
      vm.methodLabels = [];
      vm.methodData = [];
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.status = {
      schedulerGraph: true,
      methodsGraph: true
    };

  }
}());
