(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Auth
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Auth', Auth)
    .run(function ($rootScope, Auth, $cookies, imgurl) {
      var rootScope = $rootScope;

      rootScope.staticUrl = imgurl + '/';

      rootScope.$on('$stateChangeStart', function (event, toState) {
        var requireLogin = toState.data.requireLogin;
        if ($cookies.get('leAdminData')) {
          Auth.setIslogged(true);
        }
        if (requireLogin && Auth.getIslogged() === false) {
          event.preventDefault();
        }
      });
    });

  function Auth($cookies, $http, baseurl) {
    var AuthBase = {},
      isLogged = false;

    AuthBase.factoryName = 'Auth';

    AuthBase.getIslogged = function () {
      return isLogged;
    };

    AuthBase.setIslogged = function (state) {
      isLogged = state;
    };

    AuthBase.getAuthData = function () {
      var data;

      if ($cookies.get('leAdminData')) {
        data = $cookies.getObject('leAdminData');
      }

      return data;
    };

    AuthBase.login = function (username, password) {
      var data = JSON.stringify({
        login: username,
        password: password
      });
      return $http.post(baseurl + '/leadmins/session', data, {
        headers: {
          Authorization: 'Basic ',
          'Content-Type': 'application/json'
        }
      });
    };
    return AuthBase;
  }
}());
