<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <h4>{{modalService.message.messageHeader}}</h4>
  <p>{{modalService.message.messageBody}}</p>
  <div class="form-group">
    <label class="control-label" for="filterTypeSelect">Select Filter Type</label>
    <select class="form-control" name="filterTypeSelect" id="filterTypeSelect" ng-model="modalService.selected" ng-options="o.name for o in modalService.message.filters">
    </select>
  </div>
  <div ng-show="modalService.selected">
    <div class="form-group">
      <label class="control-label" for="filterTypeSelect">Select Filter</label>
      <select class="form-control" name="filterSelect" id="filterSelect" ng-model="modalService.filterSelected">
        <option ng-repeat="option in modalService.selected.details.filterlist" value="{{option.value}}">{{option.name}}</option>
      </select>
    </div>
    <div class="form-group" ng-show="modalService.filterSelected">
      <label class="control-label" for="description">Description</label>
      <textarea class="form-control" rows="3" ng-model="modalService.description"></textarea>
    </div>
    <div class="form-group" ng-show="modalService.filterSelected">
      <label class="control-label">Set Bandwidth</label>
      <rzslider rz-slider-model="modalService.priceSlider.value" rz-slider-options="modalService.priceSlider.options"></rzslider>
    </div>
  </div>
</div>
</div>
<div class="modal-footer">
  <div>
    <button class="btn btn-danger" type="button" ng-click="modalService.ok()">Submit</button>
    <button class="btn btn-warning" type="button" ng-click="modalService.cancel()">Cancel</button>
  </div>
</div>
