(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:LoginmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('main')
    .controller('LoginmodalCtrl', LoginmodalCtrl);

  function LoginmodalCtrl($modalInstance, Auth, $cookies) {
    var vm = this;
    vm.ctrlName = 'LoginmodalCtrl';
    vm.loginDetails = {};

    vm.login = function () {
      Auth.login(vm.loginDetails.username, vm.loginDetails.password).success(function (data) {
        $cookies.putObject('leAdminData', data);
        Auth.setIslogged(true);
        $modalInstance.close();
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      console.log('Dismiss was called');
    };
  }
}());
