<div class="container-fluid main-wrap">
  <!-- <div class='navbar navbar-inverse navbar-head navbar-fixed-top' role='navigation'>
    <div class='container-fluid mainNav'>
      <div class='navbar-header'>
        <p class="navbar-brand">
          <img alt="Brand" src="./images/liquidedge-logo-small.png">
        </p>
        <button class='navbar-toggle collapsed' type='button' data-ng-init='navCollapsed = true' data-ng-click='navCollapsed = !navCollapsed'>
          <span class='sr-only'>Toggle Navigation</span>
          <span class='icon-bar'></span>
          <span class='icon-bar'></span>
          <span class='icon-bar'></span>
        </button>
      </div>
      <div class='navbar-collapse collapse navbar-right' collapse='navCollapsed'>
        <ul class='nav navbar-nav'>
          <li data-ui-sref-active='active'><a data-ui-sref="main.home">Home</a></li>
          <li data-ui-sref-active='active'><a data-ui-sref="#">About</a></li>
          <li data-ui-sref-active='active'><a data-ui-sref="#">PayWall</a></li>
          <li data-ui-sref-active='active'><a data-ui-sref="#">Benefits</a></li>
          <li data-ui-sref-active='active'><a data-ui-sref="#">Contact</a></li>
          <li data-ui-sref-active='active'><a href="" ng-click="main.launchLogin()">Login</a></li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="row gradient">
    <!-- <div class='navbar navbar-inverse navbar-main' role='navigation'>
      <div class='container-fluid'>
        <div class='navbar-header'>
          <p class="navbar-brand">
            <img alt="Brand" src="./images/liquidedge-logo-act.png">
          </p>
          <button class='navbar-toggle collapsed' type='button' data-ng-init='navCollapsed = true' data-ng-click='navCollapsed = !navCollapsed'>
            <span class='sr-only'>Toggle Navigation</span>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
          </button>
        </div>
        <div class='navbar-collapse collapse navbar-right' collapse='navCollapsed'>
          <ul class='nav navbar-nav'>
            <li data-ui-sref-active='active'><a data-ui-sref="main.home">Home</a></li>
            <li data-ui-sref-active='active'><a data-ui-sref="#">About</a></li>
            <li data-ui-sref-active='active'><a data-ui-sref="#">PayWall</a></li>
            <li data-ui-sref-active='active'><a data-ui-sref="#">Benefits</a></li>
            <li data-ui-sref-active='active'><a data-ui-sref="#">Contact</a></li>
            <li data-ui-sref-active='active'><a href="" ng-click="main.launchLogin()">Login</a></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div data-ui-view>

    </div>
  </div>
</div>
