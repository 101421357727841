(function () {
  'use strict';

  angular
    .module('addorganisation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addorganisation', {
        url: '/addorganisation',
        templateUrl: 'addorganisation/views/addorganisation.tpl.html',
        controller: 'AddorganisationCtrl',
        controllerAs: 'addorganisation'
      });
  }
}());
