<div id="addap">
  <div ng-show="editap.ErrorMessage">
    <div class="alert alert-danger" role="alert">
      <button ng-click="editap.dismiss()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      <span class="sr-only">Error:</span> {{editap.Message}}
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Edit Accesspoint</h3>
    </div>
    <div class="panel-body">
      <form name="createAccesspoint" class="col-md-8 col-md-offset-2" ng-submit="editap.updateAccesspoint(createAccesspoint.$valid)" role="form" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name" name="name" ng-model="editap.accesspoint.name" placeholder="eg. Name" ng-pattern="string" required>
            </div>
            <div ng-show="createAccesspoint.name.$invalid && editap.submitted">
              <p>Name is required!
                <p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>MAC ADDRESS</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="hwAddr" name="hwAddr" ng-model="editap.accesspoint.hwAddr" placeholder="eg. 01:23:45:67:89:ab" ng-pattern="/^([a-f0-9]{2}\:[a-f0-9]{2}\:[a-f0-9]{2}\:[a-f0-9]{2}\:[a-f0-9]{2}\:[a-f0-9]{2}$)/" required>
            </div>
            <div ng-show="createAccesspoint.hwAddr.$invalid && editap.submitted">
              <p>Mac Address is required!
                <p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ipAddress</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="ipAddress" name="ipAddress" ng-model="editap.accesspoint.ipAddress" placeholder="eg. 10.37.23.126" ng-pattern='/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/' ng-model-options="{ updateOn: 'blur' }" required>
            </div>
            <div ng-show="createAccesspoint.ipAddress.$invalid && editap.submitted">
              <p>IP Address is required!
                <p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">
          <div class="panel-heading clearfix">
            <h4>USERNAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="sshUsername" name="sshUsername" ng-model="editap.accesspoint.sshUsername" placeholder="eg. Accesspoint login username">
            </div>
            <div ng-show="createAccesspoint.sshUsername.$invalid && editap.submitted">
              <p>USERNAME is required!
                <p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">
          <div class="panel-heading clearfix">
            <h4>PASSWORD</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="password" class="form-control" id="sshPassword" name="sshPassword" ng-model="editap.accesspoint.sshPassword" placeholder="eg. Accesspoint login password">
            </div>
            <div ng-show="createAccesspoint.sshPassword.$invalid && editap.submitted">
              <p>PASSWORD is required!
                <p>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-danger">Submit</button>
        <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>
      </form>
    </div>
  </div>
</div>
