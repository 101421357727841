(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addclservice.controller:AddclserviceCtrl
   *
   * @description
   *
   */
  angular
    .module('addclservice')
    .controller('AddclserviceCtrl', AddclserviceCtrl);

  function AddclserviceCtrl($state, $log, $scope, Cloudservices, $stateParams, Cloudservicetype, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'AddclserviceCtrl';
    var venueid = $stateParams.venueid;
    //var orgid = $stateParams.orgid;
    vm.orgid = $stateParams.orgid;
    vm.venue = {};
    vm.venueDetails = {};
    vm.ErrorMessage = false;
    vm.cloudserviceDetails = [];
    vm.cloudservicetypes = [];
    vm.Message = [];
    vm.selectedType = '';
    vm.types = [];
    vm.venueid = $stateParams.venueid;
    vm.ListConfirmMessage = false;
    vm.ListErrorMessage = false;
    vm.ListMessage = [];
    vm.ConfirmMessage = false;

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      vm.cloudservicetypes = data.cloudServiceTypes;
      vm.cloudservicetypesLoaded = true;
    }).error(function (err) {
      vm.cloudservicetypesLoadedError = true;
      vm.cloudservicetypesLoaded = true;
      console.log('Error: ', err);
    });

    vm.getApiKey = function () {
      Infrastructureapi.getVenue(vm.orgid, venueid).then(function (resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageurl;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website = resp.data.venue.website;
        if (resp.data.venue.hasOwnProperty('details')) {
          vm.venueDetails = resp.data.venue.details;
          vm.venueDetails = JSON.parse(vm.venueDetails);
          if (vm.venueDetails.hasOwnProperty('mailchimp')) {
            if (vm.venueDetails.mailchimp.hasOwnProperty('apiKey')) {
              vm.apiKey = vm.venueDetails.mailchimp.apiKey;
              vm.getLists();
            }
            if (vm.venueDetails.mailchimp.hasOwnProperty('listId')) {
              vm.listId = vm.venueDetails.mailchimp.listId;
              vm.newListId = vm.listId;
            }
          }
        } else {
          vm.venueDetails = {};
        }

      }, function (err) {
        $log.info(err);
      });
    };

    vm.getApiKey();

    vm.getLists = function () {

      vm.lists = [];
      Infrastructureapi.getLists(vm.apiKey).then(function (resp) {
        for (var i = 0; i < resp.data.lists.length; i++) {
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name
          });
        }
      }, function (err) {
        $log.info(err);
        vm.lists.length = 0;
      });
    };

    vm.updateApiKey = function () {
      vm.submitted = true;
      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }
      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log(data);
        vm.ConfirmMessage = true;
        vm.Message = 'Api Key Updated';
        vm.getLists();
      }, function (err) {
        vm.Message = true;
        vm.Message = err.message;
        console.log('Error = ' + err);
      });
    };

    vm.updateList = function () {
      if (vm.venueDetails.hasOwnProperty('mailchimp')) {
        vm.venueDetails.mailchimp.listId = vm.newListId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      } else {
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.listId = vm.newListId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }

      Infrastructureapi.updateVenue(vm.orgid, venueid, vm.venue).success(function (data) {
        console.log(data);
        vm.ListConfirmMessage = true;
        vm.ListMessage = 'List Updated';
        vm.getApiKey();
      }, function (err) {
        console.log('Error = ' + err);
      });
    };

    vm.createCloudService = function () {
      var typeid = vm.selectedType;
      var arr = vm.cloudservicetypes;
      var element;
      vm.test = function (id) {
        arr.forEach(function (type) {
          if (parseInt(type.id) === parseInt(id)) {
            element = type.name;
          }
        });
        return element;
      };
      vm.test(typeid);

      var data = '';
      if (element !== 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.cloudserviceDetails.apikey,
          listidentifier: '',
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      } else if (element === 'MailChimp') {
        data = JSON.stringify({
          apikey: vm.apiKey,
          listidentifier: vm.newListId,
          typeid: vm.selectedType,
          venueid: vm.venueid
        });
      }
      Cloudservices.createCloudService($stateParams.venueid, data).success(function () {
        $state.go('admin.cloudservices', {
          'venueid': $stateParams.venueid,
          'orgid': $stateParams.orgid

        });
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid,
        orgid: vm.orgid
      });
    };
    vm.listDismiss = function () {
      vm.ListMessage = {};
      vm.ListConfirmMessage = false;
    };

    vm.listDismissError = function () {
      vm.ListMessage = {};
      vm.ListErrorMessage = false;
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
