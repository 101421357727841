(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperatorpwd.controller:EditoperatorpwdCtrl
   *
   * @description
   *
   */
  angular
    .module('editoperatorpwd')
    .controller('EditoperatorpwdCtrl', EditoperatorpwdCtrl);

  function EditoperatorpwdCtrl($state, Operator, $stateParams) {
    var vm = this;
    vm.ctrlName = 'EditoperatorpwdCtrl';

    vm.ErrorMessage = false;
    vm.operatorDetails = [];
    vm.Message = [];
    vm.orgid = $stateParams.organisationid;
    vm.opid = $stateParams.opid;

    vm.updateOperatorPassword = function () {
      var data = JSON.stringify({
        password: vm.operatorDetails.password,
        orgId: vm.orgid
      });
      Operator.updateOperatorPassword(vm.opid, data).success(function (data) {
        $state.go('admin.operators', {'organisationid': vm.orgid});
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
