<div id="updategns">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h2 class="text-center">Update Guest Network Settings</h2>
    </div>
    <div class="panel-body">
      <form name="updateGns" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Provider</h4>
          </div>
          <div class="panel-body">
            <select id="provider" name="provider" class="form-control" ng-model="updategns.gns.provider" ng-options="provider.id as provider.name for provider in updategns.providers">
              <option value="">Select A Provider</option>
            </select>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Settings</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <textarea class="form-control" id="details" name="details" ng-model="updategns.gns.details" required>
              </textarea>
              <br />
              <div class="text-center text-danger"> {{updategns.error}} <a href="{{updategns.errorLink}}">{{updategns.errorLink}}</a></div>
            </div>
          </div>
        </div>
        <button ng-click="updategns.updategns()" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
