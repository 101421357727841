(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name leimages.controller:UploadmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('leimages')
    .controller('UploadmodalCtrl', UploadmodalCtrl);

  function UploadmodalCtrl($modalInstance, Auth, imageupload, Upload, $timeout) {
    var vm = this,
        token = '';
    vm.ctrlName = 'UploadmodalCtrl';

    vm.venues = [];

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: imageupload + '/images',
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            response.data.venue = vm.venueId;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());
