(function () {
  'use strict';

  angular
    .module('admins')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.admins', {
        url: '/admins',
        templateUrl: 'admins/views/admins.tpl.html',
        controller: 'AdminsCtrl',
        controllerAs: 'admins'
      });
  }
}());
