(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addsplashtype.controller:AddsplashtypeCtrl
   *
   * @description
   *
   */
  angular
    .module('addsplashtype')
    .controller('AddsplashtypeCtrl', AddsplashtypeCtrl);

  function AddsplashtypeCtrl($state, Splashtype, $log, Provider) {
    var vm = this;
    vm.ctrlName = 'AddsplashtypeCtrl';

    vm.splashtype = {};
    vm.submitted = false;

    vm.createSplash = function (valid) {
      vm.submitted = true;
      if (valid) {
        Splashtype.createSplashType(vm.splashtype).then(function () {
          $state.go('admin.splashtype');
        }, function (err) {
          $log.info(err);
        });
      }
    };
  }
}());
