<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Social Login Type</h3>
    </div>
    <div class="panel-body">
      <div class="col-md-8 col-md-offset-2">
        <div ng-show="addslt.ErrorMessage">
          <div class="alert alert-danger" role="alert">
            <button ng-click="addslt.dismiss()" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <span class="sr-only">Error:</span>
            {{addslt.Message}}
          </div>
        </div>
        <form name="createSLT" role="form" class="col-md-8 col-md-offset-2" novalidate>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Name</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Name" ng-model="addslt.slt.name" required>
                <div ng-show="form.name.$invalid">
                  <p>Name is required!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>IMAGE</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-4 col-md-offset-4">
                    <img ng-src="{{staticUrl}}{{addslt.slt.imageuri}}" class="img-responsive"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-danger" ng-click="addslt.selectImage()" tooltip-placement="left" tooltip="Select Image" name="selectimage">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-danger" ng-click="addslt.uploadImage()" tooltip-placement="left" tooltip="Upload Image" name="uploadimage">
                      <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="help-block" ng-show="!addslt.slt.imageuri">
                <p>Image is required.</p>
              </div>
            </div>
          </div>
          <button ng-click="addslt.createSLT()" class="btn btn-danger btn-md">Add Social Login</button>
        </div>
      </form>
    </div>
  </div>
</div>
