<div ng-show="organisations.ErrorMessage">
  <div class="alert alert-danger" role="alert">
    <button ng-click="organisations.dismiss()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span>
    {{organisations.Message}}
  </div>
</div>
<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Organisations
        <button class="btn btn-md btn-md btn-danger pull-right" data-ui-sref="admin.addorganisation" tooltip-placement="left" tooltip="Add Organisation">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-3 col-md-offset-9">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search Organisation" ng-model="orgSearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <thead>
        <tr>
          <th>Organisation</th>
          <th>Contact Email</th>
          <th>Contact Phone</th>
          <th>Premium</th>
          <th></th>
        </tr>
      </thead>
      <tbody dir-paginate="organisation in organisations.organisations | filter:orgSearch | itemsPerPage: 10">
        <tr>
          <td>
            <h4>{{organisation.organisationtitle}}</h4>
          </td>
          <td>
            <h4>{{organisation.details.email}}</h4>
          </td>
          <td>
            <h4>{{organisation.details.phone}}</h4>
          </td>
          <td>
            <h4 ng-show="organisation.premium" class="text-primary">
              <i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
            </h4>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-md btn-danger btn-md" ng-click="organisations.deleteEvent(organisation.id)" tooltip-placement="left" tooltip="Delete Organisation">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <a class="btn btn-md btn-danger btn-md" ui-sref="admin.editorganisation({organisationid: organisation.id})" tooltip-placement="left" tooltip="Edit Organisation">
                <i class="fa fa-pencil-square-o"></i>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan=5>
            <a class="btn btn-md btn-danger" ui-sref="admin.operators({organisationid: organisation.id})" tooltip-placement="right" tooltip="Organisation Operators">
              <i class="fa fa-users"></i>
            </a>
            <a class="btn btn-md btn-danger" ui-sref="admin.venues({orgid: organisation.id})" tooltip-placement="right" tooltip="Organisation Venues">
              <i class="fa fa-building"></i>
            </a>
            <a class="btn btn-md btn-danger" ui-sref="admin.orgInfrastructures({orgid: organisation.id})" tooltip-placement="right" tooltip="Organisation Infrastructures">
              <i class="fa fa-wifi"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
  </div>
</div>
