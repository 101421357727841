<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center"> Edit Cloud Service</h3>
    </div>
    <div class="panel-body">
      <div class="col-md-8 col-md-offset-2">
        <div ng-show="editclservice.ErrorMessage">
          <div class="alert alert-danger" role="alert" style="width: 40%;">
            <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <span class="sr-only">Error:</span>
            {{editclservice.Message}}
          </div>
        </div>
        <form name="form" ng-submit="editclservice.udpateCloudService(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>
          <div>
            <div class="panel panel-default panel-form">
              <div class="panel-heading clearfix">
                <h4>Cloud Service Type</h4>
              </div>
              <div class="panel-body">
                <div class="form-group">
                  <select class="form-control"
                  name="type" ng-model="editclservice.cloudserviceDetails.typeid"
                  ng-options="type.id as type.name for type in editclservice.cloudservicetypes"
                  ng-change="editclservice.setType(editclservice.cloudserviceDetails.typeid)" required>
                  </select>
                  <div ng-show="form.type.$invalid">
                    <p> Type is required!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div ng-repeat="type in editclservice.cloudservicetypes" ng-show="type.id == editclservice.cloudserviceDetails.typeid" ng-if="type.name !== 'MailChimp'">
              <div class="panel panel-default panel-form">
                <div class="panel panel-default panel-form">
                  <div class="panel-heading clearfix">
                    <h4>API key</h4>
                  </div>
                  <div class="panel-body">
                    <div class="form-group">
                      <input type="text" class="form-control" id="apikey" name="apikey" placeholder="apikey" ng-model="editclservice.cloudserviceDetails.apikey" class="col-md-8 col-md-offset-2" required>
                      <div ng-show="form.apikey.$invalid">
                        <p>API key is required!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ng-repeat="type in editclservice.cloudservicetypes"
            ng-show="type.id == editclservice.cloudserviceDetails.typeid"
            ng-if="type.name === 'MailChimp'">
              <div class="panel panel-default panel-form">
                <div class="panel-body">
                  <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>
                    <div class="panel panel-default panel-form ">
                      <div class="panel-heading clearfix">
                        <h4>API KEY</h4>
                      </div>
                      <div class="panel-body">
                        <div class="form-group"
                        ng-class="{ 'has-error': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">
                          <input type="text" class="form-control"
                          id="apiKey" name="apiKey"
                          ng-model="editclservice.cloudserviceDetails.apikey"
                          placeholder="eg. xxxxxxxxxx-xyz" required>
                        </div>
                        <div class="help-block" ng-messages="mailchimpSettings.apiKey.$error"
                        ng-if="editvenue.submitted">
                          <p ng-message="required">api key is required.</p>
                        </div>
                        <button type="button" ng-click="editclservice.updateApiKey(!mailchimpSettings.$invalid)"
                        class="btn btn-danger right">Update Api Key</button>
                        <div>
                          </br>
                        </div>
                        <div ng-show="editclservice.ConfirmMessage">
                          <div class="alert alert-success" role="alert" style="width: 40%;">
                            <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{editclservice.Message}}
                          </div>
                        </div>
                        <div ng-show="editclservice.ErrorMessage">
                          <div class="alert alert-danger" role="alert" style="width: 40%;">
                            <button ng-click="editclservice.dismissError()" type="button"
                            class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{editclservice.Message}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="panel-body">
                  <form name="mailchimpSettings" novalidate>
                    <div class="panel panel-default panel-form">
                      <div class="panel-heading clearfix">
                        <h4>Lists </h4>
                      </div>
                      <div class="panel-body">
                        <p>Current List ID</p>
                        <br/>
                        <p>Choose new List from the dropdown menu or create a new list</p>
                        <div class="row">
                          <div class="col-xs-9">
                            <select class="form-control"
                            ng-model="editclservice.cloudserviceDetails.listidentifier">
                              <option ng-repeat="list in editclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>
                            </select>
                           </div>
                          <div class="col-xs-3">
                            <button ng-click="editclservice.updateList()" type="button" class="btn btn-danger right">Change List</button>
                          </div>
                        </div>
                        <div>
                          </br>
                        </div>
                        <div class="row">
                          <div class="col-xs-12">
                            <button ng-click="editclservice.createNewList()" type="button" class="btn btn-info right">Create New List</button>
                          </div>
                        </div>
                        <div>
                          </br>
                        </div>
                        <div ng-show="editclservice.ListConfirmMessage">
                          <div class="alert alert-success" role="alert" style="width: 40%;">
                            <button ng-click="editclservice.listDismiss()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{editclservice.ListMessage}}
                          </div>
                        </div>
                        <div ng-show="editclservice.ListErrorMessage">
                          <div class="alert alert-danger" role="alert" style="width: 40%;">
                            <button ng-click="editclservice.listDismissError()" type="button" class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only">Alert:</span>
                            {{editclservice.ListMessage}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <button class="btn btn-danger btn-md">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
