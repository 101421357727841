(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Infrastructureapi
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Infrastructureapi', Infrastructureapi);

  function Infrastructureapi(Auth, $http, baseurl, operatorbaseurl, imageupload) {
    var InfrastructureapiBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    // Venues

    InfrastructureapiBase.createVenue = function (orgid, data) {
      return $http.post(baseurl + '/venues/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateVenue = function (orgid, id, data) {
      return $http.put(baseurl + '/venues/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getVenue = function (orgid, id) {
      return $http.get(baseurl + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteVenue = function (orgid, id) {
      return $http.delete(baseurl + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getVenues = function (orgid) {
      return $http.get(baseurl + '/venues/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // AccessPoints

    InfrastructureapiBase.createAp = function (venueId, orgid, data) {
      data.orgid = orgid;
      return $http.post(baseurl + '/apoints/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateAp = function (id, venueId, data) {
      var orgid = data.orgid;
      return $http.put(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getAp = function (id, venueId, orgid) {
      return $http.get(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteAp = function (id, venueId, orgid) {
      return $http.delete(baseurl + '/apoints/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getAps = function (venueId, orgid) {
      return $http.get(baseurl + '/apoints/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    InfrastructureapiBase.getApByZone = function (venueId, zoneId, orgid) {
      return $http.get(baseurl + '/apoints/venues/' + venueId + '/zones/' + zoneId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateApsZone = function (data) {
      return $http.post(baseurl + '/apoints/addtozone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    InfrastructureapiBase.removeApsZone = function (data) {
      return $http.post(baseurl + '/apoints/removefromzone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Zones

    InfrastructureapiBase.createZone = function (venueId, data) {
      var orgid = data.orgid;
      return $http.post(baseurl + '/zones/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateZone = function (id, venueId, data) {
      //var token = Auth.getAuthData().token;
      var orgid = data.orgid;
      return $http.put(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getZone = function (id, venueId, orgid) {
      return $http.get(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteZone = function (id, venueId, orgid) {
      return $http.delete(baseurl + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getZones = function (venueId, orgid) {
      return $http.get(baseurl + '/zones/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Images

    InfrastructureapiBase.updateImage = function (id, orgid, data) {
      return $http.post(imageupload + '/images/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getImage = function (id, orgid) {
      return $http.get(imageupload + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteImage = function (id, orgid) {
      return $http.delete(imageupload + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getImages = function (orgid) {
      return $http.get(imageupload + '/images', {
        headers: {
          'x-access-token': token
        }
      });
    };

    // infrastructures
    InfrastructureapiBase.createInfrastructure = function (venueid, data) {
      return $http.post(baseurl + '/infrastructure/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateInfrastructure = function (id, venueid, data) {
      return $http.put(baseurl + '/infrastructure/' + id + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getInfrastructure = function (id, venueid) {
      return $http.get(baseurl + '/infrastructure/' + id + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getInfrastructures = function (venueid) {
      return $http.get(baseurl + '/infrastructure/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // OrgInfrastructure

    InfrastructureapiBase.createOrgInfrastructure = function (orgid, data) {
      return $http.post(baseurl + '/infrastructure/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.updateOrgInfrastructure = function (id, orgid, data) {
      return $http.put(baseurl + '/infrastructure/' + id + '/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getOrgInfrastructure = function (id, orgid) {
      return $http.get(baseurl + '/infrastructure/' + id + '/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getOrgInfrastructures = function (orgid) {
      return $http.get(baseurl + '/infrastructure/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.deleteOrgInfrastructure = function (id, orgid) {
      return $http.delete(baseurl + '/infrastructure/'+id+'/organisation/'+orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Mailchimp

    InfrastructureapiBase.createList = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(operatorbaseurl + '/mailchimp/lists/', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getLists = function (apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(operatorbaseurl + '/mailchimp/lists/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    InfrastructureapiBase.getListById = function (listid, apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(operatorbaseurl + '/mailchimp/lists/' + listid + '/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return InfrastructureapiBase;
  }
}());
