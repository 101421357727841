(function () {
  'use strict';

  angular
    .module('addInfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addInfrastructure', {
        url: '/add-infrastructure/:venueid/:orgid',
        templateUrl: 'add-infrastructure/views/add-infrastructure.tpl.html',
        controller: 'AddInfrastructureCtrl',
        controllerAs: 'addInfrastructure'
      });
  }
}());
