(function () {
  'use strict';

  angular
    .module('addadmin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addadmin', {
        url: '/addadmin',
        templateUrl: 'addadmin/views/addadmin.tpl.html',
        controller: 'AddadminCtrl',
        controllerAs: 'addadmin'
      });
  }
}());
