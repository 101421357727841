(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudservicetypesCtrl
   *
   * @description
   *
   */
  angular
    .module('cloudservicetypes')
    .controller('CloudservicetypesCtrl', CloudservicetypesCtrl);

  function CloudservicetypesCtrl($state, Cloudservicetype, $modal) {
    var vm = this;
    vm.ctrlName = 'CloudservicetypesCtrl';

    vm.cloudservicetypesLoaded = false;
    vm.cloudservicetypesLoadedError = false;
    vm.cloudservicetypes = [];
    vm.ErrorMessage = false;
    vm.Message = [];

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      vm.cloudservicetypes = data.cloudServiceTypes;
      vm.cloudservicetypesLoaded = true;
    }).error(function (err) {
      vm.cloudservicetypesLoadedError = true;
      vm.cloudservicetypesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllTypes() {
      Cloudservicetype.getCloudServiceTypes().success(function (data) {
        vm.cloudservicetypes = data.cloudServiceTypes;
        vm.cloudservicetypesLoaded = true;
      }).error(function (err) {
        vm.cloudservicetypesLoadedError = true;
        vm.cloudservicetypesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'cloudservicetypes/views/cloudtypedeletemodal.tpl.html',
        controller: 'CloudtypedeletemodalCtrl as cloudtypedeleteModal'
      });

      modalInstance.result.then(function () {

        Cloudservicetype.deleteCloudServiceType(id).success(function () {
          reloadAllTypes();
        }).error(function (err) {
          console.log('Error: ', err);
          if (err.message.code === '23503') {
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Cloud Service Type!';
          } else {
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
