(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:baseurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('baseurl', 'https://api.liquidedge.eu/admin-api/admin-web/v1');

}());
