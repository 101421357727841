(function () {
  'use strict';

  angular
    .module('addcloudservicetype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addcloudservicetype', {
        url: '/addcloudservicetype',
        templateUrl: 'addcloudservicetype/views/addcloudservicetype.tpl.html',
        controller: 'AddcloudservicetypeCtrl',
        controllerAs: 'addcloudservicetype'
      });
  }
}());
