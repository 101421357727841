(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editprovider.controller:EditproviderCtrl
   *
   * @description
   *
   */
  angular
    .module('editprovider')
    .controller('EditproviderCtrl', EditproviderCtrl);

  function EditproviderCtrl($state, $stateParams, Provider) {
    var vm = this;
    vm.ctrlName = 'EditproviderCtrl';

    vm.ErrorMessage = false;
    vm.providerDetails = [];
    vm.Message = [];

    Provider.getProviderById($stateParams.providerid).success(function (data) {
      vm.providerDetails = data.provider;
    }).error(function (err) {
      vm.ErrorMessage = true;
      vm.Message = err.message;
    });

    vm.updateProvider = function () {
      var data = JSON.stringify({
        name: vm.providerDetails.name,
        details: vm.providerDetails.details
      });
      Provider.updateProvider(vm.providerDetails.id, data).success(function (data) {
        $state.go('admin.providers');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
