<div id="editnetworktype">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit Network Type</h3>
    </div>
    <div class="panel-body">
      <form name="editnetworkType" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="name" ng-model="editnetworktype.networkType.name" placeholder="eg. default" required>
            </div>
            <div ng-messages="editnetworkType.name.$error" ng-if="editnetworktype.submitted">
              <p ng-message="required" class="text-danger">Name is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 2">
          <div class="panel-heading clearfix">
            <h4>USER GROUP</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="name" ng-model="editnetworktype.networkType.details.usergroup" placeholder="eg. Guest" required>
            </div>
            <div ng-messages="editnetworkType.name.$error" ng-if="editnetworktype.submitted">
              <p ng-message="required" class="text-danger">Name is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 1">
          <div class="panel-heading clearfix">
            <h4>VLAN</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="number" class="form-control" id="details" name="vlan" ng-model="editnetworktype.networkType.vlanid" placeholder="eg. 700" required>
            </div>
            <div ng-messages="editnetworkType.vlan.$error" ng-if="editnetworktype.submitted">
              <p ng-message="required" class="text-danger">Vlan is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editnetworktype.provider == 1">
          <div class="panel-heading clearfix">
            <h4>NETWORK OPTIMISATION STRATEGIES</h4>
          </div>
          <div class="panel-heading">
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-search"></i>
                  </div>
                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <ul class="">
                <li class="checkbox" ng-repeat="strategy in editnetworktype.ntstrategies">
                  <label>
                    <input type="checkbox" data-checklist-model="editnetworktype.selectedStrategies" checklist-value="strategy.id">
                    {{strategy.name}}
                    -
                    {{strategy.description}}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button ng-click="editnetworktype.updateNetworkType(!editnetworkType.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
