(function () {
  'use strict';

  angular
    .module('editslt')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editslt', {
        url: '/editslt/:sltid',
        templateUrl: 'editslt/views/editslt.tpl.html',
        controller: 'EditsltCtrl',
        controllerAs: 'editslt'
      });
  }
}());
