(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addadmin.controller:AddadminCtrl
   *
   * @description
   *
   */
  angular
    .module('addadmin')
    .controller('AddadminCtrl', AddadminCtrl);

  function AddadminCtrl($state, Leadmin) {
    var vm = this;
    vm.ctrlName = 'AddadminCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];

    vm.createAdmin = function () {
      var data = JSON.stringify({
        login: vm.adminDetails.login,
        password: vm.adminDetails.password,
        name: vm.adminDetails.name,
        details: {}
      });
      Leadmin.createAdmin(data).success(function () {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
