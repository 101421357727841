(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Leimages
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Leimages', Leimages);

  function Leimages(Auth, baseurl, $http) {
    var LeimagesBase = {};

    LeimagesBase.getImages = function () {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + '/images/', {
        headers: {
          'x-access-token': token
        }
      });
    };

    LeimagesBase.deleteImage = function (id) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + '/images/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return LeimagesBase;
  }
}());
