(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editadminpwd.controller:EditadminpwdCtrl
   *
   * @description
   *
   */
  angular
    .module('editadminpwd')
    .controller('EditadminpwdCtrl', EditadminpwdCtrl);

  function EditadminpwdCtrl($state, Leadmin, $stateParams) {
    var vm = this;
    vm.ctrlName = 'EditadminpwdCtrl';

    vm.ErrorMessage = false;
    vm.adminDetails = [];
    vm.Message = [];
    vm.adminid = $stateParams.adminid;

    vm.updateAdminPassword = function () {
      var data = JSON.stringify({
        password: vm.adminDetails.password,
        adminId: vm.adminid
      });
      Leadmin.updateAdminPassword(vm.adminid, data).success(function (data) {
        $state.go('admin.admins');
      }).error(function (err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
