(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editzone.controller:EditzoneCtrl
   *
   * @description
   *
   */
  angular
    .module('editzone')
    .controller('EditzoneCtrl', EditzoneCtrl);

  function EditzoneCtrl(Infrastructureapi, $stateParams, $state, $log) {
    var vm = this;
    vm.ctrlName = 'EditzoneCtrl';

    var zoneid = $stateParams.zoneid;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedaccesspoints = [];
    vm.originalaccesspoints = [];
    vm.provider = $stateParams.provider;

    Infrastructureapi.getAps($stateParams.venueid, $stateParams.orgid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.removeAp = function(key){
      vm.selectedaccesspoints.splice(key, 1);
    };

    Infrastructureapi.getZone(zoneid, venueid, orgid).then(function(resp){
      vm.zone = resp.data.zone;
      vm.selectedaccesspoints = vm.zone.accesspoints;
      vm.zone.accesspoints.forEach(function(element) {
        vm.originalaccesspoints.push(element.id);
      });
      $log.info(vm.zone);
    }, function(err){
      $log.info(err);
    });

    vm.updateZone = function(){
      vm.zone.orgid = orgid;
      var addArray = [];
      vm.selectedaccesspoints.forEach(function(element) {
        addArray.push(element.id);
      });
      console.log(addArray);
      vm.apsToRemove = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: vm.originalaccesspoints
      };
      vm.apsToAdd = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: addArray
      };
      if (vm.apsToRemove.accessPoints.length < 1) {
        Infrastructureapi.updateApsZone(vm.apsToAdd).then(function(resp){
          $log.info(resp);
          Infrastructureapi.updateZone(zoneid, venueid, vm.zone).then(function(resp){
            $log.info(resp);
            $state.go('admin.managenetwork', {
              venueid: venueid,
              orgid: orgid
            });
          }, function(err){
            $log.info(err);
          });
        }, function(err){
          $log.info(err);
        });
      } else {
        Infrastructureapi.removeApsZone(vm.apsToRemove).then(function(resp){
          $log.info(resp);
          Infrastructureapi.updateApsZone(vm.apsToAdd).then(function(resp){
            $log.info(resp);
            Infrastructureapi.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }, function(err){
            $log.info(err);
          });
        }, function(err){
          $log.info(err);
        });
      }
    };
  }
}());
