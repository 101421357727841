(function () {
  'use strict';

  /* @ngdoc object
   * @name addInfrastructure
   * @description
   *
   */
  angular
    .module('addInfrastructure', [
      'ui.router'
    ]);
}());
