(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addgns.controller:AddgnsCtrl
   *
   * @description
   *
   */
  angular
    .module('addgns')
    .controller('AddgnsCtrl', AddgnsCtrl);

  function AddgnsCtrl(Provider, GnsApi,$stateParams, $log, $state) {
    var vm = this;
    vm.ctrlName = 'AddgnsCtrl';
    vm.providers = [];

    vm.getProviders = function(){
      Provider.getAllProviders().then(function(res){
        vm.providers = res.data.providers;
      }, function(err){
        console.log(err);
      });
    };

    vm.getProviders();

    vm.creategns = function() {
      GnsApi.createGNSType(vm.gns).success(function(){
        $state.go('admin.guestnetworksettings');
      });
    };
  }
}());
