(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Analytics
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Analytics', Analytics);

  function Analytics(baseurl, $http, Auth) {
    var AnalyticsBase = {},
        token = '';

    AnalyticsBase.someValue = 'Analytics';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    AnalyticsBase.getAllOrganisations = function () {
      return $http.get(baseurl + '/organisations', {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAllVenues = function (orgId) {
      return $http.get(baseurl + '/performance/venues/' + orgId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAllEvents = function (orgId, venueId) {
      return $http.get(baseurl + '/performance/venues/' + orgId + '/events/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getEventAnalytics = function (data) {
      return $http.post(baseurl + '/performance/listbyevent', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    AnalyticsBase.getMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/minmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getCreateMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/createminmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getDeleteMinMaxAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/deleteminmax/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/avgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getCreateAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/createavgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    AnalyticsBase.getDeleteAvgSumAnalytics = function (eventId) {
      return $http.get(baseurl + '/performance/deleteavgsum/event/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return AnalyticsBase;
  }
}());
