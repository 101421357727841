(function () {
  'use strict';

  angular
    .module('editprovider')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editprovider', {
        url: '/editprovider/:providerid',
        templateUrl: 'editprovider/views/editprovider.tpl.html',
        controller: 'EditproviderCtrl',
        controllerAs: 'editprovider'
      });
  }
}());
