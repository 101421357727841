(function () {
  'use strict';

  angular
    .module('manageimages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.manageimages', {
        url: '/manageimages',
        templateUrl: 'manageimages/views/manageimages.tpl.html',
        controller: 'ManageimagesCtrl',
        controllerAs: 'manageimages'
      });
  }
}());
