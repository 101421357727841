<div id="networktype">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Create Network Type</h3>
    </div>
    <div class="panel-body">
      <form name="createnetworktype" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.name" placeholder="eg. Guest" required>
            </div>
            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">
              <p ng-message="required" class="text-danger">Name is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="networktype.provider == 2">
          <div class="panel-heading clearfix">
            <h4>USER GROUP</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.details.usergroup" placeholder="eg. default">
            </div>
            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">
              <p ng-message="required" class="text-danger">Name is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" required ng-show="networktype.provider == 1">
          <div class="panel-heading clearfix">
            <h4>VLAN</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="number" class="form-control" id="details" name="vlan" ng-model="networktype.networkType.vlanId" placeholder="eg. 700">
            </div>
            <div ng-messages="createnetworktype.vlan.$error" ng-if="networktype.submitted">
              <p ng-message="required" class="text-danger">Vlan is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="networktype.provider == 1">
          <div class="panel-heading clearfix">
            <h4>NETWORK OPTIMISATION STRATEGIES</h4>
          </div>
          <div class="panel-heading">
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-search"></i>
                  </div>
                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <ul class="">
                <li class="checkbox" ng-repeat="st in networktype.ntstrategies | filter:stSearch">
                  <label>
                    <input type="checkbox" data-checklist-model="networktype.selectedStrategies" checklist-value="st.id">
                    {{st.name}} - {{st.description}}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button ng-click="networktype.createNetworkType(!createnetworktype.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
