(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:NetworkType
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('NetworkType', NetworkType);

  function NetworkType(baseurl, $http, Auth) {
    var NetworkTypeBase = {},
        token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    NetworkTypeBase.createNetworkType = function (venueid, data) {
      return $http.post(baseurl + '/networktype/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.getNetworkTypes = function (venueid) {
      return $http.get(baseurl + '/networktype/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.getNetworkType = function (typeid, venueid) {
      return $http.get(baseurl + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.updateNetworkType = function (typeid, venueid, data) {
      return $http.put(baseurl + '/networktype/' + typeid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    NetworkTypeBase.deleteNetworkType = function (typeid, venueid) {
      return $http.delete(baseurl + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    return NetworkTypeBase;
  }
}());
