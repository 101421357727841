(function () {
  'use strict';

  angular
    .module('editoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editoperator', {
        url: '/editoperator/:organisationid/operator/:opid',
        templateUrl: 'editoperator/views/editoperator.tpl.html',
        controller: 'EditoperatorCtrl',
        controllerAs: 'editoperator'
      });
  }
}());
