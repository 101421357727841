<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Splashpage Types
        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.addsplashtype" tooltip-placement="left" tooltip="Add new splashpage type">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation">
            <a href="" data-ui-sref="admin.categories" aria-controls="home" role="tab">Strategies</a>
          </li>
          <li role="presentation" class="active">
            <a href="" data-ui-sref="admin.splashtype" aria-controls="profile" role="tab">Splash Types</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.providers" aria-controls="profile" role="tab">Providers</a>
          </li>
          <li role="presentation">
            <a href="" data-ui-sref="admin.guestnetworksettings" aria-controls="profile" role="tab">Guest Networks</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search" ng-model="splashSearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="splash in splashtype.splashtypes | filter:splashSearch">
          <td>
            <h4>Name:</h4>
            <p>{{splash.name}}</p>
          </td>
          <td>
            <h4>Description:</h4>
            <p>{{splash.description}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="splashtype.deleteSplashType(splash.id)" tooltip-placement="left" tooltip="Delete">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ui-sref="admin.editsplashtype({typeid: splash.id})" tooltip-placement="left" tooltip="Edit">
                <i class="fa fa-pencil-square-o"></i>
              </button>
              <a class="btn btn-danger btn-md" ng-click="splashtype.setProviders(splash.id)" tooltip-placement="left" tooltip="Select Providers">
                <i class="fa fa-list"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
