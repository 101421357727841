<div id="addsplashtype">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Create Splashpage Type</h3>
    </div>
    <div class="panel-body">
      <form name="createSplash" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createSplash.name.$invalid && addsplashtype.submitted }">
              <input type="text" class="form-control" id="name" name="name" ng-model="addsplashtype.splashtype.name" placeholder="eg. Video Ad" required>
            </div>
            <div class="help-block" ng-messages="createSplash.name.$error" ng-if="addsplashtype.submitted">
              <p ng-message="required">name is required.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error': createSplash.description.$invalid && addsplashtype.submitted }">
              <input type="text" class="form-control" id="description" name="description" ng-model="addsplashtype.splashtype.description" placeholder="eg. Some Description" required>
            </div>
            <div class="help-block" ng-messages="createSplash.description.$error" ng-if="addsplashtype.submitted">
              <p ng-message="required">description is required.</p>
            </div>
          </div>
        </div>
        <button ng-click="addsplashtype.createSplash(!createSplash.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
