(function () {
  'use strict';

  angular
    .module('updategns')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.updategns', {
        url: '/updategns/:gnsid',
        templateUrl: 'updategns/views/updategns.tpl.html',
        controller: 'UpdategnsCtrl',
        controllerAs: 'updategns'
      });
  }
}());
