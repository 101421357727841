(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addInfrastructure.controller:AddInfrastructureCtrl
   *
   * @description
   *
   */
  angular
    .module('addInfrastructure')
    .controller('AddInfrastructureCtrl', AddInfrastructureCtrl);

  function AddInfrastructureCtrl(Infrastructureapi, Provider, $stateParams, $log, $state) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;
    vm.providerSelected = false;

    vm.providerTypes = [];

    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if(provider === 1){
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };

    Provider.getAllProviders().then(function(res){
      vm.providerTypes = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.create = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      thisInfrastructure.details = '{}';
      vm.submitted = true;
      if (isValid) {
        Infrastructureapi.createInfrastructure($stateParams.venueid, thisInfrastructure).then(function (res) {
          $log.info(res);
          $state.go('admin.managenetwork', {
            'orgid': $stateParams.orgid,
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          $log.console.error(err);
        });

      }
    };
  }
}());
