(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sociallogins.controller:SocialloginsCtrl
   *
   * @description
   *
   */
  angular
    .module('sociallogins')
    .controller('SocialloginsCtrl', SocialloginsCtrl);

  function SocialloginsCtrl($state, Slt, $modal) {
    var vm = this;
    vm.ctrlName = 'SocialloginsCtrl';

    vm.sltsLoaded = false;
    vm.sltsLoadedError = false;
    vm.slts = [];

    Slt.getAllSLTs().success(function (data) {
      vm.slts = data.slts;
      console.log(vm.slts);
      vm.sltsLoaded = true;
    }).error(function (err) {
      vm.sltsLoadedError = true;
      vm.sltsLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAllSLTs() {
      Slt.getAllSLTs().success(function (data) {
        vm.slts = data.slts;
        vm.sltsLoaded = true;
      }).error(function (err) {
        vm.sltsLoadedError = true;
        vm.sltsLoaded = true;
      console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'sociallogins/views/sltdeletemodal.tpl.html',
        controller: 'SltdeletemodalCtrl as sltdeleteModal'
      });

      modalInstance.result.then(function () {
        Slt.deleteSLT(id).success(function () {
          reloadAllSLTs();
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.setProviders = function (id) {

      Slt.getProviders(id).success(function (result) {
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/setprovidersmodal.tpl.html',
          controller: 'SetProviderModalCtrl as setprovidersmodal',
          resolve: {
            providers: function(){ return result.providers;}
          }
        });
        modalInstance.result.then(function (list) {
          var data = {
            providers: list
          };
          Slt.setProviders(id, data).success(function (result) {
            console.log(result);
          });
        }, function (err) {
          console.log(err);
        });
      });



    };
  }
}());
