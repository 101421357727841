(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name guestnetworksettings.controller:GuestnetworksettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('guestnetworksettings')
    .controller('GuestnetworksettingsCtrl', GuestnetworksettingsCtrl);

  function GuestnetworksettingsCtrl(GnsApi, $modal) {
    var vm = this;
    vm.ctrlName = 'GuestnetworksettingsCtrl';
    vm.gnsList = [];
    vm.getGNS = function(){
      GnsApi.getGNS().success(function(data){
        vm.gnsList = data.gns;
      });
    };
    vm.getGNS();

    vm.deletegns = function(id){
      var modalInstance = $modal.open({
        templateUrl: 'guestnetworksettings/views/deletemodal.tpl.html',
        controller: 'DeletemodalCtrl as deleteModal'
      });

      modalInstance.result.then(function () {
        GnsApi.deleteGNS(id).success(function(){
          vm.getGNS();
        });
      });
    };

  }
}());
