(function () {
  'use strict';

  angular
    .module('cloudservicetypes')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.cloudservicetypes', {
        url: '/cloudservicetypes',
        templateUrl: 'cloudservicetypes/views/cloudservicetypes.tpl.html',
        controller: 'CloudservicetypesCtrl',
        controllerAs: 'cloudservicetypes'
      });
  }
}());
