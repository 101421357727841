<div class="selectimage">
  <div class="modal-header">
    <img class="modal-img" src="./images/login/modal_image.png">
    <p class="input-group">
      <div class="input-group-addon"><i class="fa fa-search"></i></div>
      <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">
    </p>
  </div>
  <div class="modal-body imageList">
    <p ng-repeat="image in selectimage.imageList | filter:imageSearch">
      <label>
        <input type="radio" ng-model="selectimage.selectedImage" value="{{image}}">
        <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />
      </label>
    </p>
  </div>
  <div class="modal-footer">
    <div>
      <button class="btn btn-danger" type="button" ng-click="selectimage.ok()">Done</button>
      <button class="btn btn-warning" type="button" ng-click="selectimage.cancel()">Cancel</button>
    </div>
  </div>
</div>
