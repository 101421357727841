(function () {
  'use strict';

  /* @ngdoc object
   * @name editap
   * @description
   *
   */
  angular
    .module('editap', [
      'ui.router'
    ]);
}());
