<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Edit Provider</h3>
    </div>
    <div class="panel-body">
      <form name="form" ng-submit="editprovider.updateProvider(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Provider Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name" name="organisationTitle" placeholder="Provider Name" ng-model="editprovider.providerDetails.name" required>
              <div ng-show="form.name.$invalid">
                <p>Provider name is required!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Details</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" placeholder="Details" ng-model="editprovider.providerDetails.details" required>
              <div ng-show="form.details.$invalid">
                <p>Details are required!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button type="submit" class="btn btn-danger btn-md">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
