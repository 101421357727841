(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservices.factory:Cloudservices
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Cloudservices', Cloudservices);

  function Cloudservices(baseurl, $http, Auth) {
    var CloudservicesBase = {},
      token = '';
    CloudservicesBase.someValue = 'Cloudservices';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicesBase.getCloudServices = function (venueid) {
      return $http.get(baseurl + '/venues/' + venueid + '/cloudservices', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.createCloudService = function (venueid, data) {
      return $http.post(baseurl + '/venues/' + venueid + '/cloudservices/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.getCloudServiceById = function (venueid, cloudservicesid) {
      return $http.get(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.udpateCloudService = function (venueid, cloudservicesid, data) {
      return $http.put(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.deleteCloudService = function (venueid, cloudservicesid) {
      return $http.delete(baseurl + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicesBase;
  }
}());
