(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name liquidedgeLeadmin.directive:sameAs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="liquidedgeLeadmin">
       <file name="index.html">
        <same-as></same-as>
       </file>
     </example>
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .directive('sameAs', sameAs);

  function sameAs() {
    return {
      restrict: 'EA',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        ngModel.$parsers.unshift(function (viewValue, $scope) {
          var noMatch = viewValue !== scope.form.password.$viewValue;
          ngModel.$setValidity('noMatch', !noMatch);
          return !noMatch;
        });
      }
    };
  }
}());
