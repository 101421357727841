(function () {
  'use strict';

  angular
    .module('addsplashtype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addsplashtype', {
        url: '/addsplashtype',
        templateUrl: 'addsplashtype/views/addsplashtype.tpl.html',
        controller: 'AddsplashtypeCtrl',
        controllerAs: 'addsplashtype'
      });
  }
}());
