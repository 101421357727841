(function () {
  'use strict';

  angular
    .module('orgInfrastructures')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.orgInfrastructures', {
        url: '/org-infrastructures/:orgid',
        templateUrl: 'org-infrastructures/views/org-infrastructures.tpl.html',
        controller: 'OrgInfrastructuresCtrl',
        controllerAs: 'orgInfrastructures'
      });
  }
}());
