(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addcategory.controller:AddcategoryCtrl
   *
   * @description
   *
   */
  angular
    .module('addcategory')
    .controller('AddcategoryCtrl', AddcategoryCtrl);

  function AddcategoryCtrl($state, Category, Netfilter, $modal, Provider) {
    var vm = this;
    vm.ctrlName = 'AddcategoryCtrl';
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.selectedType;
    vm.selectedProvider;
    vm.types = [];
    vm.xirrusFilters = [];
    vm.premium = false;
    vm.onSave = false;
    vm.providers = [];

    Netfilter.getlistOfXirrusFilters().success(function(data) {
      vm.xirrusFilters = data.xirrusFilters;
      console.log(vm.xirrusFilters);
    }).error(function(err) {
      console.log(err);
    });

    Netfilter.getlistOfCategoryTypes().success(function(data) {
      vm.types = data.categoryTypes;
      console.log('categoryTypes', data.categoryTypes);
    }).error(function(err) {
      console.log(err);
    });

    var getCategory = function(id) {
      Category.getCategoryById(id).then(function(res) {
        vm.newCategory = res.data.category;
      }, function(err) {
        console.log(err);
      });
    };

    Provider.getAllProviders().success(function (data) {
      vm.providers = data.providers;
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.createCategory = function() {
      var data = JSON.stringify({
        name: vm.categoryDetails.name,
        provider: vm.selectedProvider,
        description: vm.categoryDetails.description,
        premium: vm.premium,
        categoryTypeId: vm.selectedType
      });
      Category.createCategory(data).success(function(result) {
        console.log(result);
        vm.onSave = true;
        getCategory(result.id);
      }).error(function(err) {
        vm.ErrorMessage = true;
        vm.Message = err.message;
        console.log(err);
      });
    };

    var getFilterList = function(id) {
      Netfilter.getFilterbyCategory(id).then(function(res) {
        console.log(res);
        vm.networkFilters = res.data.networkFilters;
      }, function(err) {
        console.log(err);
      });
    };

    vm.deleteFilter = function(id) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Filter, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function() {
        Netfilter.deleteNetFilter(id).then(function(res) {
          console.log(res);
          getFilterList(vm.newCategory.id);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.addFilter = function() {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Add Filters',
        category: vm.newCategory.id,
        filters: vm.xirrusFilters
      };

      var modalInstance = $modal.open({
        templateUrl: 'filtercreatemodal/views/filtercreatemodal.tpl.html',
        controller: 'FiltercreatemodalCtrl as modalService',
        resolve: {
          message: function() {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function(data) {
        console.log(data);
        Netfilter.createNetFilter(data).then(function(res) {
          console.log(res);
          getFilterList(data.categoryId);
        }, function(err) {
          console.log(err);
        });
      });
    };

    vm.done = function(){
      $state.go('admin.categories');
    };

    vm.dismiss = function() {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
  }
}());
