<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Infrastructures
        <button class="btn btn-danger btn-md pull-right" ng-click="orgInfrastructures.createInfrastructure()" tooltip-placement="left" tooltip="Add Infrastructure">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search orgInfrastructures" ng-model="orgInfrastructuresearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="orgInfrastructures.limit" ng-options="limit for limit in orgInfrastructures.limits" ng-change="orgInfrastructures.setLimit(orgInfrastructures.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="infra in orgInfrastructures.infrastructures | filter:orgInfrastructuresearch | limitTo:orgInfrastructures.limit" ng-hide="orgInfrastructures.orgInfrastructureselected.selected && orgInfrastructures.orgInfrastructureselected.infra !== infra.id">
          <td>
            <h4>
              Controller:
            </h4>
            <h5>
              {{infra.controlleraddress}}
            </h5>
          </td>
          <td>
            <h4>
              User:
            </h4>
            <h5>
              {{infra.username}}
            </h5>
          </td>
          <td>
            <h4>
              Limit:
            </h4>
            <h5>
              {{infra.ssidlimit}}
            </h5>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.deleteInfrastructure(infra.id)" tooltip-placement="left" tooltip="Delete Infrastructure">
                <span class="fa fa-trash-o" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.editInfrastructure(infra.id)" tooltip-placement="left" tooltip="Edit Infrastructure">
                <span class="fa fa-pencil-square-o" aria-hidden="true"></span>
              </button>
              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.manageNetwork(infra.id)" tooltip-placement="left" tooltip="Manage Controller">
                <i class="fa fa-wifi" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
