(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenue.controller:EditvenueCtrl
   *
   * @description
   *
   */
  angular
    .module('editvenue')
    .controller('EditvenueCtrl', EditvenueCtrl);

  function EditvenueCtrl(Infrastructureapi, $modal, $state, $stateParams, $log) {
    var vm = this;
    vm.ctrlName = 'EditvenueCtrl';

    vm.venue = {};
    vm.submitted = false;
    var orgid = $stateParams.orgid;
    var venueid = $stateParams.venueid;

    vm.status = {
      isopen: false
    };

    vm.selectImage = function() {

      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        var thisImage = angular.fromJson(image);
        vm.venue.imageUrl = thisImage.imageuri;
        vm.venue.imageId = thisImage.id;
      }, function() {
        console.log('canceled');
      });
    };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageuri = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.updateVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        console.log(vm.venue);
        Infrastructureapi.updateVenue(orgid, venueid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues', {
            orgid: orgid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };

    function getVenue(venueid, orgid) {
      Infrastructureapi.getVenue(orgid, venueid).then(function(resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageuri = resp.data.venue.imageuri;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website =  resp.data.venue.website;
        vm.venue.details = resp.data.venue.details;
      }, function(err) {
        $log.info(err);
      });
    }

    getVenue(venueid, orgid);
  }
}());
