<div id="editzone">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit Zone</h3>
    </div>
    <div class="panel-body">
      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ZONE NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="editzone.zone.name" placeholder="eg. Some zone" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editzone.provider == 2">
          <div class="panel-heading clearfix">
            <h4>WLAN GROUP</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.wlangroup" placeholder="eg. zone covers conference centre main suite">
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ACCESSPOINTS</h4>
          </div>
          <div class="panel-body" ng-if="editzone.selectedaccesspoints.length > 0">
            <h5>Selected</h5>
            <ul id="double">
                <li class="iplisting" dir-paginate="ap in editzone.selectedaccesspoints | filter:apSearch | itemsPerPage: 15 track by $index">
                  <label>
                    <button class="x" ng-click="editzone.removeAp($index)">&#10006;</button> {{ap.ipaddress}}
                  </label>
                </li>
            </ul>
            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
          </div>
          <div class="panel-body">
            <h5>Available</h5>
            <ul id="double">
                <li class="iplisting" dir-paginate="ap in editzone.accesspoints | orderBy: 'ipaddress'| filter:apSearch | itemsPerPage: 15">
                  <label>
                    <input type="checkbox"  checklist-model="editzone.selectedaccesspoints" checklist-value="ap"> {{ap.ipaddress}}
                  </label>
                </li>
            </ul>
            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
          </div>
        </div>
        <button ng-click="editzone.updateZone()" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
