<div id="infra">
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <h3 class="text-center">Edit Infrastructure</h3>
    </div>
    <div class="panel-body">
      <form name="createinfrastructure" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Provider</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <select class="form-control" name="provider"
              ng-options="option.id as option.name for option in editinfrastructure.providerTypes"
              ng-model="editinfrastructure.infrastructure.provider"
              ng-change="editinfrastructure.setProvider(editinfrastructure.infrastructure.provider)">
            </select>
            </div>
            <div ng-messages="createinfrastructure.provider.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">Provider is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">
          <div class="panel-heading clearfix">
            <h4>Controller Address</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="editinfrastructure.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>
            </div>
            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">Controller Address is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">
          <div class="panel-heading clearfix">
            <h4 ng-if="editinfrastructure.infrastructure.provider === 3">Authentication</h4>
            <h4 ng-if="editinfrastructure.infrastructure.provider === 2">Site ID</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="editinfrastructure.infrastructure.authentication" placeholder="">
            </div>
            <div ng-messages="createinfrastructure.authentication.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">Authentication is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">
          <div class="panel-heading clearfix">
            <h4>Username</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="username" name="username" ng-model="editinfrastructure.infrastructure.username" placeholder="">
            </div>
            <div ng-messages="createinfrastructure.username.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">username is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">
          <div class="panel-heading clearfix">
            <h4>Password</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="password" class="form-control" id="password" name="password" ng-model="editinfrastructure.infrastructure.password" placeholder="">
            </div>
            <div ng-messages="createinfrastructure.password.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">Controller Address is required</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>SSID Limit</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="editinfrastructure.infrastructure.ssidlimit" required>
            </div>
            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="editinfrastructure.submitted">
              <p ng-message="required" class="text-danger">SSID Limit is required</p>
            </div>
          </div>
        </div>
        <button ng-click="editinfrastructure.edit(!createinfrastructure.$invalid)" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>
