<div class="modal-header">
  <h3 class="text-center">Edit Infrastructure</h3>
</div>
<div class="modal-body">
  <form name="createinfrastructure" novalidate>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <!-- <h4>Provider</h4> -->
        <select class="form-control" name="provider" ng-model="orginfraeditModal.infrastructure.provider" ng-options="option.id as option.name for option in orginfraeditModal.providers" ng-change="orginfraeditModal.setProvider(orginfraeditModal.infrastructure.provider)">
          <option value="" disabled selected>- Please Select A Provider -</option>
        </select>
        <div ng-messages="createinfrastructure.provider.$error" ng-if="orginfraeditModal.control.submitted">
          <p ng-message="required" class="text-danger">Provider is required</p>
        </div>
      </div>
      <div class="panel-body">
        <div ng-show="orginfraeditModal.control.provider">
          <!-- Controller Address -->
          <div ng-hide="orginfraeditModal.matchProvider('xirrus', orginfraeditModal.selectedProvider)">
            <h5>Controller Address</h5>
            <div class="form-group">
              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="orginfraeditModal.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>
            </div>
            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="orginfraeditModal.control.submitted">
              <p ng-message="required" class="text-danger">Controller Address is required</p>
            </div>
          </div>

          <!-- AUTHENTICATION -->
          <div ng-hide="orginfraeditModal.matchProvider('xirrus', orginfraeditModal.selectedProvider) || orginfraeditModal.matchProvider('unifi', orginfraeditModal.selectedProvider)">
            <h5 ng-if="orginfraeditModal.matchProvider('cisco', orginfraeditModal.selectedProvider)">Authentication</h5>
            <h5 ng-if="orginfraeditModal.matchProvider('ruckus', orginfraeditModal.selectedProvider)">Authentication</h5>
            <div class="form-group">
              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="orginfraeditModal.infrastructure.authentication" placeholder="eg. Auth Key">
            </div>
          </div>

          <!-- Login Credencials -->
          <div ng-hide="">
            <h5>Login credentials</h5>
            <div class="form-group">
              <input type="text" class="form-control" id="username" name="username" ng-model="orginfraeditModal.infrastructure.username" placeholder="Username">
            </div>
            <div ng-messages="createinfrastructure.username.$error" ng-if="orginfraeditModal.control.submitted">
              <p ng-message="required" class="text-danger">username is required</p>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" id="password" name="password" ng-model="orginfraeditModal.infrastructure.password" placeholder="Password">
            </div>
            <div ng-messages="createinfrastructure.password.$error" ng-if="orginfraeditModal.control.submitted">
              <p ng-message="required" class="text-danger">Controller Address is required</p>
            </div>
          </div>

          <!-- SSID Limit -->
          <div>
            <h5>SSID Limit</h5>
            <div class="form-group">
              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="orginfraeditModal.infrastructure.ssidlimit" placeholder="0" required>
            </div>
            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="orginfraeditModal.control.submitted">
              <p ng-message="required" class="text-danger">SSID Limit is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="orginfraeditModal.ok()">Submit</button>
  <button class="btn btn-warning" type="button" ng-click="orginfraeditModal.cancel()">Cancel</button>
</div>
