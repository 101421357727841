<div class="panel panel-default admin">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Performance Analytics</h3>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-body">
      <label for="org" class="control-label pull-left">Organisation</label>
      <select class="form-control" ng-model="org" ng-options="org as org.organisationtitle for org in analytics.organisations" ng-change="analytics.getAllVenues(org.id)">
        <option value="">Select an Organisation</option>
      </select>
    </div>
  </div>
  <div class="panel panel-default" ng-show="analytics.venuesLoaded && !analytics.venuesLoadedError">
    <div class="panel-body">
      <label for="venue" class="control-label pull-left">Venue</label>
      <select class="form-control" ng-model="venue" ng-options="venue as venue.venuetitle for venue in analytics.venues" ng-change="analytics.getAllEvents(venue.id)">
        <option value="">Select a Venue</option>
      </select>
    </div>
  </div>
  <div class="panel panel-default" ng-show="analytics.eventsLoaded && !analytics.eventsLoadedError">
    <div class="panel-body">
      <label for="event" class="control-label pull-left">Event</label>
      <select class="form-control" ng-model="event" ng-options="event as event.name for event in analytics.events" ng-change="analytics.getAnalyticsByEvent(event.id)">
        <option value="">Select an Event</option>
      </select>
    </div>
  </div>
  <div ng-show="analytics.ErrorMessage">
    <div class="alert alert-danger" role="alert">
      <button ng-click="analytics.dismiss()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      <span class="sr-only">Error:</span> {{analytics.Message}}
    </div>
  </div>
  <div class="panel panel-default" ng-show="analytics.analytics && !analytics.eventAnalyticsLoadedError && !analytics.schedulerCreateAnalyticsLoadedError && !analytics.schedulerCreateAvgSumAnalyticsLoadedError && !analytics.schedulerDeleteAnalyticsLoadedError && !analytics.schedulerDeleteAvgSumAnalyticsLoadedError">
    <div class="panel-body">
      <accordion close-others="false">
        <div class="col-xs-12 col-ms-6 col-sm-6">
          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.schedulerGraph">
            <accordion-heading>
              <h3 class="panel-title">
                Scheduler Create Events (m)
                <i class="fa fa-circle-o pull-right"></i>
              </h3>
            </accordion-heading>
            <canvas id="bar" class="chart chart-bar" chart-data="analytics.schedulerCreateData" chart-labels="analytics.schedulerCreateLabels"></canvas>
          </accordion-group>
        </div>
        <div class="col-xs-12 col-ms-6 col-sm-6">
          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.schedulerGraph">
            <accordion-heading>
              <h3 class="panel-title">
                Scheduler Delete Events (m)
                <i class="fa fa-circle-o pull-right"></i>
              </h3>
            </accordion-heading>
            <canvas id="bar" class="chart chart-bar" chart-data="analytics.schedulerDeleteData" chart-labels="analytics.schedulerDeleteLabels"></canvas>
          </accordion-group>
        </div>
        <div class="col-xs-12 col-ms-6 col-sm-6">
          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.methodsGraph">
            <accordion-heading>
              <h3 class="panel-title">
                API Methods Average times (ms)
                <i class="fa fa-circle-o pull-right"></i>
              </h3>
            </accordion-heading>
            <canvas id="bar" class="chart chart-bar" chart-data="analytics.methodData" chart-labels="analytics.methodLabels"></canvas>
          </accordion-group>
        </div>
        <div class="col-xs-12 col-ms-6 col-sm-6">
          <accordion-group panel-class="panel-heading panel-heading-analytics" is-open="analytics.status.methodsGraph">
            <accordion-heading>
              <h3 class="panel-title">
                Total Number of API Methods calls
                <i class="fa fa-circle-o pull-right"></i>
              </h3>
            </accordion-heading>
            <canvas id="bar" class="chart chart-bar" chart-data="analytics.methodNumberData" chart-labels="analytics.methodLabels"></canvas>
          </accordion-group>
        </div>
      </accordion>
    </div>
  </div>
</div>
