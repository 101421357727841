(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name filtercreatemodal.controller:FiltercreatemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('filtercreatemodal')
    .controller('FiltercreatemodalCtrl', FiltercreatemodalCtrl);

  function FiltercreatemodalCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'FiltercreatemodalCtrl';

    vm.message = message;

    vm.priceSlider = {
      value: 0,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };

      vm.ok = function() {
          $modalInstance.close({'website':vm.filterSelected, 'bandwidth': vm.priceSlider.value.toString(), 'categoryId': vm.message.category, 'details':{'description': vm.description}});
      };

      vm.cancel = function() {
          $modalInstance.dismiss();
      };


  }
}());
