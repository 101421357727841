<div class="row row-align">
  <h2>Images
    <button class="btn btn-sm btn-danger pull-right mgn10r" ng-click="manageimages.uploadImage()">New Image</button>
  </h2>
</div>
<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-search"></i></div>
            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="manageimages.limit" ng-options="limit for limit in manageimages.limits" ng-change="manageimages.setLimit(manageimages.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body">
    <table class="table table-responsive">
      <tbody>
        <tr ng-repeat="image in manageimages.imageList | filter:imageSearch | limitTo:manageimages.limit">
          <td class="evntIMG">
            <div>
              <img ng-src="{{staticUrl}}{{image.imageuri}}" class="img-responsive" />
            </div>
          </td>
          <td>
            <p>{{image.details.description}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-sm" ng-click="manageimages.deleteImage(image)">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span> Delete</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
