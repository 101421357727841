(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.factory:Roles
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .factory('Roles', Roles);

  function Roles(baseurl, $http, Auth) {
    var RolesBase = {},
        token = '';

    RolesBase.someValue = 'Roles';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    RolesBase.getAllOrganisationRoles = function () {
      return $http.get(baseurl + '/roles', {
        headers: {
          'x-access-token': token
        }
      });
    };

    RolesBase.createOrganisationRole = function (data) {
      return $http.post(baseurl + '/roles/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    RolesBase.getOrganisationRoleById = function (id) {
      return $http.get(baseurl + '/roles/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    RolesBase.updateOrganisationRole = function (id, data) {
      return $http.put(baseurl + '/roles/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    RolesBase.deleteOrganisationRole = function (id) {
      return $http.delete(baseurl + '/roles/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return RolesBase;
  }
}());
