(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperator.controller:EditoperatorCtrl
   *
   * @description
   *
   */
  angular
    .module('editoperator')
    .controller('EditoperatorCtrl', EditoperatorCtrl);

  function EditoperatorCtrl($state, Operator, $stateParams, Roles, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'EditoperatorCtrl';

    vm.ErrorMessage = false;
    vm.operator = {};
    vm.orgid = $stateParams.organisationid;
    vm.opid = $stateParams.opid;
    vm.roles = [];

    Operator.getOperatorById(vm.orgid, vm.opid).success(function(data) {
      vm.operator = data.operator;
    }).error(function(err) {
      console.log(err);
    });

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
    }).error(function(err) {
      console.log(err);
    });

    Infrastructureapi.getVenues(vm.orgid).then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }
    };

    vm.updateOperator = function(valid) {
      vm.submitted = true;
      var thisOperator = vm.operator;
      thisOperator.roleId = vm.operator.role;
      thisOperator.orgId = vm.orgid;
      if (valid) {
        Operator.updateOperatorById(vm.opid, thisOperator).success(function() {
          $state.go('admin.operators', {
            'organisationid': vm.orgid
          });
        }).error(function(err) {
          console.log(err);
        });
      }
    };

  }
}());
