<div class="newitem">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Organisation</h3>
    </div>
    <div class="panel-body">
      <div ng-show="addorganisation.ErrorMessage">
        <div class="alert alert-danger" role="alert">
          <button ng-click="addorganisation.dismiss()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{addorganisation.Message}}
        </div>
      </div>
      <form name="form" ng-submit="form.$valid && addorganisation.createOrganisation()" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Organisation Title</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="organisationTitle" name="organisationTitle" placeholder="Organisation's Title" ng-model="addorganisation.organisationDetails.organisationTitle" required>
              <div ng-show="form.organisationTitle.$invalid">
                <p>Organisation Title is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Contact Email</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="email" class="form-control" id="email" name="email" placeholder="Contact Email" ng-model="addorganisation.organisationDetails.email" required>
              <div ng-show="form.email.$invalid">
                <p>Email is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Contact Phone</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="phone" name="phone" placeholder="Contact Number" ng-model="addorganisation.organisationDetails.phone" required>
              <div ng-show="form.phone.$invalid">
                <p>Phone number is required!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row tabrow">
          <label for="premium" class="control-label">
          <input type="checkbox" checked="false" id="premium" name="premium" ng-model="addorganisation.premium">
          Premium</label>
        </div>
        <div class="row">
          <button type="submit" class="btn btn-danger">Submit</button>
        </div>
      </form>
    </div>
  </div>
