(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:VenuesCtrl
   *
   * @description
   *
   */
  angular
    .module('venues')
    .controller('VenuesCtrl', VenuesCtrl);

  function VenuesCtrl(Infrastructureapi, $log, $q, $stateParams, Organisation, $state, $modal) {
    var vm = this;
    var orgid = $stateParams.orgid;

    vm.venues = [];
    vm.zones = [];
    vm.aps = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.venueSelected = {
      selected: false,
      venue: 0,
      zone: ''
    };

    vm.getZones = function (id) {
      if (vm.venueSelected.venue === id) {
        vm.venueSelected.selected = false;
        vm.venueSelected.venue = 0;
        vm.venueSelected.zone = '';
        vm.zones =[];
        vm.aps = [];
      } else {
        vm.zones =[];
        vm.aps = [];
        Infrastructureapi.getZones(id, orgid).then(function (res) {
          vm.venueSelected.selected = true;
          vm.venueSelected.venue = id;
          vm.zones = res.data.zones;
          console.log(res);
        }, function(err){
          console.log(err);
        });
      }
    };

    vm.getAccessPoints = function (zone) {
      vm.aps = [];
      vm.venueSelected.zone = zone.name;
      Infrastructureapi.getApByZone(vm.venueSelected.venue, zone.id, orgid).then(function (res) {
        vm.aps = res.data.apoints;
      }, function(err){
        console.log(err);
      });
    };

    vm.setLimit = function(limit) {
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.createZone = function(id) {
      $state.go('admin.addzone', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.createAccesspoint = function(id) {
      $state.go('admin.addap', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.manageNetwork = function(id) {
      $state.go('admin.managenetwork', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.newvenue = function() {
      $state.go('admin.newvenue', {
        orgid: orgid
      });
    };

    vm.editvenue = function(id) {
      $state.go('admin.editvenue', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.externalServices = function(id) {
      console.log('venueid: ' + id);
      $state.go('admin.cloudservices', {
        venueid: id,
        orgid: orgid
      });
    };

    function getVenues(orgid) {
      Infrastructureapi.getVenues(orgid).success(function(data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
        $log.info(vm.venues);
      }).error(function() {
        $log.info('get venues error');
      });
    }

    function getOrg(orgid) {
      Organisation.getOrganisationById(orgid).then(function(res) {
        vm.organisation = res.data.org;
        //console.log(vm.organisation);
      }, function(err) {
        console.log(err);
      });
    }

    vm.deleteVenue = function(id) {

      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/deletevenuemodal.tpl.html',
        controller: 'DeletevenuemodalCtrl as deleteVenueModal'
      });

      newModalInstance.result.then(function() {
        Infrastructureapi.deleteVenue(orgid, id).success(function() {
          getVenues(orgid);
        }).error(function(err) {
          var failedModalInstance = $modal.open({
            templateUrl: 'venues/views/deletefailedmodal.tpl.html',
            controller: 'DeletevenuemodalCtrl as deleteVenueModal'
          });

          failedModalInstance.result.then(function() {
            $log.error(err);
          });
        });
      });
    };

    getVenues(orgid);
    getOrg(orgid);
  }
}());
