(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:operatorbaseurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('operatorbaseurl', 'https://api.liquidedge.eu/operator-api/operator-web/v1');
}());
