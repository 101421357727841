(function () {
  'use strict';

  angular
    .module('editoperatorpwd')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editoperatorpwd', {
        url: '/editoperatorpwd/:organisationid/operator/:opid',
        templateUrl: 'editoperatorpwd/views/editoperatorpwd.tpl.html',
        controller: 'EditoperatorpwdCtrl',
        controllerAs: 'editoperatorpwd'
      });
  }
}());
