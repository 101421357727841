(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name liquidedgeLeadmin.constant:imgurl
   *
   * @description
   *
   */
  angular
    .module('liquidedgeLeadmin')
    .constant('imgurl', 'https://api.liquidedge.eu/admin-api');
}());
