(function () {
  'use strict';

  angular
    .module('editcategory')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editcategory', {
        url: '/editcategory/:categoryid',
        templateUrl: 'editcategory/views/editcategory.tpl.html',
        controller: 'EditcategoryCtrl',
        controllerAs: 'editcategory'
      });
  }
}());
