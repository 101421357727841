(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admin.controller:AdminCtrl
   *
   * @description
   *
   */
  angular
    .module('admin')
    .controller('AdminCtrl', AdminCtrl);

  function AdminCtrl($rootScope, Auth, $cookies, $state) {
    var vm = this;
    var authData = null;
    vm.ctrlName = 'AdminCtrl';

    $rootScope.bodyClass = 'admin';

    authData = Auth.getAuthData();
    //console.log(authData);

    vm.profileInfo = {
      userName: authData.admin.login,
      userImg: './images/batman.jpg'
    };

    vm.logout = function () {
      $cookies.remove('leData');
      Auth.setIslogged(false);
      $state.go('main.home');
    };
  }
}());
