(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addoperator.controller:AddoperatorCtrl
   *
   * @description
   *
   */
  angular
    .module('addoperator')
    .controller('AddoperatorCtrl', AddoperatorCtrl);

  function AddoperatorCtrl($state, Operator, Roles, $stateParams, Infrastructureapi) {
    var vm = this;
    vm.ctrlName = 'AddoperatorCtrl';

    vm.ErrorMessage = false;
    vm.operator = {};
    vm.submitted = false;
    vm.Message = '';
    vm.orgid = $stateParams.organisationid;
    vm.roles = [];

    Roles.getAllOrganisationRoles().success(function(data) {
      vm.roles = data.organisationRoles;
    }).error(function(err) {
      console.log(err);
    });

    Infrastructureapi.getVenues(vm.orgid).then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }
    };

    vm.createOrganisationOperator = function(valid) {
      vm.submitted = true;
      if (valid) {
        Operator.createOrganisationOperator(vm.orgid, vm.operator).success(function(data) {
          console.log(data);
          $state.go('admin.operators', {
            'organisationid': vm.orgid
          });
        }).error(function(err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }
    };


    vm.dismiss = function() {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
